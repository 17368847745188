import path from 'path';
import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import {
  Button,
  DragDropContext,
  Draggable,
  Droppable,
  FileDrop,
  TextField,
} from '@/components';
import { FileDropProps } from '@/components/file-drop/FileDrop';
import { FeatherIconName } from '@/components/icon';
import { RemoveFieldButton } from '@/components/remove-field-button';
import { RemoveFieldButtonProps } from '@/components/remove-field-button/RemoveFieldButton';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Mobile, Tablet } from '@/utils/responsive';
import type { FileUpload } from '@/hooks/uploads/useUploads';
import { EvidenceField } from '../../types';
import { DragIcon } from '../drag-icon';
import { BulkUploadModal } from '../bulk-upload-modal';

export type UploadEvidenceFormProps = StyleProps & {
  isDisabled?: boolean;
  fields: EvidenceField[];
  onAddField: () => void;
  // onAddFields: (values?: string[]) => void;
  onRemoveField: (index: number) => void;
  isFieldUploading: (index: number) => boolean;
  onAddFile: (index: number, file: File) => void;
  onRemoveFile: (index: number) => void;
  onUpdateSource: (index: number, source: string) => void;
  onUpdateFieldOrder: (fromIndex: number, toIndex: number) => void;
};

const ROOT = makeRootClassName('UploadEvidenceForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type EvidenceFieldProps = EvidenceField &
  Pick<
    UploadEvidenceFormProps,
    | 'isDisabled'
    | 'onAddFile'
    | 'onRemoveFile'
    | 'onUpdateSource'
    | 'onUpdateFieldOrder'
  > & {
    index: number;
    isUploading: boolean;
    showRemoveFieldButton?: boolean;
    onRemoveField: RemoveFieldButtonProps['onPress'];
  };

function EvidenceFieldComponent(props: EvidenceFieldProps): ReactElement {
  const handleDropFile: FileDropProps['onDrop'] = (files) => {
    const input = Array.from(files ?? []);

    if (input.length > 0) {
      const [first] = input;
      props.onAddFile(props.index, first);
    }
  };

  const fileDropProps = {
    file: props.upload?.file,
    isUploading: props.isUploading,
    isDisabled: props.isDisabled,
    onDrop: handleDropFile,
    onSelectFile: handleDropFile,
    onRemoveFile: () => props.onRemoveFile(props.index),
  };

  return (
    <div className={clsx(el`evidence-field`)}>
      <TextField
        aria-label="Source or URL"
        placeholder="Source or URL"
        value={props.source}
        isDisabled={props.isDisabled}
        onChange={(value) => props.onUpdateSource(props.index, value)}
        className={el`field`}
      />

      <div className={el`file-drop-wrapper`}>
        <Mobile>
          <FileDrop {...fileDropProps} hideDropCTA />
        </Mobile>
        <Tablet>
          <FileDrop {...fileDropProps} />
        </Tablet>
      </div>

      {props.showRemoveFieldButton && (
        <RemoveFieldButton
          onPress={props.onRemoveField}
          isDisabled={props.isDisabled}
          className={el`remove-field-button`}
        />
      )}
    </div>
  );
}

function UploadEvidenceForm(props: UploadEvidenceFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.fields?.length > 1;
  const uploads = p.fields
    .map((field) => field.upload)
    .filter((upload): upload is FileUpload => !!upload);

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          props.onUpdateFieldOrder(source.index, destination.index);
        }
      }}
    >
      <Droppable droppableId="drop-losses" isDropDisabled={p.isDisabled}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx(ROOT, { 'has-multiple': hasMultiple }, p.className)}
          >
            {p.fields?.map((field, index) => {
              return (
                <Draggable
                  key={field.key}
                  draggableId={field.key}
                  index={index}
                >
                  {(provided, dragSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={el`evidence-row`}
                      style={{
                        ...provided.draggableProps.style,
                        transform: dragSnapshot.isDragging
                          ? provided.draggableProps.style?.transform?.replace(
                              /\(.+,/,
                              '(0,'
                            )
                          : provided.draggableProps.style?.transform,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        {hasMultiple && <DragIcon />}
                      </div>
                      <EvidenceFieldComponent
                        {...p}
                        {...field}
                        key={field.key}
                        isDisabled={p.isDisabled}
                        onRemoveField={() => p.onRemoveField(index)}
                        showRemoveFieldButton={index !== 0}
                        isUploading={p.isFieldUploading(index)}
                        index={index}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div className={el`actions-wrapper`}>
              <Button
                variant="text-white"
                startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
                onPress={p.onAddField}
                className={el`add-button`}
                size="custom"
                isDisabled={p.isDisabled}
              >
                Add Evidence
              </Button>
              <BulkUploadModal
                onAddField={p.onAddField}
                onAddFile={p.onAddFile}
                onRemoveFile={p.onRemoveFile}
                isDisabled={p.isDisabled}
                onUpdateFieldOrder={p.onUpdateFieldOrder}
                uploads={uploads}
                onUpdateSource={p.onUpdateSource}
                isUploading={p.isDisabled ?? false}
              />
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default UploadEvidenceForm;
