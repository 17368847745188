import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { TextField, RemoveFieldButton } from '@/components';
import type { CompromiseIndicatorField } from '../../types';

type CompromiseIndicatorFieldProps = StyleProps & {
  /* Whether the component is disabled */
  isDisabled?: boolean;

  /* The value of the compromise indicator item */
  field: CompromiseIndicatorField;

  /* Whether to show the remove button */
  showRemoveFieldButton?: boolean;

  /* Callback for removing the field */
  onRemoveField: () => void;

  /* Callback for updating the field value */
  onUpdateFieldValue: (value: string) => void;
};

const ROOT = makeRootClassName('MultiCompromiseIndicatorForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export const CompromiseIndicatorFields = (
  props: CompromiseIndicatorFieldProps
): ReactElement => {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={el`indicator-row`}>
      {props.showRemoveFieldButton && (
        <RemoveFieldButton
          isDisabled={p.isDisabled}
          onPress={() => p.onRemoveField?.()}
          className={el`remove-field-button`}
        />
      )}
      <TextField
        value={props.field.value}
        isDisabled={p.isDisabled}
        placeholder="Enter IP address"
        aria-label="Enter IP address"
        className={el`value`}
        onChange={props.onUpdateFieldValue}
      />
    </div>
  );
};
