/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext } from 'react';
import type { AuthContextValue } from './provider';

const AuthContext = createContext<AuthContextValue>({
  state: {
    isPostAuthModalOpen: false,
    isAuthModalOpen: false,
    redirectTo: '/',
  },
  actions: {
    setIsAuthModalOpen: () => {},
    setRedirectTo: () => {},
    showPostAuthModal: () => {},
    closePostAuthModal: () => {},
  },
});

export default AuthContext;
