import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, ButtonProps } from '../button';
import { FeatherIconName } from '../icon';

export type BackButtonProps = StyleProps & ButtonProps;

const ROOT = makeRootClassName('BackButton');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function BackButton(props: BackButtonProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <Button
      {...props}
      startFeatherIcon={FeatherIconName.CHEVRON_LEFT}
      variant="text-white"
    >
      {p.children}
    </Button>
  );
}

export default BackButton;
