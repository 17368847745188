import { useLogoutMutation } from '@/generated/graphql';
import { clearAuthStorage } from '@/utils/auth';

export type UseLogout = {
  logout: () => Promise<boolean>;
};

export const useLogout = (): UseLogout => {
  // old logout mutation
  const [logoutMutation] = useLogoutMutation();

  const logout = async () => {
    // @TODO remove eventually. For now, just in case they're logged into pre-Auth0
    // authenticated profiles, use old logout mutation. Catch errors since this will
    // fail for auth0 users.
    try {
      // should clear refresh token
      const { data, errors } = await logoutMutation({});

      if (errors) {
        throw errors;
      }
    } catch (error) {
      // no op
    }

    // removeAuthenticatedDataFromCache();
    clearAuthStorage();
    // do not push to next router as it is a redirect and the bundle does not
    // work anyways
    window.location.assign('/api/auth/logout');
    return true;
  };

  return { logout };
};
