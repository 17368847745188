import { createHeadlessEditor } from '@lexical/headless';
import { $generateHtmlFromNodes } from '@lexical/html';
import { SerializedEditorState } from 'lexical';
import { editorTheme } from '@/components/editor/Editor';
import { getSupportedNodes } from '../components/editor/Editor';
import { SUPPORTED_BLOCKS } from '../components/editor/types';

export const parseNodesToHtml = (
  nodes: string | SerializedEditorState
): string => {
  try {
    const editor = createHeadlessEditor({
      namespace: 'headless_editor',
      theme: editorTheme,
      /**
       * @see https://github.com/facebook/lexical/discussions/2053#discussioncomment-2680473
       * */
      nodes: getSupportedNodes(SUPPORTED_BLOCKS),
      onError: (err) => console.error(err),
    });

    let parsedHtml = '';
    const editorState = editor.parseEditorState(nodes);
    editor.setEditorState(editorState);

    editor.update(() => {
      parsedHtml = $generateHtmlFromNodes(editor, null);
    });

    return parsedHtml;
  } catch (error) {
    return '';
  }
};
