import { ApolloError } from '@apollo/client';
import React, { ReactElement, useState } from 'react';
import GlobalErrorContext from './context';

type GlobalErrorProviderProps = {
  children: React.ReactNode;
};

type GlobalErrorContextState = {
  title?: string;
  description?: string;
  error?: ApolloError;
  showContactUsLink: boolean;
  onTryAgain?: () => void;
};

export type ErrorData = {
  title?: string;
  description?: string;
  error: ApolloError;
  showContactUsLink?: boolean;
  onTryAgain?: () => void;
};

type GlobalErrorContextActions = {
  sendError: (errorData: ErrorData) => void;
};

export type GlobalErrorContextValue = {
  state: GlobalErrorContextState;
  actions: GlobalErrorContextActions;
};

function GlobalErrorProvider({
  children,
}: GlobalErrorProviderProps): ReactElement {
  const [title, setTitle] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [error, setError] = useState<ApolloError>();
  const [showContactUsLink, setShowContactUsLink] = useState(false);
  const [onTryAgain, setOnTryAgain] = useState<() => void | undefined>();

  const sendError = (errorData: ErrorData): void => {
    setTitle(errorData.title);
    setDescription(errorData.description);
    setError(errorData.error);
    setShowContactUsLink(errorData.showContactUsLink ?? false);
    setOnTryAgain(errorData.onTryAgain);
  };

  const value = {
    state: { title, description, error, showContactUsLink, onTryAgain },
    actions: { sendError },
  };

  return (
    <GlobalErrorContext.Provider value={value}>
      {children}
    </GlobalErrorContext.Provider>
  );
}

export default GlobalErrorProvider;
