import clsx from 'clsx';
import { ReactElement, useEffect } from 'react';
import { Select, SelectOption as Option, Text } from '@/components';
import { GLOSSARY_ROUTE } from '@/types/routes';
import {
  getDisplayStringForScamCategoryWithType,
  ORDERED_SCAM_CATEGORIES,
  ScamCategory,
} from '@/types/scam-categories';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ScamCategorySelectProps = StyleProps & {
  /**
   * The category the category select should be set to
   * If null, the placeholder will be shown
   */
  value: ScamCategory | null;

  /**
   *  Handler to call when the selected value changes
   */
  onChange: (value: ScamCategory | null) => void;

  /**
   *  Whether the category select is disabled.
   *  @default false
   */
  isDisabled?: boolean;

  /**
   * The categories that should be disabled as options in the menu. @default []
   */
  disabledOptions?: ScamCategory[];

  /**
   * Handler to call when the focus state changes.
   */
  onFocusChange?: () => void;

  /**
   * Validation state
   */
  validationState?: 'valid' | 'invalid';
};

const ROOT = makeRootClassName('ScamCategorySelect');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ScamCategorySelect(props: ScamCategorySelectProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { value, onChange, onFocusChange, validationState } = p;

  const categoryContent = (category: ScamCategory): ReactElement => (
    <div>
      <Text>{getDisplayStringForScamCategoryWithType(category)}</Text>
    </div>
  );

  const renderSelectedCategory = (
    category: ScamCategory | null
  ): ReactElement => <div>{category && categoryContent(category)}</div>;

  /**
   * we are deprecating UKRANIAN_DONATION_SCAM category
   * this will map it to correct category incase user tries to update
   * any reports using old category
   */
  useEffect(() => {
    if (value === ScamCategory.UKRANIAN_DONATION_SCAM) {
      onChange(ScamCategory.DONATION_SCAM);
    }
  }, [onChange, value]);

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="body-md" className={el`description`}>
        Choose one of the below categories. See the{' '}
        <a href={GLOSSARY_ROUTE} target="_blank" rel="noreferrer">
          Scam Glossary
        </a>{' '}
        for full descriptions of each scam.
      </Text>
      <Select
        placeholder={'Select an option'}
        isDisabled={p.isDisabled}
        value={value}
        onChange={onChange}
        renderValue={renderSelectedCategory}
        onFocusChange={onFocusChange}
        validationState={validationState}
      >
        {ORDERED_SCAM_CATEGORIES.map((category) => (
          <Option key={`category-select-option-${category}`} value={category}>
            {categoryContent(category)}
          </Option>
        ))}
      </Select>
    </div>
  );
}

export default ScamCategorySelect;
