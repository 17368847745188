import { useSearchField } from '@react-aria/searchfield';
import { useSearchFieldState } from '@react-stately/searchfield';
import { AriaSearchFieldProps } from '@react-types/searchfield';
import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { useOptionalRef } from '@/hooks';
import { createCircleCross, createSearch } from '@/assets/icons';
import { Icon } from '..';
import { TextFieldProps } from '../textfield';
import TextFieldBase from '../textfield/TextFieldBase';

export type SearchFieldProps = StyleProps &
  AriaSearchFieldProps &
  TextFieldProps & {
    /** Whether the search field has a clear button. @default false */
    hasClearButton?: boolean;

    /** The input's ref */
    inputRef?: ForwardedRef<HTMLInputElement>;
  };

const ROOT = makeRootClassName('SearchField');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'medium',
  required: false,
  hasClearButton: false,
  placeholder: 'Enter address or URL',
} as const;

const SEARCH_ICON_PATH = createSearch;
const CLEAR_BUTTON_PATH = createCircleCross;

// component

function SearchFieldComponent(
  props: SearchFieldProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);
  const inputRef = useOptionalRef<HTMLInputElement>(p.inputRef);

  const state = useSearchFieldState(p);

  const { labelProps, inputProps, clearButtonProps } = useSearchField(
    {
      ...p,
      type: 'search',
    },
    state,
    inputRef
  );

  // helper

  const clearButton = (
    <div {...clearButtonProps} className={el`clear`}>
      <Icon content={CLEAR_BUTTON_PATH} />
    </div>
  );

  return (
    <TextFieldBase
      size={p.size}
      ref={domRef}
      className={clsx(ROOT, p.className, { 'has-clear': p.hasClearButton })}
      labelProps={labelProps}
      inputProps={inputProps}
      inputRef={inputRef}
      startIconPath={SEARCH_ICON_PATH}
      placeholder={p.placeholder}
      wrapperChildren={
        p.hasClearButton && state.value != '' && !p.isReadOnly
          ? clearButton
          : undefined
      }
      validationState={p.validationState}
      autoComplete="off"
    />
  );
}

/**
 * A SearchField is a text field designed for searches.
 */
export const SearchField =
  forwardRef<HTMLDivElement, SearchFieldProps>(SearchFieldComponent);

export default SearchField;
