import { nanoid } from 'nanoid';
import { ChannelType } from '@/types/contact-channel';
import { NameInfo, SubmitReportFormValues, KnownScammerField } from '../types';

export const getInitialKnownScammerFieldsFromFormValues = (
  props?: SubmitReportFormValues
): KnownScammerField[] | undefined => {
  if (!props) return undefined;

  return props.knownScammers.map((scammer) => {
    const nameInfo = scammer.info.find(
      (channel): channel is NameInfo => channel.type === ChannelType.NAME
    );

    const contactChannelFields = scammer.info
      .filter((channel) => channel.type !== ChannelType.NAME)
      .map((channel) => ({
        key: nanoid(),
        type: channel.type,
        contact: channel.contact,
      }));

    return {
      key: nanoid(),
      id: scammer.id,
      knownScammerName: nameInfo?.contact || '',
      nameInfo: nameInfo || {
        id: undefined,
        contact: '',
        type: ChannelType.NAME,
      },
      contactChannelFields,
    };
  });
};
