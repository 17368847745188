import clsx from 'clsx';
import React, { ForwardedRef, forwardRef, ReactElement } from 'react';
import { useOptionalRef } from '@/hooks';
import Button from './ButtonBase';
import type { BaseButtonProps } from './ButtonBase';

export type CTAButtonProps = Omit<
  BaseButtonProps,
  'variant' | 'isSelected' | 'isGhost' | 'isOutline'
> & {
  /**
   * The button's visual appearance.
   * @default 'primary'
   */
  variant?:
    | 'primary'
    | 'secondary'
    | 'warning'
    | 'text-white'
    | 'text-blue'
    | 'comment';

  /**
   * Whether teh button is light or heavy.
   * @default false
   */
  light?: boolean;
};

const DEFAULT_PROPS = {
  variant: 'primary',
  light: false,
} as const;

// config

function CTAButtonComponent(
  props: CTAButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  // const renderCTAButton = (): ReactElement => {
  switch (p.variant) {
    case 'primary':
      return <Button {...p} ref={domRef} variant="default" />;
    case 'secondary':
      return <Button {...p} ref={domRef} variant="cta" isOutline />;
    case 'warning':
      return (
        <Button
          {...p}
          ref={domRef}
          variant="cta"
          isOutline
          className={(p.className, 'cta-warning')}
        />
      );
    case 'text-white':
      return (
        <Button
          {...p}
          ref={domRef}
          variant="custom"
          isGhost
          className={clsx('cta-text-white', p.className, {
            'is-light': p.light,
          })}
        />
      );
    case 'text-blue':
      return (
        <Button
          {...p}
          ref={domRef}
          variant="custom"
          isGhost
          className={clsx('cta-text-blue', p.className)}
        />
      );
    case 'comment':
      return <Button {...p} ref={domRef} variant="comment" />;
    default:
      return <Button {...p} ref={domRef} variant="default" />;
  }
  // };

  // return renderCTAButton();
}

/**
 * A CTAButton is a wrapper utility around a full featured ButtonBase
 * that limits prop choices to make it easier to match [design spec](https://www.figma.com/file/IC7kVg7FBzxpOif0lC26lp/Chain-Abuse-V1?node-id=891%3A7591)
 * It is re exported as Button to be invisible to the client.
 */
export const CTAButton =
  forwardRef<HTMLButtonElement, CTAButtonProps>(CTAButtonComponent);

export default CTAButton;
