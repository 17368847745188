import { ScamCategoryKind } from '@/generated/graphql';
export { ScamCategoryKind as ScamCategory };

export type ScamKindWithReportsCount = {
  kind: ScamCategoryKind;
  reportsFiledCount: number;
};

/**
 * The preferred order to display the scam categories in a list.
 * Loosely based on the number of reports filed for each category on
 * 9/9/2022, but allows manual override (move Other to last)
 */
const preferredScamCategoryOrder: Record<ScamCategoryKind, number> = {
  [ScamCategoryKind.SEXTORTION]: 1,
  [ScamCategoryKind.RANSOMWARE]: 2,
  [ScamCategoryKind.OTHER_BLACKMAIL]: 3,
  [ScamCategoryKind.AIRDROP]: 4,
  [ScamCategoryKind.PHISHING]: 5,
  [ScamCategoryKind.IMPERSONATION]: 6,
  [ScamCategoryKind.UKRANIAN_DONATION_SCAM]: 10,
  [ScamCategoryKind.DONATION_SCAM]: 10,
  [ScamCategoryKind.ROMANCE]: 15,
  [ScamCategoryKind.PIGBUTCHERING]: 16,
  [ScamCategoryKind.FAKE_PROJECT]: 18,
  [ScamCategoryKind.RUG_PULL]: 19,
  [ScamCategoryKind.FAKE_RETURNS]: 20,
  [ScamCategoryKind.OTHER]: 480,
  [ScamCategoryKind.SIM_SWAP]: 482,
  [ScamCategoryKind.CONTRACT_EXPLOIT]: 485,
  [ScamCategoryKind.OTHER_HACK]: 490,
  [ScamCategoryKind.OTHER_INVESTMENT_SCAM]: 500,
  [ScamCategoryKind.MAN_IN_THE_MIDDLE_ATTACK]: 520,
  [ScamCategoryKind.UPGRADE_SCAM]: 530,
};

const EXCLUDED_SELECT_SCAM_CATEGORIES = [
  ScamCategoryKind.OTHER_INVESTMENT_SCAM,
  ScamCategoryKind.MAN_IN_THE_MIDDLE_ATTACK,
  ScamCategoryKind.UPGRADE_SCAM,
  ScamCategoryKind.UKRANIAN_DONATION_SCAM, // deprecated in favor of DONATION_SCAM
];

export const ORDERED_SCAM_CATEGORIES = Object.values(ScamCategoryKind)
  .filter(
    (scamCategory) => !EXCLUDED_SELECT_SCAM_CATEGORIES.includes(scamCategory)
  )
  .sort((a, b) => {
    return preferredScamCategoryOrder[a] - preferredScamCategoryOrder[b];
  });

export const getDisplayStringForScamCategory = (
  scamCategory: ScamCategoryKind
): string => {
  switch (scamCategory) {
    case ScamCategoryKind.RUG_PULL:
      return 'Rug Pull Scam';
    case ScamCategoryKind.SEXTORTION:
      return 'Sextortion Scam';
    case ScamCategoryKind.SIM_SWAP:
      return 'SIM Swap Scam';
    case ScamCategoryKind.PHISHING:
      return 'Phishing Scam';
    case ScamCategoryKind.RANSOMWARE:
      return 'Ransomware';
    case ScamCategoryKind.CONTRACT_EXPLOIT:
      return 'Contract Exploit Scam';
    case ScamCategoryKind.AIRDROP:
      return 'NFT Airdrop Scam';
    case ScamCategoryKind.UKRANIAN_DONATION_SCAM:
      return 'Ukraine Donation Impersonation Scam';
    case ScamCategoryKind.DONATION_SCAM:
      return 'Donation Impersonation Scam';
    case ScamCategoryKind.ROMANCE:
      return 'Romance Scam';
    case ScamCategoryKind.PIGBUTCHERING:
      return 'Pigbutchering Scam';
    case ScamCategoryKind.FAKE_PROJECT:
      return 'Fake Project Scam';
    case ScamCategoryKind.OTHER_BLACKMAIL:
      return 'Other Blackmail Scam';
    case ScamCategoryKind.IMPERSONATION:
      return 'Impersonation Scam';
    case ScamCategoryKind.FAKE_RETURNS:
      return 'Fake Returns Scam';
    case ScamCategoryKind.UPGRADE_SCAM:
      return 'Upgrade Scam';
    case ScamCategoryKind.MAN_IN_THE_MIDDLE_ATTACK:
      return 'Man-In-The-Middle Attack Scam';
    case ScamCategoryKind.OTHER_HACK:
      return 'Hack - Other';
    case ScamCategoryKind.OTHER:
      return 'Other';
    case ScamCategoryKind.OTHER_INVESTMENT_SCAM:
      return 'Other Investment Scam';
    default:
      throw new Error(`Unrecognized category type: ${scamCategory}`);
  }
};

export const getDescriptionForScamCategory = (
  category: ScamCategoryKind
): string => {
  switch (category) {
    case ScamCategoryKind.RUG_PULL:
      return 'A Rug Pull occurs when an individual or group  deletes their online foot print after accepting investor funds. It is the same as an exit scam where the goal is to run off with investor funds.';
    case ScamCategoryKind.SEXTORTION:
      return 'Sextortion occurs when individuals claim to hold sensitive or revealing information on a target that they will threaten to release unless they receive payment in crypto. ';
    case ScamCategoryKind.SIM_SWAP:
      return 'A Sim Swap occurs when an individual takes control of your cell phone. ';
    case ScamCategoryKind.PHISHING:
      return 'Phishing is a tactic used by criminals to trick individuals into providing personal information that typically is associated with login information.';
    case ScamCategoryKind.RANSOMWARE:
      return 'Ransomware occurs when an attacker locks up a computer system and demands payment. ';
    case ScamCategoryKind.CONTRACT_EXPLOIT:
      return 'A Contract Exploit occurs when an individual exceeds access to a contract or cryptocurrency wallet, often leading to large losses. ';
    case ScamCategoryKind.AIRDROP:
      return 'An Airdrop Scam occurs when scammers send fake tokens in mass to users in hopes they will visit a website that is set up to steal user funds.';
    case ScamCategoryKind.DONATION_SCAM:
      return 'Attempts to appear like a legitimate business that accepts cryptocurrency donations.';
    case ScamCategoryKind.ROMANCE:
      return "A Romance scam involves a criminal assuming a fake identity in order to gain a victim's affection, which often leads to financial loss through manipulation and deception.";
    case ScamCategoryKind.PIGBUTCHERING:
      return 'The pigbutchering (a.k.a. “sha zhu pan”) scam is a cryptocurrency romance investment scheme that targets victims all over the globe.';
    case ScamCategoryKind.FAKE_PROJECT:
      return 'A Fake project scam occurs when the scammer pretends to be building a fake project and demands investments. Fake project scams include rug pulls or exit scams, when an individual or group deletes their online footprint after accepting investor funds.';
    case ScamCategoryKind.OTHER_BLACKMAIL:
      return 'A blackmail scam (other) occurs when the scammer threatens to start targeting the victim (them or their systems) if they do not transfer crypto funds.';
    case ScamCategoryKind.IMPERSONATION:
      return 'An impersonation scam occurs when the scammer pretends to be someone they are not - a celebrity, an established investor - or to be associated with an organization - such as an NGO or a project - when they are not. The goal is to have the victim invest with them or donate money to them.';
    case ScamCategoryKind.FAKE_RETURNS:
      return `A Fake return scam occurs when the scammer promises fake returns to convince their victim to invest. The scammer can lure their victim by:
      promising fake payments - payout scam or load up scam
      faking returns by paying profits to earlier investors with funds from more recent investors - Ponzi scheme
      fraudulently and artificially inflating the price of a cryptocurrency, such as during a pump and dump scam - using misleading information or other techniques.`;
    case ScamCategoryKind.UPGRADE_SCAM:
      return 'An Upgrade scam occurs when the hacker sends an email to their victim telling them they need to upgrade - for example, migrate to the new ETH chain, hoping they will click on a malicious link, including malware. By clicking on this malicious link, the victim signs transactions or shares their private keys.';
    case ScamCategoryKind.MAN_IN_THE_MIDDLE_ATTACK:
      return 'A Man in middle attack occurs when the scammer exploits a vulnerability in a communication network to intercept information and access log-in information or wallet private keys.';
    case ScamCategoryKind.OTHER_HACK:
      return 'It seems you have experienced a hack. During a hack, the scammer, a hacker, steals information from their victim to gain control over their wallet (private key or seed phrase), web-app account (log-in information), or phone to sign transactions on their behalf.';
    case ScamCategoryKind.OTHER:
      return '';
    case ScamCategoryKind.OTHER_INVESTMENT_SCAM:
      return '';
    default:
      return '';
  }
};

export enum ScamCategoryType {
  BLACKMAIL = 'BLACKMAIL',
  FRAUDS = 'FRAUDS',
  HACKS = 'HACKS',
}

export const ScamCategoryByType: Record<ScamCategoryType, ScamCategoryKind[]> =
  {
    [ScamCategoryType.BLACKMAIL]: [
      ScamCategoryKind.SEXTORTION,
      ScamCategoryKind.RANSOMWARE,
    ],
    [ScamCategoryType.FRAUDS]: [
      ScamCategoryKind.AIRDROP,
      ScamCategoryKind.PHISHING,
      ScamCategoryKind.IMPERSONATION,
      ScamCategoryKind.DONATION_SCAM,
      ScamCategoryKind.ROMANCE,
      ScamCategoryKind.PIGBUTCHERING,
      ScamCategoryKind.FAKE_PROJECT,
      ScamCategoryKind.RUG_PULL,
      ScamCategoryKind.FAKE_RETURNS,
    ],
    [ScamCategoryType.HACKS]: [
      ScamCategoryKind.SIM_SWAP,
      ScamCategoryKind.CONTRACT_EXPLOIT,
    ],
  };

export const getScamCategoryType = (
  category: ScamCategoryKind
): ScamCategoryType | null => {
  for (const [key, value] of Object.entries(ScamCategoryByType)) {
    if (value.includes(category)) return key as ScamCategoryType;
  }

  return null;
};

export const getDisplayStringForScamCategoryType = (
  type: ScamCategoryType
): string | null => {
  switch (type) {
    case ScamCategoryType.BLACKMAIL:
      return 'Blackmail';
    case ScamCategoryType.FRAUDS:
      return 'Frauds';
    case ScamCategoryType.HACKS:
      return 'Hacks';
    default:
      return null;
  }
};

export const getDisplayStringForScamCategorySelectType = (
  type: ScamCategoryType
): string | null => {
  switch (type) {
    case ScamCategoryType.BLACKMAIL:
      return 'Blackmail';
    case ScamCategoryType.FRAUDS:
      return 'Fraud';
    case ScamCategoryType.HACKS:
      return 'Hack';
    default:
      return null;
  }
};

export const getDisplayStringForScamCategoryWithType = (
  category: ScamCategoryKind
): string => {
  const categoryType = getScamCategoryType(category);
  switch (category) {
    case ScamCategoryKind.SEXTORTION:
      return 'Blackmail - Sextortion Scam';
    case ScamCategoryKind.RANSOMWARE:
      return 'Blackmail - Ransomware';
    case ScamCategoryKind.OTHER_BLACKMAIL:
      return 'Other Blackmail Scam';
    case ScamCategoryKind.AIRDROP:
      return 'Fraud - NFT Airdrop Scam';
    case ScamCategoryKind.PHISHING:
      return 'Fraud - Other Phishing Scam';
    case ScamCategoryKind.IMPERSONATION:
      return 'Fraud - Other Impersonation Scam';
    case ScamCategoryKind.DONATION_SCAM:
      return 'Fraud - Donation Impersonation Scam';
    case ScamCategoryKind.ROMANCE:
      return 'Fraud - Romance Scam';
    case ScamCategoryKind.PIGBUTCHERING:
      return 'Fraud - Pig Butchering Scam';
    case ScamCategoryKind.FAKE_PROJECT:
      return 'Fraud - Fake Project Investment Scam';
    case ScamCategoryKind.RUG_PULL:
      return 'Fraud - Rug Pull Investment Scam';
    case ScamCategoryKind.FAKE_RETURNS:
      return 'Fraud - Fake Returns Investment Scam';
    case ScamCategoryKind.OTHER:
      return 'Other Fraud Scam';
    case ScamCategoryKind.SIM_SWAP:
      return 'Hack - Sim Swap';
    case ScamCategoryKind.CONTRACT_EXPLOIT:
      return 'Hack - Contract Exploit';
    case ScamCategoryKind.OTHER_HACK:
      return 'Other Hack Scam';
    default:
      if (categoryType) {
        return `${getDisplayStringForScamCategorySelectType(
          categoryType
        )} - ${getDisplayStringForScamCategory(category)}`;
      }
      return getDisplayStringForScamCategory(category);
  }
};

export const getDescriptionForScamCategoryType = (
  type: ScamCategoryType
): string | null => {
  switch (type) {
    case ScamCategoryType.BLACKMAIL:
      return 'During a blackmail scam, the scammer demands payment from their victim for not revealing damaging information the scammer claims to have about them or to unblock something their victim needs. Blackmail scams differ in the information scammers leverage to threaten their victims.';
    case ScamCategoryType.FRAUDS:
      return 'During a crypto fraud, the scammer lures their victim to have them transfer crypto funds. The scammer can lure their victim into promising fake returns, pretending they are associated with a fake project or pretending they are someone they are not. Frauds differ from hacks as the victim signs the transaction themselves.';
    case ScamCategoryType.HACKS:
      return 'During a hack, the scammer, a hacker, steals information from their victim to gain control over their wallet (private key or seed phrase), web-app account (log-in information), or phone to sign transactions on their behalf. ';
    default:
      return null;
  }
};
