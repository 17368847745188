import { nanoid } from 'nanoid';
import { useReducer, useEffect } from 'react';
import { TokenIdField, ValidTokenIdData } from '../types';
import { reorder } from '../utils/reorder';

type UseTokenIdsOptions = {
  /** Initial fields to populate */
  initialFields?: TokenIdField[];

  /** Wheter the data for the initial fields is loading */
  isLoading?: boolean;
};

type UseTokenIdsState = {
  /** The fields */
  fields: TokenIdField[];

  /** The sanitized fields */
  sanitizedTokenIdFields: ValidTokenIdData[];
};

enum ReducerActionType {
  ADD_FIELD = 'ADD_FIELD',
  ADD_FIELDS = 'ADD_FIELDS',
  REMOVE_FIELD = 'REMOVE_FIELD',
  UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
  UPDATE_FIELD_ORDER = 'UPDATE_FIELD_ORDER',
  RESET_FIELDS = 'RESET_FIELDS',
}

type AddFieldAction = {
  type: ReducerActionType.ADD_FIELD;
  value: string;
};

type AddFieldsAction = {
  type: ReducerActionType.ADD_FIELDS;
  value: string[];
};

type RemoveFieldAction = {
  type: ReducerActionType.REMOVE_FIELD;
  index: number;
};

type UpdateFieldValueAction = {
  type: ReducerActionType.UPDATE_FIELD_VALUE;
  index: number;
  value: string;
};


type UpdateFieldOrderAction = {
  type: ReducerActionType.UPDATE_FIELD_ORDER;
  fromIndex: number;
  toIndex: number;
};

type ResetFieldsAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: TokenIdField[];
};

type ReducerAction =
  | AddFieldAction
  | AddFieldsAction
  | RemoveFieldAction
  | UpdateFieldValueAction
  | UpdateFieldOrderAction
  | ResetFieldsAction;

type UseTokenIdsActions = {
  addField: () => void;
  addFields: () => void;
  removeField: (index: number) => void;
  updateFieldValue: (index: number, value: string) => void;
  updateFieldOrder: (fromIndex: number, toIndex: number) => void;
};

export type UseTokenIdsValue = UseTokenIdsState & UseTokenIdsActions;

const useTokenIds = (props?: UseTokenIdsOptions): UseTokenIdsValue => {
  const [fields, dispatch] = useReducer(
    (prev: TokenIdField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_FIELD:
          return [...prev, {
            key: nanoid(),
            value: action.value || '',
          }];
        case ReducerActionType.ADD_FIELDS:
          return [
            ...prev.filter((field) => field.value !== ''),
            ...action.value.map((value): TokenIdField => ({
              key: nanoid(),
              value,
            })),
          ];
        case ReducerActionType.REMOVE_FIELD:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.UPDATE_FIELD_VALUE:
          return prev.map((field, index) =>
            index === action.index ? { ...field, value: action.value } : field
          );
        case ReducerActionType.UPDATE_FIELD_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      {
        key: nanoid(),
        value: '',
      }
    ]
  );

  const actions: UseTokenIdsActions = {
    addField: (value?: string) => {
      dispatch({ type: ReducerActionType.ADD_FIELD, value: value || '' });
    },
    addFields: (values?: string[]) => {
      dispatch({ type: ReducerActionType.ADD_FIELDS, value: values || [] });
    },
    removeField: (index: number) => {
      if (index === 0) {
        throw new Error('Cannot remove the first token ID input field');
      }
      dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
    },
    updateFieldValue: (index: number, value: string) => {
      dispatch({
        type: ReducerActionType.UPDATE_FIELD_VALUE,
        index,
        value,
      });
    },
    updateFieldOrder: (fromIndex: number, toIndex: number) => {
      dispatch({
        type: ReducerActionType.UPDATE_FIELD_ORDER,
        fromIndex,
        toIndex,
      });
    },
  };

  const sanitizedTokenIdFields = fields
    .filter((field) => field.value !== '')
    .map((field) => {
      return {
        id: field.id,
        tokenId: field.value,
      };
    });

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    fields,
    sanitizedTokenIdFields,
    ...actions,
  };
};

export default useTokenIds;
