export function reorder<T>(
  list: Array<T>,
  fromIndex: number,
  toIndex: number
): T[] {
  const result = Array.from(list);
  const [removed] = result.splice(fromIndex, 1);
  result.splice(toIndex, 0, removed);

  return result;
}
