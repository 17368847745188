import { useEffect, useState } from 'react';
import isEmail from 'validator/lib/isEmail';
import { useReportUserLocationLazyQuery } from '@/generated/graphql';
import { City, Country, State } from '@/types/location';

export type SupportOptInInitialValues = {
  agreed?: boolean;
  agreedToBeContactedByLawEnforcement?: boolean;
  name?: string;
  isNameValid?: boolean;
  email?: string;
  isEmailValid?: boolean;
  phoneNumber?: string;
  country?: string;
  countryCode?: string;
  state?: string;
  stateCode?: string;
  city?: string;
  zipCode?: string;
};

type UseSupportOptInOptions = {
  isLoading?: boolean;
  initialValues?: SupportOptInInitialValues;
};

export type SupportOptInState = {
  agreed: boolean;
  agreedToBeContactedByLawEnforcement: boolean;
  name?: string;
  isNameValid?: boolean;
  email?: string;
  isEmailValid?: boolean;
  phoneNumber?: string;
  countries: Country[];
  country?: Country | null;
  states: State[];
  state?: State | null;
  cities: City[];
  city?: City | null;
  zipCode?: string;
  countriesLoading: boolean;
  statesLoading: boolean;
  citiesLoading: boolean;
};

type SupportOptInActions = {
  toggleSupportOptIn: () => void;
  updateName: (name: string) => void;
  updateEmail: (email: string) => void;
  updatePhoneNumber: (phone: string) => void;
  updateCountry: (country: Country) => void;
  updateState: (state: State) => void;
  updateCity: (city: City) => void;
  updateZipCode: (zipCode: string) => void;
  fetchCountries: () => void;
  setIsNameFieldTouched: (touched: boolean) => void;
  setIsEmailFieldTouched: (touched: boolean) => void;
};

type UseSupportOptInValue = SupportOptInState & SupportOptInActions;

const useSupportOptIn = (
  props?: UseSupportOptInOptions
): UseSupportOptInValue => {
  const [userLocationQuery] = useReportUserLocationLazyQuery();

  const [agreed, setAgreed] = useState(props?.initialValues?.agreed ?? false);
  const [
    agreedToBeContactedByLawEnforcement,
    setAgreedToBeContactedByLawEnforcement,
  ] = useState(
    props?.initialValues?.agreedToBeContactedByLawEnforcement ?? false
  );

  const toggleSupportOptIn = () => {
    setAgreed(!agreed);
    setAgreedToBeContactedByLawEnforcement(
      !agreedToBeContactedByLawEnforcement
    );
  };

  const [name, updateName] = useState(props?.initialValues?.name ?? '');
  const [isNameFieldTouched, setIsNameFieldTouched] = useState(false);
  const [isNameValid, setIsNameValid] = useState(true);

  const [email, updateEmail] = useState(props?.initialValues?.email ?? '');
  const [isEmailFieldTouched, setIsEmailFieldTouched] = useState(false);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const [phoneNumber, updatePhoneNumber] = useState(
    props?.initialValues?.phoneNumber ?? ''
  );

  const [countries, setCountries] = useState<Country[]>([]);
  const [countriesLoading, setCountriesLoading] = useState(false);
  const [country, setCountry] = useState<Country | null>(null);

  const [states, setStates] = useState<State[]>([]);
  const [statesLoading, setStatesLoading] = useState(false);
  const [state, setState] = useState<State | null>(null);

  const [cities, setCities] = useState<City[]>([]);
  const [citiesLoading, setCitiesLoading] = useState(false);
  const [city, updateCity] = useState<City | null>(null);

  const [zipCode, updateZipCode] = useState(
    props?.initialValues?.zipCode ?? ''
  );

  const fetchCountries = async () => {
    if (!countries.length) {
      setCountriesLoading(true);
      const { data } = await userLocationQuery().finally(() =>
        setCountriesLoading(false)
      );

      if (data?.reportUserLocation.countries?.length) {
        setCountries(
          data?.reportUserLocation.countries.map(({ label, isoCode }) => ({
            label,
            isoCode,
          }))
        );
      }
    }
  };

  const fetchStatesForCountry = async (countryCode: string) => {
    setStatesLoading(true);
    const { data } = await userLocationQuery({
      variables: {
        input: {
          countryCode,
        },
      },
    }).finally(() => setStatesLoading(false));

    if (data?.reportUserLocation.states?.length) {
      setStates(
        data.reportUserLocation.states.map(({ label, isoCode }) => ({
          label,
          isoCode,
        }))
      );
    }
  };

  const fetchCitiesForState = async (
    countryCode: string,
    stateCode: string
  ) => {
    setCitiesLoading(true);
    const { data } = await userLocationQuery({
      variables: {
        input: {
          countryCode,
          stateCode,
        },
      },
    }).finally(() => setCitiesLoading(false));

    if (data?.reportUserLocation.cities?.length) {
      setCities(
        data.reportUserLocation.cities.map(({ label }) => ({
          label,
        }))
      );
    }
  };

  const updateCountry = (value: Country) => {
    if (value.isoCode !== country?.isoCode) {
      setCountry(value);
      setState(null);
      updateCity(null);
      setStates([]);
      setCities([]);

      fetchStatesForCountry(value.isoCode);
    }
  };

  const updateState = async (value: State) => {
    if (country?.isoCode && value.isoCode !== state?.isoCode) {
      setState(value);
      updateCity(null);
      setCities([]);

      fetchCitiesForState(country.isoCode, value.isoCode);
    }
  };

  // handle loading state
  useEffect(() => {
    if (props?.initialValues) {
      setAgreed(props.initialValues.agreed ?? false);
      setAgreedToBeContactedByLawEnforcement(
        props.initialValues.agreedToBeContactedByLawEnforcement ?? false
      );
      updateName(props.initialValues.name ?? '');
      updateEmail(props.initialValues.email ?? '');
      updatePhoneNumber(props.initialValues.phoneNumber ?? '');
      updateZipCode(props.initialValues.zipCode ?? '');

      if (props.initialValues.country && props.initialValues.countryCode) {
        setCountry({
          label: props.initialValues.country,
          isoCode: props.initialValues.countryCode,
        });

        fetchCountries();
        fetchStatesForCountry(props.initialValues.countryCode);

        if (props.initialValues.state && props.initialValues.stateCode) {
          setState({
            label: props.initialValues.state,
            isoCode: props.initialValues.stateCode,
          });

          fetchCitiesForState(
            props.initialValues.countryCode,
            props.initialValues.stateCode
          );

          if (props.initialValues.city) {
            updateCity({
              label: props.initialValues.city,
            });
          }
        }
      }
    }
  }, [props?.isLoading]);

  useEffect(() => {
    if (agreedToBeContactedByLawEnforcement) {
      if (name.length > 2) {
        setIsNameValid(true);
      } else setIsNameValid(false);
    } else {
      if (name && isNameFieldTouched) {
        if (name.length > 2) {
          setIsNameValid(true);
        } else setIsNameValid(false);
      } else {
        setIsNameValid(true);
      }
    }

    if (agreed || agreedToBeContactedByLawEnforcement) {
      if (isEmail(email)) {
        setIsEmailValid(true);
      } else setIsEmailValid(false);
    } else {
      if (email && isEmailFieldTouched) {
        if (isEmail(email)) {
          setIsEmailValid(true);
        } else setIsEmailValid(false);
      } else {
        setIsEmailValid(true);
      }
    }
  }, [
    agreed,
    agreedToBeContactedByLawEnforcement,
    email,
    isEmailFieldTouched,
    isNameFieldTouched,
    name,
  ]);

  return {
    agreed,
    toggleSupportOptIn,
    agreedToBeContactedByLawEnforcement,
    name,
    isNameValid,
    email,
    isEmailValid,
    setIsNameFieldTouched,
    setIsEmailFieldTouched,
    phoneNumber,
    updatePhoneNumber,
    updateName,
    updateEmail,
    updateCountry,
    updateState,
    updateCity,
    updateZipCode,
    countries,
    country,
    states,
    state,
    cities,
    city,
    zipCode,
    fetchCountries,
    countriesLoading,
    statesLoading,
    citiesLoading,
  };
};

export default useSupportOptIn;
