import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { AutoLinkPlugin } from '@lexical/react/LexicalAutoLinkPlugin';
import {
  InitialEditorStateType,
  LexicalComposer,
} from '@lexical/react/LexicalComposer';
import { ContentEditable } from '@lexical/react/LexicalContentEditable';
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin';
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin';
import { ListPlugin } from '@lexical/react/LexicalListPlugin';
import { OnChangePlugin } from '@lexical/react/LexicalOnChangePlugin';
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin';
import { HeadingNode } from '@lexical/rich-text';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import clsx from 'clsx';
import {
  $createParagraphNode,
  $createTextNode,
  $getRoot,
  EditorState,
  Klass,
  LexicalEditor,
  LexicalNode,
  SerializedEditorState,
} from 'lexical';
import { ReactElement, useRef } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { TextAreaProps } from '@/components/textfield';
import {
  OnBlurPlugin,
  // MarkdownPasteConverterPlugin,
  ToolbarPlugin,
} from './plugins';
import {
  FormattingOption,
  FORMATTING_OPTIONS,
  SupportedBlock,
  SUPPORTED_BLOCKS,
} from './types';
import { AUTOLINK_MATCHERS } from './utils';

export type EditorProps = StyleProps &
  Pick<TextAreaProps, 'validationState'> & {
    /**
     * The namespace of the editor - required by lexical
     */
    namespace: string;

    /**
     * The default values for setting initial editor content
     * either from serializedEditorState or plain text
     * if both are passed serializedEditorState will be used
     */
    defaultValues?: {
      serializedEditorState?: SerializedEditorState;
      text?: string;
    };

    /**
     * The placeholder text to show when the content is empty
     */
    placeholder?: string;
    /**
     * Whether to show the undo / redo buttons
     */
    showHistory?: boolean;
    /**
     * Whether the editor is disabled
     * @default false
     */
    isDisabled?: boolean;
    /**
     * A list of supported elements the editor has
     * @default ['paraghraph', 'h2', 'h3', 'h4', 'ul', 'ol']
     */
    supportedElements?: ReadonlyArray<SupportedBlock>;
    /**
     * A list of formatting options the editor supports
     * * @default ['bold', 'italic', 'underline', 'strikethrough']
     */
    formattingOptions?: ReadonlyArray<FormattingOption>;
    /**
     * Callback for when an error happens
     */
    onError: (error: Error) => void;
    /**
     * Callback for when a change occures
     */
    onChange?: (value: string, editorState: EditorState) => void;
    /**
     * On blur callback
     */
    onBlur?: (editor: LexicalEditor) => void;
  };

const ROOT = makeRootClassName('Editor');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  placeholder: 'Enter your text here...',
  supportedElements: SUPPORTED_BLOCKS,
  formattingOptions: FORMATTING_OPTIONS,
  showHistory: false,
  isDisabled: false,
} as const;

export const editorTheme = {
  ltr: el`ltr`,
  rtl: el`rtl`,
  paragraph: el`paragraph`,
  placeholder: el`placeholder`,
  image: el`image`,
  link: el`link`,
  heading: {
    h2: el`h2`,
    h3: el`h3`,
    h4: el`h4`,
  },
  list: {
    ol: el`ordered-list`,
    ul: el`unordered-list`,
    listitem: el`list-item`,
  },
  text: {
    bold: el`text-bold`,
    italic: el`text-italic`,
    overflowed: el`text-overflowed`,
    hashtag: el`text-hashtag`,
    underline: el`text-underline`,
    strikethrough: el`text-strikethrough`,
    underlineStrikethrough: el`text-underline-strikethrough`,
  },
};

const DEFAULT_SUPPORTED_NODES = [LinkNode, AutoLinkNode];

const supportedElementToNodeMapping: Record<
  SupportedBlock,
  Klass<LexicalNode> | Klass<LexicalNode>[] | undefined
> = {
  paragraph: undefined, // included by default, can't skip having p tags
  h2: HeadingNode,
  h3: HeadingNode,
  h4: HeadingNode,
  ul: [ListNode, ListItemNode],
  ol: [ListNode, ListItemNode],
};

export const getSupportedNodes = (
  supportedBlocks: ReadonlyArray<SupportedBlock>
) => {
  return supportedBlocks
    .map((block) => {
      return supportedElementToNodeMapping[block];
    })
    .flat()
    .concat(DEFAULT_SUPPORTED_NODES)
    .filter((node) => node !== undefined) as Klass<LexicalNode>[];
};

const hasList = (elements: ReadonlyArray<SupportedBlock>) => {
  return elements.find((element) => element === 'ol' || element === 'ul')
    ? true
    : false;
};

function Editor(props: EditorProps): ReactElement {
  const editorRef = useRef(null);
  const p = { ...DEFAULT_PROPS, ...props };
  const { isHovered, hoverProps } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible, isFocused } = useFocusRing({
    within: true,
  });

  const setInitialEditorState: InitialEditorStateType = (editor) => {
    if (p.defaultValues?.serializedEditorState) {
      editor.setEditorState(
        editor.parseEditorState(p.defaultValues.serializedEditorState)
      );
    } else if (p.defaultValues?.text) {
      editor.update(() => {
        $getRoot().append(
          $createParagraphNode().append($createTextNode(p.defaultValues?.text))
        );
      });
    }
  };

  const initialConfig = {
    theme: editorTheme,
    namespace: p.namespace,
    onError: p.onError,
    nodes: getSupportedNodes(p.supportedElements),
    editorState: setInitialEditorState,
    editable: !p.isDisabled,
  };

  return (
    <div
      {...hoverProps}
      className={clsx(ROOT, p.className, {
        'is-disabled': p.isDisabled,
        'is-hovered': isHovered,
        'is-focused': isFocused,
        'is-focus-visible': isFocusVisible,
        'is-valid': p.validationState === 'valid',
        'is-invalid': p.validationState === 'invalid',
      })}
    >
      <LexicalComposer initialConfig={initialConfig}>
        <ToolbarPlugin
          isDisabled={p.isDisabled}
          showHistory={p.showHistory}
          editorRef={editorRef}
          formattingOptions={p.formattingOptions}
          supportedElements={p.supportedElements}
        />
        <div className={el`wrapper`} ref={editorRef} {...focusProps}>
          <RichTextPlugin
            contentEditable={<ContentEditable className={el`editable`} />}
            placeholder={<div className={el`placeholder`}>{p.placeholder}</div>}
          />
        </div>
        <HistoryPlugin />
        {hasList(p.supportedElements) ? <ListPlugin /> : ''}
        <LinkPlugin />
        <AutoLinkPlugin matchers={AUTOLINK_MATCHERS} />
        {/* @TODO bring back markdown paste conversion, but was seeing an issue
        where it would replace the entire editor state instead of pasting in place */}
        {/* <MarkdownPasteConverterPlugin /> */}
        {p.onBlur ? <OnBlurPlugin onBlur={p.onBlur} /> : ''}
        <OnChangePlugin
          onChange={(editorState) => {
            editorState.read(() => {
              p.onChange?.($getRoot().getTextContent(), editorState);
            });
          }}
        />
      </LexicalComposer>
    </div>
  );
}

export default Editor;
