import clsx from 'clsx';
import React, { ReactElement, useRef } from 'react';
import {
  HoverProps,
  PressHookProps,
  useHover,
  usePress,
} from '@react-aria/interactions';
import { FocusRingProps, useFocusRing } from '@react-aria/focus';
import { mergeProps } from '@react-aria/utils';
import { Slot } from '@radix-ui/react-slot';
import { StyleProps } from '@/utils';

export type InteractableComponentProps = StyleProps &
  PressHookProps &
  HoverProps &
  FocusRingProps & {
    /**
     * The child elements to render.
     */
    children: ReactElement;

    /**
     * If true, merges this DOM node with the only child of this component.
     * This will merge the original component props with the props
     * of the supplied element/component and change the underlying DOM node
     * to that of the only child.
     *
     * If false, this component will be a `div`.
     *
     * @default false
     */
    asChild?: boolean;
  };

const DEFAULT_PROPS = {
  asChild: false,
} as const;

function InteractableComponent(
  props: InteractableComponentProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const ref = useRef<any>();

  const { pressProps, isPressed } = usePress({
    ref,
    isPressed: p.isPressed,
    isDisabled: p.isDisabled,
    preventFocusOnPress: p.preventFocusOnPress,
    shouldCancelOnPointerExit: p.shouldCancelOnPointerExit,
    allowTextSelectionOnPress: p.allowTextSelectionOnPress,
    onPress: p.onPress,
    onPressStart: p.onPressStart,
    onPressEnd: p.onPressEnd,
    onPressChange: p.onPressChange,
    onPressUp: p.onPressUp,
  });
  const { hoverProps, isHovered } = useHover({
    isDisabled: p.isDisabled,
    onHoverStart: p.onHoverStart,
    onHoverEnd: p.onHoverEnd,
    onHoverChange: p.onHoverChange,
  });
  const { focusProps, isFocused, isFocusVisible } = useFocusRing({
    within: p.within,
    isTextInput: p.isTextInput,
    autoFocus: p.autoFocus,
  });
  const behaviorProps = mergeProps(pressProps, hoverProps, focusProps);

  const Comp = p.asChild ? Slot : 'div';

  return (
    <Comp
      ref={ref}
      className={clsx(
        {
          'is-pressed': isPressed,
          'is-hovered': isHovered,
          'is-focused': isFocused,
          'is-focus-visible': isFocusVisible,
        },
        p.className
      )}
      {...behaviorProps}
    >
      {p.children}
    </Comp>
  );
}

export default InteractableComponent;
