import { SupportOptInInitialValues } from '../hooks/useSupportOptIn';
import { SubmitReportFormValues } from '../types';

export const getInitialSupportOptInFromFormValues = (
  values?: SubmitReportFormValues
): SupportOptInInitialValues | undefined => {
  if (!values?.agreedToBeContacted) {
    return undefined;
  }

  const {
    agreed,
    agreedToBeContactedByLawEnforcement,
    name,
    email,
    phoneNumber,
    country,
    countryCode,
    state,
    stateCode,
    city,
    zipCode,
  } = values.agreedToBeContacted;

  return {
    agreed: agreed ?? false,
    agreedToBeContactedByLawEnforcement:
      agreedToBeContactedByLawEnforcement ?? false,
    name: name ?? undefined,
    email: email ?? undefined,
    phoneNumber: phoneNumber ?? undefined,
    country: country ?? undefined,
    countryCode: countryCode ?? undefined,
    state: state ?? undefined,
    stateCode: stateCode ?? undefined,
    city: city ?? undefined,
    zipCode: zipCode ?? undefined,
  };
};
