import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import { Avatar, Text } from '@/components';
import { FeatherIcon, FeatherIconName } from '@/components/icon';
import {
  CommentableEntityKind,
  useDeleteCommentMutation,
  useGetCommentsForReportQuery,
  useUpdateCommentMutation,
} from '@/generated/graphql';
import { getRouteForProfile } from '@/types/routes';
import { makeElementClassNameFactory, makeRootClassName } from '@/utils';
import { useMe } from '../../../../hooks';
import { CommentData } from '../../types';
import { getFormattedDate } from '../../utils';
import { SubmitComment } from '../submit-comment';

const ROOT = makeRootClassName('Comment');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export type CommentProps = {
  comment: CommentData;
  isLast: boolean;

  reportId: string;

  /**
   * Whether or not the current viewer is the author of this comment.
   */
  isViewerAuthoredComment: boolean;

  /**
   * Action that runs when the user wants to remove the comment.
   */
  onRemoveComment: () => void;

  /**
   * Action that runs when the user wants to edit the comment.
   */
  onEditComment: (updatedComment: string) => void | Promise<void>;
};

export function Comment(props: CommentProps): ReactElement {
  const [isEditing, setIsEditing] = useState(false);
  const p = { ...DEFAULT_PROPS, ...props };
  const router = useRouter();

  const handleDelete = () => {
    p.onRemoveComment();
  };
  const handleEdit = () => {
    setIsEditing(!isEditing);
  };
  const handleEditSubmit = async (updatedComment: string) => {
    await p.onEditComment(updatedComment);
    setIsEditing(false);
  };
  const handleEditCancel = () => {
    setIsEditing(false);
  };

  return (
    <div className={ROOT} key={p.comment.id}>
      {!p.isLast && <div className={el`vertical-line`}></div>}
      <div className={el`header`}>
        <Avatar
          name={p.comment.username}
          image={p.comment.profileImgSrc ?? '/images/avatar.svg'}
          onPress={() => router.push(getRouteForProfile(p.comment.username))}
        />
        <div className={el`info`}>
          <Text type="body-sm" isHeavy>
            {p.comment.username}
          </Text>
          <Text type="h5" className={el`date`}>
            {getFormattedDate(p.comment.date)}
          </Text>
          {p.isViewerAuthoredComment && !isEditing && (
            <div className={el`actions`}>
              <button onClick={handleEdit} className={el`icon-action`}>
                <FeatherIcon
                  size="small"
                  className={el`icon-action`}
                  content={FeatherIconName.EDIT}
                  tooltipContent="Edit"
                />
              </button>
              <button onClick={handleDelete} className={el`icon-action`}>
                <FeatherIcon
                  size="small"
                  content={FeatherIconName.TRASH}
                  tooltipContent="Delete"
                />
              </button>
            </div>
          )}
        </div>
      </div>
      <div className={el`content`}>
        {isEditing ? (
          <SubmitComment
            className={el`edit-form`}
            onSubmitComment={handleEditSubmit}
            initialValue={p.comment.content}
            onCancel={handleEditCancel}
          />
        ) : (
          <>
            <Text type="body-sm">{p.comment.content}</Text>
            {!p.isLast && <div className={el`horizontal-line`}></div>}
          </>
        )}
      </div>
    </div>
  );
}

type CommentContainerProps = Omit<
  CommentProps,
  'isViewerAuthoredComment' | 'onRemoveComment' | 'onEditComment'
>;

const CommentSectionContainer = (
  props: CommentContainerProps
): ReactElement => {
  const { me } = useMe();
  const [deleteCommentMutation, { loading: deleteCommentLoading }] =
    useDeleteCommentMutation();
  const [updateCommentMutation, { loading: updateCommentLoading }] =
    useUpdateCommentMutation();
  const { data } = useGetCommentsForReportQuery({
    variables: { input: { id: props.reportId } },
  });

  const isViewerAuthoredComment = props.comment.authorId === me?.id;

  return (
    <Comment
      {...props}
      isViewerAuthoredComment={isViewerAuthoredComment}
      onEditComment={(body) => {
        if (isViewerAuthoredComment && !updateCommentLoading) {
          updateCommentMutation({
            variables: {
              input: {
                id: props.comment.id,
                subjectType: CommentableEntityKind.REPORT,
                body,
              },
            },
            optimisticResponse: {
              updateComment: {
                success: true,
                comment: {
                  __typename: 'ReportComment',
                  body,
                  id: props.comment.id,
                },
              },
            },
          });
        }
      }}
      onRemoveComment={() => {
        const newCommentsCount = data?.report
          ? data.report.commentsCount - 1
          : 0;

        const newCommentsList = data?.report.comments?.filter(
          (comment) => comment.id !== props.comment.id
        );

        if (isViewerAuthoredComment && !deleteCommentLoading) {
          deleteCommentMutation({
            variables: {
              input: {
                id: props.comment.id,
                subjectType: CommentableEntityKind.REPORT,
              },
            },
            optimisticResponse: {
              deleteComment: {
                success: true,
                subject: {
                  id: props.reportId,
                  __typename: 'Report',
                  commentsCount: newCommentsCount,
                  comments: newCommentsList,
                },
              },
            },
          });
        }
      }}
    />
  );
};

export default CommentSectionContainer;
