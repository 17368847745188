import { JSONValue } from "@/types/json"
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** The javascript `Date` as string. Type represents date and time as the ISO Date string. */
  DateTime: string;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: JSONValue;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

/** An object with standard fields for created and updated data */
export type AbstractEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
};

export type AccusedScammer = Node & AbstractEntity & {
  __typename?: 'AccusedScammer';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  info: Array<AccusedScammerInfo>;
};

export enum AccusedScammerContactInfoKind {
  NAME = 'NAME',
  EMAIL = 'EMAIL',
  PHONE = 'PHONE',
  DISCORD = 'DISCORD',
  TELEGRAM = 'TELEGRAM',
  TWITTER = 'TWITTER',
  INSTAGRAM = 'INSTAGRAM',
  GITHUB = 'GITHUB',
  FACEBOOK = 'FACEBOOK',
  WHATSAPP = 'WHATSAPP',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
  OTHER = 'OTHER'
}

export type AccusedScammerInfo = Node & AbstractEntity & {
  __typename?: 'AccusedScammerInfo';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  contact: Scalars['String'];
  type: AccusedScammerContactInfoKind;
};

export type AccusedScammerInfoInput = {
  contact: Scalars['String'];
  type: AccusedScammerContactInfoKind;
};

export type AccusedScammerInput = {
  info: Array<AccusedScammerInfoInput>;
};

export type ActivityEvent = {
  __typename?: 'ActivityEvent';
  actor: ActorPayload;
  subject: SubjectPayload;
  activityKind: ActivityKind;
  context: Scalars['JSON'];
};

export type ActivityKind = {
  __typename?: 'ActivityKind';
  name: Scalars['String'];
  slug: ActivityKindSlug;
};

export enum ActivityKindSlug {
  REPORT_COMMENT = 'REPORT_COMMENT',
  REPORT_UPVOTE = 'REPORT_UPVOTE'
}

export enum ActorKind {
  USER = 'USER'
}

export type ActorPayload = {
  __typename?: 'ActorPayload';
  id: Scalars['ID'];
  kind: ActorKind;
  label: Scalars['String'];
};

/** Input type for addComment mutation. */
export type AddCommentInput = {
  body: Scalars['String'];
  subjectId: Scalars['ID'];
  subjectType: CommentableEntityKind;
};

/** The return type for the addComment mutation */
export type AddCommentPayload = {
  __typename?: 'AddCommentPayload';
  /** The subject that was commented on */
  subject?: Maybe<CommentableEntity>;
  /** The comment that was created */
  comment?: Maybe<Comment>;
  success: Scalars['Boolean'];
};

/** Input type for addFlag mutation. */
export type AddFlagInput = {
  reason: Scalars['String'];
  subjectId: Scalars['ID'];
  subjectType: FlaggableEntityKind;
};

/** The return type for the addFlag mutation. */
export type AddFlagPayload = {
  __typename?: 'AddFlagPayload';
  /** The subject that was flagged. */
  subject: FlaggableEntity;
  /** The flag that was created. */
  flag: Flag;
  success: Scalars['Boolean'];
};

/** Parameters for addVote mutation. */
export type AddVoteInput = {
  /** The direction the vote was cast. */
  direction: VoteDirection;
  /** The ID of the subject to apply the vote to. */
  subjectId: Scalars['ID'];
  /** The type of subject to apply the vote to. */
  subjectType: VotableEntityKind;
};

/** Return type for addVote mutation. */
export type AddVotePayload = {
  __typename?: 'AddVotePayload';
  /** The subject that was voted on */
  subject?: Maybe<VotableEntity>;
  /** Indicates whether adding the vote was successful */
  success: Scalars['Boolean'];
};

export type AddressConnection = {
  __typename?: 'AddressConnection';
  pageInfo: PageInfo;
  edges: Array<AddressLabelEdge>;
  nodes?: Maybe<Array<AddressLabel>>;
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type AddressInput = {
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  chain?: Maybe<ChainKind>;
  label?: Maybe<Scalars['String']>;
};

export type AddressLabel = {
  __typename?: 'AddressLabel';
  body: Scalars['String'];
};

export type AddressLabelEdge = {
  __typename?: 'AddressLabelEdge';
  node: AddressLabel;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type AddressLabelsInput = {
  /** Search query for address labels */
  query?: Maybe<Scalars['String']>;
};

export type AddressWithMistakenScammerState = {
  __typename?: 'AddressWithMistakenScammerState';
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  chain?: Maybe<ChainKind>;
  label?: Maybe<Scalars['String']>;
  /** Whether address is mistaken scammer. */
  isMistakenScammer: Scalars['Boolean'];
};

export type AgreedToBeContactedByLawEnforcementData = {
  __typename?: 'AgreedToBeContactedByLawEnforcementData';
  agreed: Scalars['Boolean'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  cityAndState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AgreedToBeContactedByLawEnforcementDataInput = {
  agreed: Scalars['Boolean'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber?: Maybe<Scalars['String']>;
  cityAndState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
};

export type AgreedToBeContactedData = {
  __typename?: 'AgreedToBeContactedData';
  agreed: Scalars['Boolean'];
  agreedToBeContactedByLawEnforcement?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  whereDidYouHear?: Maybe<Scalars['String']>;
  cityAndState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  krollGroupActionOptIn?: Maybe<Scalars['Boolean']>;
  krollRequestInvestigativeSupport?: Maybe<Scalars['Boolean']>;
};

export type AgreedToBeContactedDataInput = {
  agreed: Scalars['Boolean'];
  agreedToBeContactedByLawEnforcement?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  phoneNumber?: Maybe<Scalars['String']>;
  whereDidYouHear?: Maybe<Scalars['String']>;
  cityAndState?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  countryCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  krollGroupActionOptIn?: Maybe<Scalars['Boolean']>;
  krollRequestInvestigativeSupport?: Maybe<Scalars['Boolean']>;
};

export type BasicUser = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  photo?: Maybe<UserProfilePhoto>;
  trusted?: Maybe<Scalars['Boolean']>;
};

export type BiDirectionalVote = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  voter: User;
  direction: Scalars['String'];
};

export type Chain = {
  __typename?: 'Chain';
  kind: ChainKind;
  reportsFiledCount: Scalars['Int'];
};

export enum ChainKind {
  BTC = 'BTC',
  ETH = 'ETH',
  TRON = 'TRON',
  SOL = 'SOL',
  POLYGON = 'POLYGON',
  BINANCE = 'BINANCE',
  HBAR = 'HBAR',
  CARDANO = 'CARDANO',
  LITECOIN = 'LITECOIN',
  AVALANCHE = 'AVALANCHE',
  MULTIVERSX = 'MULTIVERSX',
  ARBITRUM = 'ARBITRUM',
  ALGORAND = 'ALGORAND',
  BASE = 'BASE'
}

export type ChainsDateFilter = {
  /** List Chains that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List Chains that have been created at or before the given date. */
  before?: Maybe<Scalars['DateTime']>;
};

export type ChainsInput = {
  /** Deprecated, use `scamCategories` instead */
  scamCategory?: Maybe<ScamCategoryKind>;
  scamCategories?: Maybe<Array<ScamCategoryKind>>;
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  reportedById?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<ChainsOrderBy>;
  org?: Maybe<OrganizationForReportsInput>;
  dateFilter?: Maybe<ChainsDateFilter>;
};

export type ChainsOrderBy = {
  field: ChainsOrderByField;
  direction: OrderByDirection;
};

export enum ChainsOrderByField {
  REPORTS_FILED_COUNT = 'REPORTS_FILED_COUNT'
}

export type CheckAddressForMistakenScammerInput = {
  address: Scalars['String'];
  chain: ChainKind;
};

export type CheckAddressForMistakenScammerPayload = {
  __typename?: 'CheckAddressForMistakenScammerPayload';
  isMistakenScammer: Scalars['Boolean'];
};

export type CheckUrlForMistakenScammerInput = {
  /** URLs of the scammer that is being reported. */
  urls: Array<Scalars['String']>;
};

export type CheckUrlForMistakenScammerPayload = {
  __typename?: 'CheckUrlForMistakenScammerPayload';
  /** Whether or not the URL is mistaken scammer URL. */
  hasMistakenScammer: Scalars['Boolean'];
};

export type City = Node & AbstractEntity & {
  __typename?: 'City';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  label: Scalars['String'];
  countryCode: Scalars['String'];
  stateCode: Scalars['String'];
};

export type Comment = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  author: User;
};

export type CommentableEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  comments?: Maybe<Array<Comment>>;
  commentsCount: Scalars['Int'];
};

export enum CommentableEntityKind {
  REPORT = 'REPORT'
}

export type CompromiseIndicatorInput = {
  type: CompromiseIndicatorKind;
  value: Scalars['String'];
};

export enum CompromiseIndicatorKind {
  FILE = 'FILE',
  HASH = 'HASH',
  IP = 'IP',
  CATEGORY = 'CATEGORY',
  VARIANT = 'VARIANT'
}

/** The input for the contactUs mutation */
export type ContactUsInput = {
  /** The full name of the requester */
  fullName: Scalars['String'];
  /** The email of the requester */
  email: Scalars['String'];
  /** The message sent */
  message: Scalars['String'];
};

/** The return type for the contactUs mutation */
export type ContactUsPayload = {
  __typename?: 'ContactUsPayload';
  /** Whether or not the inquiry was successfully submitted */
  success: Scalars['Boolean'];
};

export type Country = Node & AbstractEntity & {
  __typename?: 'Country';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isoCode: Scalars['String'];
  label: Scalars['String'];
};

/** Parameters for createMarketingLead mutation. */
export type CreateMarketingLeadInput = {
  /** The email for the lead being created */
  email: Scalars['String'];
};

/** Return type for the createMarketingLead mutation. */
export type CreateMarketingLeadPayload = {
  __typename?: 'CreateMarketingLeadPayload';
  /** The marketing lead that was created. */
  marketingLead: MarketingLead;
  /** Indicates whether the marketing lead was created */
  success: Scalars['Boolean'];
};

/** The input type for the createOrganization mutation. */
export type CreateOrganizationInput = {
  /** The name of the organization. */
  name: Scalars['String'];
  /** The private keywords of the organization. */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** The public keywords of the organization. */
  subdomainKeywords?: Maybe<Array<Scalars['String']>>;
  /** The users of the organization. */
  userIds?: Maybe<Array<Scalars['ID']>>;
  /** The roles of the organization. */
  roles?: Maybe<Array<OrganizationRole>>;
  /** The url of the organization's image. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The description of the organization. */
  description?: Maybe<Scalars['String']>;
  /** The api call limit of the organization. */
  apiCallLimit?: Maybe<Scalars['Int']>;
};

/** The response type for the createOrganization mutation. */
export type CreateOrganizationPayload = {
  __typename?: 'CreateOrganizationPayload';
  organization: Organization;
  success: Scalars['Boolean'];
};


/** Input type for deleteComment mutation. */
export type DeleteCommentInput = {
  id: Scalars['ID'];
  subjectType: CommentableEntityKind;
};

/** The return type for the deleteComment mutation */
export type DeleteCommentPayload = {
  __typename?: 'DeleteCommentPayload';
  /** The subject that was commented on */
  subject?: Maybe<CommentableEntity>;
  success: Scalars['Boolean'];
};

export type DeleteReportInput = {
  id: Scalars['ID'];
};

export type DeleteReportPayload = {
  __typename?: 'DeleteReportPayload';
  /** Whether or not the report was deleted successfully */
  success: Scalars['Boolean'];
};

export type DownloadCsvInput = {
  scamCategory?: Maybe<ScamCategoryKind>;
  chain?: Maybe<ChainKind>;
  reporterId?: Maybe<Scalars['ID']>;
  reportIds?: Maybe<Array<Scalars['ID']>>;
  address?: Maybe<Scalars['String']>;
  organizationId: Scalars['String'];
  /** The search string to look for. */
  query?: Maybe<Scalars['String']>;
};

export type DownloadCsvPayload = {
  __typename?: 'DownloadCSVPayload';
  /** The url to download the CSV file from. */
  url: Scalars['String'];
};

export type EmailAvailableInput = {
  email: Scalars['String'];
};

export type EmailAvailablePayload = {
  __typename?: 'EmailAvailablePayload';
  available: Scalars['Boolean'];
};

/** Return type of fileGuestReport mutation */
export type FileGuestReportPayload = {
  __typename?: 'FileGuestReportPayload';
  /** The report that was filed */
  report: Report;
  /** Whether or not the report was filed successfully */
  success: Scalars['Boolean'];
};

export type FileReportEvidenceInput = {
  fileUploadId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

export type FileReportInput = {
  /** This indicates that the report is submitted privately */
  isPrivate?: Maybe<Scalars['Boolean']>;
  addresses?: Maybe<Array<AddressInput>>;
  tokens?: Maybe<Array<TokenInput>>;
  transactionHashes?: Maybe<Array<TransactionHashInput>>;
  scamCategory: ScamCategoryKind;
  /** The description of the type of problem. Only used when the scamCategory is set to OTHER. */
  categoryDescription?: Maybe<Scalars['String']>;
  lexicalSerializedDescription: Scalars['JSON'];
  evidence?: Maybe<Array<FileReportEvidenceInput>>;
  accusedScammers?: Maybe<Array<AccusedScammerInput>>;
  agreedToBeContactData?: Maybe<AgreedToBeContactedDataInput>;
  agreedToBeContactedByLawEnforcementData?: Maybe<AgreedToBeContactedByLawEnforcementDataInput>;
  losses?: Maybe<Array<ReportLossInput>>;
  compromiseIndicators?: Maybe<Array<CompromiseIndicatorInput>>;
  source?: Maybe<ReportSourceKind>;
};

/** Return type of fileReport mutation */
export type FileReportPayload = {
  __typename?: 'FileReportPayload';
  /** The report that was filed */
  report: Report;
  /** Whether or not the report was filed successfully */
  success: Scalars['Boolean'];
};

export type FileUpload = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  author: User;
};

export type Flag = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy: User;
};

export type FlaggableEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  flags?: Maybe<Array<Flag>>;
};

export enum FlaggableEntityKind {
  REPORT = 'REPORT'
}

/** Return type for the generateApiKey mutation. */
export type GenerateApiKeyPayload = {
  __typename?: 'GenerateApiKeyPayload';
  /** Generated api key. */
  apiKey: Scalars['String'];
};


export enum KrollReportSupportOptInKind {
  GROUP_ACTION = 'GROUP_ACTION',
  INVESTIGATIVE_OPTIONS = 'INVESTIGATIVE_OPTIONS'
}

export type LogoutResponse = {
  __typename?: 'LogoutResponse';
  success: Scalars['Boolean'];
};

/** Input type for the markAsReadByIds mutation. */
export type MarkAsReadByIdsInput = {
  ids: Array<Scalars['ID']>;
};

/** Return type for the markAsReadByIds mutation. */
export type MarkAsReadByIdsPayload = {
  __typename?: 'MarkAsReadByIdsPayload';
  /** The amount of notifications marked as read. */
  count: Scalars['Int'];
  /** Whether or not all notifications were marked as read. */
  success: Scalars['Boolean'];
};

export type MarketingLead = Node & AbstractEntity & {
  __typename?: 'MarketingLead';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  email: Scalars['String'];
};

export type MatchingKeywordsInput = {
  org: OrganizationForReportsInput;
};

export type MistakenScammerAddressList = Node & AbstractEntity & {
  __typename?: 'MistakenScammerAddressList';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  address: Scalars['String'];
  chain: ChainKind;
};

/** Input type for the mistakenScammer query. */
export type MistakenScammerInput = {
  addresses: Array<AddressInput>;
};

/** Return type for the mistakenScammer query. */
export type MistakenScammerPayload = {
  __typename?: 'MistakenScammerPayload';
  addresses: Array<AddressWithMistakenScammerState>;
};

export type MistakenScammerUrl = Node & AbstractEntity & {
  __typename?: 'MistakenScammerUrl';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  url: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /** Generate api key. */
  generateApiKey: GenerateApiKeyPayload;
  /** Revoke api key. */
  revokeApiKey: RevokeApiKeyPayload;
  requestOneTimePassword: RequestOneTimePasswordPayload;
  logout: LogoutResponse;
  addVote: AddVotePayload;
  removeVote: RemoveVotePayload;
  addComment: AddCommentPayload;
  deleteComment: DeleteCommentPayload;
  updateComment: UpdateCommentPayload;
  contactUsInquiry: ContactUsPayload;
  uploadFile: UploadFilePayload;
  addFlag: AddFlagPayload;
  createMarketingLead: CreateMarketingLeadPayload;
  createOrganization: CreateOrganizationPayload;
  updateOrganization: UpdateOrganizationPayload;
  fileReport: FileReportPayload;
  fileGuestReport: FileGuestReportPayload;
  /** Update a report. */
  updateReport: UpdateReportPayload;
  deleteReport: DeleteReportPayload;
  reportSupportOptIn: ReportSupportOptInPayload;
  updateUserProfile: UpdateUserProfilePayload;
  /** Mark as read by ids. */
  markAsReadByIds: MarkAsReadByIdsPayload;
  partnerContactUsInquiry: PartnerContactUsPayload;
};


export type MutationAddVoteArgs = {
  input: AddVoteInput;
};


export type MutationRemoveVoteArgs = {
  input: RemoveVoteInput;
};


export type MutationAddCommentArgs = {
  input: AddCommentInput;
};


export type MutationDeleteCommentArgs = {
  input: DeleteCommentInput;
};


export type MutationUpdateCommentArgs = {
  input: UpdateCommentInput;
};


export type MutationContactUsInquiryArgs = {
  input: ContactUsInput;
};


export type MutationUploadFileArgs = {
  file: Scalars['Upload'];
  input: UploadFileInput;
};


export type MutationAddFlagArgs = {
  input: AddFlagInput;
};


export type MutationCreateMarketingLeadArgs = {
  input: CreateMarketingLeadInput;
};


export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};


export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};


export type MutationFileReportArgs = {
  input: FileReportInput;
};


export type MutationFileGuestReportArgs = {
  input: FileReportInput;
};


export type MutationUpdateReportArgs = {
  input: UpdateReportInput;
};


export type MutationDeleteReportArgs = {
  input: DeleteReportInput;
};


export type MutationReportSupportOptInArgs = {
  input: ReportSupportOptInInput;
};


export type MutationUpdateUserProfileArgs = {
  input: UpdateUserProfileInput;
};


export type MutationMarkAsReadByIdsArgs = {
  input: MarkAsReadByIdsInput;
};


export type MutationPartnerContactUsInquiryArgs = {
  input: PartnerContactUsInput;
};

/** An object with an ID */
export type Node = {
  id: Scalars['ID'];
};

export enum OrderByDirection {
  ASC = 'ASC',
  DESC = 'DESC'
}

export type Organization = Node & AbstractEntity & {
  __typename?: 'Organization';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  keywords: Array<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  apiCallLimit?: Maybe<Scalars['Int']>;
  isSubdomainViewable: Scalars['Boolean'];
  hasPartnerApiAccess: Scalars['Boolean'];
};

export type OrganizationForReportsInput = {
  id: Scalars['ID'];
  filterBy?: Maybe<ReportsByOrganizationFilter>;
};

/** The input type for the organization query. */
export type OrganizationInput = {
  /** The id of the organization. */
  id?: Maybe<Scalars['ID']>;
  /** The slug of the organization. */
  slug?: Maybe<Scalars['String']>;
};

/** The response type for the organization query. */
export type OrganizationPayload = {
  __typename?: 'OrganizationPayload';
  organization: Organization;
  success: Scalars['Boolean'];
};

export enum OrganizationRole {
  SENTINEL_PARTNERSHIP = 'SENTINEL_PARTNERSHIP',
  CENTURION_PARTNERSHIP = 'CENTURION_PARTNERSHIP',
  PALADIN_PARTNERSHIP = 'PALADIN_PARTNERSHIP',
  LAW_ENFORCEMENT = 'LAW_ENFORCEMENT',
  LEGACY_LAUNCH_PARTNER_API_CAPABILITIES = 'LEGACY_LAUNCH_PARTNER_API_CAPABILITIES',
  ALLOWED_LEDGER_QUERY_SOURCE = 'ALLOWED_LEDGER_QUERY_SOURCE',
  ALLOWED_SAFEPAL_QUERY_SOURCE = 'ALLOWED_SAFEPAL_QUERY_SOURCE',
  ALLOWED_1INCH_QUERY_SOURCE = 'ALLOWED_1INCH_QUERY_SOURCE',
  ALLOWED_BITFLY_QUERY_SOURCE = 'ALLOWED_BITFLY_QUERY_SOURCE',
  ALLOWED_SOLANAFM_QUERY_SOURCE = 'ALLOWED_SOLANAFM_QUERY_SOURCE',
  ALLOWED_METAMASK_QUERY_SOURCE = 'ALLOWED_METAMASK_QUERY_SOURCE',
  ALLOWED_BLOKK_QUERY_SOURCE = 'ALLOWED_BLOKK_QUERY_SOURCE',
  ALLOWED_DANY_QUERY_SOURCE = 'ALLOWED_DANY_QUERY_SOURCE',
  ALLOWED_FBI_QUERY_SOURCE = 'ALLOWED_FBI_QUERY_SOURCE',
  ALLOWED_SANJOSECA_GOV_QUERY_SOURCE = 'ALLOWED_SANJOSECA_GOV_QUERY_SOURCE',
  ALLOWED_OPP_QUERY_SOURCE = 'ALLOWED_OPP_QUERY_SOURCE',
  ALLOWED_MTV_QUERY_SOURCE = 'ALLOWED_MTV_QUERY_SOURCE',
  ALLOWED_COINBASE_QUERY_SOURCE = 'ALLOWED_COINBASE_QUERY_SOURCE',
  ALLOWED_RCMP_QUERY_SOURCE = 'ALLOWED_RCMP_QUERY_SOURCE',
  ALLOWED_ACTION_FRAUD_QUERY_SOURCE = 'ALLOWED_ACTION_FRAUD_QUERY_SOURCE',
  ALLOWED_NYPD_QUERY_SOURCE = 'ALLOWED_NYPD_QUERY_SOURCE',
  ALLOWED_AVASCAN_QUERY_SOURCE = 'ALLOWED_AVASCAN_QUERY_SOURCE',
  ALLOWED_WEBACY_QUERY_SOURCE = 'ALLOWED_WEBACY_QUERY_SOURCE',
  ALLOWED_SHAMROCK_QUERY_SOURCE = 'ALLOWED_SHAMROCK_QUERY_SOURCE',
  CAN_PASS_CONTRIBUTOR = 'CAN_PASS_CONTRIBUTOR'
}

export type PageInfo = {
  __typename?: 'PageInfo';
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
  endCursor?: Maybe<Scalars['String']>;
};

/** The input for the contactUs mutation */
export type PartnerContactUsInput = {
  /** The full name of the requester */
  fullName: Scalars['String'];
  /** The email of the requester */
  email: Scalars['String'];
  /** The organization of the requester */
  organization: Scalars['String'];
  /** The message sent */
  message: Scalars['String'];
};

/** The return type for the partnerContactUs mutation */
export type PartnerContactUsPayload = {
  __typename?: 'PartnerContactUsPayload';
  /** Whether or not the inquiry was successfully submitted */
  success: Scalars['Boolean'];
};

export type Query = {
  __typename?: 'Query';
  addressLabels: AddressConnection;
  organization: OrganizationPayload;
  reports: ReportConnection;
  reportUserLocation: ReportUserLocation;
  downloadCSV: DownloadCsvPayload;
  report: Report;
  scamCategories: Array<ScamCategory>;
  chains: Array<Chain>;
  reportStats: ReportStats;
  me?: Maybe<User>;
  user: BasicUser;
  usernameAvailable: UsernameAvailablePayload;
  emailAvailable: EmailAvailablePayload;
  topUsers: TopUsersPayload;
  notifications: UserAppNotificationConnection;
  /** Mistaken scammer. */
  mistakenScammer: MistakenScammerPayload;
  checkAddressForMistakenScammer: CheckAddressForMistakenScammerPayload;
  checkUrlForMistakenScammer: CheckUrlForMistakenScammerPayload;
};


export type QueryAddressLabelsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
  input?: Maybe<AddressLabelsInput>;
};


export type QueryOrganizationArgs = {
  input: OrganizationInput;
};


export type QueryReportsArgs = {
  input?: Maybe<ReportsInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
};


export type QueryReportUserLocationArgs = {
  input?: Maybe<ReportUserLocationInput>;
};


export type QueryDownloadCsvArgs = {
  input: DownloadCsvInput;
};


export type QueryReportArgs = {
  input: ReportInput;
};


export type QueryScamCategoriesArgs = {
  input?: Maybe<ScamCategoriesInput>;
};


export type QueryChainsArgs = {
  input?: Maybe<ChainsInput>;
};


export type QueryUserArgs = {
  input?: Maybe<UserInput>;
};


export type QueryUsernameAvailableArgs = {
  input: UsernameAvailableInput;
};


export type QueryEmailAvailableArgs = {
  input: EmailAvailableInput;
};


export type QueryTopUsersArgs = {
  input?: Maybe<TopUsersInput>;
};


export type QueryNotificationsArgs = {
  after?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
};


export type QueryMistakenScammerArgs = {
  input: MistakenScammerInput;
};


export type QueryCheckAddressForMistakenScammerArgs = {
  input: CheckAddressForMistakenScammerInput;
};


export type QueryCheckUrlForMistakenScammerArgs = {
  input: CheckUrlForMistakenScammerInput;
};

/** Parameters for removeVote mutation. */
export type RemoveVoteInput = {
  /** The ID of the subject to remove the vote from. */
  subjectId: Scalars['ID'];
  /** The type of subject to remove the vote from. */
  subjectType: VotableEntityKind;
};

/** Return type for removeVote mutation. */
export type RemoveVotePayload = {
  __typename?: 'RemoveVotePayload';
  /** The subject that was voted on */
  subject?: Maybe<VotableEntity>;
  /** Indicates whether removing the vote was successful */
  success: Scalars['Boolean'];
};

export type Report = Node & AbstractEntity & VotableEntity & CommentableEntity & FlaggableEntity & {
  __typename?: 'Report';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  flags?: Maybe<Array<Flag>>;
  biDirectionalVoteCount: Scalars['Int'];
  comments?: Maybe<Array<ReportComment>>;
  commentsCount: Scalars['Int'];
  addresses: Array<ReportAddress>;
  scamCategory: ScamCategoryKind;
  /** This indicates that the team checked the report against spam */
  checked?: Maybe<Scalars['Boolean']>;
  /** This indicates that the report is submitted privately */
  isPrivate: Scalars['Boolean'];
  /** A description of the type of scam that was reported. */
  categoryDescription?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  evidences?: Maybe<Array<ReportEvidence>>;
  accusedScammers: Array<AccusedScammer>;
  agreedToBeContacted?: Maybe<AgreedToBeContactedData>;
  agreedToBeContactedByLawEnforcement?: Maybe<AgreedToBeContactedByLawEnforcementData>;
  reportedBy: User;
  viewerDidVote?: Maybe<VoteDirection>;
  matchingKeywords?: Maybe<Array<Scalars['String']>>;
  losses: Array<ReportLoss>;
  source: ReportSourceKind;
  compromiseIndicators: Array<ReportCompromiseIndicator>;
  lexicalSerializedDescription: Scalars['JSON'];
  tokens: Array<ReportToken>;
  transactionHashes: Array<ReportTransactionHash>;
};


export type ReportMatchingKeywordsArgs = {
  input?: Maybe<MatchingKeywordsInput>;
};

export type ReportAddress = Node & AbstractEntity & {
  __typename?: 'ReportAddress';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  chain?: Maybe<ChainKind>;
  /** The label of the address */
  label?: Maybe<Scalars['String']>;
};

export type ReportComment = AbstractEntity & Comment & Node & {
  __typename?: 'ReportComment';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  body: Scalars['String'];
  author: User;
  subject: Report;
};

/**
 * Note that `since` and `until` will be processed, example input/output:
 * `since = 2022-05-30T19:44:24.983Z` will be turned into `since = 2022-05-30T00:00:00.000Z` (time reset to 00:00:00:000)
 * `until = 2022-09-29T19:44:24.983Z` will be turned into `until = 2022-09-29T23:59:59.999Z` (time reset to 23:59:59.999)`
 */
export type ReportCommentsGivenCountDateFilter = {
  /** List ReportCommentsGivenCount that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List ReportCommentsGivenCount that have been created at or before the given date. */
  until?: Maybe<Scalars['DateTime']>;
};

export type ReportCommentsGivenCountInput = {
  /** List given report comments count only for reports that have been filed between `since` and `until`. */
  createdAtFilter?: Maybe<ReportCommentsGivenCountDateFilter>;
};

/**
 * Note that `since` and `until` will be processed, example input/output:
 * `since = 2022-05-30T19:44:24.983Z` will be turned into `since = 2022-05-30T00:00:00.000Z` (time reset to 00:00:00:000)
 * `until = 2022-09-29T19:44:24.983Z` will be turned into `until = 2022-09-29T23:59:59.999Z` (time reset to 23:59:59.999)`
 */
export type ReportCommentsReceivedCountDateFilter = {
  /** List ReportCommentsReceivedCount that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List ReportCommentsReceivedCount that have been created at or before the given date. */
  until?: Maybe<Scalars['DateTime']>;
};

export type ReportCommentsReceivedCountInput = {
  /** List received report comments count only for reports that have been filed between `since` and `until`. */
  createdAtFilter?: Maybe<ReportCommentsReceivedCountDateFilter>;
};

export type ReportCompromiseIndicator = Node & AbstractEntity & {
  __typename?: 'ReportCompromiseIndicator';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  type: CompromiseIndicatorKind;
  value: Scalars['String'];
};

export type ReportConnection = {
  __typename?: 'ReportConnection';
  pageInfo: PageInfo;
  edges: Array<ReportEdge>;
  nodes?: Maybe<Array<Report>>;
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type ReportDateFilter = {
  /** List Reports that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List Reports that have been created at or before the given date. */
  before?: Maybe<Scalars['DateTime']>;
};

export type ReportEdge = {
  __typename?: 'ReportEdge';
  node: Report;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type ReportEvidence = Node & AbstractEntity & {
  __typename?: 'ReportEvidence';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  photo?: Maybe<ReportEvidenceUpload>;
};

export type ReportEvidenceUpload = Node & AbstractEntity & FileUpload & {
  __typename?: 'ReportEvidenceUpload';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  author: User;
  description?: Maybe<Scalars['String']>;
};

export type ReportFlag = Node & AbstractEntity & Flag & {
  __typename?: 'ReportFlag';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  flaggedBy: User;
};

export type ReportInput = {
  id: Scalars['ID'];
};

export type ReportLoss = Node & AbstractEntity & {
  __typename?: 'ReportLoss';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  /** The type of asset that was lost */
  asset: Scalars['String'];
  /** The amount that was lost */
  amount: Scalars['Float'];
};

export type ReportLossInput = {
  asset: Scalars['String'];
  amount: Scalars['Float'];
};

export enum ReportSourceKind {
  CHAINABUSE = 'CHAINABUSE',
  PARTNER_RANSOMWHERE = 'PARTNER_RANSOMWHERE',
  BITCOINABUSE = 'BITCOINABUSE',
  REST_API = 'REST_API',
  PARTNER_LEDGER = 'PARTNER_LEDGER',
  PARTNER_SAFEPAL = 'PARTNER_SAFEPAL',
  PARTNER_1INCH = 'PARTNER_1INCH',
  PARTNER_1INCH_ANDROID = 'PARTNER_1INCH_ANDROID',
  PARTNER_1INCH_IOS = 'PARTNER_1INCH_IOS',
  PARTNER_BLOKK = 'PARTNER_BLOKK',
  PARTNER_DANY = 'PARTNER_DANY',
  PARTNER_FBI = 'PARTNER_FBI',
  PARTNER_SANJOSECA_GOV = 'PARTNER_SANJOSECA_GOV',
  PARTNER_OPP = 'PARTNER_OPP',
  PARTNER_MTV = 'PARTNER_MTV',
  PARTNER_COINBASE = 'PARTNER_COINBASE',
  PARTNER_RCMP = 'PARTNER_RCMP',
  PARTNER_ACTION_FRAUD = 'PARTNER_ACTION_FRAUD',
  PARTNER_NYPD = 'PARTNER_NYPD',
  PARTNER_BITFLY = 'PARTNER_BITFLY',
  PARTNER_SOLANAFM = 'PARTNER_SOLANAFM',
  PARTNER_METAMASK = 'PARTNER_METAMASK',
  PARTNER_AVASCAN = 'PARTNER_AVASCAN',
  PARTNER_WEBACY = 'PARTNER_WEBACY',
  PARTNER_SHAMROCK = 'PARTNER_SHAMROCK'
}

export type ReportStats = {
  __typename?: 'ReportStats';
  reportsCount: Scalars['Int'];
  reportedAddressesCount: Scalars['Int'];
  reportedUrlsCount: Scalars['Int'];
};

export type ReportSupportOptInInput = {
  email?: Maybe<Scalars['String']>;
  reportId: Scalars['ID'];
  partner: ReportSupportPartner;
  kind: KrollReportSupportOptInKind;
};

export type ReportSupportOptInPayload = {
  __typename?: 'ReportSupportOptInPayload';
  success: Scalars['Boolean'];
};

export enum ReportSupportPartner {
  KROLL = 'KROLL'
}

export type ReportToken = Node & AbstractEntity & {
  __typename?: 'ReportToken';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  tokenId: Scalars['String'];
};

export type ReportTransactionHash = Node & AbstractEntity & {
  __typename?: 'ReportTransactionHash';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  hash: Scalars['String'];
  chain: ChainKind;
  /** The label of the transaction hash */
  label?: Maybe<Scalars['String']>;
};

export type ReportUserLocation = {
  __typename?: 'ReportUserLocation';
  countries?: Maybe<Array<Country>>;
  states?: Maybe<Array<State>>;
  cities?: Maybe<Array<City>>;
};

export type ReportUserLocationInput = {
  countryCode?: Maybe<Scalars['String']>;
  stateCode?: Maybe<Scalars['String']>;
};

export type ReportVote = Node & AbstractEntity & BiDirectionalVote & {
  __typename?: 'ReportVote';
  voter: User;
  direction: Scalars['String'];
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  subject: Report;
};

/**
 * Note that `since` and `until` will be processed, example input/output:
 * `since = 2022-05-30T19:44:24.983Z` will be turned into `since = 2022-05-30T00:00:00.000Z` (time reset to 00:00:00:000)
 * `until = 2022-09-29T19:44:24.983Z` will be turned into `until = 2022-09-29T23:59:59.999Z` (time reset to 23:59:59.999)`
 */
export type ReportVotesCountDateFilter = {
  /** List ReportVotesCount that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List ReportVotesCount that have been created at or before the given date. */
  until?: Maybe<Scalars['DateTime']>;
};

export type ReportVotesCountInput = {
  /** List received report votes count only for reports that have been filed between `since` and `until`. */
  createdAtFilter?: Maybe<ReportVotesCountDateFilter>;
};

/**
 * Note that `since` and `until` will be processed, example input/output:
 * `since = 2022-05-30T19:44:24.983Z` will be turned into `since = 2022-05-30T00:00:00.000Z` (time reset to 00:00:00:000)
 * `until = 2022-09-29T19:44:24.983Z` will be turned into `until = 2022-09-29T23:59:59.999Z` (time reset to 23:59:59.999)`
 */
export type ReportVotesGivenCountDateFilter = {
  /** List ReportVotesGivenCount that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List ReportVotesGivenCount that have been created at or before the given date. */
  until?: Maybe<Scalars['DateTime']>;
};

export type ReportVotesGivenCountInput = {
  /** List given report votes count only for reports that have been filed between `since` and `until`. */
  createdAtFilter?: Maybe<ReportVotesGivenCountDateFilter>;
};

export enum ReportsByOrganizationFilter {
  PUBLIC_KEYWORDS = 'PUBLIC_KEYWORDS',
  PRIVATE_KEYWORDS = 'PRIVATE_KEYWORDS'
}

export type ReportsInput = {
  /** Deprecated, use `scamCategories` instead */
  scamCategory?: Maybe<ScamCategoryKind>;
  scamCategories?: Maybe<Array<ScamCategoryKind>>;
  /** Deprecated, use `chains` instead */
  chain?: Maybe<ChainKind>;
  chains?: Maybe<Array<ChainKind>>;
  reporterId?: Maybe<Scalars['ID']>;
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  org?: Maybe<OrganizationForReportsInput>;
  orderBy?: Maybe<ReportsOrderBy>;
  dateFilter?: Maybe<ReportDateFilter>;
  /** The search string to look for. */
  query?: Maybe<Scalars['String']>;
  /** Show reports by trusted users. */
  trusted?: Maybe<Scalars['Boolean']>;
  /** Show verified reports. */
  checked?: Maybe<Scalars['Boolean']>;
  /** Reported nfts token ids to filter by. */
  tokenIds?: Maybe<Array<Scalars['String']>>;
  /** Reported transaction hash to filter by. */
  transactionHashes?: Maybe<Array<Scalars['String']>>;
};

export type ReportsOrderBy = {
  field: ReportsOrderByField;
  direction: OrderByDirection;
};

export enum ReportsOrderByField {
  CREATED_AT = 'CREATED_AT',
  SUBMITTED_BY = 'SUBMITTED_BY',
  UPVOTES_COUNT = 'UPVOTES_COUNT',
  DOWNVOTES_COUNT = 'DOWNVOTES_COUNT',
  COMMENTS_COUNT = 'COMMENTS_COUNT'
}

export type RequestOneTimePasswordPayload = {
  __typename?: 'RequestOneTimePasswordPayload';
  /** Indicates whether or not the one time password was successfully requested. */
  success: Scalars['Boolean'];
};

/** Return type for the revokeApiKey mutation. */
export type RevokeApiKeyPayload = {
  __typename?: 'RevokeApiKeyPayload';
  /** Whether the user's api key was revoked. */
  success: Scalars['Boolean'];
};

export type ScamCategoriesDateFilter = {
  /** List ScamCategories that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List ScamCategories that have been created at or before the given date. */
  before?: Maybe<Scalars['DateTime']>;
};

export type ScamCategoriesInput = {
  /** Deprecated, use `chains` instead */
  chain?: Maybe<ChainKind>;
  chains?: Maybe<Array<ChainKind>>;
  address?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  reportedById?: Maybe<Scalars['ID']>;
  orderBy?: Maybe<ScamCategoriesOrderBy>;
  take?: Maybe<Scalars['Int']>;
  org?: Maybe<OrganizationForReportsInput>;
  dateFilter?: Maybe<ScamCategoriesDateFilter>;
};

export type ScamCategoriesOrderBy = {
  field: ScamCategoriesOrderByField;
  direction: OrderByDirection;
};

export enum ScamCategoriesOrderByField {
  REPORTS_FILED_COUNT = 'REPORTS_FILED_COUNT'
}

export type ScamCategory = {
  __typename?: 'ScamCategory';
  kind: ScamCategoryKind;
  reportsFiledCount: Scalars['Int'];
};

export enum ScamCategoryKind {
  RUG_PULL = 'RUG_PULL',
  UKRANIAN_DONATION_SCAM = 'UKRANIAN_DONATION_SCAM',
  DONATION_SCAM = 'DONATION_SCAM',
  SEXTORTION = 'SEXTORTION',
  SIM_SWAP = 'SIM_SWAP',
  PHISHING = 'PHISHING',
  RANSOMWARE = 'RANSOMWARE',
  CONTRACT_EXPLOIT = 'CONTRACT_EXPLOIT',
  AIRDROP = 'AIRDROP',
  ROMANCE = 'ROMANCE',
  PIGBUTCHERING = 'PIGBUTCHERING',
  OTHER = 'OTHER',
  FAKE_PROJECT = 'FAKE_PROJECT',
  OTHER_BLACKMAIL = 'OTHER_BLACKMAIL',
  IMPERSONATION = 'IMPERSONATION',
  FAKE_RETURNS = 'FAKE_RETURNS',
  UPGRADE_SCAM = 'UPGRADE_SCAM',
  MAN_IN_THE_MIDDLE_ATTACK = 'MAN_IN_THE_MIDDLE_ATTACK',
  OTHER_HACK = 'OTHER_HACK',
  OTHER_INVESTMENT_SCAM = 'OTHER_INVESTMENT_SCAM'
}

export enum SocialServiceKind {
  TWITTER = 'TWITTER',
  DISCORD = 'DISCORD',
  INSTAGRAM = 'INSTAGRAM',
  TELEGRAM = 'TELEGRAM'
}

export type State = Node & AbstractEntity & {
  __typename?: 'State';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  isoCode: Scalars['String'];
  label: Scalars['String'];
  countryCode: Scalars['String'];
};

export enum SubjectKind {
  REPORT = 'REPORT'
}

export type SubjectPayload = {
  __typename?: 'SubjectPayload';
  id: Scalars['ID'];
  kind: SubjectKind;
  label?: Maybe<Scalars['String']>;
  reportScamCategory?: Maybe<ScamCategoryKind>;
};

export type SuspectedPhishingDomain = Node & AbstractEntity & {
  __typename?: 'SuspectedPhishingDomain';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  domain: Scalars['String'];
  similarTo: Array<Scalars['String']>;
};

export type TokenInput = {
  tokenId: Scalars['String'];
};

/**
 * Note that `since` and `until` will be processed, example input/output:
 * `since = 2022-05-30T19:44:24.983Z` will be turned into `since = 2022-05-30T00:00:00.000Z` (time reset to 00:00:00:000)
 * `until = 2022-09-29T19:44:24.983Z` will be turned into `until = 2022-09-29T23:59:59.999Z` (time reset to 23:59:59.999)`
 */
export type TopUsersDateFilter = {
  /** List TopUsers that have been created at or after the given date. */
  since?: Maybe<Scalars['DateTime']>;
  /** List TopUsers that have been created at or before the given date. */
  until?: Maybe<Scalars['DateTime']>;
};

export type TopUsersInput = {
  /** The number of top users (based on report filed count) to be returned. */
  top?: Maybe<Scalars['Int']>;
  createdAtFilter?: Maybe<TopUsersDateFilter>;
};

export type TopUsersPayload = {
  __typename?: 'TopUsersPayload';
  users: Array<User>;
};

export type TransactionHashInput = {
  hash: Scalars['String'];
  chain: ChainKind;
  label?: Maybe<Scalars['String']>;
};

/** Accused Scammer input for the updateReport mutation. */
export type UpdateAccusedScammerInfoInput = {
  /** The ID of the scammer info to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['ID']>;
  contact?: Maybe<Scalars['String']>;
  type?: Maybe<AccusedScammerContactInfoKind>;
};

/** Accused Scammer input for the updateReport mutation. */
export type UpdateAccusedScammerInput = {
  /** The ID of the scammer to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['ID']>;
  info?: Maybe<Array<UpdateAccusedScammerInfoInput>>;
};

/** Input type for updateComment mutation. */
export type UpdateCommentInput = {
  body: Scalars['String'];
  id: Scalars['ID'];
  subjectType: CommentableEntityKind;
};

/** The return type for the updateComment mutation */
export type UpdateCommentPayload = {
  __typename?: 'UpdateCommentPayload';
  /** The comment that was created */
  comment?: Maybe<Comment>;
  success: Scalars['Boolean'];
};

/** Input type for the updateReport query. If a field is not specified, then it will not be updated. If a field is specified with a null value, then it will be deleted! */
export type UpdateCompromiseIndicatorInput = {
  /** The ID of the indicator of compromise to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['String']>;
  /** The type of indicater of compromise */
  type?: Maybe<CompromiseIndicatorKind>;
  /** The value of the indicator of compromise */
  value?: Maybe<Scalars['String']>;
};

/** The input type for the updateOrganization mutation. */
export type UpdateOrganizationInput = {
  /** The id of the organization. */
  id: Scalars['ID'];
  /** The name of the organization. */
  name?: Maybe<Scalars['String']>;
  /** The private keywords of the organization. */
  keywords?: Maybe<Array<Scalars['String']>>;
  /** The public keywords of the organization. */
  subdomainKeywords?: Maybe<Array<Scalars['String']>>;
  /** The users of the organization. */
  userIds?: Maybe<Array<Scalars['ID']>>;
  /** The roles of the organization. */
  roles?: Maybe<Array<OrganizationRole>>;
  /** The url of the organization's image. */
  imageUrl?: Maybe<Scalars['String']>;
  /** The description of the organization. */
  description?: Maybe<Scalars['String']>;
  /** The api call limit of the organization. */
  apiCallLimit?: Maybe<Scalars['Int']>;
};

/** The response type for the UpdateOrganization mutation. */
export type UpdateOrganizationPayload = {
  __typename?: 'UpdateOrganizationPayload';
  organization: Organization;
  success: Scalars['Boolean'];
};

/** Input type for the updateReport query. If a field is not specified, then it will not be updated. If a field is specified with a null value, then it will be deleted! */
export type UpdateReportAddressInput = {
  /** The ID of the address to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['String']>;
  /** The blockchain address that is being reported for a scam. */
  address?: Maybe<Scalars['String']>;
  /** The domain that is being reported for a scam. */
  domain?: Maybe<Scalars['String']>;
  /** The chain that the reported blockchain address is associated with. */
  chain?: Maybe<ChainKind>;
  /** The label of the address. */
  label?: Maybe<Scalars['String']>;
};

/** Report Evidence input for the updateReport mutation. */
export type UpdateReportEvidenceInput = {
  /** The ID of the evidence to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['ID']>;
  fileUploadId?: Maybe<Scalars['ID']>;
  source?: Maybe<Scalars['String']>;
};

/** Input type for the updateReport query. If a field is not specified, then it will not be updated. If a field is specified with a null value, then it will be deleted! */
export type UpdateReportInput = {
  /** The ID of the report to update. */
  id: Scalars['ID'];
  /** The identifying address or domain that this report should be associated with. */
  addresses?: Maybe<Array<UpdateReportAddressInput>>;
  scamCategory?: Maybe<ScamCategoryKind>;
  /** The description of the type of problem. Only used when the scamCategory is set to OTHER. */
  categoryDescription?: Maybe<Scalars['String']>;
  lexicalSerializedDescription?: Maybe<Scalars['JSON']>;
  evidences?: Maybe<Array<UpdateReportEvidenceInput>>;
  accusedScammers?: Maybe<Array<UpdateAccusedScammerInput>>;
  agreedToBeContactedData?: Maybe<AgreedToBeContactedDataInput>;
  agreedToBeContactedByLawEnforcementData?: Maybe<AgreedToBeContactedByLawEnforcementDataInput>;
  losses?: Maybe<Array<UpdateReportLossInput>>;
  transactionHashes?: Maybe<Array<UpdateReportTransactionHashInput>>;
  compromiseIndicators?: Maybe<Array<UpdateCompromiseIndicatorInput>>;
  source?: Maybe<ReportSourceKind>;
  tokens?: Maybe<Array<UpdateReportTokenInput>>;
};

/** Input type for the updateReport query. If a field is not specified, then it will not be updated. If a field is specified with a null value, then it will be deleted! */
export type UpdateReportLossInput = {
  /** The ID of the address to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['String']>;
  /** The asset of the loss that is being reported for a scam. */
  asset?: Maybe<Scalars['String']>;
  /** The amount of the loss that is being reported for a scam. */
  amount?: Maybe<Scalars['Float']>;
};

/** Return type for the updateReport query. */
export type UpdateReportPayload = {
  __typename?: 'UpdateReportPayload';
  /** The report that was updated. */
  report: Report;
  /** Whether or not the report was updated successfully. */
  success: Scalars['Boolean'];
};

export type UpdateReportTokenInput = {
  id?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
};

/** Input type for the updateReport query. If a field is not specified, then it will not be updated. If a field is specified with a null value, then it will be deleted! */
export type UpdateReportTransactionHashInput = {
  /** The ID of the hash to update, if specified. Otherwise, undefined. */
  id?: Maybe<Scalars['String']>;
  /** The blockchain transaction hash that is being reported for a scam. */
  hash?: Maybe<Scalars['String']>;
  /** The chain that the reported blockchain transaction hash is associated with. */
  chain?: Maybe<ChainKind>;
  /** The label of the hash. */
  label?: Maybe<Scalars['String']>;
};

export type UpdateUserProfileInput = {
  username?: Maybe<Scalars['String']>;
  /** This is only valid for users that can edit their emails. */
  email?: Maybe<Scalars['String']>;
  agreedToBeContacted?: Maybe<Scalars['Boolean']>;
  socialInfos?: Maybe<Array<UserSocialInfoInput>>;
  acceptedPrivacyPolicyVersion?: Maybe<Scalars['String']>;
};

export type UpdateUserProfilePayload = {
  __typename?: 'UpdateUserProfilePayload';
  success: Scalars['Boolean'];
  user: User;
};


/** File upload metadata. */
export type UploadFileInput = {
  /** The description of this upload. */
  description?: Maybe<Scalars['String']>;
  /** The type of subject that this upload is associated with */
  subjectType: UploadableEntity;
  /** The ID of subject that this upload is associated with */
  subjectId?: Maybe<Scalars['ID']>;
};

/** Return type for the uploadFile mutation */
export type UploadFilePayload = {
  __typename?: 'UploadFilePayload';
  /** The subject that is associated with this upload */
  subject?: Maybe<AbstractEntity>;
  /** The upload that was created */
  upload?: Maybe<FileUpload>;
  /** Whether or not the upload was uploaded. */
  success: Scalars['Boolean'];
  /** The url for the upload. */
  url: Scalars['String'];
};

export enum UploadableEntity {
  REPORT_EVIDENCE_UPLOAD = 'REPORT_EVIDENCE_UPLOAD',
  PROFILE_PHOTO_UPLOAD = 'PROFILE_PHOTO_UPLOAD'
}

export type User = Node & AbstractEntity & BasicUser & {
  __typename?: 'User';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  username: Scalars['String'];
  photo?: Maybe<UserProfilePhoto>;
  trusted?: Maybe<Scalars['Boolean']>;
  acceptedPrivacyPolicyVersion?: Maybe<Scalars['String']>;
  /** This user's email if available. */
  email?: Maybe<Scalars['String']>;
  canEditEmail: Scalars['Boolean'];
  profile: UserProfile;
  isVerified: Scalars['Boolean'];
  /** @deprecated This does not work! */
  reportsFiled: Array<Report>;
  reportsFiledCount: Scalars['Int'];
  /** The net vote count on reports filed by this user. */
  reportVotesCount: Scalars['Int'];
  /** The net vote count given by this user. */
  reportVotesGivenCount: Scalars['Int'];
  /** The number of comments received on reports filed by this user. */
  reportCommentsReceivedCount: Scalars['Int'];
  /** The number of comments given by this user on reports. */
  reportCommentsGivenCount: Scalars['Int'];
  organizations: Array<Organization>;
  roles: Array<UserRole>;
  socialInfos: Array<UserSocialInfo>;
  hasApiKey: Scalars['Boolean'];
};


export type UserReportVotesCountArgs = {
  input?: Maybe<ReportVotesCountInput>;
};


export type UserReportVotesGivenCountArgs = {
  input?: Maybe<ReportVotesGivenCountInput>;
};


export type UserReportCommentsReceivedCountArgs = {
  input?: Maybe<ReportCommentsReceivedCountInput>;
};


export type UserReportCommentsGivenCountArgs = {
  input?: Maybe<ReportCommentsGivenCountInput>;
};

export type UserAppNotification = Node & AbstractEntity & {
  __typename?: 'UserAppNotification';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  event: ActivityEvent;
  lastViewedAt?: Maybe<Scalars['DateTime']>;
};

export type UserAppNotificationConnection = {
  __typename?: 'UserAppNotificationConnection';
  pageInfo: PageInfo;
  edges: Array<UserAppNotificationEdge>;
  nodes?: Maybe<Array<UserAppNotification>>;
  count: Scalars['Int'];
  totalCount: Scalars['Int'];
};

export type UserAppNotificationEdge = {
  __typename?: 'UserAppNotificationEdge';
  node: UserAppNotification;
  /** Used in `before` and `after` args */
  cursor: Scalars['String'];
};

export type UserInput = {
  username: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  agreedToBeContacted: Scalars['Boolean'];
  /** @deprecated deprecated in favor of report level kroll opt in fields */
  agreedToBeContactedByKrollSupportPartner: Scalars['Boolean'];
};

export type UserProfilePhoto = Node & AbstractEntity & FileUpload & {
  __typename?: 'UserProfilePhoto';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  url: Scalars['String'];
  author: User;
};

export enum UserRole {
  SYSTEM = 'SYSTEM',
  USER = 'USER',
  ADMIN = 'ADMIN',
  TRM_INTERNAL = 'TRM_INTERNAL'
}

export type UserSocialInfo = Node & AbstractEntity & {
  __typename?: 'UserSocialInfo';
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  service: SocialServiceKind;
  handle: Scalars['String'];
};

export type UserSocialInfoInput = {
  service: SocialServiceKind;
  handle: Scalars['String'];
};

export type UsernameAvailableInput = {
  username: Scalars['String'];
};

export type UsernameAvailablePayload = {
  __typename?: 'UsernameAvailablePayload';
  available: Scalars['Boolean'];
};

export type VotableEntity = {
  id: Scalars['ID'];
  createdAt: Scalars['DateTime'];
  updatedAt?: Maybe<Scalars['DateTime']>;
  deletedAt?: Maybe<Scalars['DateTime']>;
  biDirectionalVoteCount: Scalars['Int'];
};

export enum VotableEntityKind {
  REPORT = 'REPORT'
}

export enum VoteDirection {
  DOWN = 'DOWN',
  UP = 'UP'
}

export type AddVoteMutationVariables = Exact<{
  input: AddVoteInput;
}>;


export type AddVoteMutation = (
  { __typename?: 'Mutation' }
  & { addVote: (
    { __typename?: 'AddVotePayload' }
    & Pick<AddVotePayload, 'success'>
    & { subject?: Maybe<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
  ) }
);

export type RemoveVoteMutationVariables = Exact<{
  input: RemoveVoteInput;
}>;


export type RemoveVoteMutation = (
  { __typename?: 'Mutation' }
  & { removeVote: (
    { __typename?: 'RemoveVotePayload' }
    & Pick<RemoveVotePayload, 'success'>
    & { subject?: Maybe<(
      { __typename?: 'Report' }
      & ReportFragment
    )> }
  ) }
);

export type FlagReportMutationVariables = Exact<{
  input: AddFlagInput;
}>;


export type FlagReportMutation = (
  { __typename?: 'Mutation' }
  & { addFlag: (
    { __typename?: 'AddFlagPayload' }
    & Pick<AddFlagPayload, 'success'>
  ) }
);

export type AddressLabelsQueryVariables = Exact<{
  input: AddressLabelsInput;
}>;


export type AddressLabelsQuery = (
  { __typename?: 'Query' }
  & { addressLabels: (
    { __typename?: 'AddressConnection' }
    & { nodes?: Maybe<Array<(
      { __typename?: 'AddressLabel' }
      & Pick<AddressLabel, 'body'>
    )>> }
  ) }
);

export type CheckAddressForMistakenScammerQueryVariables = Exact<{
  input: CheckAddressForMistakenScammerInput;
}>;


export type CheckAddressForMistakenScammerQuery = (
  { __typename?: 'Query' }
  & { checkAddressForMistakenScammer: (
    { __typename?: 'CheckAddressForMistakenScammerPayload' }
    & Pick<CheckAddressForMistakenScammerPayload, 'isMistakenScammer'>
  ) }
);

export type MistakenScammerQueryVariables = Exact<{
  input: MistakenScammerInput;
}>;


export type MistakenScammerQuery = (
  { __typename?: 'Query' }
  & { mistakenScammer: (
    { __typename?: 'MistakenScammerPayload' }
    & { addresses: Array<(
      { __typename?: 'AddressWithMistakenScammerState' }
      & Pick<AddressWithMistakenScammerState, 'address' | 'domain' | 'chain' | 'label' | 'isMistakenScammer'>
    )> }
  ) }
);

export type LogoutMutationVariables = Exact<{ [key: string]: never; }>;


export type LogoutMutation = (
  { __typename?: 'Mutation' }
  & { logout: (
    { __typename?: 'LogoutResponse' }
    & Pick<LogoutResponse, 'success'>
  ) }
);

export type RequestOtpMutationVariables = Exact<{ [key: string]: never; }>;


export type RequestOtpMutation = (
  { __typename?: 'Mutation' }
  & { requestOneTimePassword: (
    { __typename?: 'RequestOneTimePasswordPayload' }
    & Pick<RequestOneTimePasswordPayload, 'success'>
  ) }
);

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'isVerified' | 'email' | 'canEditEmail' | 'createdAt' | 'acceptedPrivacyPolicyVersion'>
    & { organizations: Array<(
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'keywords' | 'hasPartnerApiAccess'>
    )> }
    & UserFragment
  )> }
);

export type AddCommentMutationVariables = Exact<{
  input: AddCommentInput;
}>;


export type AddCommentMutation = (
  { __typename?: 'Mutation' }
  & { addComment: (
    { __typename?: 'AddCommentPayload' }
    & Pick<AddCommentPayload, 'success'>
    & { subject?: Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
      & ReportCommentsFragment
    )>, comment?: Maybe<(
      { __typename?: 'ReportComment' }
      & ReportCommentFragment
    )> }
  ) }
);

export type DeleteCommentMutationVariables = Exact<{
  input: DeleteCommentInput;
}>;


export type DeleteCommentMutation = (
  { __typename?: 'Mutation' }
  & { deleteComment: (
    { __typename?: 'DeleteCommentPayload' }
    & Pick<DeleteCommentPayload, 'success'>
    & { subject?: Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
      & ReportCommentsFragment
    )> }
  ) }
);

export type GetCommentsForReportQueryVariables = Exact<{
  input: ReportInput;
}>;


export type GetCommentsForReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id'>
    & ReportCommentsFragment
  ) }
);

export type UpdateCommentMutationVariables = Exact<{
  input: UpdateCommentInput;
}>;


export type UpdateCommentMutation = (
  { __typename?: 'Mutation' }
  & { updateComment: (
    { __typename?: 'UpdateCommentPayload' }
    & Pick<UpdateCommentPayload, 'success'>
    & { comment?: Maybe<(
      { __typename?: 'ReportComment' }
      & Pick<ReportComment, 'id' | 'body'>
    )> }
  ) }
);

export type ContactUsInquiryMutationVariables = Exact<{
  input: ContactUsInput;
}>;


export type ContactUsInquiryMutation = (
  { __typename?: 'Mutation' }
  & { contactUsInquiry: (
    { __typename?: 'ContactUsPayload' }
    & Pick<ContactUsPayload, 'success'>
  ) }
);

export type GetEvidencesForReportQueryVariables = Exact<{
  input: ReportInput;
}>;


export type GetEvidencesForReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id'>
    & ReportEvidencesFragment
  ) }
);

export type ChainsQueryVariables = Exact<{
  input: ChainsInput;
}>;


export type ChainsQuery = (
  { __typename?: 'Query' }
  & { chains: Array<(
    { __typename?: 'Chain' }
    & Pick<Chain, 'kind' | 'reportsFiledCount'>
  )> }
);

export type ReportStatsQueryVariables = Exact<{ [key: string]: never; }>;


export type ReportStatsQuery = (
  { __typename?: 'Query' }
  & { reportStats: (
    { __typename?: 'ReportStats' }
    & Pick<ReportStats, 'reportsCount' | 'reportedAddressesCount' | 'reportedUrlsCount'>
  ) }
);

export type CreateMarketingLeadMutationVariables = Exact<{
  input: CreateMarketingLeadInput;
}>;


export type CreateMarketingLeadMutation = (
  { __typename?: 'Mutation' }
  & { createMarketingLead: (
    { __typename?: 'CreateMarketingLeadPayload' }
    & Pick<CreateMarketingLeadPayload, 'success'>
    & { marketingLead: (
      { __typename?: 'MarketingLead' }
      & Pick<MarketingLead, 'email'>
    ) }
  ) }
);

export type ScamCategoriesQueryVariables = Exact<{
  input: ScamCategoriesInput;
}>;


export type ScamCategoriesQuery = (
  { __typename?: 'Query' }
  & { scamCategories: Array<(
    { __typename?: 'ScamCategory' }
    & Pick<ScamCategory, 'kind' | 'reportsFiledCount'>
  )> }
);

export type TopUsersQueryVariables = Exact<{
  input?: Maybe<TopUsersInput>;
  reportVotesGivenCountInput?: Maybe<ReportVotesGivenCountInput>;
  reportCommentsGivenCountInput?: Maybe<ReportCommentsGivenCountInput>;
}>;


export type TopUsersQuery = (
  { __typename?: 'Query' }
  & { topUsers: (
    { __typename?: 'TopUsersPayload' }
    & { users: Array<(
      { __typename?: 'User' }
      & Pick<User, 'createdAt' | 'reportsFiledCount' | 'reportCommentsGivenCount' | 'reportVotesGivenCount'>
      & BasicUserFragment
    )> }
  ) }
);

export type GetLossesForReportQueryVariables = Exact<{
  input: ReportInput;
}>;


export type GetLossesForReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'source'>
    & ReportLossesFragment
  ) }
);

export type DownloadCsvQueryVariables = Exact<{
  input: DownloadCsvInput;
}>;


export type DownloadCsvQuery = (
  { __typename?: 'Query' }
  & { downloadCSV: (
    { __typename?: 'DownloadCSVPayload' }
    & Pick<DownloadCsvPayload, 'url'>
  ) }
);

export type PartnerContactUsInquiryMutationVariables = Exact<{
  input: PartnerContactUsInput;
}>;


export type PartnerContactUsInquiryMutation = (
  { __typename?: 'Mutation' }
  & { partnerContactUsInquiry: (
    { __typename?: 'PartnerContactUsPayload' }
    & Pick<PartnerContactUsPayload, 'success'>
  ) }
);

export type GenerateApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { generateApiKey: (
    { __typename?: 'GenerateApiKeyPayload' }
    & Pick<GenerateApiKeyPayload, 'apiKey'>
  ) }
);

export type MyProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type MyProfileQuery = (
  { __typename?: 'Query' }
  & { me?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'email' | 'acceptedPrivacyPolicyVersion'>
    & { profile: (
      { __typename?: 'UserProfile' }
      & Pick<UserProfile, 'agreedToBeContacted'>
    ) }
    & UserFragment
    & UserSocialInfosFragment
    & UserReportsStatsFragment
  )> }
);

export type GetProfileQueryVariables = Exact<{
  input?: Maybe<UserInput>;
}>;


export type GetProfileQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'User' }
    & Pick<User, 'createdAt'>
    & BasicUserFragment
    & UserSocialInfosFragment
    & UserReportsStatsFragment
  ) }
);

export type RevokeApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type RevokeApiKeyMutation = (
  { __typename?: 'Mutation' }
  & { revokeApiKey: (
    { __typename?: 'RevokeApiKeyPayload' }
    & Pick<RevokeApiKeyPayload, 'success'>
  ) }
);

export type UpdateUserProfileMutationVariables = Exact<{
  input: UpdateUserProfileInput;
}>;


export type UpdateUserProfileMutation = (
  { __typename?: 'Mutation' }
  & { updateUserProfile: (
    { __typename?: 'UpdateUserProfilePayload' }
    & Pick<UpdateUserProfilePayload, 'success'>
    & { user: (
      { __typename?: 'User' }
      & { profile: (
        { __typename?: 'UserProfile' }
        & Pick<UserProfile, 'agreedToBeContacted'>
      ), socialInfos: Array<(
        { __typename?: 'UserSocialInfo' }
        & Pick<UserSocialInfo, 'id' | 'service' | 'handle'>
      )> }
      & UserFragment
    ) }
  ) }
);

export type GetReportQueryVariables = Exact<{
  input: ReportInput;
  matchingKeywordsInput?: Maybe<MatchingKeywordsInput>;
}>;


export type GetReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'matchingKeywords'>
    & ReportFragment
  ) }
);

export type GetReportForSeoQueryVariables = Exact<{
  input: ReportInput;
}>;


export type GetReportForSeoQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & Pick<Report, 'id' | 'scamCategory' | 'description'>
    & ReportAuthorFragment
  ) }
);

export type GetReportIdsQueryVariables = Exact<{
  input?: Maybe<ReportsInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
}>;


export type GetReportIdsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'ReportConnection' }
    & { edges: Array<(
      { __typename?: 'ReportEdge' }
      & { node: (
        { __typename?: 'Report' }
        & Pick<Report, 'id'>
      ) }
    )> }
  ) }
);

export type GetOrganizationQueryVariables = Exact<{
  input: OrganizationInput;
}>;


export type GetOrganizationQuery = (
  { __typename?: 'Query' }
  & { organization: (
    { __typename?: 'OrganizationPayload' }
    & Pick<OrganizationPayload, 'success'>
    & { organization: (
      { __typename?: 'Organization' }
      & Pick<Organization, 'id' | 'name' | 'imageUrl' | 'description' | 'isSubdomainViewable'>
    ) }
  ) }
);

export type DeleteReportMutationVariables = Exact<{
  input: DeleteReportInput;
}>;


export type DeleteReportMutation = (
  { __typename?: 'Mutation' }
  & { deleteReport: (
    { __typename?: 'DeleteReportPayload' }
    & Pick<DeleteReportPayload, 'success'>
  ) }
);

export type FileGuestReportMutationVariables = Exact<{
  input: FileReportInput;
}>;


export type FileGuestReportMutation = (
  { __typename?: 'Mutation' }
  & { fileGuestReport: (
    { __typename?: 'FileGuestReportPayload' }
    & Pick<FileGuestReportPayload, 'success'>
    & { report: (
      { __typename?: 'Report' }
      & Pick<Report, 'id'>
    ) }
  ) }
);

export type FileReportMutationVariables = Exact<{
  input: FileReportInput;
}>;


export type FileReportMutation = (
  { __typename?: 'Mutation' }
  & { fileReport: (
    { __typename?: 'FileReportPayload' }
    & Pick<FileReportPayload, 'success'>
    & { report: (
      { __typename?: 'Report' }
      & ReportFragment
    ) }
  ) }
);

export type UpdateReportMutationVariables = Exact<{
  input: UpdateReportInput;
}>;


export type UpdateReportMutation = (
  { __typename?: 'Mutation' }
  & { updateReport: (
    { __typename?: 'UpdateReportPayload' }
    & Pick<UpdateReportPayload, 'success'>
    & { report: (
      { __typename?: 'Report' }
      & ReportFragment
      & ReportAuthorSpecificDetailsFragment
      & ReportEvidencesFragment
      & ReportLossesFragment
      & ReportTokenIDsFragment
      & ReportTransactionHashesFragment
      & ReportCompromiseIndicatorsFragment
    ) }
  ) }
);

export type ReportQueryVariables = Exact<{
  input: ReportInput;
}>;


export type ReportQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & ReportFragment
    & ReportAuthorSpecificDetailsFragment
    & ReportAccusedScammersFragment
    & ReportLossesFragment
    & ReportCompromiseIndicatorsFragment
  ) }
);

export type ReportSuccessDetailsQueryVariables = Exact<{
  input: ReportInput;
}>;


export type ReportSuccessDetailsQuery = (
  { __typename?: 'Query' }
  & { report: (
    { __typename?: 'Report' }
    & ReportFragment
    & ReportLossesFragment
  ) }
);

export type ReportUserLocationQueryVariables = Exact<{
  input?: Maybe<ReportUserLocationInput>;
}>;


export type ReportUserLocationQuery = (
  { __typename?: 'Query' }
  & { reportUserLocation: (
    { __typename?: 'ReportUserLocation' }
    & { countries?: Maybe<Array<(
      { __typename?: 'Country' }
      & Pick<Country, 'label' | 'isoCode'>
    )>>, states?: Maybe<Array<(
      { __typename?: 'State' }
      & Pick<State, 'label' | 'isoCode'>
    )>>, cities?: Maybe<Array<(
      { __typename?: 'City' }
      & Pick<City, 'label'>
    )>> }
  ) }
);

export type ReportSupportOptInMutationVariables = Exact<{
  input: ReportSupportOptInInput;
}>;


export type ReportSupportOptInMutation = (
  { __typename?: 'Mutation' }
  & { reportSupportOptIn: (
    { __typename?: 'ReportSupportOptInPayload' }
    & Pick<ReportSupportOptInPayload, 'success'>
  ) }
);

export type AgreedToBeContactedByLawEnforcementDataFragment = (
  { __typename?: 'AgreedToBeContactedByLawEnforcementData' }
  & Pick<AgreedToBeContactedByLawEnforcementData, 'agreed' | 'name' | 'email' | 'phoneNumber' | 'cityAndState' | 'country'>
);

export type AgreedToBeContactedDataFragment = (
  { __typename?: 'AgreedToBeContactedData' }
  & Pick<AgreedToBeContactedData, 'agreed' | 'agreedToBeContactedByLawEnforcement' | 'name' | 'email' | 'phoneNumber' | 'whereDidYouHear' | 'cityAndState' | 'country' | 'countryCode' | 'state' | 'stateCode' | 'city' | 'zipCode'>
);

export type BasicUserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'id' | 'username' | 'trusted'>
  & { photo?: Maybe<(
    { __typename?: 'UserProfilePhoto' }
    & Pick<UserProfilePhoto, 'id' | 'url'>
  )> }
);

export type ReportFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'id' | 'isPrivate'>
  & ReportPreviewDetailsFragment
  & ReportAccusedScammersFragment
  & ReportAuthorFragment
  & ReportAddressesFragment
  & ReportEvidencesFragment
  & ReportCompromiseIndicatorsFragment
  & ReportTokenIDsFragment
  & ReportTransactionHashesFragment
);

export type ReportAccusedScammersFragment = (
  { __typename?: 'Report' }
  & { accusedScammers: Array<(
    { __typename?: 'AccusedScammer' }
    & Pick<AccusedScammer, 'id'>
    & { info: Array<(
      { __typename?: 'AccusedScammerInfo' }
      & Pick<AccusedScammerInfo, 'id' | 'contact' | 'type'>
    )> }
  )> }
);

export type ReportAddressesFragment = (
  { __typename?: 'Report' }
  & { addresses: Array<(
    { __typename?: 'ReportAddress' }
    & Pick<ReportAddress, 'id' | 'address' | 'chain' | 'domain' | 'label'>
  )> }
);

export type ReportAuthorFragment = (
  { __typename?: 'Report' }
  & { reportedBy: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username' | 'trusted'>
  ) }
);

export type ReportAuthorSpecificDetailsFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'categoryDescription'>
  & { agreedToBeContacted?: Maybe<(
    { __typename?: 'AgreedToBeContactedData' }
    & AgreedToBeContactedDataFragment
  )>, agreedToBeContactedByLawEnforcement?: Maybe<(
    { __typename?: 'AgreedToBeContactedByLawEnforcementData' }
    & AgreedToBeContactedByLawEnforcementDataFragment
  )> }
  & ReportAuthorFragment
);

export type ReportCommentFragment = (
  { __typename?: 'ReportComment' }
  & Pick<ReportComment, 'id' | 'createdAt' | 'body'>
  & { author: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'username'>
    & { photo?: Maybe<(
      { __typename?: 'UserProfilePhoto' }
      & Pick<UserProfilePhoto, 'url'>
    )> }
  ) }
);

export type ReportCommentsFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'commentsCount'>
  & { comments?: Maybe<Array<(
    { __typename?: 'ReportComment' }
    & ReportCommentFragment
  )>> }
);

export type ReportCompromiseIndicatorsFragment = (
  { __typename?: 'Report' }
  & { compromiseIndicators: Array<(
    { __typename?: 'ReportCompromiseIndicator' }
    & Pick<ReportCompromiseIndicator, 'id' | 'type' | 'value'>
  )> }
);

export type ReportEvidencesFragment = (
  { __typename?: 'Report' }
  & { evidences?: Maybe<Array<(
    { __typename?: 'ReportEvidence' }
    & Pick<ReportEvidence, 'id' | 'description'>
    & { photo?: Maybe<(
      { __typename?: 'ReportEvidenceUpload' }
      & Pick<ReportEvidenceUpload, 'id' | 'name' | 'description' | 'url'>
    )> }
  )>> }
);

export type ReportLossesFragment = (
  { __typename?: 'Report' }
  & { losses: Array<(
    { __typename?: 'ReportLoss' }
    & Pick<ReportLoss, 'id' | 'amount' | 'asset'>
  )> }
);

export type ReportPreviewDetailsFragment = (
  { __typename?: 'Report' }
  & Pick<Report, 'createdAt' | 'scamCategory' | 'categoryDescription' | 'biDirectionalVoteCount' | 'viewerDidVote' | 'description' | 'lexicalSerializedDescription' | 'commentsCount' | 'source' | 'checked'>
);

export type ReportTokenIDsFragment = (
  { __typename?: 'Report' }
  & { tokens: Array<(
    { __typename?: 'ReportToken' }
    & Pick<ReportToken, 'id' | 'tokenId'>
  )> }
);

export type ReportTransactionHashesFragment = (
  { __typename?: 'Report' }
  & { transactionHashes: Array<(
    { __typename?: 'ReportTransactionHash' }
    & Pick<ReportTransactionHash, 'id' | 'hash' | 'chain' | 'label'>
  )> }
);

export type UserFragment = (
  { __typename?: 'User' }
  & Pick<User, 'roles' | 'isVerified' | 'hasApiKey'>
  & BasicUserFragment
);

export type UserReportsStatsFragment = (
  { __typename?: 'User' }
  & Pick<User, 'reportsFiledCount' | 'reportVotesGivenCount' | 'reportCommentsGivenCount'>
);

export type UserSocialInfosFragment = (
  { __typename?: 'User' }
  & { socialInfos: Array<(
    { __typename?: 'UserSocialInfo' }
    & Pick<UserSocialInfo, 'id' | 'service' | 'handle'>
  )> }
);

export type EmailAvailableQueryVariables = Exact<{
  input: EmailAvailableInput;
}>;


export type EmailAvailableQuery = (
  { __typename?: 'Query' }
  & { emailAvailable: (
    { __typename?: 'EmailAvailablePayload' }
    & Pick<EmailAvailablePayload, 'available'>
  ) }
);

export type UsernameAvailableQueryVariables = Exact<{
  input: UsernameAvailableInput;
}>;


export type UsernameAvailableQuery = (
  { __typename?: 'Query' }
  & { usernameAvailable: (
    { __typename?: 'UsernameAvailablePayload' }
    & Pick<UsernameAvailablePayload, 'available'>
  ) }
);

export type GetReportsQueryVariables = Exact<{
  input?: Maybe<ReportsInput>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  last?: Maybe<Scalars['Float']>;
  first?: Maybe<Scalars['Float']>;
}>;


export type GetReportsQuery = (
  { __typename?: 'Query' }
  & { reports: (
    { __typename?: 'ReportConnection' }
    & Pick<ReportConnection, 'count' | 'totalCount'>
    & { pageInfo: (
      { __typename?: 'PageInfo' }
      & Pick<PageInfo, 'hasNextPage' | 'hasPreviousPage' | 'startCursor' | 'endCursor'>
    ), edges: Array<(
      { __typename?: 'ReportEdge' }
      & Pick<ReportEdge, 'cursor'>
      & { node: (
        { __typename?: 'Report' }
        & ReportFragment
      ) }
    )> }
  ) }
);

export type UploadFileMutationVariables = Exact<{
  input: UploadFileInput;
  file: Scalars['Upload'];
}>;


export type UploadFileMutation = (
  { __typename?: 'Mutation' }
  & { uploadFile: (
    { __typename?: 'UploadFilePayload' }
    & Pick<UploadFilePayload, 'success' | 'url'>
    & { upload?: Maybe<(
      { __typename?: 'ReportEvidenceUpload' }
      & Pick<ReportEvidenceUpload, 'id' | 'url'>
    ) | (
      { __typename?: 'UserProfilePhoto' }
      & Pick<UserProfilePhoto, 'id' | 'url'>
    )> }
  ) }
);

export const ReportPreviewDetailsFragmentDoc = gql`
    fragment ReportPreviewDetails on Report {
  createdAt
  scamCategory
  categoryDescription
  biDirectionalVoteCount
  viewerDidVote
  description
  lexicalSerializedDescription
  commentsCount
  source
  checked
}
    `;
export const ReportAccusedScammersFragmentDoc = gql`
    fragment ReportAccusedScammers on Report {
  accusedScammers {
    id
    info {
      id
      contact
      type
    }
  }
}
    `;
export const ReportAuthorFragmentDoc = gql`
    fragment ReportAuthor on Report {
  reportedBy {
    id
    username
    trusted
  }
}
    `;
export const ReportAddressesFragmentDoc = gql`
    fragment ReportAddresses on Report {
  addresses {
    id
    address
    chain
    domain
    label
  }
}
    `;
export const ReportEvidencesFragmentDoc = gql`
    fragment ReportEvidences on Report {
  evidences {
    id
    description
    photo {
      id
      name
      description
      url
    }
  }
}
    `;
export const ReportCompromiseIndicatorsFragmentDoc = gql`
    fragment ReportCompromiseIndicators on Report {
  compromiseIndicators {
    id
    type
    value
  }
}
    `;
export const ReportTokenIDsFragmentDoc = gql`
    fragment ReportTokenIDs on Report {
  tokens {
    id
    tokenId
  }
}
    `;
export const ReportTransactionHashesFragmentDoc = gql`
    fragment ReportTransactionHashes on Report {
  transactionHashes {
    id
    hash
    chain
    label
  }
}
    `;
export const ReportFragmentDoc = gql`
    fragment Report on Report {
  id
  isPrivate
  ...ReportPreviewDetails
  ...ReportAccusedScammers
  ...ReportAuthor
  ...ReportAddresses
  ...ReportEvidences
  ...ReportCompromiseIndicators
  ...ReportTokenIDs
  ...ReportTransactionHashes
}
    ${ReportPreviewDetailsFragmentDoc}
${ReportAccusedScammersFragmentDoc}
${ReportAuthorFragmentDoc}
${ReportAddressesFragmentDoc}
${ReportEvidencesFragmentDoc}
${ReportCompromiseIndicatorsFragmentDoc}
${ReportTokenIDsFragmentDoc}
${ReportTransactionHashesFragmentDoc}`;
export const AgreedToBeContactedDataFragmentDoc = gql`
    fragment AgreedToBeContactedData on AgreedToBeContactedData {
  agreed
  agreedToBeContactedByLawEnforcement
  name
  email
  phoneNumber
  whereDidYouHear
  cityAndState
  country
  countryCode
  state
  stateCode
  city
  zipCode
}
    `;
export const AgreedToBeContactedByLawEnforcementDataFragmentDoc = gql`
    fragment AgreedToBeContactedByLawEnforcementData on AgreedToBeContactedByLawEnforcementData {
  agreed
  name
  email
  phoneNumber
  cityAndState
  country
}
    `;
export const ReportAuthorSpecificDetailsFragmentDoc = gql`
    fragment ReportAuthorSpecificDetails on Report {
  ...ReportAuthor
  categoryDescription
  agreedToBeContacted {
    ...AgreedToBeContactedData
  }
  agreedToBeContactedByLawEnforcement {
    ...AgreedToBeContactedByLawEnforcementData
  }
}
    ${ReportAuthorFragmentDoc}
${AgreedToBeContactedDataFragmentDoc}
${AgreedToBeContactedByLawEnforcementDataFragmentDoc}`;
export const ReportCommentFragmentDoc = gql`
    fragment ReportComment on ReportComment {
  id
  createdAt
  body
  author {
    id
    username
    photo {
      url
    }
  }
}
    `;
export const ReportCommentsFragmentDoc = gql`
    fragment ReportComments on Report {
  commentsCount
  comments {
    ...ReportComment
  }
}
    ${ReportCommentFragmentDoc}`;
export const ReportLossesFragmentDoc = gql`
    fragment ReportLosses on Report {
  losses {
    id
    amount
    asset
  }
}
    `;
export const BasicUserFragmentDoc = gql`
    fragment BasicUser on User {
  id
  username
  trusted
  photo {
    id
    url
  }
}
    `;
export const UserFragmentDoc = gql`
    fragment User on User {
  ...BasicUser
  roles
  isVerified
  hasApiKey
}
    ${BasicUserFragmentDoc}`;
export const UserReportsStatsFragmentDoc = gql`
    fragment UserReportsStats on User {
  reportsFiledCount
  reportVotesGivenCount
  reportCommentsGivenCount
}
    `;
export const UserSocialInfosFragmentDoc = gql`
    fragment UserSocialInfos on User {
  socialInfos {
    id
    service
    handle
  }
}
    `;
export const AddVoteDocument = gql`
    mutation AddVote($input: AddVoteInput!) {
  addVote(input: $input) {
    subject {
      ...Report
    }
    success
  }
}
    ${ReportFragmentDoc}`;
export type AddVoteMutationFn = Apollo.MutationFunction<AddVoteMutation, AddVoteMutationVariables>;

/**
 * __useAddVoteMutation__
 *
 * To run a mutation, you first call `useAddVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addVoteMutation, { data, loading, error }] = useAddVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddVoteMutation(baseOptions?: Apollo.MutationHookOptions<AddVoteMutation, AddVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddVoteMutation, AddVoteMutationVariables>(AddVoteDocument, options);
      }
export type AddVoteMutationHookResult = ReturnType<typeof useAddVoteMutation>;
export type AddVoteMutationResult = Apollo.MutationResult<AddVoteMutation>;
export type AddVoteMutationOptions = Apollo.BaseMutationOptions<AddVoteMutation, AddVoteMutationVariables>;
export const RemoveVoteDocument = gql`
    mutation RemoveVote($input: RemoveVoteInput!) {
  removeVote(input: $input) {
    subject {
      ...Report
    }
    success
  }
}
    ${ReportFragmentDoc}`;
export type RemoveVoteMutationFn = Apollo.MutationFunction<RemoveVoteMutation, RemoveVoteMutationVariables>;

/**
 * __useRemoveVoteMutation__
 *
 * To run a mutation, you first call `useRemoveVoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveVoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeVoteMutation, { data, loading, error }] = useRemoveVoteMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemoveVoteMutation(baseOptions?: Apollo.MutationHookOptions<RemoveVoteMutation, RemoveVoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveVoteMutation, RemoveVoteMutationVariables>(RemoveVoteDocument, options);
      }
export type RemoveVoteMutationHookResult = ReturnType<typeof useRemoveVoteMutation>;
export type RemoveVoteMutationResult = Apollo.MutationResult<RemoveVoteMutation>;
export type RemoveVoteMutationOptions = Apollo.BaseMutationOptions<RemoveVoteMutation, RemoveVoteMutationVariables>;
export const FlagReportDocument = gql`
    mutation FlagReport($input: AddFlagInput!) {
  addFlag(input: $input) {
    success
  }
}
    `;
export type FlagReportMutationFn = Apollo.MutationFunction<FlagReportMutation, FlagReportMutationVariables>;

/**
 * __useFlagReportMutation__
 *
 * To run a mutation, you first call `useFlagReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFlagReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [flagReportMutation, { data, loading, error }] = useFlagReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFlagReportMutation(baseOptions?: Apollo.MutationHookOptions<FlagReportMutation, FlagReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FlagReportMutation, FlagReportMutationVariables>(FlagReportDocument, options);
      }
export type FlagReportMutationHookResult = ReturnType<typeof useFlagReportMutation>;
export type FlagReportMutationResult = Apollo.MutationResult<FlagReportMutation>;
export type FlagReportMutationOptions = Apollo.BaseMutationOptions<FlagReportMutation, FlagReportMutationVariables>;
export const AddressLabelsDocument = gql`
    query AddressLabels($input: AddressLabelsInput!) {
  addressLabels(input: $input) {
    nodes {
      body
    }
  }
}
    `;

/**
 * __useAddressLabelsQuery__
 *
 * To run a query within a React component, call `useAddressLabelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useAddressLabelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useAddressLabelsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddressLabelsQuery(baseOptions: Apollo.QueryHookOptions<AddressLabelsQuery, AddressLabelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<AddressLabelsQuery, AddressLabelsQueryVariables>(AddressLabelsDocument, options);
      }
export function useAddressLabelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<AddressLabelsQuery, AddressLabelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<AddressLabelsQuery, AddressLabelsQueryVariables>(AddressLabelsDocument, options);
        }
export type AddressLabelsQueryHookResult = ReturnType<typeof useAddressLabelsQuery>;
export type AddressLabelsLazyQueryHookResult = ReturnType<typeof useAddressLabelsLazyQuery>;
export type AddressLabelsQueryResult = Apollo.QueryResult<AddressLabelsQuery, AddressLabelsQueryVariables>;
export const CheckAddressForMistakenScammerDocument = gql`
    query CheckAddressForMistakenScammer($input: CheckAddressForMistakenScammerInput!) {
  checkAddressForMistakenScammer(input: $input) {
    isMistakenScammer
  }
}
    `;

/**
 * __useCheckAddressForMistakenScammerQuery__
 *
 * To run a query within a React component, call `useCheckAddressForMistakenScammerQuery` and pass it any options that fit your needs.
 * When your component renders, `useCheckAddressForMistakenScammerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCheckAddressForMistakenScammerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCheckAddressForMistakenScammerQuery(baseOptions: Apollo.QueryHookOptions<CheckAddressForMistakenScammerQuery, CheckAddressForMistakenScammerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<CheckAddressForMistakenScammerQuery, CheckAddressForMistakenScammerQueryVariables>(CheckAddressForMistakenScammerDocument, options);
      }
export function useCheckAddressForMistakenScammerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CheckAddressForMistakenScammerQuery, CheckAddressForMistakenScammerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<CheckAddressForMistakenScammerQuery, CheckAddressForMistakenScammerQueryVariables>(CheckAddressForMistakenScammerDocument, options);
        }
export type CheckAddressForMistakenScammerQueryHookResult = ReturnType<typeof useCheckAddressForMistakenScammerQuery>;
export type CheckAddressForMistakenScammerLazyQueryHookResult = ReturnType<typeof useCheckAddressForMistakenScammerLazyQuery>;
export type CheckAddressForMistakenScammerQueryResult = Apollo.QueryResult<CheckAddressForMistakenScammerQuery, CheckAddressForMistakenScammerQueryVariables>;
export const MistakenScammerDocument = gql`
    query MistakenScammer($input: MistakenScammerInput!) {
  mistakenScammer(input: $input) {
    addresses {
      address
      domain
      chain
      label
      isMistakenScammer
    }
  }
}
    `;

/**
 * __useMistakenScammerQuery__
 *
 * To run a query within a React component, call `useMistakenScammerQuery` and pass it any options that fit your needs.
 * When your component renders, `useMistakenScammerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMistakenScammerQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useMistakenScammerQuery(baseOptions: Apollo.QueryHookOptions<MistakenScammerQuery, MistakenScammerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MistakenScammerQuery, MistakenScammerQueryVariables>(MistakenScammerDocument, options);
      }
export function useMistakenScammerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MistakenScammerQuery, MistakenScammerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MistakenScammerQuery, MistakenScammerQueryVariables>(MistakenScammerDocument, options);
        }
export type MistakenScammerQueryHookResult = ReturnType<typeof useMistakenScammerQuery>;
export type MistakenScammerLazyQueryHookResult = ReturnType<typeof useMistakenScammerLazyQuery>;
export type MistakenScammerQueryResult = Apollo.QueryResult<MistakenScammerQuery, MistakenScammerQueryVariables>;
export const LogoutDocument = gql`
    mutation Logout {
  logout {
    success
  }
}
    `;
export type LogoutMutationFn = Apollo.MutationFunction<LogoutMutation, LogoutMutationVariables>;

/**
 * __useLogoutMutation__
 *
 * To run a mutation, you first call `useLogoutMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLogoutMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [logoutMutation, { data, loading, error }] = useLogoutMutation({
 *   variables: {
 *   },
 * });
 */
export function useLogoutMutation(baseOptions?: Apollo.MutationHookOptions<LogoutMutation, LogoutMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LogoutMutation, LogoutMutationVariables>(LogoutDocument, options);
      }
export type LogoutMutationHookResult = ReturnType<typeof useLogoutMutation>;
export type LogoutMutationResult = Apollo.MutationResult<LogoutMutation>;
export type LogoutMutationOptions = Apollo.BaseMutationOptions<LogoutMutation, LogoutMutationVariables>;
export const RequestOtpDocument = gql`
    mutation RequestOTP {
  requestOneTimePassword {
    success
  }
}
    `;
export type RequestOtpMutationFn = Apollo.MutationFunction<RequestOtpMutation, RequestOtpMutationVariables>;

/**
 * __useRequestOtpMutation__
 *
 * To run a mutation, you first call `useRequestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpMutation, { data, loading, error }] = useRequestOtpMutation({
 *   variables: {
 *   },
 * });
 */
export function useRequestOtpMutation(baseOptions?: Apollo.MutationHookOptions<RequestOtpMutation, RequestOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOtpMutation, RequestOtpMutationVariables>(RequestOtpDocument, options);
      }
export type RequestOtpMutationHookResult = ReturnType<typeof useRequestOtpMutation>;
export type RequestOtpMutationResult = Apollo.MutationResult<RequestOtpMutation>;
export type RequestOtpMutationOptions = Apollo.BaseMutationOptions<RequestOtpMutation, RequestOtpMutationVariables>;
export const MeDocument = gql`
    query Me {
  me {
    ...User
    isVerified
    email
    canEditEmail
    organizations {
      id
      name
      keywords
      hasPartnerApiAccess
    }
    createdAt
    acceptedPrivacyPolicyVersion
  }
}
    ${UserFragmentDoc}`;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const AddCommentDocument = gql`
    mutation AddComment($input: AddCommentInput!) {
  addComment(input: $input) {
    subject {
      id
      ...ReportComments
    }
    comment {
      ...ReportComment
    }
    success
  }
}
    ${ReportCommentsFragmentDoc}
${ReportCommentFragmentDoc}`;
export type AddCommentMutationFn = Apollo.MutationFunction<AddCommentMutation, AddCommentMutationVariables>;

/**
 * __useAddCommentMutation__
 *
 * To run a mutation, you first call `useAddCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addCommentMutation, { data, loading, error }] = useAddCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddCommentMutation(baseOptions?: Apollo.MutationHookOptions<AddCommentMutation, AddCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddCommentMutation, AddCommentMutationVariables>(AddCommentDocument, options);
      }
export type AddCommentMutationHookResult = ReturnType<typeof useAddCommentMutation>;
export type AddCommentMutationResult = Apollo.MutationResult<AddCommentMutation>;
export type AddCommentMutationOptions = Apollo.BaseMutationOptions<AddCommentMutation, AddCommentMutationVariables>;
export const DeleteCommentDocument = gql`
    mutation DeleteComment($input: DeleteCommentInput!) {
  deleteComment(input: $input) {
    subject {
      id
      ...ReportComments
    }
    success
  }
}
    ${ReportCommentsFragmentDoc}`;
export type DeleteCommentMutationFn = Apollo.MutationFunction<DeleteCommentMutation, DeleteCommentMutationVariables>;

/**
 * __useDeleteCommentMutation__
 *
 * To run a mutation, you first call `useDeleteCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteCommentMutation, { data, loading, error }] = useDeleteCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteCommentMutation(baseOptions?: Apollo.MutationHookOptions<DeleteCommentMutation, DeleteCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteCommentMutation, DeleteCommentMutationVariables>(DeleteCommentDocument, options);
      }
export type DeleteCommentMutationHookResult = ReturnType<typeof useDeleteCommentMutation>;
export type DeleteCommentMutationResult = Apollo.MutationResult<DeleteCommentMutation>;
export type DeleteCommentMutationOptions = Apollo.BaseMutationOptions<DeleteCommentMutation, DeleteCommentMutationVariables>;
export const GetCommentsForReportDocument = gql`
    query GetCommentsForReport($input: ReportInput!) {
  report(input: $input) {
    id
    ...ReportComments
  }
}
    ${ReportCommentsFragmentDoc}`;

/**
 * __useGetCommentsForReportQuery__
 *
 * To run a query within a React component, call `useGetCommentsForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCommentsForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCommentsForReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetCommentsForReportQuery(baseOptions: Apollo.QueryHookOptions<GetCommentsForReportQuery, GetCommentsForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCommentsForReportQuery, GetCommentsForReportQueryVariables>(GetCommentsForReportDocument, options);
      }
export function useGetCommentsForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCommentsForReportQuery, GetCommentsForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCommentsForReportQuery, GetCommentsForReportQueryVariables>(GetCommentsForReportDocument, options);
        }
export type GetCommentsForReportQueryHookResult = ReturnType<typeof useGetCommentsForReportQuery>;
export type GetCommentsForReportLazyQueryHookResult = ReturnType<typeof useGetCommentsForReportLazyQuery>;
export type GetCommentsForReportQueryResult = Apollo.QueryResult<GetCommentsForReportQuery, GetCommentsForReportQueryVariables>;
export const UpdateCommentDocument = gql`
    mutation UpdateComment($input: UpdateCommentInput!) {
  updateComment(input: $input) {
    comment {
      id
      body
    }
    success
  }
}
    `;
export type UpdateCommentMutationFn = Apollo.MutationFunction<UpdateCommentMutation, UpdateCommentMutationVariables>;

/**
 * __useUpdateCommentMutation__
 *
 * To run a mutation, you first call `useUpdateCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateCommentMutation, { data, loading, error }] = useUpdateCommentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateCommentMutation(baseOptions?: Apollo.MutationHookOptions<UpdateCommentMutation, UpdateCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateCommentMutation, UpdateCommentMutationVariables>(UpdateCommentDocument, options);
      }
export type UpdateCommentMutationHookResult = ReturnType<typeof useUpdateCommentMutation>;
export type UpdateCommentMutationResult = Apollo.MutationResult<UpdateCommentMutation>;
export type UpdateCommentMutationOptions = Apollo.BaseMutationOptions<UpdateCommentMutation, UpdateCommentMutationVariables>;
export const ContactUsInquiryDocument = gql`
    mutation ContactUsInquiry($input: ContactUsInput!) {
  contactUsInquiry(input: $input) {
    success
  }
}
    `;
export type ContactUsInquiryMutationFn = Apollo.MutationFunction<ContactUsInquiryMutation, ContactUsInquiryMutationVariables>;

/**
 * __useContactUsInquiryMutation__
 *
 * To run a mutation, you first call `useContactUsInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useContactUsInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [contactUsInquiryMutation, { data, loading, error }] = useContactUsInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useContactUsInquiryMutation(baseOptions?: Apollo.MutationHookOptions<ContactUsInquiryMutation, ContactUsInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ContactUsInquiryMutation, ContactUsInquiryMutationVariables>(ContactUsInquiryDocument, options);
      }
export type ContactUsInquiryMutationHookResult = ReturnType<typeof useContactUsInquiryMutation>;
export type ContactUsInquiryMutationResult = Apollo.MutationResult<ContactUsInquiryMutation>;
export type ContactUsInquiryMutationOptions = Apollo.BaseMutationOptions<ContactUsInquiryMutation, ContactUsInquiryMutationVariables>;
export const GetEvidencesForReportDocument = gql`
    query GetEvidencesForReport($input: ReportInput!) {
  report(input: $input) {
    id
    ...ReportEvidences
  }
}
    ${ReportEvidencesFragmentDoc}`;

/**
 * __useGetEvidencesForReportQuery__
 *
 * To run a query within a React component, call `useGetEvidencesForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEvidencesForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEvidencesForReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetEvidencesForReportQuery(baseOptions: Apollo.QueryHookOptions<GetEvidencesForReportQuery, GetEvidencesForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetEvidencesForReportQuery, GetEvidencesForReportQueryVariables>(GetEvidencesForReportDocument, options);
      }
export function useGetEvidencesForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetEvidencesForReportQuery, GetEvidencesForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetEvidencesForReportQuery, GetEvidencesForReportQueryVariables>(GetEvidencesForReportDocument, options);
        }
export type GetEvidencesForReportQueryHookResult = ReturnType<typeof useGetEvidencesForReportQuery>;
export type GetEvidencesForReportLazyQueryHookResult = ReturnType<typeof useGetEvidencesForReportLazyQuery>;
export type GetEvidencesForReportQueryResult = Apollo.QueryResult<GetEvidencesForReportQuery, GetEvidencesForReportQueryVariables>;
export const ChainsDocument = gql`
    query Chains($input: ChainsInput!) {
  chains(input: $input) {
    kind
    reportsFiledCount
  }
}
    `;

/**
 * __useChainsQuery__
 *
 * To run a query within a React component, call `useChainsQuery` and pass it any options that fit your needs.
 * When your component renders, `useChainsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useChainsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useChainsQuery(baseOptions: Apollo.QueryHookOptions<ChainsQuery, ChainsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ChainsQuery, ChainsQueryVariables>(ChainsDocument, options);
      }
export function useChainsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ChainsQuery, ChainsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ChainsQuery, ChainsQueryVariables>(ChainsDocument, options);
        }
export type ChainsQueryHookResult = ReturnType<typeof useChainsQuery>;
export type ChainsLazyQueryHookResult = ReturnType<typeof useChainsLazyQuery>;
export type ChainsQueryResult = Apollo.QueryResult<ChainsQuery, ChainsQueryVariables>;
export const ReportStatsDocument = gql`
    query ReportStats {
  reportStats {
    reportsCount
    reportedAddressesCount
    reportedUrlsCount
  }
}
    `;

/**
 * __useReportStatsQuery__
 *
 * To run a query within a React component, call `useReportStatsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportStatsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportStatsQuery({
 *   variables: {
 *   },
 * });
 */
export function useReportStatsQuery(baseOptions?: Apollo.QueryHookOptions<ReportStatsQuery, ReportStatsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportStatsQuery, ReportStatsQueryVariables>(ReportStatsDocument, options);
      }
export function useReportStatsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportStatsQuery, ReportStatsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportStatsQuery, ReportStatsQueryVariables>(ReportStatsDocument, options);
        }
export type ReportStatsQueryHookResult = ReturnType<typeof useReportStatsQuery>;
export type ReportStatsLazyQueryHookResult = ReturnType<typeof useReportStatsLazyQuery>;
export type ReportStatsQueryResult = Apollo.QueryResult<ReportStatsQuery, ReportStatsQueryVariables>;
export const CreateMarketingLeadDocument = gql`
    mutation CreateMarketingLead($input: CreateMarketingLeadInput!) {
  createMarketingLead(input: $input) {
    marketingLead {
      email
    }
    success
  }
}
    `;
export type CreateMarketingLeadMutationFn = Apollo.MutationFunction<CreateMarketingLeadMutation, CreateMarketingLeadMutationVariables>;

/**
 * __useCreateMarketingLeadMutation__
 *
 * To run a mutation, you first call `useCreateMarketingLeadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMarketingLeadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMarketingLeadMutation, { data, loading, error }] = useCreateMarketingLeadMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateMarketingLeadMutation(baseOptions?: Apollo.MutationHookOptions<CreateMarketingLeadMutation, CreateMarketingLeadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMarketingLeadMutation, CreateMarketingLeadMutationVariables>(CreateMarketingLeadDocument, options);
      }
export type CreateMarketingLeadMutationHookResult = ReturnType<typeof useCreateMarketingLeadMutation>;
export type CreateMarketingLeadMutationResult = Apollo.MutationResult<CreateMarketingLeadMutation>;
export type CreateMarketingLeadMutationOptions = Apollo.BaseMutationOptions<CreateMarketingLeadMutation, CreateMarketingLeadMutationVariables>;
export const ScamCategoriesDocument = gql`
    query ScamCategories($input: ScamCategoriesInput!) {
  scamCategories(input: $input) {
    kind
    reportsFiledCount
  }
}
    `;

/**
 * __useScamCategoriesQuery__
 *
 * To run a query within a React component, call `useScamCategoriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useScamCategoriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useScamCategoriesQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useScamCategoriesQuery(baseOptions: Apollo.QueryHookOptions<ScamCategoriesQuery, ScamCategoriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ScamCategoriesQuery, ScamCategoriesQueryVariables>(ScamCategoriesDocument, options);
      }
export function useScamCategoriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ScamCategoriesQuery, ScamCategoriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ScamCategoriesQuery, ScamCategoriesQueryVariables>(ScamCategoriesDocument, options);
        }
export type ScamCategoriesQueryHookResult = ReturnType<typeof useScamCategoriesQuery>;
export type ScamCategoriesLazyQueryHookResult = ReturnType<typeof useScamCategoriesLazyQuery>;
export type ScamCategoriesQueryResult = Apollo.QueryResult<ScamCategoriesQuery, ScamCategoriesQueryVariables>;
export const TopUsersDocument = gql`
    query TopUsers($input: TopUsersInput, $reportVotesGivenCountInput: ReportVotesGivenCountInput, $reportCommentsGivenCountInput: ReportCommentsGivenCountInput) {
  topUsers(input: $input) {
    users {
      ...BasicUser
      createdAt
      reportsFiledCount
      reportCommentsGivenCount(input: $reportCommentsGivenCountInput)
      reportVotesGivenCount(input: $reportVotesGivenCountInput)
    }
  }
}
    ${BasicUserFragmentDoc}`;

/**
 * __useTopUsersQuery__
 *
 * To run a query within a React component, call `useTopUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useTopUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTopUsersQuery({
 *   variables: {
 *      input: // value for 'input'
 *      reportVotesGivenCountInput: // value for 'reportVotesGivenCountInput'
 *      reportCommentsGivenCountInput: // value for 'reportCommentsGivenCountInput'
 *   },
 * });
 */
export function useTopUsersQuery(baseOptions?: Apollo.QueryHookOptions<TopUsersQuery, TopUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TopUsersQuery, TopUsersQueryVariables>(TopUsersDocument, options);
      }
export function useTopUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TopUsersQuery, TopUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TopUsersQuery, TopUsersQueryVariables>(TopUsersDocument, options);
        }
export type TopUsersQueryHookResult = ReturnType<typeof useTopUsersQuery>;
export type TopUsersLazyQueryHookResult = ReturnType<typeof useTopUsersLazyQuery>;
export type TopUsersQueryResult = Apollo.QueryResult<TopUsersQuery, TopUsersQueryVariables>;
export const GetLossesForReportDocument = gql`
    query GetLossesForReport($input: ReportInput!) {
  report(input: $input) {
    id
    source
    ...ReportLosses
  }
}
    ${ReportLossesFragmentDoc}`;

/**
 * __useGetLossesForReportQuery__
 *
 * To run a query within a React component, call `useGetLossesForReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLossesForReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLossesForReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetLossesForReportQuery(baseOptions: Apollo.QueryHookOptions<GetLossesForReportQuery, GetLossesForReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetLossesForReportQuery, GetLossesForReportQueryVariables>(GetLossesForReportDocument, options);
      }
export function useGetLossesForReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetLossesForReportQuery, GetLossesForReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetLossesForReportQuery, GetLossesForReportQueryVariables>(GetLossesForReportDocument, options);
        }
export type GetLossesForReportQueryHookResult = ReturnType<typeof useGetLossesForReportQuery>;
export type GetLossesForReportLazyQueryHookResult = ReturnType<typeof useGetLossesForReportLazyQuery>;
export type GetLossesForReportQueryResult = Apollo.QueryResult<GetLossesForReportQuery, GetLossesForReportQueryVariables>;
export const DownloadCsvDocument = gql`
    query DownloadCSV($input: DownloadCSVInput!) {
  downloadCSV(input: $input) {
    url
  }
}
    `;

/**
 * __useDownloadCsvQuery__
 *
 * To run a query within a React component, call `useDownloadCsvQuery` and pass it any options that fit your needs.
 * When your component renders, `useDownloadCsvQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useDownloadCsvQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDownloadCsvQuery(baseOptions: Apollo.QueryHookOptions<DownloadCsvQuery, DownloadCsvQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<DownloadCsvQuery, DownloadCsvQueryVariables>(DownloadCsvDocument, options);
      }
export function useDownloadCsvLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<DownloadCsvQuery, DownloadCsvQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<DownloadCsvQuery, DownloadCsvQueryVariables>(DownloadCsvDocument, options);
        }
export type DownloadCsvQueryHookResult = ReturnType<typeof useDownloadCsvQuery>;
export type DownloadCsvLazyQueryHookResult = ReturnType<typeof useDownloadCsvLazyQuery>;
export type DownloadCsvQueryResult = Apollo.QueryResult<DownloadCsvQuery, DownloadCsvQueryVariables>;
export const PartnerContactUsInquiryDocument = gql`
    mutation PartnerContactUsInquiry($input: PartnerContactUsInput!) {
  partnerContactUsInquiry(input: $input) {
    success
  }
}
    `;
export type PartnerContactUsInquiryMutationFn = Apollo.MutationFunction<PartnerContactUsInquiryMutation, PartnerContactUsInquiryMutationVariables>;

/**
 * __usePartnerContactUsInquiryMutation__
 *
 * To run a mutation, you first call `usePartnerContactUsInquiryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePartnerContactUsInquiryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [partnerContactUsInquiryMutation, { data, loading, error }] = usePartnerContactUsInquiryMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePartnerContactUsInquiryMutation(baseOptions?: Apollo.MutationHookOptions<PartnerContactUsInquiryMutation, PartnerContactUsInquiryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PartnerContactUsInquiryMutation, PartnerContactUsInquiryMutationVariables>(PartnerContactUsInquiryDocument, options);
      }
export type PartnerContactUsInquiryMutationHookResult = ReturnType<typeof usePartnerContactUsInquiryMutation>;
export type PartnerContactUsInquiryMutationResult = Apollo.MutationResult<PartnerContactUsInquiryMutation>;
export type PartnerContactUsInquiryMutationOptions = Apollo.BaseMutationOptions<PartnerContactUsInquiryMutation, PartnerContactUsInquiryMutationVariables>;
export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey {
  generateApiKey {
    apiKey
  }
}
    `;
export type GenerateApiKeyMutationFn = Apollo.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, options);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = Apollo.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = Apollo.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const MyProfileDocument = gql`
    query MyProfile {
  me {
    ...User
    ...UserSocialInfos
    ...UserReportsStats
    email
    profile {
      agreedToBeContacted
    }
    acceptedPrivacyPolicyVersion
  }
}
    ${UserFragmentDoc}
${UserSocialInfosFragmentDoc}
${UserReportsStatsFragmentDoc}`;

/**
 * __useMyProfileQuery__
 *
 * To run a query within a React component, call `useMyProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useMyProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMyProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useMyProfileQuery(baseOptions?: Apollo.QueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
      }
export function useMyProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MyProfileQuery, MyProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MyProfileQuery, MyProfileQueryVariables>(MyProfileDocument, options);
        }
export type MyProfileQueryHookResult = ReturnType<typeof useMyProfileQuery>;
export type MyProfileLazyQueryHookResult = ReturnType<typeof useMyProfileLazyQuery>;
export type MyProfileQueryResult = Apollo.QueryResult<MyProfileQuery, MyProfileQueryVariables>;
export const GetProfileDocument = gql`
    query GetProfile($input: UserInput) {
  user(input: $input) {
    ...BasicUser
    ...UserSocialInfos
    ...UserReportsStats
    createdAt
  }
}
    ${BasicUserFragmentDoc}
${UserSocialInfosFragmentDoc}
${UserReportsStatsFragmentDoc}`;

/**
 * __useGetProfileQuery__
 *
 * To run a query within a React component, call `useGetProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProfileQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetProfileQuery(baseOptions?: Apollo.QueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
      }
export function useGetProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetProfileQuery, GetProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetProfileQuery, GetProfileQueryVariables>(GetProfileDocument, options);
        }
export type GetProfileQueryHookResult = ReturnType<typeof useGetProfileQuery>;
export type GetProfileLazyQueryHookResult = ReturnType<typeof useGetProfileLazyQuery>;
export type GetProfileQueryResult = Apollo.QueryResult<GetProfileQuery, GetProfileQueryVariables>;
export const RevokeApiKeyDocument = gql`
    mutation RevokeApiKey {
  revokeApiKey {
    success
  }
}
    `;
export type RevokeApiKeyMutationFn = Apollo.MutationFunction<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>;

/**
 * __useRevokeApiKeyMutation__
 *
 * To run a mutation, you first call `useRevokeApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRevokeApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [revokeApiKeyMutation, { data, loading, error }] = useRevokeApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useRevokeApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>(RevokeApiKeyDocument, options);
      }
export type RevokeApiKeyMutationHookResult = ReturnType<typeof useRevokeApiKeyMutation>;
export type RevokeApiKeyMutationResult = Apollo.MutationResult<RevokeApiKeyMutation>;
export type RevokeApiKeyMutationOptions = Apollo.BaseMutationOptions<RevokeApiKeyMutation, RevokeApiKeyMutationVariables>;
export const UpdateUserProfileDocument = gql`
    mutation UpdateUserProfile($input: UpdateUserProfileInput!) {
  updateUserProfile(input: $input) {
    success
    user {
      ...User
      profile {
        agreedToBeContacted
      }
      socialInfos {
        id
        service
        handle
      }
    }
  }
}
    ${UserFragmentDoc}`;
export type UpdateUserProfileMutationFn = Apollo.MutationFunction<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;

/**
 * __useUpdateUserProfileMutation__
 *
 * To run a mutation, you first call `useUpdateUserProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserProfileMutation, { data, loading, error }] = useUpdateUserProfileMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserProfileMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>(UpdateUserProfileDocument, options);
      }
export type UpdateUserProfileMutationHookResult = ReturnType<typeof useUpdateUserProfileMutation>;
export type UpdateUserProfileMutationResult = Apollo.MutationResult<UpdateUserProfileMutation>;
export type UpdateUserProfileMutationOptions = Apollo.BaseMutationOptions<UpdateUserProfileMutation, UpdateUserProfileMutationVariables>;
export const GetReportDocument = gql`
    query GetReport($input: ReportInput!, $matchingKeywordsInput: MatchingKeywordsInput) {
  report(input: $input) {
    ...Report
    matchingKeywords(input: $matchingKeywordsInput)
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useGetReportQuery__
 *
 * To run a query within a React component, call `useGetReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *      matchingKeywordsInput: // value for 'matchingKeywordsInput'
 *   },
 * });
 */
export function useGetReportQuery(baseOptions: Apollo.QueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
      }
export function useGetReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportQuery, GetReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportQuery, GetReportQueryVariables>(GetReportDocument, options);
        }
export type GetReportQueryHookResult = ReturnType<typeof useGetReportQuery>;
export type GetReportLazyQueryHookResult = ReturnType<typeof useGetReportLazyQuery>;
export type GetReportQueryResult = Apollo.QueryResult<GetReportQuery, GetReportQueryVariables>;
export const GetReportForSeoDocument = gql`
    query GetReportForSeo($input: ReportInput!) {
  report(input: $input) {
    id
    ...ReportAuthor
    scamCategory
    description
  }
}
    ${ReportAuthorFragmentDoc}`;

/**
 * __useGetReportForSeoQuery__
 *
 * To run a query within a React component, call `useGetReportForSeoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportForSeoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportForSeoQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetReportForSeoQuery(baseOptions: Apollo.QueryHookOptions<GetReportForSeoQuery, GetReportForSeoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportForSeoQuery, GetReportForSeoQueryVariables>(GetReportForSeoDocument, options);
      }
export function useGetReportForSeoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportForSeoQuery, GetReportForSeoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportForSeoQuery, GetReportForSeoQueryVariables>(GetReportForSeoDocument, options);
        }
export type GetReportForSeoQueryHookResult = ReturnType<typeof useGetReportForSeoQuery>;
export type GetReportForSeoLazyQueryHookResult = ReturnType<typeof useGetReportForSeoLazyQuery>;
export type GetReportForSeoQueryResult = Apollo.QueryResult<GetReportForSeoQuery, GetReportForSeoQueryVariables>;
export const GetReportIdsDocument = gql`
    query GetReportIds($input: ReportsInput, $after: String, $before: String, $last: Float, $first: Float) {
  reports(
    input: $input
    after: $after
    before: $before
    last: $last
    first: $first
  ) {
    edges {
      node {
        id
      }
    }
  }
}
    `;

/**
 * __useGetReportIdsQuery__
 *
 * To run a query within a React component, call `useGetReportIdsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportIdsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportIdsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetReportIdsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportIdsQuery, GetReportIdsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportIdsQuery, GetReportIdsQueryVariables>(GetReportIdsDocument, options);
      }
export function useGetReportIdsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportIdsQuery, GetReportIdsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportIdsQuery, GetReportIdsQueryVariables>(GetReportIdsDocument, options);
        }
export type GetReportIdsQueryHookResult = ReturnType<typeof useGetReportIdsQuery>;
export type GetReportIdsLazyQueryHookResult = ReturnType<typeof useGetReportIdsLazyQuery>;
export type GetReportIdsQueryResult = Apollo.QueryResult<GetReportIdsQuery, GetReportIdsQueryVariables>;
export const GetOrganizationDocument = gql`
    query GetOrganization($input: OrganizationInput!) {
  organization(input: $input) {
    organization {
      id
      name
      imageUrl
      description
      isSubdomainViewable
    }
    success
  }
}
    `;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetOrganizationQuery(baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
      }
export function useGetOrganizationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
        }
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const DeleteReportDocument = gql`
    mutation DeleteReport($input: DeleteReportInput!) {
  deleteReport(input: $input) {
    success
  }
}
    `;
export type DeleteReportMutationFn = Apollo.MutationFunction<DeleteReportMutation, DeleteReportMutationVariables>;

/**
 * __useDeleteReportMutation__
 *
 * To run a mutation, you first call `useDeleteReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteReportMutation, { data, loading, error }] = useDeleteReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useDeleteReportMutation(baseOptions?: Apollo.MutationHookOptions<DeleteReportMutation, DeleteReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteReportMutation, DeleteReportMutationVariables>(DeleteReportDocument, options);
      }
export type DeleteReportMutationHookResult = ReturnType<typeof useDeleteReportMutation>;
export type DeleteReportMutationResult = Apollo.MutationResult<DeleteReportMutation>;
export type DeleteReportMutationOptions = Apollo.BaseMutationOptions<DeleteReportMutation, DeleteReportMutationVariables>;
export const FileGuestReportDocument = gql`
    mutation FileGuestReport($input: FileReportInput!) {
  fileGuestReport(input: $input) {
    report {
      id
    }
    success
  }
}
    `;
export type FileGuestReportMutationFn = Apollo.MutationFunction<FileGuestReportMutation, FileGuestReportMutationVariables>;

/**
 * __useFileGuestReportMutation__
 *
 * To run a mutation, you first call `useFileGuestReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileGuestReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileGuestReportMutation, { data, loading, error }] = useFileGuestReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileGuestReportMutation(baseOptions?: Apollo.MutationHookOptions<FileGuestReportMutation, FileGuestReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileGuestReportMutation, FileGuestReportMutationVariables>(FileGuestReportDocument, options);
      }
export type FileGuestReportMutationHookResult = ReturnType<typeof useFileGuestReportMutation>;
export type FileGuestReportMutationResult = Apollo.MutationResult<FileGuestReportMutation>;
export type FileGuestReportMutationOptions = Apollo.BaseMutationOptions<FileGuestReportMutation, FileGuestReportMutationVariables>;
export const FileReportDocument = gql`
    mutation FileReport($input: FileReportInput!) {
  fileReport(input: $input) {
    report {
      ...Report
    }
    success
  }
}
    ${ReportFragmentDoc}`;
export type FileReportMutationFn = Apollo.MutationFunction<FileReportMutation, FileReportMutationVariables>;

/**
 * __useFileReportMutation__
 *
 * To run a mutation, you first call `useFileReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFileReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fileReportMutation, { data, loading, error }] = useFileReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFileReportMutation(baseOptions?: Apollo.MutationHookOptions<FileReportMutation, FileReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FileReportMutation, FileReportMutationVariables>(FileReportDocument, options);
      }
export type FileReportMutationHookResult = ReturnType<typeof useFileReportMutation>;
export type FileReportMutationResult = Apollo.MutationResult<FileReportMutation>;
export type FileReportMutationOptions = Apollo.BaseMutationOptions<FileReportMutation, FileReportMutationVariables>;
export const UpdateReportDocument = gql`
    mutation UpdateReport($input: UpdateReportInput!) {
  updateReport(input: $input) {
    report {
      ...Report
      ...ReportAuthorSpecificDetails
      ...ReportEvidences
      ...ReportLosses
      ...ReportTokenIDs
      ...ReportTransactionHashes
      ...ReportCompromiseIndicators
    }
    success
  }
}
    ${ReportFragmentDoc}
${ReportAuthorSpecificDetailsFragmentDoc}
${ReportEvidencesFragmentDoc}
${ReportLossesFragmentDoc}
${ReportTokenIDsFragmentDoc}
${ReportTransactionHashesFragmentDoc}
${ReportCompromiseIndicatorsFragmentDoc}`;
export type UpdateReportMutationFn = Apollo.MutationFunction<UpdateReportMutation, UpdateReportMutationVariables>;

/**
 * __useUpdateReportMutation__
 *
 * To run a mutation, you first call `useUpdateReportMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateReportMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateReportMutation, { data, loading, error }] = useUpdateReportMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateReportMutation(baseOptions?: Apollo.MutationHookOptions<UpdateReportMutation, UpdateReportMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateReportMutation, UpdateReportMutationVariables>(UpdateReportDocument, options);
      }
export type UpdateReportMutationHookResult = ReturnType<typeof useUpdateReportMutation>;
export type UpdateReportMutationResult = Apollo.MutationResult<UpdateReportMutation>;
export type UpdateReportMutationOptions = Apollo.BaseMutationOptions<UpdateReportMutation, UpdateReportMutationVariables>;
export const ReportDocument = gql`
    query Report($input: ReportInput!) {
  report(input: $input) {
    ...Report
    ...ReportAuthorSpecificDetails
    ...ReportAccusedScammers
    ...ReportLosses
    ...ReportCompromiseIndicators
  }
}
    ${ReportFragmentDoc}
${ReportAuthorSpecificDetailsFragmentDoc}
${ReportAccusedScammersFragmentDoc}
${ReportLossesFragmentDoc}
${ReportCompromiseIndicatorsFragmentDoc}`;

/**
 * __useReportQuery__
 *
 * To run a query within a React component, call `useReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportQuery(baseOptions: Apollo.QueryHookOptions<ReportQuery, ReportQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
      }
export function useReportLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportQuery, ReportQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportQuery, ReportQueryVariables>(ReportDocument, options);
        }
export type ReportQueryHookResult = ReturnType<typeof useReportQuery>;
export type ReportLazyQueryHookResult = ReturnType<typeof useReportLazyQuery>;
export type ReportQueryResult = Apollo.QueryResult<ReportQuery, ReportQueryVariables>;
export const ReportSuccessDetailsDocument = gql`
    query ReportSuccessDetails($input: ReportInput!) {
  report(input: $input) {
    ...Report
    ...ReportLosses
  }
}
    ${ReportFragmentDoc}
${ReportLossesFragmentDoc}`;

/**
 * __useReportSuccessDetailsQuery__
 *
 * To run a query within a React component, call `useReportSuccessDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportSuccessDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportSuccessDetailsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSuccessDetailsQuery(baseOptions: Apollo.QueryHookOptions<ReportSuccessDetailsQuery, ReportSuccessDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportSuccessDetailsQuery, ReportSuccessDetailsQueryVariables>(ReportSuccessDetailsDocument, options);
      }
export function useReportSuccessDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportSuccessDetailsQuery, ReportSuccessDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportSuccessDetailsQuery, ReportSuccessDetailsQueryVariables>(ReportSuccessDetailsDocument, options);
        }
export type ReportSuccessDetailsQueryHookResult = ReturnType<typeof useReportSuccessDetailsQuery>;
export type ReportSuccessDetailsLazyQueryHookResult = ReturnType<typeof useReportSuccessDetailsLazyQuery>;
export type ReportSuccessDetailsQueryResult = Apollo.QueryResult<ReportSuccessDetailsQuery, ReportSuccessDetailsQueryVariables>;
export const ReportUserLocationDocument = gql`
    query ReportUserLocation($input: ReportUserLocationInput) {
  reportUserLocation(input: $input) {
    countries {
      label
      isoCode
    }
    states {
      label
      isoCode
    }
    cities {
      label
    }
  }
}
    `;

/**
 * __useReportUserLocationQuery__
 *
 * To run a query within a React component, call `useReportUserLocationQuery` and pass it any options that fit your needs.
 * When your component renders, `useReportUserLocationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReportUserLocationQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportUserLocationQuery(baseOptions?: Apollo.QueryHookOptions<ReportUserLocationQuery, ReportUserLocationQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReportUserLocationQuery, ReportUserLocationQueryVariables>(ReportUserLocationDocument, options);
      }
export function useReportUserLocationLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReportUserLocationQuery, ReportUserLocationQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReportUserLocationQuery, ReportUserLocationQueryVariables>(ReportUserLocationDocument, options);
        }
export type ReportUserLocationQueryHookResult = ReturnType<typeof useReportUserLocationQuery>;
export type ReportUserLocationLazyQueryHookResult = ReturnType<typeof useReportUserLocationLazyQuery>;
export type ReportUserLocationQueryResult = Apollo.QueryResult<ReportUserLocationQuery, ReportUserLocationQueryVariables>;
export const ReportSupportOptInDocument = gql`
    mutation ReportSupportOptIn($input: ReportSupportOptInInput!) {
  reportSupportOptIn(input: $input) {
    success
  }
}
    `;
export type ReportSupportOptInMutationFn = Apollo.MutationFunction<ReportSupportOptInMutation, ReportSupportOptInMutationVariables>;

/**
 * __useReportSupportOptInMutation__
 *
 * To run a mutation, you first call `useReportSupportOptInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useReportSupportOptInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [reportSupportOptInMutation, { data, loading, error }] = useReportSupportOptInMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useReportSupportOptInMutation(baseOptions?: Apollo.MutationHookOptions<ReportSupportOptInMutation, ReportSupportOptInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ReportSupportOptInMutation, ReportSupportOptInMutationVariables>(ReportSupportOptInDocument, options);
      }
export type ReportSupportOptInMutationHookResult = ReturnType<typeof useReportSupportOptInMutation>;
export type ReportSupportOptInMutationResult = Apollo.MutationResult<ReportSupportOptInMutation>;
export type ReportSupportOptInMutationOptions = Apollo.BaseMutationOptions<ReportSupportOptInMutation, ReportSupportOptInMutationVariables>;
export const EmailAvailableDocument = gql`
    query EmailAvailable($input: EmailAvailableInput!) {
  emailAvailable(input: $input) {
    available
  }
}
    `;

/**
 * __useEmailAvailableQuery__
 *
 * To run a query within a React component, call `useEmailAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailAvailableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useEmailAvailableQuery(baseOptions: Apollo.QueryHookOptions<EmailAvailableQuery, EmailAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailAvailableQuery, EmailAvailableQueryVariables>(EmailAvailableDocument, options);
      }
export function useEmailAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailAvailableQuery, EmailAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailAvailableQuery, EmailAvailableQueryVariables>(EmailAvailableDocument, options);
        }
export type EmailAvailableQueryHookResult = ReturnType<typeof useEmailAvailableQuery>;
export type EmailAvailableLazyQueryHookResult = ReturnType<typeof useEmailAvailableLazyQuery>;
export type EmailAvailableQueryResult = Apollo.QueryResult<EmailAvailableQuery, EmailAvailableQueryVariables>;
export const UsernameAvailableDocument = gql`
    query UsernameAvailable($input: UsernameAvailableInput!) {
  usernameAvailable(input: $input) {
    available
  }
}
    `;

/**
 * __useUsernameAvailableQuery__
 *
 * To run a query within a React component, call `useUsernameAvailableQuery` and pass it any options that fit your needs.
 * When your component renders, `useUsernameAvailableQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUsernameAvailableQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUsernameAvailableQuery(baseOptions: Apollo.QueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(UsernameAvailableDocument, options);
      }
export function useUsernameAvailableLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UsernameAvailableQuery, UsernameAvailableQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UsernameAvailableQuery, UsernameAvailableQueryVariables>(UsernameAvailableDocument, options);
        }
export type UsernameAvailableQueryHookResult = ReturnType<typeof useUsernameAvailableQuery>;
export type UsernameAvailableLazyQueryHookResult = ReturnType<typeof useUsernameAvailableLazyQuery>;
export type UsernameAvailableQueryResult = Apollo.QueryResult<UsernameAvailableQuery, UsernameAvailableQueryVariables>;
export const GetReportsDocument = gql`
    query GetReports($input: ReportsInput, $after: String, $before: String, $last: Float, $first: Float) {
  reports(
    input: $input
    after: $after
    before: $before
    last: $last
    first: $first
  ) {
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
    edges {
      cursor
      node {
        ...Report
      }
    }
    count
    totalCount
  }
}
    ${ReportFragmentDoc}`;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      input: // value for 'input'
 *      after: // value for 'after'
 *      before: // value for 'before'
 *      last: // value for 'last'
 *      first: // value for 'first'
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const UploadFileDocument = gql`
    mutation UploadFile($input: UploadFileInput!, $file: Upload!) {
  uploadFile(file: $file, input: $input) {
    upload {
      id
      url
    }
    success
    url
  }
}
    `;
export type UploadFileMutationFn = Apollo.MutationFunction<UploadFileMutation, UploadFileMutationVariables>;

/**
 * __useUploadFileMutation__
 *
 * To run a mutation, you first call `useUploadFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadFileMutation, { data, loading, error }] = useUploadFileMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadFileMutation(baseOptions?: Apollo.MutationHookOptions<UploadFileMutation, UploadFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadFileMutation, UploadFileMutationVariables>(UploadFileDocument, options);
      }
export type UploadFileMutationHookResult = ReturnType<typeof useUploadFileMutation>;
export type UploadFileMutationResult = Apollo.MutationResult<UploadFileMutation>;
export type UploadFileMutationOptions = Apollo.BaseMutationOptions<UploadFileMutation, UploadFileMutationVariables>;