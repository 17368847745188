import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Button, DragDropContext, Draggable, Droppable } from '@/components';
import { FeatherIconName } from '@/components/icon';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { DragIcon } from '../drag-icon';
import { BulkAddModal } from '../bulk-add-modal';
import { TokenIdFields } from './TokenIdFields';
import type { TokenIdField } from '../../types';

export type MultiTokenIdFormProps = StyleProps & {
  isDisabled?: boolean;
  tokenIdFields: TokenIdField[];
  onAddField: (value?: string) => void;
  onAddFields: (values?: string[]) => void;
  onRemoveField: (index: number) => void;
  onUpdateFieldValue: (index: number, value: string) => void;
  onUpdateFieldOrder: (fromIndex: number, toIndex: number) => void;
};

const ROOT = makeRootClassName('MultiTokenIdForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  tokenIdFields: [],
} as const;

function MultiTokenIdForm(props: MultiTokenIdFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.tokenIdFields.length > 1;

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          p.onUpdateFieldOrder(source.index, destination.index);
        }
      }}
    >
      <Droppable droppableId="drop-token-id" isDropDisabled={p.isDisabled}>
        {(provided) => (
          <div
            className={clsx(ROOT, p.className, {
              'has-multiple': hasMultiple,
            })}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {p.tokenIdFields.map((field, index) => {
              return (
                <Draggable
                  key={field.key}
                  draggableId={field.key || `${index}`}
                  index={index}
                >
                  {(provided, dragSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={el`draggable-item`}
                      style={{
                        ...provided.draggableProps.style,
                        transform: dragSnapshot.isDragging
                          ? provided.draggableProps.style?.transform?.replace(
                              /\(.+,/,
                              '(0,'
                            )
                          : provided.draggableProps.style?.transform,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        {hasMultiple && <DragIcon />}
                      </div>
                      <TokenIdFields
                        field={field}
                        isDisabled={p.isDisabled}
                        showRemoveFieldButton={index !== 0}
                        onRemoveField={() => props.onRemoveField(index)}
                        onUpdateFieldValue={(value) =>
                          props.onUpdateFieldValue(index, value)
                        }
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div className={el`actions-wrapper`}>
              <Button
                variant="text-white"
                startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
                onPress={() => {
                  p.onAddField();
                }}
                className={el`add-button`}
                size="custom"
                isDisabled={p.isDisabled}
              >
                Add Token ID
              </Button>
              <BulkAddModal
                type="token"
                onSubmit={(value) => {
                  p.onAddFields(value);
                }}
              />
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default MultiTokenIdForm;
