import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import React, { forwardRef } from 'react';
import { useOptionalRef } from '@/hooks';
import { createChevronDown } from '@/assets/icons';
import { Icon } from '..';
import { makeElementClassNameFactory, makeRootClassName } from '../../utils';
import { FeatherIcon, FeatherIconName } from '../icon';
import type { ForwardedRef, ReactElement } from 'react';
import type { AriaButtonProps } from '@react-types/button';
import type { OmittedAriaProps, StyleProps } from '../../utils';
import type { IconData, IconProps } from '../icon/Icon';

// types

export type BaseButtonProps = StyleProps &
  Omit<AriaButtonProps<'button'>, OmittedAriaProps> & {
    /**
     * The size of the button.
     * @default "medium"
     */
    size?: 'xs' | 'small' | 'medium' | 'large' | 'custom';

    /**
     * The type of the button.
     * @default "button"
     */
    type?: 'submit' | 'button';

    /**
     * Whether the button is selected.
     * @default false
     */
    isSelected?: boolean;

    /**
     * Whether the button has de-emphasized (ghost) styles.
     * @default false
     */
    isGhost?: boolean;

    /**
     * Whether the button is a dropdown trigger.
     * @default false
     */
    isDropdown?: boolean;

    /**
     * Whether the button should have an outline style.
     */
    isOutline?: boolean;

    /**
     * The button's visual appearance.
     * @default "default"
     */
    variant?:
      | 'default'
      | 'cta'
      | 'comment'
      | 'primary'
      | 'secondary'
      | 'success'
      | 'danger'
      | 'dark'
      | 'light'
      // You probably don't want this, but available for select cases
      // where you need to override the regular design.
      | 'custom';

    /** The button's left icon. */
    startIcon?: IconData;

    /** The button's right icon. */
    endIcon?: IconData;

    /** The left icon's viewBoxWidth @default 20*/
    startIconViewBoxWidth?: number;

    /** The left icon's viewBoxWidth @default 20*/
    startIconViewBoxHeight?: number;

    /** The left icon's className */
    startIconClassName?: string;

    /** The right icon's viewBoxWidth @default 20*/
    endIconViewBoxWidth?: number;

    /** The right icon's viewBoxWidth @default 20*/
    endIconViewBoxHeight?: number;

    /** The right icon's className */
    endIconClassName?: string;

    /**
     * The button's left icon, if it comes from the feather icon set.
     * If this prop is used, it takes prcedence over any other icon set on
     * the button.
     */
    startFeatherIcon?: FeatherIconName;

    /**
     * The button's right icon, if it comes from the feather icon set.
     * If this prop is used, it takes prcedence over any other icon set on
     * the button.
     */
    endFeatherIcon?: FeatherIconName;

    /**
     * The left icon's size.
     * @default 'medium'
     */
    startIconSize?: IconProps['size'];

    /**
     * The right icon's size.
     * @default 'medium'
     */
    endIconSize?: IconProps['size'];

    /** The button's label. */
    children?: string;

    /**
     * You probably do not want to use this, but instead `onPress`. Because of
     * [this issue](https://github.com/adobe/react-spectrum/issues/963) `onPress`
     * does not expose `preventDefault` on the `PressEvent` created through
     * onPress. This is a mitigation of that ommission in case the click event
     * should not propagate.
     *
     * NOTE: we prefer `onPress` over `onClick usually because `onClick` happens
     * after the full click is processed rather than on mouse down, and it does
     * not support keyboard bounded events.
     */
    rawOnClick?: (
      ...params: Parameters<React.MouseEventHandler<HTMLButtonElement>>
    ) => NonNullable<ReturnType<React.MouseEventHandler<HTMLButtonElement>>>;
  };

// config

const ROOT = makeRootClassName('Button');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  type: 'button',
  variant: 'default',
  color: 'gray',
  size: 'medium',
  isOutline: false,
  isSelected: false,
  isGhost: false,
  startIconViewBoxWidth: 20,
  startIconViewBoxHeight: 20,
  endIconViewBoxWidth: 20,
  endIconViewBoxHeight: 20,
  // dirty onClick type fix
  rawOnClick: undefined,
  startIconSize: 'medium',
  endIconSize: 'medium',
} as const;

const DROPDOWN_ICON = createChevronDown;
// main

function ButtonComponent(
  props: BaseButtonProps,
  ref: ForwardedRef<HTMLButtonElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useOptionalRef(ref);

  // behavior
  // --------

  // remove onHackyClick from props to be appended later
  // eslint-disable-next-line prefer-destructuring
  const rawOnClick = p.rawOnClick;
  delete p.rawOnClick;

  const { buttonProps, isPressed } = useButton(p, domRef);
  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(
    buttonProps,
    hoverProps,
    focusProps,
    ...(rawOnClick ? [{ onClick: rawOnClick }] : [{}])
  );

  // rendering
  // ---------

  return (
    <button
      ref={domRef}
      {...behaviorProps}
      className={clsx([
        `${ROOT} variant-${p.variant} color-${p.color} size-${p.size}`,
        {
          'is-selected': p.isSelected,
          'is-ghost': p.isGhost,
          'is-icon-button': !p.children,
          'has-icon-start': p.startIcon || p.startFeatherIcon,
          'has-icon-end': (!p.isDropdown && p.endIcon) || p.endFeatherIcon,
          'is-disabled': p.isDisabled,
          'is-pressed': isPressed,
          'is-hovered': isHovered,
          'is-focus-visible': isFocusVisible,
          'is-outline': p.isOutline,
          'is-dropdown': p.isDropdown,
        },
        p.className,
      ])}
    >
      {p.startFeatherIcon && (
        <FeatherIcon content={p.startFeatherIcon} size={p.startIconSize} />
      )}
      {p.startIcon && !p.startFeatherIcon && (
        <Icon
          content={p.startIcon}
          size={p.startIconSize}
          viewBoxWidth={p.startIconViewBoxWidth}
          viewBoxHeight={p.startIconViewBoxHeight}
          className={p.startIconClassName}
        />
      )}
      {p.children && <span className={clsx(el`label`)}>{p.children}</span>}
      {p.isDropdown && (
        <Icon
          content={DROPDOWN_ICON}
          size="custom"
          className={el`dropdown-icon`}
          viewBoxWidth={p.endIconViewBoxWidth}
          viewBoxHeight={p.endIconViewBoxHeight}
        />
      )}
      {!p.isDropdown && p.endFeatherIcon && (
        <FeatherIcon content={p.endFeatherIcon} size={p.endIconSize} />
      )}
      {!p.isDropdown && p.endIcon && !p.endFeatherIcon && (
        <Icon
          content={p.endIcon}
          size={p.endIconSize}
          viewBoxWidth={p.endIconViewBoxWidth}
          viewBoxHeight={p.endIconViewBoxHeight}
          className={p.endIconClassName}
        />
      )}
    </button>
  );
}

/**
 * A control that allows users to perform an action or to navigate to another page.
 */
export const Button =
  forwardRef<HTMLButtonElement, BaseButtonProps>(ButtonComponent);

export default Button;
