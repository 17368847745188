import path from 'path';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, Modal, BulkFileDrop, Text } from '@/components';
import { FeatherIconName } from '@/components/icon';
import { FileUpload } from '@/hooks/uploads/useUploads';
import { FileDropProps } from '@/components/file-drop/FileDrop';
import { BulkFileDropProps } from '@/components/bulk-file-drop/BulkFileDrop';
import { UploadEvidenceFormProps } from '../upload-evidence-form/UploadEvidenceForm';

export type BulkUploadModalProps = StyleProps &
  Pick<
    UploadEvidenceFormProps,
    | 'isDisabled'
    | 'onRemoveFile'
    | 'onUpdateSource'
    | 'onUpdateFieldOrder'
    | 'onAddFile'
    | 'onAddField'
  > & {
    /**  */
    uploads?: FileUpload[];
    /** Whether or not the file is in an uploading state */
    isUploading: boolean;
    /** Whether or not actions are disabled */
    isDisabled?: boolean;
  };

const ROOT = makeRootClassName('BulkUploadModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function BulkUploadModal(props: BulkUploadModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);
  const countFiles = p.uploads?.length ?? 0;
  const isAboveLimit = countFiles > 50;
  const isNoValueExtracted = countFiles === 0;
  const isError = isNoValueExtracted || isAboveLimit;
  const isEmpty = countFiles === 0;

  const toggleOpen = () => {
    setOpen(!open);
  };

  const handleDropFile: FileDropProps['onDrop'] = (files) => {
    const input = Array.from(files ?? []);

    if (input.length > 0) {
      input.forEach((file, index) => {
        p.onAddField();
        p.onAddFile(index, file);
      });
    }
  };

  const fileDropProps: BulkFileDropProps = {
    files: p.uploads?.map((file) => file.file) ?? [],
    isUploading: p.isUploading,
    isDisabled: p.isDisabled,
    onDrop: handleDropFile,
    onSelectFiles: handleDropFile,
    onRemoveFile: p.onRemoveFile,
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Button
        onPress={toggleOpen}
        startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
        variant="text-white"
      >
        Bulk Add
      </Button>

      <Modal.Root open={open} onOpenChange={setOpen} className={el`modal`}>
        <Modal.Content>
          <Modal.Header
            title="Bulk Upload Evidence"
            subtitle="We'll try to recognize image URLs in any format you enter or paste below."
            hasCloseButton
          />
          <div className={el`text-area-wrapper`}>
            <BulkFileDrop {...fileDropProps} />
            {!isNoValueExtracted && (
              <Text
                type="body-sm"
                className={clsx(el`description`, {
                  'is-error': isError,
                })}
              >
                Detected {countFiles} {countFiles === 1 ? 'file' : 'files'}
              </Text>
            )}
            {isEmpty && (
              <Text
                type="body-sm"
                className={clsx(el`description`, {
                  'is-error': isError,
                })}
              >
                No urls or files detected.
              </Text>
            )}
          </div>
          <Modal.Footer className={el`modal-footer`}>
            <Modal.FooterButton isCloseButton>Cancel</Modal.FooterButton>
            <Modal.FooterButton
              isDisabled={isNoValueExtracted}
              isPrimary
              onPress={() => {
                setOpen(false);
              }}
            >
              Attach Evidence
            </Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

export default BulkUploadModal;
