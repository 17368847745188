import clsx from 'clsx';
import React, { ReactElement, useEffect, useState } from 'react';
import { Button, Modal, Text } from '@/components';
import { useMe } from '@/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Switch, Link } from '@/components';
import { PRIVACY_POLICY_URL } from '@/types/routes';
import {
  getIsAcceptedPrivacyPolicyCookieValue,
  setIsAcceptedPrivacyPolicyCookie,
} from '@/utils/privacyPolicy';
import { useUpdateUserProfileMutation } from '@/generated/graphql';
import { PRIVACY_POLICY_VERSION } from '@/constants';
import { useCookieConsent } from '@/providers';
import type { DialogProps } from '@radix-ui/react-dialog';

type LabeledSwitchProps = {
  isDisabled?: boolean;
  checked?: boolean;
  onCheckedChange: (checked: boolean) => void;
};

function LabeledSwitch(props: LabeledSwitchProps) {
  return (
    <div className={el`toggle-wrapper`}>
      <div>
        <Switch
          checked={props.checked}
          onCheckedChange={props.onCheckedChange}
          disabled={props.isDisabled}
          className={el`toggle`}
        />
      </div>
      <div className="text-body-sm text-trm-blue-1 flex">
        <Text type="custom" className={clsx(el`text`)}>
          I have read and agree with the Privacy Policy
        </Text>
      </div>
    </div>
  );
}

export type PrivacyPolicyModalProps = StyleProps &
  DialogProps & {
    handleClickPrivacyPolicy: () => void;
  };

const ROOT = makeRootClassName('PrivacyPolicyModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  hasTrigger: true,
} as const;

export function PrivacyPolicyModal(
  props: PrivacyPolicyModalProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);
  const [isAgreedPrivacyPolicy, setIsAgreedPrivacyPolicy] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);
  const { me, loading } = useMe();
  const {
    state: { isAccepted },
  } = useCookieConsent();

  useEffect(() => {
    if (loading) return;

    if (me && me?.acceptedPrivacyPolicyVersion !== PRIVACY_POLICY_VERSION)
      setOpen(true);

    if (isAccepted && !me && !getIsAcceptedPrivacyPolicyCookieValue())
      setOpen(true);
  }, [isAccepted, loading, me]);

  const onSubmit = () => {
    if (isAgreedPrivacyPolicy) {
      props.handleClickPrivacyPolicy();
      return setOpen(false);
    }
    setShowErrorMsg(true);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Modal.Root open={open}>
        <Modal.Content size="small">
          <div className={el`header`}>Updated terms</div>
          <main className={el`body`}>
            <div className="text-center">
              <Text type="body-sm" className={el`text`}>
                Dear Chainabuse community member,
              </Text>
              <div className="flex justify-center">
                <Text type="body-sm" className={el`text`}>
                  we have revised our
                </Text>
                <Link
                  variant="white"
                  size="small"
                  isExternal
                  href={PRIVACY_POLICY_URL}
                  className="ml-[5px] underline"
                >
                  Privacy Policy.
                </Link>
              </div>
            </div>

            <div className="text-center">
              <Text type="body-sm" className={el`text`}>
                By accepting, you agree to our
              </Text>
              <div className="flex justify-center">
                <Text type="body-sm" className={el`text`}>
                  updated
                </Text>
                <Link
                  variant="white"
                  size="small"
                  isExternal
                  href={PRIVACY_POLICY_URL}
                  className="ml-[5px] underline"
                >
                  Privacy Policy.
                </Link>
              </div>
              <Text type="body-sm" className={el`text`}>
                Please take a few moments to read
              </Text>
              <Text type="body-sm" className={el`text`}>
                and understand it.
              </Text>
            </div>

            <div className={el`section`}>
              <LabeledSwitch
                checked={isAgreedPrivacyPolicy}
                onCheckedChange={() => {
                  setIsAgreedPrivacyPolicy(!isAgreedPrivacyPolicy);
                  if (!isAgreedPrivacyPolicy) setShowErrorMsg(false);
                }}
              />

              {showErrorMsg && (
                <Text type="custom" className={el`error-msg`}>
                  You need to accept the privacy terms before submitting.
                </Text>
              )}
              <Button variant="primary" onPress={onSubmit}>
                SUBMIT
              </Button>
            </div>
          </main>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

const PrivacyPolicyModalContainer = (): ReactElement => {
  const { me } = useMe();
  const [UpdateUserProfileMutation] = useUpdateUserProfileMutation();

  const handleClickPrivacyPolicy = async () => {
    if (me) {
      await UpdateUserProfileMutation({
        variables: {
          input: {
            acceptedPrivacyPolicyVersion: PRIVACY_POLICY_VERSION,
          },
        },
      });
      return;
    }
    setIsAcceptedPrivacyPolicyCookie(true);
  };

  return (
    <>
      <PrivacyPolicyModal handleClickPrivacyPolicy={handleClickPrivacyPolicy} />
    </>
  );
};

export default PrivacyPolicyModalContainer;
