import { createContext } from 'react';
import { MyFeedContextValue } from './provider';

const MyFeedContext = createContext<MyFeedContextValue>({
  actions: {
    setSearchQuery: () => {
      throw new Error(
        "No MyFeedContext available. You must use this hook in a component that's a descendent of MyFeedProvider"
      );
    },
  },
  state: {
    searchQuery: undefined,
  },
});

export default MyFeedContext;
