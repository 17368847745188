import { useContext } from 'react';
import SettingsModalContext from './context';
import type { AuthContextValue } from './provider';

function useAuthModal(): AuthContextValue {
  const context = useContext(SettingsModalContext);
  if (!context) {
    throw new Error(
      "No AuthContext available. You need to use this hook in a component that's a downstream child of AuthModalProvider"
    );
  }
  return context;
}

export default useAuthModal;
