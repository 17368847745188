import { DialogProps } from '@radix-ui/react-dialog';
import clsx from 'clsx';
import { Formik, FormikErrors } from 'formik';
import React, { ReactElement } from 'react';
import * as yup from 'yup';
import { Modal, Text } from '@/components';
import { useAuthModal } from '@/features/auth';
import { FormikTextField } from '@/features/formik';
import { useUpdateProfile } from '@/features/profile-page';
import { useMe, useUsernameAvailable } from '@/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { usernameSchema } from '@/utils/usernameSchema';

const EditUsernameSchema = yup.object().shape({
  username: usernameSchema,
});

type EditUsernameFormValues = {
  username: string;
};

export type PostAuthModalProps = StyleProps &
  DialogProps & {
    /** The initial value to suggest to the user as a username */
    initialUsername?: string;

    /** Handler to check whether username is free */
    isUsernameFree: (username: string) => Promise<boolean>;

    /** Handler to call on confirm username */
    onConfirmUsername: (username: string) => void;

    /** Handler to call if the user dismisses the modal (only by canceling) */
    onDismissModal: () => void;
  };

const ROOT = makeRootClassName('PostAuthModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function PostAuthModal(props: PostAuthModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      {/* // always render open -- either render or don't render to close */}
      <Modal.Root open>
        <Modal.Content size="small">
          <Modal.Header title="Thank you for signing up to Chainabuse!" />
          <main className={el`body`}>
            <Formik
              initialValues={{ username: p.initialUsername ?? '' }}
              validationSchema={EditUsernameSchema}
              onSubmit={async (values, { setSubmitting, setErrors }) => {
                const { username } = values;

                const errors: FormikErrors<EditUsernameFormValues> = {};

                if (username !== p.initialUsername) {
                  try {
                    const unique = await p.isUsernameFree(values.username);
                    if (!unique) {
                      errors.username =
                        'Username taken. Please choose a different one.';
                      setErrors(errors);
                      return;
                    }
                  } catch (e) {
                    errors.username =
                      'Something went wrong. Please try again later.';
                    setErrors(errors);
                    return;
                  }
                }

                p.onConfirmUsername(username);
              }}
            >
              {({ values, errors, submitForm }) => (
                <>
                  <FormikTextField
                    type="text"
                    name="username"
                    label="Enter a username"
                    placeholder="username"
                    autoFocus
                    autoComplete="off"
                  />
                  {!errors.username && values.username === p.initialUsername && (
                    <Text type="h5" className={el`help-text`}>
                      Lacking ideas? Here is a random name for you.
                    </Text>
                  )}
                  <Modal.Footer>
                    <Modal.FooterButton
                      isPrimary
                      isDisabled={values.username === ''}
                      onPress={submitForm}
                    >
                      Confirm
                    </Modal.FooterButton>
                    <Modal.FooterButton onPress={() => p.onDismissModal()}>
                      Cancel
                    </Modal.FooterButton>
                  </Modal.Footer>
                </>
              )}
            </Formik>
          </main>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

type PostAuthModalContainerProps = Pick<PostAuthModalProps, 'onOpenChange'> & {
  // add props
};

const PostAuthModalContainer = (
  props: PostAuthModalContainerProps
): ReactElement => {
  const { me } = useMe();
  const { isUsernameAvailable } = useUsernameAvailable();
  const [updateProfile] = useUpdateProfile();
  const {
    actions: { closePostAuthModal },
  } = useAuthModal();

  // can only show PostAuthModal if there's an auth'd user
  if (!me) {
    return <></>;
  }

  const suggestedUsername = me?.username ?? 'wickedwarlock24';

  return (
    <PostAuthModal
      initialUsername={suggestedUsername}
      onOpenChange={props.onOpenChange}
      onConfirmUsername={async (username) => {
        await updateProfile({ username });
        closePostAuthModal();
      }}
      onDismissModal={closePostAuthModal}
      isUsernameFree={isUsernameAvailable}
    />
  );
};

export default PostAuthModalContainer;
