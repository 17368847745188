import { ReportedSectionProps } from './ReportedSection';

export const shouldShowLabel = (
  variant: ReportedSectionProps['variant']
): boolean => {
  switch (variant) {
    case 'compact':
      return false;

    case 'expanded':
      return false;

    case 'default':
      return true;

    default:
      return true;
  }
};
