import {
  Root,
  SwitchProps as RadixSwitchProps,
  Thumb,
} from '@radix-ui/react-switch';
import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type SwitchProps = StyleProps & RadixSwitchProps;

const ROOT = makeRootClassName('Switch');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function Switch(props: SwitchProps): ReactElement {
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <Root
      {...focusProps}
      {...props}
      className={clsx(
        ROOT,
        {
          'is-focus-visible': isFocusVisible,
        },
        props.className
      )}
    >
      <Thumb className={el`thumb`} />
    </Root>
  );
}

export default Switch;
