import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { BLUR_COMMAND, COMMAND_PRIORITY_LOW, LexicalEditor } from 'lexical';
import { useEffect } from 'react';
import { StyleProps } from '@/utils';

export type OnBlurPluginProps = StyleProps & {
  /**
   * On blur callback
   */
  onBlur: (editor: LexicalEditor) => void;
};

const DEFAULT_PROPS = {} as const;

function OnBlurPlugin(props: OnBlurPluginProps): null {
  const p = { ...DEFAULT_PROPS, ...props };
  const [editor] = useLexicalComposerContext();

  useEffect(
    () =>
      editor.registerCommand(
        BLUR_COMMAND,
        () => {
          p.onBlur(editor);
          return false;
        },
        COMMAND_PRIORITY_LOW
      ),
    []
  );

  return null;
}

export default OnBlurPlugin;
