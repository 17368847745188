import React, { ReactElement } from 'react';
import { useMe } from '@/hooks';
import { PreAuthModal } from './components/pre-auth-modal';
import { VerificationModal } from './components/verification-modal';
import type { DialogProps } from '@radix-ui/react-dialog';

enum AuthStep {
  PREAUTH,
  VERFICIATION,
}

export type AuthFlowProps = DialogProps & {
  modalType: AuthStep;
  returnTo: string;
};

const DEFAULT_PROPS = {} as const;

function AuthFlow(props: AuthFlowProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  switch (p.modalType) {
    case AuthStep.PREAUTH:
      return (
        <PreAuthModal
          hasTrigger={false}
          returnTo={props.returnTo}
          shouldStartOpen
          onOpenChange={p.onOpenChange}
        />
      );
    case AuthStep.VERFICIATION:
      return <VerificationModal onOpenChange={p.onOpenChange} />;
  }
}

type AuthFlowContainerProps = Omit<AuthFlowProps, 'modalType'>;

const AuthFlowContainer = (props: AuthFlowContainerProps): ReactElement => {
  const { me, loading } = useMe();
  if (loading) {
    return <></>;
  }

  if (!me) {
    return (
      <AuthFlow
        onOpenChange={props.onOpenChange}
        modalType={AuthStep.PREAUTH}
        returnTo={props.returnTo}
      />
    );
  }

  if (!me.isVerified) {
    return (
      <AuthFlow
        onOpenChange={props.onOpenChange}
        modalType={AuthStep.VERFICIATION}
        returnTo={props.returnTo}
      />
    );
  }

  return <></>;
};

export default AuthFlowContainer;
