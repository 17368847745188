import * as cookie from 'cookie';
import isServer from '@/utils/isServer';

const COOKIE_CONSENT_COOKIE_NAME = 'cookie_consent_is_accepted';
const ONE_YEAR_IN_SECONDS = 365 * 24 * 60 * 60;

export const getIsAcceptedCookieValue = (): boolean => {
  if (!isServer()) {
    const cookies = cookie.parse(document.cookie);
    const isAccepted = cookies[COOKIE_CONSENT_COOKIE_NAME];
    return isAccepted === 'true';
  }
  return false;
};

export const setIsAcceptedCookie = (isAccepted: boolean): void => {
  if (!isServer()) {
    document.cookie = cookie.serialize(
      COOKIE_CONSENT_COOKIE_NAME,
      isAccepted.toString(),
      {
        maxAge: ONE_YEAR_IN_SECONDS,
        path: '/',
        sameSite: 'lax',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || undefined,
      }
    );
  }
};

export const removeIsAcceptedCookie = (): void => {
  if (!isServer()) {
    document.cookie = cookie.serialize(COOKIE_CONSENT_COOKIE_NAME, '', {
      maxAge: -1,
    });
  }
};
