import clsx from 'clsx';
import React, { ReactElement, ReactNode } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type LabeledInfoProps = StyleProps & {
  label: string;
  children?: ReactNode;
};

const ROOT = makeRootClassName('LabeledInfo');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function LabeledInfo(props: LabeledInfoProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <label htmlFor={props.label}>{props.label}</label>
      {props.children}
    </div>
  );
}

export default LabeledInfo;
