import { ReactElement, ReactNode, useEffect, useState } from 'react';
import { Button, Modal, Text } from '@/components';
import { CONTACT_SUPPORT_ROUTE } from '@/types/routes';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import {
  GlobalErrorProvider,
  useErrorModal,
} from './contexts/global-error-context';

export type GlobalErrorNotifierProps = StyleProps & {
  children: ReactNode;
};

const ROOT = makeRootClassName('GlobalErrorNotifier');
const el = makeElementClassNameFactory(ROOT);

enum ErrorType {
  SERVER = 'server',
  UNKNOWN = 'unknown',
}

const getDefaultTitleFromErrorType = (errorType: ErrorType): string => {
  switch (errorType) {
    case ErrorType.SERVER:
      return 'Server Error';
    case ErrorType.UNKNOWN:
      return 'Unknown Error';
  }
};

const getDefaultDescriptionFromErrorType = (errorType: ErrorType): string => {
  switch (errorType) {
    case ErrorType.SERVER:
      return 'The server is temporarily down. Please try again.';
    case ErrorType.UNKNOWN:
      return `Something went wrong and we couldn't complete your request. Please try again.`;
  }
};

function GlobalErrorModal(): ReactElement {
  const [open, setOpen] = useState(false);

  const {
    state: { title, description, onTryAgain, error, showContactUsLink },
  } = useErrorModal();

  useEffect(() => {
    if (error) {
      setOpen(true);
    }
  }, [error]);

  const errorType = error?.networkError ? ErrorType.SERVER : ErrorType.UNKNOWN;

  const handleTryAgain = () => {
    setOpen(false);
    onTryAgain?.();
  };

  return (
    <Modal.Root open={open} onOpenChange={setOpen}>
      <Modal.Content>
        <Modal.Header
          hasCloseButton
          title={title || getDefaultTitleFromErrorType(errorType)}
        />
        <Text>
          {description || getDefaultDescriptionFromErrorType(errorType)}
        </Text>
        {showContactUsLink && (
          <Text as="span" className={el`contact-us-link`}>
            <br />
            If this continues, please{' '}
            <a href={CONTACT_SUPPORT_ROUTE}>contact us</a> to report a problem.
          </Text>
        )}
        <div className={el`buttons`}>
          <Button onPress={handleTryAgain}>TRY AGAIN</Button>
          <Button variant="secondary" onPress={() => setOpen(false)}>
            CANCEL
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}

function GlobalErrorNotifier({
  children,
}: GlobalErrorNotifierProps): ReactElement {
  return (
    <GlobalErrorProvider>
      {children}
      <GlobalErrorModal />
    </GlobalErrorProvider>
  );
}

export default GlobalErrorNotifier;
