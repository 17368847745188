import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  ChainType,
  getDisplayStringForChainType,
  getImageForChain,
} from '@/types/chain';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ChainIconProps = StyleProps & {
  /** The chain */
  chainType?: ChainType;
  /** The size of the icon. @default small */
  size?: 'xs' | 'small' | 'large';
};

const ROOT = makeRootClassName('ChainIcon');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'small',
} as const;

function ChainIcon(props: ChainIconProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <>
      {p.chainType && (
        <img
          src={getImageForChain(p.chainType)}
          alt={`${getDisplayStringForChainType(p.chainType)} logo`}
          className={clsx(`${ROOT} size-${p.size}`, p.className)}
        />
      )}
    </>
  );
}

export default ChainIcon;
