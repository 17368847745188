import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { ChainIcon, Modal, Text } from '@/components';
import { ModalRootProps } from '@/components/modal';
import {
  ChainType,
  getDisplayStringForChainType,
  getTickerForChain,
} from '@/types/chain';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ChainSelectModalProps = StyleProps &
  Pick<ModalRootProps, 'open' | 'onOpenChange'> & {
    /** The address that we're showing chain options for */
    value: string;

    /** The chain options for the address */
    options: ChainType[];

    /** Handler to call when the user chooses a chain */
    onSelectChain: (chain: ChainType) => void;
  };

const ROOT = makeRootClassName('ChainSelectModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ChainSelectModal(props: ChainSelectModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <Modal.Root open={p.open} onOpenChange={p.onOpenChange}>
      <Modal.Content size="medium" className={clsx(ROOT, p.className)}>
        <Modal.Header
          title="Select a chain for your search"
          subtitle={p.value}
          hasCloseButton
          variant="subtle"
        />
        <ul className={el`options`}>
          {p.options.map((option): ReactElement => {
            return (
              <li
                key={`chain-option-${option}`}
                {...buttonize(() => p.onSelectChain(option))}
                className={clsx(el`option`)}
              >
                <ChainIcon size="small" chainType={option} />
                <Text type="body-md" className={el`chain-label`}>
                  {`${getDisplayStringForChainType(
                    option
                  )} (${getTickerForChain(option)})`}
                </Text>
              </li>
            );
          })}
        </ul>
      </Modal.Content>
    </Modal.Root>
  );
}

export default ChainSelectModal;
