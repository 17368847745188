import { useContext } from 'react';
import CookieConsentContext from './context';
import { CookieConsentContextValue } from './provider';

export default function useCookieConsentContext(): CookieConsentContextValue {
  const context = useContext(CookieConsentContext);
  if (!context) {
    throw new Error(
      "No CookieConsentContext available. You must use this hook in a component that's a descendent of CookieConsentProvider"
    );
  }

  return context;
}
