import {
  CommentableEntityKind,
  ReportCommentFragment,
  useAddCommentMutation,
  useGetCommentsForReportQuery,
} from '@/generated/graphql';
import { useMe } from '@/hooks';

type UseAddEditCommentState = {
  /** @TODO  */
};
type UseAddEditCommentActions = {
  addComment: (comment: string) => void;
};
type UseAddEditCommentValue = UseAddEditCommentState & UseAddEditCommentActions;

type UseAddEditCommentOptions = {
  reportId: string;
};

const useAddEditComment = (
  options: UseAddEditCommentOptions
): UseAddEditCommentValue => {
  const [addCommentMutation] = useAddCommentMutation();

  const { me } = useMe();

  const { data } = useGetCommentsForReportQuery({
    variables: { input: { id: options.reportId } },
  });

  const addComment = async (comment: string) => {
    const newCommentsCount = data?.report ? data.report.commentsCount + 1 : 1;

    const newComment: ReportCommentFragment = {
      __typename: 'ReportComment',
      id: 'temp-id',
      body: comment,
      createdAt: new Date().toISOString(),
      author: {
        __typename: 'User',
        id: me?.id || '',
        username: me?.username || '',
        photo: me?.photo,
      },
    };

    const newCommentsList = data?.report.comments
      ? [newComment, ...data.report.comments]
      : [newComment];

    const { errors } = await addCommentMutation({
      variables: {
        input: {
          body: comment,
          subjectId: options.reportId,
          subjectType: CommentableEntityKind.REPORT,
        },
      },
      optimisticResponse: {
        addComment: {
          success: true,
          subject: {
            __typename: 'Report',
            id: options.reportId,
            commentsCount: newCommentsCount,
            comments: newCommentsList,
          },
          comment: newComment,
        },
      },
    });

    if (errors) {
      // @TODO handle errors
    }
  };

  return {
    addComment,
  };
};

export default useAddEditComment;
