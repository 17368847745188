export const isBTCTransactionHash = (value: string): boolean =>
  /^[a-fA-F0-9]{64}$/i.test(value);
export const isETHTransactionHash = (value: string): boolean =>
  /^0x[a-fA-F0-9]{64}$/i.test(value);
export const isTRXTransactionHash = (value: string): boolean =>
  /^[a-fA-F0-9]{64}$/i.test(value);
export const isSOLTransactionHash = (value: string): boolean =>
  /^[1-9A-HJ-NP-Za-km-z]{88}$/.test(value);
export const isHBARTransactionHash = (value: string): boolean =>
  /^\d+\.\d+\.\d+@\d+\.\d+$/.test(value);
export const isADATransactionHash = (value: string): boolean =>
  /^[a-fA-F0-9]{64}$/i.test(value);
export const isLTCTransactionHash = (value: string): boolean =>
  /^[a-fA-F0-9]{64}$/i.test(value);
export const isAVAXTransactionHash = (value: string): boolean =>
  /^0x[a-fA-F0-9]{64}$/i.test(value);
export const isMVXTransactionHash = (value: string): boolean =>
  /^[a-fA-F0-9]{64}$/i.test(value);
export const isATTNTransactionHash = (value: string): boolean =>
  /^0x[a-fA-F0-9]{64}$/i.test(value);
export const isALGOTransactionHash = (value: string): boolean =>
  /^[A-Z2-7]{44,52}$/.test(value);
export const isBASETransactionHash = (value: string): boolean =>
  /^0x[a-fA-F0-9]{64}$/i.test(value); // Base chain is a coinbase made Ethereum L2

export const isPossibleTransactionHash = (value: string): boolean =>
  isBTCTransactionHash(value) ||
  isETHTransactionHash(value) ||
  isTRXTransactionHash(value) ||
  isSOLTransactionHash(value) ||
  isHBARTransactionHash(value) ||
  isADATransactionHash(value) ||
  isLTCTransactionHash(value) ||
  isAVAXTransactionHash(value) ||
  isMVXTransactionHash(value) ||
  isATTNTransactionHash(value) ||
  isALGOTransactionHash(value) ||
  isBASETransactionHash(value);