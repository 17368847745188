import React, { ReactElement, useState } from 'react';
import AuthContext from './context';

type AuthContextProvider = {
  children: React.ReactNode;
};

type AuthContextState = {
  isPostAuthModalOpen: boolean;
  isAuthModalOpen: boolean;
  redirectTo: string;
};

type AuthContextActions = {
  setIsAuthModalOpen: (isAuthModalOpen: boolean) => void;
  setRedirectTo: (redirectTo: string) => void;
  showPostAuthModal: () => void;
  closePostAuthModal: () => void;
};

export type AuthContextValue = {
  state: AuthContextState;
  actions: AuthContextActions;
};

function AuthContextProvider({ children }: AuthContextProvider): ReactElement {
  const [isAuthModalOpen, setIsAuthModalOpen] = useState(false);
  const [isPostAuthModalOpen, setIsPostAuthModalOpen] = useState(false);
  const [redirectTo, setRedirectTo] = useState('/');

  const value = {
    state: { isAuthModalOpen, isPostAuthModalOpen, redirectTo },
    actions: {
      setIsAuthModalOpen,
      setRedirectTo,
      closePostAuthModal: () => setIsPostAuthModalOpen(false),
      showPostAuthModal: () => setIsPostAuthModalOpen(true),
    },
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export default AuthContextProvider;
