import {
  FlaggableEntityKind,
  useFlagReportMutation,
} from '@/generated/graphql';

type UseFlagReportOptions = {
  /** The id of the report */
  reportId: string;
};

type UseFlagReportState = {
  // add any state the hook provides
};

type UseFlagReportActions = {
  flagReport: (reason: string) => Promise<boolean>;
};

export type UseFlagReportValue = UseFlagReportState & UseFlagReportActions;

const useFlagReport = (props: UseFlagReportOptions): UseFlagReportValue => {
  const [flagReportMutation] = useFlagReportMutation();

  const flagReport = async (reason: string) => {
    const response = await flagReportMutation({
      variables: {
        input: {
          subjectId: props.reportId,
          subjectType: FlaggableEntityKind.REPORT,
          reason,
        },
      },
    });

    return response.data?.addFlag.success ?? false;
  };

  return {
    flagReport,
  };
};

export default useFlagReport;
