import { nanoid } from 'nanoid';
import { SubmitReportFormValues, CompromiseIndicatorField } from '../types';

export const getInitialCompromiseIndicatorFieldsFromFormValues = (
  props?: SubmitReportFormValues
): CompromiseIndicatorField[] | undefined => {
  if (!props) return undefined;

  const fields =
    props.compromiseIndicators?.map((indicator) => ({
      key: nanoid(),
      id: indicator.id,
      type: indicator.type,
      value: indicator.value,
    })) || [];

  return fields.length > 0 ? fields : undefined;
};
