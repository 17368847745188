import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Text } from '@/components';
import { ReportLoss, useGetLossesForReportQuery } from '@/generated/graphql';
import { ReportSource } from '@/types/scam-report-source';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

const ROOT = makeRootClassName('LossesSection');
const el = makeElementClassNameFactory(ROOT);

type Loss = {
  asset: string;
  amount: number;
};

type LossesSectionProps = StyleProps & {
  /**
   * List of losses
   */
  losses: Loss[];

  /**
   * If this is a report from ransomwhe.re
   * @default false
   */
  isRansomwhereReport?: boolean;

  loading?: boolean;
};

const DEFAULT_PROPS = {
  isRansomwhereReport: false,
} as const;

/**
 * Gets the amount label based on asset.
 *
 * Instead of showing a fixed number of decimals (for example 2 decimals
 * for any amount & asset combination), the amount of decimals shown vary
 * based on the asset.
 * We would want to have 2 decimals shown for USD, but more decimals shown for
 * other assets such as BTC. For a better understanding, showing only
 * two decimals for BTC can lead to a considerable loss of information.
 * For example (at the time of this writing):
 * showing 0,12999 BTC -> 2974,71 USD vs
 * showing 0.12 BTC -> 2746,28 USD
 * Which is a difference of $228!
 *
 * Currently it is a hacky implementation to only work for USD.
 */
const getLabelForAmount = (
  amount: ReportLoss['amount'],
  asset: ReportLoss['asset']
): string => {
  switch (asset) {
    case 'USD':
      return amount.toFixed(2);

    default:
      return amount.toString();
  }
};

function LossesSection(props: LossesSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Text type="body-lg" isHeavy>
        Amount {p.isRansomwhereReport ? 'requested' : 'lost'}
      </Text>

      <Text>
        {p.losses
          .map(
            (loss) =>
              `${getLabelForAmount(loss.amount, loss.asset)} ${loss.asset}`
          )
          .join(', ')}
      </Text>
    </div>
  );
}

type LossesSectionContainerProps = StyleProps & { reportId: string };

const LossesSectionContainer = (
  props: LossesSectionContainerProps
): ReactElement => {
  const { data, loading } = useGetLossesForReportQuery({
    variables: { input: { id: props.reportId } },
  });
  const losses = data?.report.losses;

  const shouldShowLosses = losses && losses.length > 0;

  // @todo show loading indicator instead of null while loading
  if (!shouldShowLosses) {
    return <></>;
  }

  return (
    <LossesSection
      {...props}
      losses={losses}
      isRansomwhereReport={
        data?.report.source === ReportSource.PARTNER_RANSOMWHERE
      }
      loading={loading}
    />
  );
};

export default LossesSectionContainer;
