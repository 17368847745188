import { UserProvider } from '@auth0/nextjs-auth0';
import { ApolloProvider } from '@apollo/client';
import React, { ReactElement } from 'react';
import { Provider as ComponentProvider } from '@/components';
import AuthModalProvider from '@/features/auth/AuthModalProvider';
import { AuthContextProvider } from '@/features/auth/contexts/auth-context';
import { GlobalErrorNotifier } from '@/features/global-error-notifier';
import {
  CookieConsentProvider,
  MyFeedProvider,
  SSRProvider,
} from '@/providers';
import { useApollo } from './apollo';
import { MediaContextProvider } from './responsive';
import { ProviderType, withProviders } from './withProviders';

type AppProviderProps = {
  children: React.ReactChild;
  initialApolloState: any;
  ssrIsMobile?: boolean;
};

const AppProvider = ({
  children,
  initialApolloState,
  ssrIsMobile,
}: AppProviderProps): ReactElement => {
  const client = useApollo(initialApolloState);

  // Below code is inspired from
  // https://maximzubarev.com/wrap-providers-using-withprovider-hoc
  const providers: ProviderType[] = [
    CookieConsentProvider,
    UserProvider,
    MediaContextProvider,
    ComponentProvider,
    [SSRProvider, { isMobile: ssrIsMobile }],
    GlobalErrorNotifier,
    [ApolloProvider, { client }],
    AuthContextProvider,
    AuthModalProvider,
    MyFeedProvider,
  ];

  return withProviders(children, providers);
};

export default AppProvider;
