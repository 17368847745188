import { print as getGraphqlQuery } from 'graphql';
import localStorage, { CookieStorage } from 'local-storage-fallback';
import { MeDocument, MeQuery } from '@/generated/graphql';
import { initializeApollo } from './apollo';
import isServer from './isServer';
import { getStorageItem, removeStorageItem } from './storage';

export type Me = NonNullable<MeQuery['me']>;
export const AUTHORIZATION_STORAGE_KEY = 'authToken';
const CURRENT_ME = 'me';

export const hasAuth = (): boolean => {
  const authorization = getStorageItem(AUTHORIZATION_STORAGE_KEY);
  return !!authorization;
};

export const getAuthHeaders = (): Record<string, string> => {
  const authorization = getStorageItem(AUTHORIZATION_STORAGE_KEY);
  return {
    ...(authorization ? { authorization } : {}),
  };
};

const ME_QUERY = getGraphqlQuery(MeDocument);

/**
 * Store a user in local storage from the me query.
 * @example
 * ```ts
 * const { data } = useMe();
 * useEffect(() => {
 *   if (me) {
 *     storeMe({ me: data?.me });
 *   }
 * }, [me]);
 * ```
 */
export const storeMe = ({
  me,
  remember,
}: {
  me: Me;
  remember: boolean;
}): void => {
  if (isServer()) return;

  const store = remember ? localStorage : new CookieStorage();
  store.setItem(
    CURRENT_ME,
    JSON.stringify({
      ...me,
      _query: ME_QUERY,
    })
  );
};

export const clearAuthStorage = (): void => {
  removeStorageItem(AUTHORIZATION_STORAGE_KEY);
  removeStorageItem(CURRENT_ME);
};

/**
 * @return {Me} - the user from auth if was stored using the query that this
 * service is expecting. Otherwise, returns null.
 */
export const getCurrentMe = (): Me | null => {
  const me = getStorageItem(CURRENT_ME) ?? '';
  let currentUser = null;
  try {
    currentUser = JSON.parse(me);
    // only return user from local storage if matches same query in this build
    if (!currentUser || currentUser._query !== ME_QUERY) {
      return null;
    }
    delete currentUser._query;
  } catch (ex) {
    // no-op
  }
  return currentUser ?? null;
};

export const removeAuthenticatedDataFromCache = (): void => {
  // never log out on server because SSR isn't being authenticated right now
  if (!isServer()) {
    clearAuthStorage();
    const apolloClient = initializeApollo();
    /**
     *
     * Make sure we don't have any in-progress processes before
     * resetting the store.
     *
     * @link {https://github.com/apollographql/apollo-client/issues/3766}
     */
    apolloClient.stop();
    apolloClient.resetStore();
  }
};
