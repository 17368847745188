import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Select, SelectOption as Option, Text } from '@/components';
import {
  ChannelType,
  getDisplayStringForContactChannel,
} from '@/types/contact-channel';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ContactChannelSelectProps = StyleProps & {
  /** The category the category select should be set to */
  value?: ChannelType;

  /** Handler to call when the selected value changes */
  onChange: (value?: ChannelType) => void;

  /** Whether the channel select is disabled. @default false */
  isDisabled?: boolean;

  /** The channels that should be disabled as options in the menu. @default []*/
  disabledOptions?: ChannelType[];

  /** The select's placeholder when no value is selected */
  placeholder?: string;
};

const ROOT = makeRootClassName('ContactChannelSelect');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ContactChannelSelect(props: ContactChannelSelectProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const contactChannelContent = (channel: ChannelType): ReactElement => (
    <div>
      <Text>{getDisplayStringForContactChannel(channel)}</Text>
    </div>
  );

  const renderSelectedChannel = (channel?: ChannelType): ReactElement => (
    <>{channel ? contactChannelContent(channel) : null}</>
  );

  /**
   * Filter out name since while backend wants it as an option, we have
   * another field for it
   */
  const options = Object.values(ChannelType).filter(
    (type) => type !== ChannelType.NAME
  );

  return (
    <Select
      placeholder={p.placeholder}
      isDisabled={p.isDisabled}
      value={p.value}
      renderValue={renderSelectedChannel}
      onChange={p.onChange}
      className={clsx(ROOT, p.className)}
    >
      {options.map((channel) => (
        <Option key={`channel-select-option-${channel}`} value={channel}>
          {contactChannelContent(channel)}
        </Option>
      ))}
    </Select>
  );
}

export default ContactChannelSelect;
