import { sanitizeUrl } from '@braintree/sanitize-url';
import { useHover } from '@react-aria/interactions';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import { Fragment, ReactElement } from 'react';
import { createLinkExternal, svgGlobe } from '@/assets/icons';
import {
  Badge,
  BlockExplorerLink,
  ChainIcon,
  Icon,
  ResponsiveAddress,
  Text,
  Tooltip,
} from '@/components';
import { Address } from '@/types/address';
import { ChainType } from '@/types/chain';
import {
  getRouteForAddressSearch,
  getRouteForDomainSearch,
} from '@/types/routes';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Mobile, Tablet } from '@/utils/responsive';
import { shouldShowLabel } from './utils';

export type ReportedSectionProps = StyleProps & {
  addresses: Address[];
  /**
   * Reported section's variant.
   *
   * The `default` variant shows a label next to reported addresses, while
   * `compact` and `expanded` do not.
   *
   * `compact` looks like `expanded` but in a smaller footprint
   *
   * @default "default"
   */
  variant?: 'default' | 'compact' | 'expanded';
};

const MAX_REPORTED_ITEMS = 3;

const ROOT = makeRootClassName('ReportedSection');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  variant: 'default',
} as const;

const getSectionHeaderCopy = (addresses: Address[]): string => {
  const hasMultiple = addresses.length > 1;
  const hasDomains = addresses.some((reported) => reported.domain);
  const hasAddresses = addresses.some((reported) => reported.address);
  const hasBoth = hasDomains && hasAddresses;

  if (hasBoth) {
    return 'Reported Addresses and Domains';
  }
  if (hasDomains) {
    return hasMultiple ? 'Reported Domains' : 'Reported Domain';
  }
  if (hasAddresses) {
    return hasMultiple ? 'Reported Addresses' : 'Reported Address';
  }

  return 'Reported Addresses / Domains';
};

const ALL_REPORTS_ICON = createLinkExternal;

export type AllReportsButtonProps = {
  /** Whether the reported value is an address or omain */
  type: 'address' | 'domain';
  /** The reported value */
  reported: string;
  /** The chain for the reported, if known */
  chain?: ChainType;
};

function AllReportsButton(props: AllReportsButtonProps): ReactElement {
  const { type } = props;
  const router = useRouter();

  const isAddress = type === 'address';

  const { hoverProps, isHovered } = useHover({ isDisabled: false });

  return (
    <Tooltip
      content={`See all reports for this ${isAddress ? 'address' : 'domain'}`}
      isInstant
    >
      <button
        {...hoverProps}
        aria-label={`See all reports for this ${
          isAddress ? 'address' : 'domain'
        }`}
        onClick={(e) => {
          e.stopPropagation();

          if (isAddress) {
            router.push(getRouteForAddressSearch(props.reported, props.chain));
            return;
          }
          router.push(getRouteForDomainSearch(props.reported));
        }}
        className={clsx(el`all-reports-button`, {
          'is-hovered': isHovered,
        })}
      >
        <Icon
          size="custom"
          content={ALL_REPORTS_ICON}
          className={el`all-reports-icon`}
        />
      </button>
    </Tooltip>
  );
}

export type ReportedAddressProps = StyleProps & {
  report: Address;
  showLabel?: boolean;
};

function ReportedAddress(props: ReportedAddressProps): ReactElement {
  const { address, domain, chain, label } = props.report;

  const isDomain = domain !== undefined && domain !== null;
  const hasChain = chain !== undefined && chain !== null;
  const isAddress = address !== undefined && address !== null;

  let sanitizedDomain = '';
  if (isDomain) {
    // sanitize any url that might be displayed to prevent malicious url formats
    // causing XSS risk
    sanitizedDomain = sanitizeUrl(domain);
  }

  return (
    <Fragment>
      {props.showLabel && (
        <Tablet className={el`label-section`}>
          <Text type="h5">Reported {domain ? 'Domain' : 'Address'}</Text>
        </Tablet>
      )}
      <div className={el`address-section`}>
        {isDomain && <Icon content={svgGlobe} className={el`domain-icon`} />}
        {hasChain && <ChainIcon size="small" chainType={chain} />}
        {label && <Badge variant="address">{label}</Badge>}
        {isDomain && (
          <Text type="body-md" className={el`domain`}>
            {sanitizedDomain}
          </Text>
        )}
        {isAddress && <ResponsiveAddress address={address} />}
        <div className={el`cta-buttons`}>
          {isDomain && (
            <AllReportsButton type="domain" reported={sanitizedDomain} />
          )}
          {isAddress && (
            <>
              <AllReportsButton
                type="address"
                reported={address}
                chain={chain ?? undefined}
              />
              <BlockExplorerLink address={{ chain, address }} />
            </>
          )}
        </div>
      </div>
    </Fragment>
  );
}

type SectionHeaderProps = {
  addresses: ReportedSectionProps['addresses'];
};

function SectionHeader(props: SectionHeaderProps): ReactElement {
  return (
    <div className={el`section-header-container`}>
      <Text type="h5">{getSectionHeaderCopy(props.addresses)}</Text>
    </div>
  );
}

export default function ReportedSection(
  props: ReportedSectionProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const hasTooManyReportedItems =
    p.variant !== 'expanded' && p.addresses.length > MAX_REPORTED_ITEMS;
  const displayedAddresses =
    p.variant === 'expanded'
      ? p.addresses
      : p.addresses.slice(0, MAX_REPORTED_ITEMS);

  if (p.addresses.length === 0) {
    return <></>;
  }

  return (
    <div className={clsx(ROOT, `variant-${p.variant}`, p.className)}>
      {p.variant === 'compact' && <SectionHeader addresses={p.addresses} />}
      {(p.variant === 'default' || p.variant === 'expanded') && (
        <Mobile>
          <SectionHeader addresses={p.addresses} />
        </Mobile>
      )}
      {p.variant === 'expanded' && (
        <Tablet className={el`section-header-container`}>
          <Text type="body-lg" isHeavy>
            {getSectionHeaderCopy(p.addresses)}
          </Text>
        </Tablet>
      )}
      {displayedAddresses.map((reported, i) => (
        <ReportedAddress
          key={i}
          report={reported}
          showLabel={shouldShowLabel(p.variant)}
        />
      ))}
      {hasTooManyReportedItems && (
        <Text className={el`too-many-addresses`}>
          + {p.addresses.length - MAX_REPORTED_ITEMS} more
        </Text>
      )}
    </div>
  );
}
