import { useState } from 'react';
import { ScamCategoryKind } from '@/generated/graphql';
import { QuizTree } from '../components/scam-quiz-modal/types';
import { QUIZ_TREE } from '../components/scam-quiz-modal/utils';
import { ScamQuizModalProps } from '../components/scam-quiz-modal/ScamQuizModal';

type ScamQuizState = {
  title: string;
  options: QuizTree[] | undefined;
  selectedOption: string | null;
  category: ScamCategoryKind | null;
  isBackDisabled: boolean;
  isNextDisabled: boolean;
  reset: () => void;
  handleBack: () => void;
  handleNext: () => void;
  onOptionPressed: (option: QuizTree) => void;
  handleModalOpenChange: (open: boolean) => void;
};

export const useScamQuiz = ({
  onOpenChange,
  onSelectCategory,
}: Pick<
  ScamQuizModalProps,
  'onOpenChange' | 'onSelectCategory'
>): ScamQuizState => {
  const [selectedOption, setSelectedOption] = useState<string | null>(null);
  const [category, setCategory] = useState<ScamCategoryKind | null>(null);
  const [currentStep, setCurrentStep] = useState<QuizTree | null>(QUIZ_TREE);
  const [previousSteps, setPreviousSteps] = useState<
    {
      selectedOption: string | null;
      step: QuizTree | null;
    }[]
  >([]);

  // get next step based on selected option and current position
  // in quiz tree
  function getNextStep(option: string, step: QuizTree) {
    const nextStep = step?.context?.options.find(
      (item) => item.option === option
    );

    if (nextStep) return nextStep;

    return step;
  }

  function onOptionPressed(option: QuizTree) {
    return setSelectedOption(option.option);
  }

  const title = currentStep?.context?.title || 'How did the scam occur?';
  const options = currentStep?.context?.options;
  const isBackDisabled = !previousSteps.length;
  const isNextDisabled =
    !category &&
    !currentStep?.context?.options.some(
      ({ option }) => option === selectedOption
    );

  const reset = () => {
    setCategory(null);
    setSelectedOption(null);
    setPreviousSteps([]);
    setCurrentStep(QUIZ_TREE);
  };

  const handleModalOpenChange = (open: boolean) => {
    if (open) return;

    onOpenChange?.(false);

    // delay as resetting state right after closing modal
    // causes modal to show initial state for a brief moment
    setTimeout(() => {
      reset();
    }, 100);
  };

  const handleBack = () => {
    const lastStep = previousSteps[previousSteps.length - 1];

    setSelectedOption(lastStep.selectedOption);
    setCurrentStep(lastStep.step);
    setPreviousSteps(previousSteps.slice(0, -1));
    setCategory(null);
  };

  const handleNext = () => {
    if (category) {
      // if we have category select category and close the scam quiz modal
      onSelectCategory(category);
      return handleModalOpenChange(false);
    }

    if (selectedOption && currentStep) {
      const nextTree = getNextStep(selectedOption, currentStep);

      // if tree has category that means we reached the end of the quiz tree
      if (nextTree.category) {
        setCategory(nextTree.category);
      }

      setPreviousSteps((previousTrees) => [
        ...previousTrees,
        {
          selectedOption: selectedOption,
          step: currentStep,
        },
      ]);

      return setCurrentStep(nextTree);
    }

    return setCurrentStep(QUIZ_TREE);
  };

  return {
    title,
    options,
    selectedOption,
    category,
    isBackDisabled,
    isNextDisabled,
    reset,
    handleBack,
    handleNext,
    onOptionPressed,
    handleModalOpenChange,
  };
};
