import clsx from 'clsx';
import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  ReactNode,
} from 'react';
import { Text, Tooltip } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type WrapWithLabelProps = StyleProps & {
  label: string;
  size?: 'medium' | 'small';
  id?: string;
  children: ReactNode;
  cta?: ReactElement;
  additionalText?: string;
  /** @default false */
  isRequired?: boolean;
  isOptional?: boolean;
};

const ROOT = makeRootClassName('FieldWithLabel');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'medium',
  isRequired: false,
} as const;

function WrapWithLabelComponent(
  props: WrapWithLabelProps,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const isLarge = p.size === 'medium';

  return (
    <div ref={ref} className={clsx(ROOT, p.className)} id={p.id}>
      <div className={el`label-wrapper`}>
        <Text
          type={isLarge ? 'h4' : 'body-md'}
          isHeavy={isLarge}
          className={el`label`}
        >
          {p.label}
          {p.isRequired && <span className={el`required-icon`}>*</span>}
          {p.additionalText && <span className={clsx(el`additional-text`)}>{p.additionalText}</span>}
          {p.isOptional && (
            <span className={el`optional-text`}> - Optional </span>
          )}
        </Text>
        {p.cta || null}
      </div>
      {p.children}
    </div>
  );
}

export const WrapWithLabel = forwardRef<HTMLDivElement, WrapWithLabelProps>(
  WrapWithLabelComponent
);

export default WrapWithLabel;
