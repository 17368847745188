import { useButton } from '@react-aria/button';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { AriaButtonProps } from '@react-types/button';
import clsx from 'clsx';
import prettyBytes from 'pretty-bytes';
import React, {
  DragEventHandler as ReactDragEventHandler,
  ReactElement,
  useRef,
  useState,
} from 'react';
import FadeLoader from 'react-spinners/FadeLoader';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Icon, Text } from '@/components';
import { createCross, createPaperclip } from '@/assets/icons';
import { FeatherIcon, FeatherIconName } from '../icon';

export type FileDropProps = StyleProps & {
  /**
   * The title of the drop area
   * @default 'Drop image here'
   */
  dropCTA?: string;

  /**
   * Whether or not the drop CTA is hidden
   * @default false
   */
  hideDropCTA?: boolean;

  /**
   * The title of the select area
   * @default 'Select file'
   */
  selectCTA?: string;

  /**
   * The file in the drop zone, if one has been dropped
   * or selected in.
   */
  file?: File;

  /**
   * Whether the drop zone should visually show the user
   * it is uploading.
   */
  isUploading?: boolean;

  /* Whether the component is disabled */
  isDisabled?: boolean;

  onDragOver?: ReactDragEventHandler<HTMLDivElement>;
  onDragEnter?: ReactDragEventHandler<HTMLDivElement>;
  onDragLeave?: ReactDragEventHandler<HTMLDivElement>;

  /** Handler called when the user drops a file onto the drop area */
  onDrop?: (files: FileList | null) => void;
  /** Handler to call when the user chooses a file via pressing the input */
  onSelectFile?: (files: FileList | null) => void;
  /**
   * Handler to call when the user clicks to remove the file from
   * the drop zone.
   */
  onRemoveFile?: () => void;
};

// config

const ROOT = makeRootClassName('FileDrop');
const el = makeElementClassNameFactory(ROOT);

const SELECT_FILE_ICON = createPaperclip;
const DISMISS_ICON = createCross;

const DEFAULT_PROPS = {
  dropCTA: 'Drop image here',
  selectCTA: 'Select file',
  hideDropCTA: false,
} as const;

const useDragProps = (
  props: FileDropProps
): Pick<
  FileDropProps,
  'onDragOver' | 'onDragEnter' | 'onDragLeave' | 'onDrop'
> => {
  const {
    dropCTA,
    hideDropCTA,
    selectCTA,
    file,
    isUploading,
    onSelectFile,
    onRemoveFile,
    isDisabled,
    ...dragProps
  } = props;
  return dragProps;
};

function DismissButton(p: AriaButtonProps<'button'>) {
  const domRef = useRef<HTMLButtonElement>(null);

  const { buttonProps, isPressed } = useButton(p, domRef);
  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(buttonProps, hoverProps, focusProps);

  return (
    <button
      {...behaviorProps}
      tabIndex={0}
      className={clsx(el`dismiss-button`, {
        'is-hovered': isHovered,
        'is-pressed': isPressed,
        'is-focus-visible': isFocusVisible,
      })}
    >
      <Icon
        className={el`dismiss-button-icon`}
        content={DISMISS_ICON}
        size="custom"
      />
    </button>
  );
}

const FileDrop = (props: FileDropProps): ReactElement => {
  const p = { ...DEFAULT_PROPS, ...props };
  const dragProps = useDragProps(p);
  const [inDropZone, setInDropZone] = useState(false);
  const inputRef = React.useRef<HTMLInputElement>(null);

  const openFileInput = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleChooseSelectFile = () => {
    if (!p.isUploading || !p.file) {
      openFileInput();
    }
  };

  const handleDragOver: ReactDragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    event.dataTransfer.dropEffect = 'copy';
    if (!inDropZone) setInDropZone(true);

    p.onDragOver?.(event);
  };

  const handleDragLeave: ReactDragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    setInDropZone(false);
    p.onDragLeave?.(event);
  };

  const handleDrop: ReactDragEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();
    event.stopPropagation();

    const files = event.dataTransfer?.files;
    p.onDrop?.(files);
  };

  const handleRemoveFile = () => {
    setInDropZone(false);
    p.onRemoveFile?.();
  };

  const handleSelectFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    p.onSelectFile?.(files);
  };

  const { hoverProps, isHovered } = useHover({ isDisabled: false });
  const { focusProps, isFocusVisible } = useFocusRing();
  const behaviorProps = mergeProps(hoverProps, focusProps);

  return (
    <div
      {...behaviorProps}
      {...dragProps}
      {...buttonize(handleChooseSelectFile)}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={clsx(ROOT, {
        'is-dragged-over': inDropZone,
        'is-hovered': isHovered,
        'is-uploading': p.isUploading,
        'has-file': p.file,
        'is-focus-visible': isFocusVisible,
      })}
    >
      {!p.isUploading && !p.file && (
        <div className={el`instructions`}>
          <input
            ref={inputRef}
            type="file"
            accept=".png, .jpg, .jpeg, .pdf"
            className="hidden"
            disabled={p.isDisabled}
            onChange={!p.isDisabled ? handleSelectFile : undefined}
          />
          {!p.hideDropCTA && (
            <Text type="body-md" className={el`drop-cta`}>
              {p.dropCTA}
            </Text>
          )}
          <div className={el`select-cta`}>
            <Icon content={SELECT_FILE_ICON} />
            <Text type="body-md">{p.selectCTA}</Text>
          </div>
        </div>
      )}
      {p.isUploading && (
        <div className={el`loading`}>
          <div className={el`loading-spinner`}>
            <FadeLoader height={18} width={5} color="#447EF8" loading />
          </div>
          <Text isHeavy>Uploading...</Text>
        </div>
      )}
      {!p.isUploading && p.file && (
        <div className={el`success`}>
          <div className={el`file-info`}>
            <FeatherIcon
              content={FeatherIconName.IMAGE}
              className={el`image-icon`}
            />
            <Text type="body-md" className={el`file-name`}>
              {p.file.name}
            </Text>
            {p.file.size > 0 && (
              <Text type="body-sm" className={el`file-size`}>{`${prettyBytes(
                p.file.size
              )}`}</Text>
            )}
          </div>
          <DismissButton onPress={handleRemoveFile} />
        </div>
      )}
    </div>
  );
};

export default FileDrop;
