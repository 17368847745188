import { useRouter } from 'next/router';
import { ReactElement } from 'react';
import { CHAIN_ABUSE_LINK } from '@/types/routes';
import Seo from '../seo/Seo';
import { getSeoPropsForPath } from './utils';

export function SeoWrapper(): ReactElement {
  const { route, asPath } = useRouter();
  const { title, description, isIndexingDisabled } = getSeoPropsForPath({
    route,
  });

  return (
    <Seo
      title={title}
      description={description}
      canonicalLink={`${CHAIN_ABUSE_LINK}${asPath}`}
      isIndexingDisabled={isIndexingDisabled}
    />
  );
}
export default SeoWrapper;
