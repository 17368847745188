import clsx from 'clsx';
import { ReactElement } from 'react';
import { ClientOnly, Switch, Text, TextField } from '@/components';
import FieldWithError from '@/features/formik/components/FieldWithError';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { AutocompleteBase } from '@/components/autocomplete';
import { City, Country, State } from '@/types/location';
import { WrapWithLabel } from '../wrap-with-label';

export type RequestSupportFormProps = StyleProps & {
  name?: string;
  isNameValid?: boolean;
  email?: string;
  isEmailValid?: boolean;
  onUpdateName: (name: string) => void;
  onUpdateEmail: (email: string) => void;
  isDisabled?: boolean;
  id?: string;
  countries: Country[];
  country?: Country | null;
  states: State[];
  state?: State | null;
  cities: City[];
  city?: City | null;
  zipCode?: string;
  countriesLoading: boolean;
  statesLoading: boolean;
  citiesLoading: boolean;
  onUpdateCountry: (country: Country) => void;
  onUpdateState: (state: State) => void;
  onUpdateCity: (city: City) => void;
  onUpdateZipCode: (zipCode: string) => void;
  onLoadCountries: () => void;
  setIsNameFieldTouched: (touched: boolean) => void;
  setIsEmailFieldTouched: (touched: boolean) => void;

  agreed?: boolean;
  onToggleSupportOptIn: () => void;

  agreedToBeContactedByLawEnforcement?: boolean;
  phone?: string;
  onUpdatePhone: (phone: string) => void;
};

const ROOT = makeRootClassName('RequestSupportForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type LabeledSwitchProps = {
  isDisabled?: boolean;
  checked?: boolean;
  onCheckedChange: (checked: boolean) => void;
  label: string;
  description?: string;
};

function LabeledSwitch(props: LabeledSwitchProps) {
  return (
    <div className={el`toggle-wrapper`}>
      <div>
        <Switch
          checked={props.checked}
          onCheckedChange={props.onCheckedChange}
          disabled={props.isDisabled}
          className={el`toggle`}
        />
      </div>
      <div>
        <Text type="body-lg">{props.label}</Text>
        {props.description && (
          <Text type="body-md" className={el`description`}>
            {props.description}
          </Text>
        )}
      </div>
    </div>
  );
}

function RequestSupportForm(props: RequestSupportFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <>
      <div className={clsx(ROOT, p.className)} id={p.id}>
        {/*
          @HACK: wrapping with ClientOnly because this gets messed up otherwise
        */}
        <ClientOnly>
          <Text type="h4">Contact & Support (optional)</Text>
          <Text type="body-md" className={el`description`}>
            By entering data below, you confirm that you are providing your own
            personal information or have the consent of the victim to do so. All
            information will remain strictly private.
          </Text>
          <div className={el`equal-field`}>
            <FieldWithError
              error={
                !p.isNameValid
                  ? 'Name must be at least 3 characters'
                  : undefined
              }
            >
              <TextField
                placeholder="Full Name"
                aria-label="Full Name"
                value={p.name}
                onChange={p.onUpdateName}
                onBlur={() => p.setIsNameFieldTouched(true)}
                className={clsx(el`field`, el`fullname-field`)}
                isDisabled={p.isDisabled}
                validationState={!p.isNameValid ? 'invalid' : undefined}
              />
            </FieldWithError>
          </div>

          <div className={el`input-fields`}>
            <div className={el`equal-field`}>
              <FieldWithError
                error={
                  !p.isEmailValid
                    ? 'Please provide an email address to receive support.'
                    : undefined
                }
              >
                <TextField
                  placeholder="Email"
                  aria-label="Email"
                  value={p.email}
                  onChange={p.onUpdateEmail}
                  onBlur={() => p.setIsEmailFieldTouched(true)}
                  className={clsx(el`field`, el`email-field`)}
                  isDisabled={p.isDisabled}
                  validationState={!p.isEmailValid ? 'invalid' : undefined}
                />
              </FieldWithError>
            </div>
            <div className={el`equal-field`}>
              <TextField
                placeholder="Phone number"
                aria-label="Phone number"
                value={p.phone}
                onChange={p.onUpdatePhone}
                className={clsx(el`field`, el`equal-field`)}
                isDisabled={p.isDisabled}
              />
            </div>
          </div>

          <div className={el`input-fields`}>
            <div className={el`equal-field`}>
              <FieldWithError
                error={
                  p.agreedToBeContactedByLawEnforcement && !p.country?.isoCode
                    ? 'Please select a country'
                    : undefined
                }
              >
                <AutocompleteBase
                  combobox={true}
                  isLoading={p.countriesLoading}
                  placeholder="Select your country"
                  options={p.countries}
                  onOpen={p.onLoadCountries}
                  value={p.country || ''}
                  isDisabled={p.isDisabled}
                  shouldUnselectOnClick
                  onChange={async (_, value) => {
                    if (
                      value &&
                      typeof value !== 'string' &&
                      !Array.isArray(value)
                    ) {
                      p.onUpdateCountry(value);
                    } else {
                      p.onUpdateCountry({ label: '', isoCode: '' });
                    }
                  }}
                  inputProps={{
                    name: 'Country',
                    'aria-label': 'Select your country',
                  }}
                  validationState={
                    p.agreedToBeContactedByLawEnforcement && !p.country?.isoCode
                      ? 'invalid'
                      : undefined
                  }
                />
              </FieldWithError>
            </div>
            <div className={el`equal-field`}>
              <FieldWithError
                error={
                  p.agreedToBeContactedByLawEnforcement && !p.state?.isoCode
                    ? 'Please select a state'
                    : undefined
                }
              >
                <AutocompleteBase
                  combobox={true}
                  isLoading={p.statesLoading}
                  placeholder="Select State/Province/Region"
                  options={p.states}
                  value={p.state || ''}
                  isDisabled={!p.states.length || p.isDisabled}
                  shouldUnselectOnClick
                  onChange={async (_, value) => {
                    if (
                      value &&
                      typeof value !== 'string' &&
                      !Array.isArray(value)
                    ) {
                      p.onUpdateState(value);
                    } else {
                      p.onUpdateState({ label: '', isoCode: '' });
                    }
                  }}
                  inputProps={{
                    name: 'state',
                    'aria-label': 'Select State/Province/Region',
                  }}
                  validationState={
                    p.agreedToBeContactedByLawEnforcement && !p.state?.isoCode
                      ? 'invalid'
                      : undefined
                  }
                />
              </FieldWithError>
            </div>
          </div>

          <div className={el`input-fields`}>
            <div className={el`equal-field`}>
              <FieldWithError
                error={
                  p.agreedToBeContactedByLawEnforcement && !p.city?.label
                    ? 'Please select a city'
                    : undefined
                }
              >
                <AutocompleteBase
                  isLoading={p.citiesLoading}
                  placeholder="Select City"
                  options={p.cities}
                  value={p.city || ''}
                  isDisabled={!p.state?.isoCode || p.isDisabled}
                  onInputChange={(_, value) =>
                    p.onUpdateCity({
                      label: value,
                    })
                  }
                  shouldUnselectOnClick
                  onChange={(_, value) => {
                    if (
                      value &&
                      typeof value !== 'string' &&
                      !Array.isArray(value)
                    ) {
                      p.onUpdateCity(value);
                    } else {
                      p.onUpdateCity({ label: '' });
                    }
                  }}
                  inputProps={{
                    name: 'City',
                    'aria-label': 'Select City',
                  }}
                  validationState={
                    p.agreedToBeContactedByLawEnforcement && !p.city?.label
                      ? 'invalid'
                      : undefined
                  }
                />
              </FieldWithError>
            </div>
            <div className={el`equal-field`}>
              <FieldWithError
                error={
                  p.agreedToBeContactedByLawEnforcement && !p.zipCode
                    ? 'Please provide a postal code'
                    : undefined
                }
              >
                <TextField
                  placeholder="Postal Code"
                  aria-label="Postal Code"
                  value={p.zipCode}
                  onChange={p.onUpdateZipCode}
                  className={el`field`}
                  isDisabled={p.isDisabled || !p.city}
                  validationState={
                    p.agreedToBeContactedByLawEnforcement && !p.zipCode
                      ? 'invalid'
                      : undefined
                  }
                />
              </FieldWithError>
            </div>
          </div>
        </ClientOnly>
      </div>

      <WrapWithLabel className={el`opt-in-wrapper`} label="Support request">
        {/*
          @HACK: wrapping with ClientOnly because this gets messed up otherwise
        */}
        <ClientOnly>
          <LabeledSwitch
            checked={p.agreed && p.agreedToBeContactedByLawEnforcement}
            onCheckedChange={p.onToggleSupportOptIn}
            isDisabled={p.isDisabled}
            label="I want to receive free and personalized support on my case and agree to share my contact details with Law Enforcement."
          />
          <Text type="body-sm" className="ml-[40px] mt-[-16px] text-gray-200">
            If you select this option, you consent that we share your contact
            information with law enforcement officials if they inquire about
            your report.
          </Text>
        </ClientOnly>
      </WrapWithLabel>
    </>
  );
}

export default RequestSupportForm;
