import ms from 'ms';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';
import { useAuthModal } from '@/features/auth';
import { useFileReportMutation } from '@/generated/graphql';
import { useMe } from '@/hooks';
import { removeQueryParam } from '@/utils/router';
import { GTMEvent, trackGTMEvent } from '@/hooks/use-google-tag-manager';
import { storeMe } from '@/utils/auth';
import { FILE_REPORT_ROUTE, getRouteForEditReport } from '@/types/routes';
import {
  clearReportFormLocalState,
  getReportFormLocalState,
} from '@/features/submit-report-page/components/report-form/utils';
import { getFileReportInputFromFormValues } from '@/features/submit-report-page/FileReportPage';
import { FormValuesFromReportFragment } from '@/features/submit-report-page/types';

type UseHandlePostAuthOptions = {
  // add config options here
};

type UseHandlePostAuthState = {
  // add any state the hook provides
};

type UseHandlePostAuthActions = {
  // add any actions the hook provides
};

export type UseHandlePostAuthValue = UseHandlePostAuthState &
  UseHandlePostAuthActions;

export const postAuthQueryParam = 'postAuth';

/**
 * When including this hook, if `postAuth=1` is set in the query params and
 * the user was created in the last minuted, will show the post auth modal.
 * Otherwise, does nothing. Additonally, removes the query param from the URL.
 */
const useHandlePostAuth = (
  props?: UseHandlePostAuthOptions
): UseHandlePostAuthValue => {
  const { me, loading: meLoading } = useMe();
  const [reportSubmitted, setIsReportSubmitted] = useState(false);
  const router = useRouter();
  const { isReady, query, push, pathname } = router;
  const {
    actions: { showPostAuthModal },
  } = useAuthModal();
  const [fileReportMutation, { loading: fileReportLoading }] =
    useFileReportMutation({});
  const isPostAuth =
    isReady && !!query.postAuth && query[postAuthQueryParam] !== '0';
  const loading = meLoading || fileReportLoading;
  const reportFormLocalState = getReportFormLocalState();
  const initialValues: FormValuesFromReportFragment | undefined | null =
    reportFormLocalState;

  const fileReport = useCallback(async () => {
    if (initialValues) {
      const reportData = getFileReportInputFromFormValues(initialValues, '');
      const response = await fileReportMutation({
        variables: {
          input: reportData,
        },
      });

      if (response) {
        const { data } = response;
        if (data?.fileReport.report) {
          trackGTMEvent(GTMEvent.SCAM_REPORT_FILED);
          push({
            pathname: getRouteForEditReport(data.fileReport.report.id),
            query: { submitted: true },
          });
          clearReportFormLocalState();
        } else {
          removeQueryParam(router, postAuthQueryParam);
        }
      }
    }
  }, [initialValues, fileReportMutation, push, router]);

  useEffect(() => {
    if (!loading && me && isPostAuth) {
      storeMe({ me, remember: true });
      // HACK: check for if the user was created in the last 60 seconds.
      // We do this because Auth0 does not appear to allow different
      // redirects depending on if the user is logging in or registering,
      // especially for social users (e.g. twitter), who don't go through
      // the post user registration flow.
      if (new Date().getTime() - new Date(me.createdAt).getTime() < ms('60s')) {
        trackGTMEvent(GTMEvent.USER_SIGN_UP);
        showPostAuthModal();
      }

      if (pathname === FILE_REPORT_ROUTE && initialValues && me.isVerified) {
        if (!reportSubmitted) {
          fileReport();
          setIsReportSubmitted(true);
        }
      } else {
        removeQueryParam(router, postAuthQueryParam);
      }
    }
  }, [
    fileReport,
    initialValues,
    isPostAuth,
    loading,
    me,
    pathname,
    reportSubmitted,
    router,
    showPostAuthModal,
  ]);

  return {};
};

export default useHandlePostAuth;
