/**
 * @param {Function} onFail - the function to run if the returned closure's
 * usage fails.
 * @returns {Function} a closure that accepts a function, `fn`, that when run,
 * if `fn` throws, will catch and run `onFail` and return its result instead.
 */
export function consistentCatch<Q>(
  onFail: (error: unknown) => Q
): <T extends Array<unknown>, U>(
  fn: (...args: T) => U,
  ...args: T
) => Promise<U | Q> {
  return async function (fn, ...args) {
    try {
      return await fn(...args);
    } catch (error) {
      return onFail(error);
    }
  };
}
