import { z } from 'zod';
import localStorage from 'local-storage-fallback';
import { ScamCategory } from '@/types/scam-categories';
import { ChannelType } from '@/types/contact-channel';
import { CompromiseIndicatorKind, ChainKind } from '@/generated/graphql';
import { getStorageItem, removeStorageItem } from '@/utils/storage';
import { SubmitReportFormValues } from '../../types';

export const REPORT_FORM_DATA_KEY = 'report_form_data';

export enum ReportFormSection {
  CASE_DETAILS = 'CASE_DETAILS',
  SCAMMER_INFORMATION = 'SCAMMER_INFORMATION',
  DESCRIPTION = 'DESCRIPTION',
  CONTACT_SUPPORT = 'CONTACT_SUPPORT',
}

export const getLabelForReportFormSection = (
  option: ReportFormSection
): string => {
  switch (option) {
    case ReportFormSection.CASE_DETAILS:
      return 'Case Details';
    case ReportFormSection.SCAMMER_INFORMATION:
      return 'Scammer Information';
    case ReportFormSection.DESCRIPTION:
      return 'Description';
    case ReportFormSection.CONTACT_SUPPORT:
      return 'Contact & Support (optional)';
    default:
      return '';
  }
};

export const ReportFormSchema = z.object({
  isPrivate: z.boolean().optional(),
  category: z.nativeEnum(ScamCategory),
  otherCategoryDescription: z.string().optional(),
  evidence: z.array(
    z.object({
      id: z.string().uuid().optional(),
      source: z.string(),
      uploadId: z.string(),
      uploadName: z.string().optional(),
      shouldUnsetExistingUpload: z.boolean().optional(),
    })
  ),
  knownScammers: z.array(
    z.object({
      id: z.string().uuid().optional(),
      info: z.array(
        z.object({
          id: z.string().uuid().optional(),
          type: z.nativeEnum(ChannelType),
          contact: z.string(),
        })
      ),
    })
  ),
  addresses: z
    .array(
      z.object({
        id: z.string().optional(),
        address: z.string().optional(),
        domain: z.string().optional(),
        chain: z.nativeEnum(ChainKind).optional(),
        label: z.string().optional(),
      })
    )
    .optional(),
  compromiseIndicators: z
    .array(
      z.object({
        id: z.string().optional(),
        type: z.nativeEnum(CompromiseIndicatorKind),
        value: z.string(),
      })
    )
    .optional(),
  tokens: z
    .array(
      z.object({
        id: z.string().optional(),
        tokenId: z.string(),
      })
    )
    .optional(),
  transactionHashes: z
    .array(
      z.object({
        id: z.string().optional(),
        hash: z.string(),
        chain: z.nativeEnum(ChainKind),
        label: z.string().optional(),
      })
    )
    .optional(),
  losses: z
    .array(
      z.object({
        id: z.string().optional(),
        key: z.string(),
        amount: z.string(),
        asset: z.string(),
      })
    )
    .optional(),
  agreedToBeContacted: z
    .object({
      agreed: z.boolean(),
      name: z.string().optional(),
      email: z.string().optional(),
    })
    .optional(),
  agreedToBeContactedByLawEnforcement: z
    .object({
      agreed: z.boolean(),
      name: z.string(),
      email: z.string(),
      phoneNumber: z.string().optional(),
      cityAndState: z.string().optional(),
      country: z.string().optional(),
    })
    .optional(),
  scamDescription: z.string().optional(),
});

export const validateReportFormLocalState = (data: string): null => {
  try {
    const parsedData = JSON.parse(data);
    return ReportFormSchema.safeParse(parsedData).success ? parsedData : null;
  } catch (error) {
    return null;
  }
};

export const getReportFormLocalState = (): null => {
  const prevFormData = getStorageItem(REPORT_FORM_DATA_KEY);
  return prevFormData ? validateReportFormLocalState(prevFormData) : null;
};

export const setReportFormLocalState = (
  reportFormValues: SubmitReportFormValues & {
    scamDescription?: string;
  }
): void => {
  localStorage.setItem(REPORT_FORM_DATA_KEY, JSON.stringify(reportFormValues));
};

export const clearReportFormLocalState = (): void =>
  removeStorageItem(REPORT_FORM_DATA_KEY);
