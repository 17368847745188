import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import { Address } from '@/types/address';
import {
  getBlockExplorerAddressDetailUrl,
  getBlockExplorerNameByAddress,
  hasBlockExplorerPartner,
} from '@/types/block-explorer';
import { ChainType, getTickerForChain } from '@/types/chain';
import {
  makeRootClassName,
  makeElementClassNameFactory,
  StyleProps,
} from '@/utils';
import { Tooltip } from '../tooltip';
import { solana, solanaHover, solanaFocus } from './svgs/solana';
import { avascan, avascanHover, avascanFocus } from './svgs/avascan';

export type BlockExplorerLinkProps = StyleProps & {
  address: Address;
};

const ROOT = makeRootClassName('BlockExplorerLink');
const el = makeElementClassNameFactory(ROOT);

function getBlockExplorerImage(
  chain: ChainType,
  isHovered: boolean,
  isPressed: boolean
): ReactElement {
  switch (chain) {
    case ChainType.SOL:
      return isPressed ? solanaFocus :
        isHovered ? solanaHover :
          solana;
    case ChainType.AVALANCHE:
      return isPressed ? avascanFocus :
        isHovered ? avascanHover :
          avascan;
    default:
      return <div></div>;
  }
}

function BlockExplorerLink(props: BlockExplorerLinkProps): ReactElement {
  const p = props;
  const { hoverProps, isHovered } = useHover({});
  const { pressProps, isPressed } = usePress({});
  const behaviorProps = mergeProps(hoverProps, pressProps);

  return (
    <>
      {p.address.chain && hasBlockExplorerPartner(p.address.chain) && (
        <Tooltip
          content={`See on ${getBlockExplorerNameByAddress(p.address)}`}
          variant="block"
          isInstant
        >
          <a
            {...behaviorProps}
            href={getBlockExplorerAddressDetailUrl(p.address)}
            target="_blank"
            rel="noreferrer"
            className='outline-none'
          >
            {getBlockExplorerImage(p.address.chain, isHovered, isPressed)}
          </a>
        </Tooltip>
      )}
    </>
  );
}

export default BlockExplorerLink;
