import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { Button, DragDropContext, Draggable, Droppable } from '@/components';
import { FeatherIconName } from '@/components/icon';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { DragIcon } from '../drag-icon';
import { CompromiseIndicatorFields } from './CompromiseIndicatorFields';
import type { CompromiseIndicatorField } from '../../types';

export type MultiCompromiseIndicatorFormProps = StyleProps & {
  isDisabled?: boolean;
  compromiseIndicatorFields: CompromiseIndicatorField[];
  onAddField: () => void;
  onRemoveField: (index: number) => void;
  onUpdateFieldValue: (index: number, value: string) => void;
  onUpdateFieldOrder: (fromIndex: number, toIndex: number) => void;
};

const ROOT = makeRootClassName('MultiCompromiseIndicatorForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  compromiseIndicatorFields: [],
} as const;

function MultiCompromiseIndicatorForm(
  props: MultiCompromiseIndicatorFormProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.compromiseIndicatorFields.length > 1;

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          p.onUpdateFieldOrder(source.index, destination.index);
        }
      }}
    >
      <Droppable
        droppableId="drop-compromise-indicator"
        isDropDisabled={p.isDisabled}
      >
        {(provided) => (
          <div
            className={clsx(ROOT, p.className, {
              'has-multiple': hasMultiple,
            })}
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            {p.compromiseIndicatorFields.map((field, index) => {
              return (
                <Draggable
                  key={field.key}
                  draggableId={field.key}
                  index={index}
                >
                  {(provided, dragSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={el`draggable-item`}
                      style={{
                        ...provided.draggableProps.style,
                        transform: dragSnapshot.isDragging
                          ? provided.draggableProps.style?.transform?.replace(
                              /\(.+,/,
                              '(0,'
                            )
                          : provided.draggableProps.style?.transform,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        {hasMultiple && <DragIcon />}
                      </div>
                      <CompromiseIndicatorFields
                        field={field}
                        isDisabled={p.isDisabled}
                        showRemoveFieldButton={index !== 0}
                        onRemoveField={() => props.onRemoveField(index)}
                        onUpdateFieldValue={(value) =>
                          props.onUpdateFieldValue(index, value)
                        }
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
            <div>
              <Button
                variant="text-white"
                startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
                onPress={p.onAddField}
                className={el`add-button`}
                size="custom"
                isDisabled={p.isDisabled}
              >
                Add IP address
              </Button>
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default MultiCompromiseIndicatorForm;
