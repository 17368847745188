export const SUPPORTED_BLOCKS = [
  'paragraph',
  'h2',
  'h3',
  'h4',
  'ul',
  'ol',
] as const;

export type SupportedBlock = typeof SUPPORTED_BLOCKS[number];

export const FORMATTING_OPTIONS = [
  'bold',
  'italic',
  'underline',
  'strikethrough',
] as const;

export type FormattingOption = typeof FORMATTING_OPTIONS[number];

export enum EditorCommandPriority {
  LastPriority,
  LowPriority,
  MediumPriority,
  HighPriority,
}
