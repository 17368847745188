import { AccusedScammerContactInfoKind as ChannelType } from '@/generated/graphql';
export { ChannelType };

export const getDisplayStringForContactChannel = (
  channel: ChannelType
): string => {
  switch (channel) {
    case ChannelType.NAME:
      return 'Name';
    case ChannelType.EMAIL:
      return 'Email';
    case ChannelType.PHONE:
      return 'Phone';
    case ChannelType.DISCORD:
      return 'Discord';
    case ChannelType.TELEGRAM:
      return 'Telegram';
    case ChannelType.TWITTER:
      return 'Twitter';
    case ChannelType.INSTAGRAM:
      return 'Instagram';
    case ChannelType.GITHUB:
      return 'Github';
    case ChannelType.YOUTUBE:
      return 'Youtube';
    case ChannelType.TIKTOK:
      return 'Tiktok';
    case ChannelType.WHATSAPP:
      return 'Whatsapp';
    case ChannelType.FACEBOOK:
      return 'Facebook';
    case ChannelType.OTHER:
      return 'Other';
    default:
      throw new Error(`Unrecognized channel type: ${channel}`);
  }
};
