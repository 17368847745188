import { ScamCategory } from '@/types/scam-categories';
import { QuizTree } from './types';

export const QUIZ_TREE: QuizTree = {
  option: null,
  context: {
    title: 'How did the scam occur?',
    options: [
      {
        option: 'I was blackmailed to send crypto',
        context: {
          title: 'How did the scammer blackmail you?',
          options: [
            {
              option:
                'Threatened me to release compromising sexual information',
              category: ScamCategory.SEXTORTION,
            },
            {
              option: 'Blocked my system and asked payment to release it',
              category: ScamCategory.RANSOMWARE,
            },
            {
              option: 'Threatened to harm me or my system',
              category: ScamCategory.OTHER_BLACKMAIL,
            },
            {
              option: 'Other',
              category: ScamCategory.OTHER_BLACKMAIL,
            },
          ],
        },
      },
      {
        option: 'I was tricked to transfer crypto',
        context: {
          title: 'How did the scammer trick you?',
          options: [
            {
              option: 'Scammer pretended to be someone else',
              category: ScamCategory.IMPERSONATION,
            },
            {
              option: 'Scammer promised fake returns',
              category: ScamCategory.FAKE_RETURNS,
            },
            {
              option:
                'Scammer gained my affection on social media or on a dating app',
              category: ScamCategory.ROMANCE,
            },
            {
              option: 'Scammer promised to build a project and run away',
              category: ScamCategory.FAKE_PROJECT,
            },
          ],
        },
      },
      {
        option: 'Someone made a payment  I never authorized',
        context: {
          title: 'How did the scammer access your wallet or online account?',
          options: [
            {
              option:
                'I may have  clicked on a malicious link or may have  provided log-in details',
              context: {
                title: 'What did the scammer do to trick you to do that?',
                options: [
                  {
                    option: 'Acted as a legitimate person or project',
                    category: ScamCategory.PHISHING,
                  },
                  {
                    option: 'Other',
                    category: ScamCategory.PHISHING,
                  },
                  {
                    option: 'Airdropped NFTS or tokens',
                    category: ScamCategory.AIRDROP,
                  },
                ],
              },
            },
            {
              option: 'I have  no idea how it happened',
              category: ScamCategory.OTHER_HACK,
            },
          ],
        },
      },
    ],
  },
};
