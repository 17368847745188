import { merge } from 'lodash';
import Head from 'next/head';
import { ReactElement } from 'react';

export const SEO_URL_PREFIX = 'https://chainabuse.com/images/opengraph';

type SeoProps = {
  /**
   * The title of the page.
   * @default 'Chainabuse'
   */
  title?: string;
  /**
   * The description of the page.
   * @default 'Let’s make web3 safer, together'
   */
  description?: string;
  /**
   * Source of image to override default
   * @default 'SEO_URL_PREFIX/general.png'
   */
  imageSrc?: string;

  /**
   * The url to set the canonical link of this page to.
   * By default, this link will be stripped of any query params
   * that follow a ?
   * e.g. test.com/address/0x0?chain=ethereum -> test.com/address/0x0
   * If undefined, no canonical link tag will be added to the page
   */
  canonicalLink?: string;

  isIndexingDisabled?: boolean;
};

const DEFAULT_PROPS = {
  title: 'Chainabuse',
  description: `Let’s make web3 safer, together`,
  imageSrc: `${SEO_URL_PREFIX}/general.png`,
  isIndexingDisabled: false,
} as const;

export default function Seo(props: SeoProps): ReactElement {
  const p = merge({}, DEFAULT_PROPS, props);

  return (
    <Head>
      {/* Primary meta tags */}
      <title>{p.title}</title>
      <meta name="title" content={p.title} key="title" />
      <meta name="description" content={p.description} key="description" />
      {p.canonicalLink && (
        <link rel="canonical" href={p.canonicalLink.split('?')[0]} /> // strip query params
      )}

      {/* OpenGraph meta tags */}
      <meta property="og:type" content="website" key="og:type" />
      <meta property="og:title" content={p.title} key="og:title" />
      <meta
        property="og:description"
        content={p.description}
        key="og:description"
      />
      <meta property="og:image" content={p.imageSrc} key="og:image" />

      {/* Twitter meta tags */}
      <meta
        property="twitter:card"
        content="summary_large_image"
        key="twitter:card"
      />
      <meta property="twitter:title" content={p.title} key="twitter:title" />
      <meta
        property="twitter:description"
        content={p.description}
        key="twitter:description"
      />
      <meta property="twitter:image" content={p.imageSrc} key="twitter:image" />
      {/** When explicitly set, disable seo index. */}
      {(process.env.NEXT_PUBLIC_DISABLE_SEO_INDEX || p.isIndexingDisabled) && (
        <meta name="robots" content="noindex" />
      )}
    </Head>
  );
}
