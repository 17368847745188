import { useCallback, useState } from 'react';
import {
  useAddVoteMutation,
  useRemoveVoteMutation,
  VotableEntityKind,
  VoteDirection,
} from '@/generated/graphql';
import useAuthRequiredAction from '@/hooks/auth/useAuthRequiredAction';

export type Vote = 'up' | 'down';

type UseVoteState = {
  /** Whether sending the vote to the server is in progress */
  isLoading?: boolean;
  /** The user's vote if it exists */
  vote?: 'up' | 'down';
};

type UseVoteActions = {
  /**
   * Handler to call when a user selects a vote direction
   * If logged in, should submit choice to server and refetch.
   * If not logged in and/or verified, should trigger AuthModal.
   */
  toggleVote: (vote: Vote) => void;
};

type UseVoteValue = UseVoteState & UseVoteActions;

type UseVoteOptions = {
  /** The id of the report */
  reportId: string;
  /** The current vote on the report */
  vote?: Vote;
};

const useVote = (options: UseVoteOptions): UseVoteValue => {
  const { openAuthModal, isLoggedIn } = useAuthRequiredAction();
  const [vote, setVote] = useState<'up' | 'down' | undefined>(options.vote);
  const [addVoteMutation, { loading: addVoteLoading }] = useAddVoteMutation();
  const [removeVoteMutation, { loading: removeVoteLoading }] =
    useRemoveVoteMutation();

  const toggleVote = useCallback(
    async (newVote: 'up' | 'down') => {
      if (!isLoggedIn) {
        openAuthModal();
        return;
      }

      // ignore if previous requests are still loading
      if (addVoteLoading || removeVoteLoading) {
        return;
      }

      const direction =
        newVote === 'up' ? VoteDirection.UP : VoteDirection.DOWN;
      if (newVote === vote) {
        // optimistic update
        setVote(undefined);
        const { data, errors } = await removeVoteMutation({
          variables: {
            input: {
              subjectId: options.reportId,
              subjectType: VotableEntityKind.REPORT,
            },
          },
        });

        if (errors) {
          // @TODO handle errors
          return;
        }

        if (data && data.removeVote.success) {
          setVote(
            data.removeVote.subject?.viewerDidVote
              ? data.removeVote.subject?.viewerDidVote === VoteDirection.UP
                ? 'up'
                : 'down'
              : undefined
          );
        }
      } else {
        // optimistic update
        setVote(newVote);
        const { data, errors } = await addVoteMutation({
          variables: {
            input: {
              subjectId: options.reportId,
              subjectType: VotableEntityKind.REPORT,
              direction,
            },
          },
        });

        if (errors) {
          // @TODO handle errors
          return;
        }

        if (data && data.addVote.success) {
          setVote(
            data.addVote.subject?.viewerDidVote
              ? data.addVote.subject?.viewerDidVote === VoteDirection.UP
                ? 'up'
                : 'down'
              : newVote
          );
        }
      }
    },
    [
      addVoteLoading,
      addVoteMutation,
      isLoggedIn,
      openAuthModal,
      options.reportId,
      removeVoteLoading,
      removeVoteMutation,
      vote,
    ]
  );

  return {
    vote,
    isLoading: addVoteLoading || removeVoteLoading,
    toggleVote,
  };
};

export default useVote;
