import React, { ReactElement, ReactNode, useState } from 'react';
import MyFeedContext from './context';

type MyFeedContextState = {
  searchQuery?: string;
};

type MyFeedContextActions = {
  setSearchQuery: (search: string) => void;
};

export type MyFeedContextValue = {
  state: MyFeedContextState;
  actions: MyFeedContextActions;
};

type MyFeedProviderProps = {
  children: ReactNode;
};

export default function MyFeedProvider({
  children,
}: MyFeedProviderProps): ReactElement {
  const [searchQuery, setSearchQuery] = useState<string>();

  const value = {
    state: {
      searchQuery,
    },
    actions: {
      setSearchQuery,
    },
  };

  return (
    <MyFeedContext.Provider value={value}>{children}</MyFeedContext.Provider>
  );
}
