import {
  ApolloClient,
  ApolloLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { createUploadLink } from 'apollo-upload-client';
import { useMemo } from 'react';
import { merge } from 'lodash';
import { API_ENDPOINT } from '@/utils/api';
import { getAuthHeaders, removeAuthenticatedDataFromCache } from '../auth';
import isServer from '../isServer';

const authLink = setContext(async (_, { headers }) => {
  return {
    headers: {
      ...headers,
      ...getAuthHeaders(),
    },
  };
});

export const onErrorLink = onError(({ graphQLErrors, networkError }) => {
  console.error({
    graphQLErrors,
    networkError,
  });

  // If any authentication errors are returned, we want to sign the user out
  if (graphQLErrors) {
    graphQLErrors.some(({ extensions, path }) => {
      // Only remove authenticated data when getting unauthenticated on
      // anything except for the me query, which is often requested to get
      // the state of the user, and is often Unauthenticated. If there is a
      // real query other than this, though we should clear the data from the
      // cache as it represents an attempt to update or fetch authorized, and
      // the user is no longer authorized.
      if (
        extensions?.code === 'UNAUTHENTICATED' &&
        path?.some((queryName) => queryName !== 'me')
      ) {
        removeAuthenticatedDataFromCache();
        return true;
      }
      return false;
    });
  }
});

export const retryLink = new RetryLink({
  delay: (count) => {
    return count * 500 * Math.random();
  },
  attempts: {
    max: 5,
    retryIf: (error) => {
      return Boolean(
        error?.networkError ||
          error?.message?.includes('Network request failed')
      );
    },
  },
});

/**
 * Setup ApolloClient to handle SSR
 * Taken from [here](https://www.apollographql.com/blog/apollo-client/next-js/building-a-next-js-app-with-slash-graphql/)
 */
let apolloClient: ApolloClient<NormalizedCacheObject> | undefined;
function createApolloClient(headers?: Record<string, unknown>) {
  const link = ApolloLink.from([
    authLink,
    onErrorLink,
    retryLink,
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore - this has the wrong type because of a version mismatch but
    // works, see: https://github.com/jaydenseric/apollo-upload-client/issues/221
    createUploadLink({
      // On SSR, request the endpoint directly, but on client, proxy to
      // grab additional data related to the session.
      uri: isServer() ? `${API_ENDPOINT}/api` : '/api/graphql-proxy',
      credentials: 'include',
    }),
  ]);

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          // // @TODO: specifying all fields means we don't cache when requesting
          // // relay-style pagination fields (first, after, before, last), which
          // // effectively equates to no caching
          // reports: relayStylePagination([
          //   'first',
          //   'after',
          //   'before',
          //   'last',
          //   'input',
          // ]),
        },
      },
      Chain: {
        keyFields: ['kind'],
      },
      ScamCategory: {
        keyFields: ['kind'],
      },
    },
  });

  return new ApolloClient({
    ssrMode: isServer(),
    connectToDevTools: !isServer(),
    link,
    cache,
  });
}

export function initializeApollo(
  initialState: NormalizedCacheObject | null = null,
  headers?: Record<string, unknown>
): ApolloClient<NormalizedCacheObject> {
  // For SSG and SSR always create a new Apollo Client
  const _apolloClient =
    !apolloClient || isServer() ? createApolloClient(headers) : apolloClient;

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();
    // Restore the cache using the data passed from getStaticProps/getServerSideProps
    // combined with the existing cached data

    _apolloClient.cache.restore(merge({}, existingCache, initialState));
  }
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;
  return _apolloClient;
}

export function useApollo(
  initialState: NormalizedCacheObject | null = null
): ApolloClient<NormalizedCacheObject> {
  const store = useMemo(() => initializeApollo(initialState), [initialState]);
  return store;
}
