import * as yup from 'yup';

export const usernameSchema = yup
  .string()
  .trim()
  .min(2, 'Too short')
  .max(15, 'Too long')
  .matches(
    /^[a-zA-Z0-9_]*$/,
    'Username can only contain letters, numbers, and underscores.'
  )
  .required('Username is required.');
