import { nanoid } from 'nanoid';
import { TransactionHashField, SubmitReportFormValues } from '../types';

export const getInitialTransactionHashFieldsFromFormValues = (
  props?: SubmitReportFormValues
): TransactionHashField[] | undefined => {
  if (!props) return undefined;

  const fields = props.transactionHashes?.map((transaction) => ({
    key: nanoid(),
    id: transaction.id ?? undefined,
    value: transaction.hash,
    chain: transaction.chain ?? undefined,
    label: transaction.label ?? undefined,
    isValid: true,
  }));

  return fields;
};
