import { debounce, DebouncedFunc } from 'lodash';
import { useEffect, useMemo } from 'react';

type UseDebounce<T> = {
  /**
   * Function to debounce
   */
  fn: T;

  /**
   * Delay in milliseconds
   */
  delay: number;

  /**
   * Whether to invoke the function on the leading edge of the timeout
   * @default false
   */
  leading?: boolean;
};

export default function useDebounce<T extends (...args: any[]) => unknown>({
  fn,
  delay,
  leading,
}: UseDebounce<T>): DebouncedFunc<T> {
  const debouncedFn = useMemo(() => debounce(fn, delay, { leading }), []);

  useEffect(() => {
    return () => {
      debouncedFn.cancel();
    };
  }, [debouncedFn]);

  return debouncedFn;
}
