import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  Button,
  DragDropContext,
  Draggable,
  Droppable,
  RemoveFieldButton,
  Text,
  TextField,
} from '@/components';
import { FeatherIconName } from '@/components/icon';
import { RemoveFieldButtonProps } from '@/components/remove-field-button/RemoveFieldButton';
import { ChannelType } from '@/types/contact-channel';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { ContactChannelField } from '../../types';
import { ContactChannelSelect } from '../contact-channel-select';
import { DragIcon } from '../drag-icon';
import type { UseKnownScammersValue } from '../../hooks/useKnownScammers';

const ROOT = makeRootClassName('MultiKnownScammerForm');
const el = makeElementClassNameFactory(ROOT);

type ContactChannelFieldProps = ContactChannelField &
  Pick<
    ScammerInformationProps,
    'onUpdateInfoType' | 'onUpdateInfoContact' | 'isDisabled'
  > & {
    index: number;
    showRemoveFieldButton: boolean;
    onRemoveField: RemoveFieldButtonProps['onPress'];
  };

function ContactChannelFieldComponent(
  props: ContactChannelFieldProps
): ReactElement {
  return (
    <div
      className={clsx(el`contact-channel-field`, {
        'has-remove-button': props.showRemoveFieldButton,
      })}
    >
      <ContactChannelSelect
        placeholder="Channel"
        isDisabled={props.isDisabled}
        value={props.type}
        onChange={(value) => props.onUpdateInfoType(props.index, value)}
        className={el`select`}
      />
      <TextField
        placeholder="Enter phone number or social media handle"
        value={props.contact}
        isDisabled={props.isDisabled}
        aria-label="Contact info"
        onChange={(value) => props.onUpdateInfoContact(props.index, value)}
        className={el`field`}
      />
      {props.showRemoveFieldButton && (
        <RemoveFieldButton
          onPress={props.onRemoveField}
          isDisabled={props.isDisabled}
          className={el`remove-field-button`}
        />
      )}
    </div>
  );
}

type ScammerInformationProps = {
  /** Whether the component is disabled */
  isDisabled?: boolean;

  /** The scammer's name */
  name: string;

  /** The scammer's contact details */
  contactChannelFields: ContactChannelField[];

  /** Handler to update the scammer's name */
  onUpdateName: (name: string) => void;

  /** Handler to call to add a new contact info field */
  onAddInfoField: () => void;

  /** Handler to call to remove a contact info field */
  onRemoveInfoField: (index: number) => void;

  /** Handler to call to update a contact info field */
  onUpdateInfoOrder: (fromIndex: number, toIndex: number) => void;

  /** Handler to call to update a contact info field's type */
  onUpdateInfoType: (index: number, channel?: ChannelType) => void;

  /** Handler to call to update contact info */
  onUpdateInfoContact: (index: number, contact: string) => void;

  /** Whether to show the remove scammer button */
  showRemoveScammerButton: boolean;

  /** Handler to call to remove the scammer */
  onRemoveScammer: () => void;
};

function ScammerInformation(props: ScammerInformationProps): ReactElement {
  const hasMultiple = props.contactChannelFields.length > 1;

  return (
    <div className={el`scammer-information`}>
      <div
        className={clsx(el`scammer-name-field`, {
          'has-remove-button': props.showRemoveScammerButton,
        })}
      >
        <TextField
          isDisabled={props.isDisabled}
          aria-label="Scammer name"
          placeholder="Known name on social media"
          className={el`scammer-name-input`}
          value={props.name}
          onChange={props.onUpdateName}
        />

        {props.showRemoveScammerButton && (
          <RemoveFieldButton
            isDisabled={props.isDisabled}
            onPress={props.onRemoveScammer}
            className={el`remove-field-button`}
          />
        )}
      </div>
      <div>
        <div className={el`contact-channel-header`}>
          <Text type="body-md">Known contact channels</Text>
          <Button
            variant="text-white"
            isDisabled={props.isDisabled}
            startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
            onPress={props.onAddInfoField}
            size="custom"
            className={el`add-contact-channel-button`}
          >
            Add Contact Channel
          </Button>
        </div>
        <DragDropContext
          onDragEnd={({ destination, source }) => {
            if (destination && hasMultiple) {
              props.onUpdateInfoOrder(source.index, destination.index);
            }
          }}
        >
          <Droppable
            droppableId="drop-scammer-contact-channels"
            isDropDisabled={props.isDisabled}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {props.contactChannelFields.map((field, index) => (
                  <Draggable
                    key={field.key}
                    draggableId={field.key}
                    index={index}
                  >
                    {(provided, dragSnapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={el`contact-channel`}
                        style={{
                          ...provided.draggableProps.style,
                          transform: dragSnapshot.isDragging
                            ? provided.draggableProps.style?.transform?.replace(
                                /\(.+,/,
                                '(0,'
                              )
                            : provided.draggableProps.style?.transform,
                        }}
                      >
                        <div {...provided.dragHandleProps}>
                          {hasMultiple && <DragIcon />}
                        </div>
                        <ContactChannelFieldComponent
                          {...field}
                          key={field.key}
                          isDisabled={props.isDisabled}
                          showRemoveFieldButton={index !== 0}
                          onRemoveField={() => props.onRemoveInfoField(index)}
                          onUpdateInfoType={props.onUpdateInfoType}
                          onUpdateInfoContact={props.onUpdateInfoContact}
                          index={index}
                        />
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
    </div>
  );
}

export type MultiKnownScammerFormProps = StyleProps & {
  /* Whether the component is disabled */
  isDisabled?: boolean;

  /** List of scammers held in state */
  knownScammerFields: UseKnownScammersValue['knownScammerFields'];

  /** Handler to call to add a new scammer */
  onAddScammer: UseKnownScammersValue['addScammer'];

  /** Handler to call to remove a scammer */
  onRemoveScammer: UseKnownScammersValue['removeScammer'];

  /** Handler to call to update a scammer's order */
  onUpdateScammerOrder: UseKnownScammersValue['updateScammerOrder'];

  /** Handler to call to update a scammer's name */
  onUpdateName: UseKnownScammersValue['updateName'];

  /** Handler to call to add a new info field for a scammer */
  onAddInfoField: UseKnownScammersValue['addInfoField'];

  /** Handler to call to remove a scammer's info field */
  onRemoveInfoField: UseKnownScammersValue['removeInfoField'];

  /** Handler to call to update a scammer's info order */
  onUpdateInfoOrder: UseKnownScammersValue['updateInfoOrder'];

  /** Handler to call to update a scammer's info type */
  onUpdateInfoContact: UseKnownScammersValue['updateInfoContact'];

  /** Handler to call to update a scammer's info contact */
  onUpdateInfoType: UseKnownScammersValue['updateInfoType'];
};

const DEFAULT_PROPS = {} as const;

function MultiKnownScammerForm(
  props: MultiKnownScammerFormProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const hasMultiple = p.knownScammerFields.length > 1;

  return (
    <div
      className={clsx(ROOT, p.className, {
        'has-multiple': hasMultiple,
      })}
    >
      <DragDropContext
        onDragEnd={({ destination, source }) => {
          if (destination && hasMultiple) {
            p.onUpdateScammerOrder(source.index, destination.index);
          }
        }}
      >
        <Droppable droppableId="drop-scammers" isDropDisabled={p.isDisabled}>
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {p.knownScammerFields.map((field, scammerIndex) => (
                <Draggable
                  key={field.key}
                  draggableId={field.key}
                  index={scammerIndex}
                >
                  {(provided, dragSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={el`scammer`}
                      style={{
                        ...provided.draggableProps.style,
                        transform: dragSnapshot.isDragging
                          ? provided.draggableProps.style?.transform?.replace(
                              /\(.+,/,
                              '(0,'
                            )
                          : provided.draggableProps.style?.transform,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        {hasMultiple && <DragIcon />}
                      </div>
                      <ScammerInformation
                        key={field.key}
                        name={field.knownScammerName}
                        isDisabled={props.isDisabled}
                        onUpdateName={(name) =>
                          p.onUpdateName(name, scammerIndex)
                        }
                        contactChannelFields={field.contactChannelFields}
                        onAddInfoField={() => p.onAddInfoField(scammerIndex)}
                        onRemoveInfoField={(index) =>
                          p.onRemoveInfoField({ index, scammerIndex })
                        }
                        onUpdateInfoOrder={(fromIndex, toIndex) =>
                          p.onUpdateInfoOrder({
                            fromIndex,
                            toIndex,
                            scammerIndex,
                          })
                        }
                        onUpdateInfoType={(index, channel) =>
                          p.onUpdateInfoType({ index, scammerIndex, channel })
                        }
                        onUpdateInfoContact={(index, contact) =>
                          p.onUpdateInfoContact({
                            index,
                            scammerIndex,
                            contact,
                          })
                        }
                        showRemoveScammerButton={scammerIndex !== 0}
                        onRemoveScammer={() => p.onRemoveScammer(scammerIndex)}
                      />
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <div>
        <Button
          variant="text-white"
          startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
          onPress={p.onAddScammer}
          className={el`add-button`}
          size="custom"
          isDisabled={props.isDisabled}
        >
          Add Scammer
        </Button>
      </div>
    </div>
  );
}

export default MultiKnownScammerForm;
