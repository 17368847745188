import { nanoid } from 'nanoid';
import { v4 as uuidv4 } from 'uuid';
import { UploadFileStatus } from '@/hooks/uploads/useUploads';
import { EvidenceField, SubmitReportFormValues } from '../types';

export const getInitialEvidenceUploadFieldsFromFormValues = (
  props?: SubmitReportFormValues
): EvidenceField[] | undefined => {
  if (!props) return undefined;

  const fields = props.evidence.map((evidence, idx) => ({
    key: nanoid(),
    id: evidence.id,
    source: evidence.source ?? '',
    upload: evidence.uploadId
      ? {
          uuid: evidence.uploadId ?? `${idx}-${uuidv4()}`,
          file: new File([], evidence.uploadName ?? `upload-${idx + 1}.jpg`),
          uploadId: evidence.uploadId,
          uploadName: evidence.uploadName,
          status: UploadFileStatus.SUCCESS,
        }
      : undefined,
  }));

  return fields.length > 0 ? fields : undefined;
};
