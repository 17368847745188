import { SerializedEditorState } from 'lexical';
import { FileUpload } from '@/hooks/uploads/useUploads';
import { Address } from '@/types/address';
import { ChainType } from '@/types/chain';
import { CompromiseIndicatorKind } from '@/types/compromise-indicators';
import { ChannelType } from '@/types/contact-channel';
import { ScamCategory } from '@/types/scam-categories';
import { ReportSourceKind } from '@/generated/graphql';

export type EvidenceField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the ReportEvidence in the db. Used to prefill.
   */
  id?: string;
  source?: string;
  upload?: FileUpload;
  /** Whether the user removed the existing db upload from this field */
  shouldUnsetExistingUpload?: boolean;
};

export type FilledEvidenceField = EvidenceField &
  ({ source: string } | { upload: FileUpload });

export type SuccessfulEvidenceUpload =
  | {
      id: string | undefined;
      source: string;
      uploadId: string;
      uploadName: string | undefined;
      // at least one of source or uploadId are required
      shouldUnsetExistingUpload: boolean | undefined;
    }
  | {
      id: string | undefined;
      source: string;
      uploadId: undefined;
      uploadName: string | undefined;
      shouldUnsetExistingUpload: boolean | undefined;
    }
  | {
      id: string | undefined;
      source: undefined;
      uploadId: string;
      uploadName: string | undefined;
      shouldUnsetExistingUpload: boolean | undefined;
    };

export type EvidenceFieldData = {
  source: string | undefined;
  uploadId: string | undefined;
};

export const isSuccessfulEvidenceUpload = (
  field: EvidenceFieldData
): boolean => {
  return (
    (field.source !== undefined && field.uploadId === undefined) ||
    (field.source === undefined && field.uploadId !== undefined) ||
    (field.source !== undefined && field.uploadId !== undefined)
  );
};

export type ContactChannelField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the AccusedScammerInfo in the db. Used to prefill.
   */
  id?: string;
  type?: ChannelType;
  contact?: string;
};

export type NameInfo = {
  /** The id of the name info in the db if it exists */
  id: string | undefined;
  type: ChannelType.NAME;
  contact: string;
};

export type KnownScammerField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the AccusedScammerInfo in the db. Used to prefill.
   */
  id?: string;
  knownScammerName: string;
  nameInfo: NameInfo;
  contactChannelFields: ContactChannelField[];
};

export type ValidKnownScammer = {
  id?: string;
  info: ValidContactChannelData[];
};

export type ValidContactChannelData = {
  id: string | undefined;
  type: ChannelType;
  contact: string;
};

export type SupportOptInInfo = {
  agreed: boolean;
  agreedToBeContactedByLawEnforcement: boolean;
  name?: string | null;
  email?: string | null;
  phoneNumber?: string | null;
  country?: string | null;
  countryCode?: string | null;
  state?: string | null;
  stateCode?: string | null;
  city?: string | null;
  zipCode?: string | null;
};

export type ContactDetailsForLawEnforcement = {
  agreed: boolean;
  name: string;
  email: string;
  phoneNumber?: string | null;
  cityAndState?: string | null;
  country?: string | null;
};

export type ValidTokenIdData = {
  /**
   * The id of the token in the db.
   * Used to prefill fields from backend.
   */
  id?: string;
  /** The value of the field. */
  tokenId: string;
};

export type ValidReportAddressData =
  | {
      id?: string;
      address: string;
      domain?: string;
      chain: ChainType;
      label?: string;
    }
  | {
      id?: string;
      address?: string;
      domain: string;
      chain?: ChainType;
      label?: string;
    };

export type ValidReportTransactionHashData = {
  id?: string;
  hash: string;
  chain: ChainType;
  label?: string;
};

export type AddressDomainField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the ReportAddress in the db. Used to prefill fields from backend.
   */
  id?: string;
  /**
   * The value of the field. Could be a crypto address or a domain
   */
  value: string;
  /**
   * If the value is a crypto address,
   * the chain the user chooses it to belong to
   */
  chain?: ChainType;
  /**
   * Whether the field is valid.
   * A field is valid if the value is either a crypto address or a web domain
   */
  isValid: boolean;
  /**
   * The address's label, if it exists
   */
  label?: string;
  /**
   ** Whether the address is a mistaken scammer address
   */
  isMistakenAddress?: boolean;
};

export type TransactionHashField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the ReportTransactionHash in the db. Used to prefill fields from backend.
   */
  id?: string;
  /**
   * The value of the field. Could be a crypto transaction hash
   */
  value: string;

  chain?: ChainType;
  /**
   * Whether the field is valid.
   * A field is valid if the value is the transaction hash
   */
  isValid: boolean;
  /**
   * The transaction hash's label, if it exists
   */
  label?: string;
};

export type CompromiseIndicatorField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the CompromiseIndicator in the db.
   * Used to prefill fields from backend.
   */
  id?: string;
  /** The value of the field. Description of the indicator of compromise */
  value: string;
  /** The type of the indicator of compromise */
  type?: CompromiseIndicatorKind;
};

export type ValidCompromiseIndicator = {
  id?: string;
  type: CompromiseIndicatorKind;
  value: string;
};

export type TokenIdField = {
  /**
   * A unique id representing the field for react renders.
   * Must be stable and unique.
   */
  key: string;
  /**
   * The id of the token in the db.
   * Used to prefill fields from backend.
   */
  id?: string;
  /** The value of the field. */
  value: string;
};

export const isValidReportAddressData = (
  data: Address
): data is ValidReportAddressData => {
  return !!((data.address && data.chain) || data.domain);
};

export type LossField = {
  id?: string;
  key: string;
  amount: string;
  asset: string;
};

export type SubmitReportFormValues = {
  isPrivate: boolean;
  category: ScamCategory;
  otherCategoryDescription?: string;
  evidence: SuccessfulEvidenceUpload[];
  knownScammers: ValidKnownScammer[];
  addresses?: ValidReportAddressData[];
  compromiseIndicators?: ValidCompromiseIndicator[];
  tokens?: ValidTokenIdData[];
  losses?: LossField[];
  lexicalSerializedDescription?: SerializedEditorState;
  agreedToBeContacted?: SupportOptInInfo | null;
  agreedToBeContactedByLawEnforcement?: ContactDetailsForLawEnforcement | null;
  source: ReportSourceKind;
  transactionHashes?: ValidReportTransactionHashData[];
};

export type FormValuesFromReportFragment = SubmitReportFormValues & {
  scamDescription: string;
};
