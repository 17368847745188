import { nanoid } from 'nanoid';
import { useEffect, useReducer } from 'react';
import { ChannelType } from '@/types/contact-channel';
import {
  KnownScammerField,
  NameInfo,
  ValidContactChannelData,
  ValidKnownScammer,
} from '../types';
import { reorder } from '../utils/reorder';

enum ReducerActionType {
  ADD_SCAMMER = 'ADD_SCAMMER',
  REMOVE_SCAMMER = 'REMOVE_SCAMMER',
  UPDATE_SCAMMER_ORDER = 'UPDATE_SCAMMER_ORDER',
  UPDATE_NAME = 'UPDATE_NAME',
  ADD_INFO = 'ADD_INFO',
  REMOVE_INFO = 'REMOVE_INFO',
  UPDATE_INFO_TYPE = 'UPDATE_INFO_TYPE',
  UPDATE_INFO_CONTACT = 'UPDATE_INFO_CONTACT',
  UPDATE_INFO_ORDER = 'UPDATE_INFO_ORDER',
  RESET_FIELDS = 'RESET_FIELDS',
}

type AddScammerAction = {
  type: ReducerActionType.ADD_SCAMMER;
};

type RemoveScammerAction = {
  type: ReducerActionType.REMOVE_SCAMMER;
  index: number;
};

type UpdateScammerOrderAction = {
  type: ReducerActionType.UPDATE_SCAMMER_ORDER;
  fromIndex: number;
  toIndex: number;
};

type UpdateNameAction = {
  type: ReducerActionType.UPDATE_NAME;
  name: string;
  index: number;
};

type AddInfoAction = {
  type: ReducerActionType.ADD_INFO;
  scammerIndex: number;
};

type RemoveInfoAction = {
  type: ReducerActionType.REMOVE_INFO;
  index: number;
  scammerIndex: number;
};

type UpdateInfoOrderAction = {
  type: ReducerActionType.UPDATE_INFO_ORDER;
  fromIndex: number;
  toIndex: number;
  scammerIndex: number;
};

type UpdateInfoTypeAction = {
  type: ReducerActionType.UPDATE_INFO_TYPE;
  channel?: ChannelType;
  index: number;
  scammerIndex: number;
};

type UpdateInfoContactAction = {
  type: ReducerActionType.UPDATE_INFO_CONTACT;
  index: number;
  contact: string;
  scammerIndex: number;
};

type ResetFieldsAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: KnownScammerField[];
};

type ReducerAction =
  | AddScammerAction
  | RemoveScammerAction
  | UpdateScammerOrderAction
  | UpdateNameAction
  | AddInfoAction
  | RemoveInfoAction
  | UpdateInfoOrderAction
  | UpdateInfoTypeAction
  | UpdateInfoContactAction
  | ResetFieldsAction;

export type UseKnownScammersOptions = {
  isLoading?: boolean;
  initialFields?: KnownScammerField[];
};

type UseKnownScammersState = {
  knownScammerFields: KnownScammerField[];
  sanitizedScammers: ValidKnownScammer[];
};

type UseKnownScammersActions = {
  addScammer: () => void;

  removeScammer: (index: number) => void;

  updateScammerOrder: (fromIndex: number, toIndex: number) => void;

  updateName: (name: string, scammerIndex: number) => void;

  addInfoField: (scammerIndex: number) => void;

  removeInfoField: (options: { index: number; scammerIndex: number }) => void;

  updateInfoOrder: (options: {
    fromIndex: number;
    toIndex: number;
    scammerIndex: number;
  }) => void;

  updateInfoType: (options: {
    index: number;
    scammerIndex: number;
    channel?: ChannelType;
  }) => void;

  updateInfoContact: (options: {
    index: number;
    scammerIndex: number;
    contact: string;
  }) => void;
};

export type UseKnownScammersValue = UseKnownScammersState &
  UseKnownScammersActions;

const useKnownScammers = (
  props?: UseKnownScammersOptions
): UseKnownScammersValue => {
  const [knownScammerFields, dispatch] = useReducer(
    (prev: KnownScammerField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_SCAMMER: {
          const nameInfo: NameInfo = {
            id: undefined,
            type: ChannelType.NAME,
            contact: '',
          };

          const newState = [
            ...prev,
            {
              key: nanoid(),
              knownScammerName: '',
              nameInfo,
              contactChannelFields: [
                { key: nanoid(), type: undefined, contact: undefined },
              ],
            },
          ];

          return newState;
        }
        case ReducerActionType.REMOVE_SCAMMER:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.UPDATE_SCAMMER_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.UPDATE_NAME:
          return prev.map((value, index) =>
            index === action.index
              ? {
                  ...value,
                  knownScammerName: action.name,
                  nameInfo: {
                    ...value.nameInfo,
                    contact: action.name,
                  },
                }
              : value
          );
        case ReducerActionType.ADD_INFO: {
          const newState = [...prev];

          newState[action.scammerIndex] = {
            ...prev[action.scammerIndex],
            contactChannelFields: [
              ...prev[action.scammerIndex].contactChannelFields,
              { key: nanoid(), type: undefined, contact: undefined },
            ],
          };

          return newState;
        }
        case ReducerActionType.REMOVE_INFO: {
          const newState = [...prev];

          newState[action.scammerIndex] = {
            ...prev[action.scammerIndex],
            contactChannelFields: prev[
              action.scammerIndex
            ].contactChannelFields.filter((_, index) => index !== action.index),
          };

          return newState;
        }
        case ReducerActionType.UPDATE_INFO_ORDER: {
          const newState = [...prev];

          newState[action.scammerIndex] = {
            ...prev[action.scammerIndex],
            contactChannelFields: reorder(
              prev[action.scammerIndex].contactChannelFields,
              action.fromIndex,
              action.toIndex
            ),
          };

          return newState;
        }
        case ReducerActionType.UPDATE_INFO_CONTACT: {
          const newState = [...prev];

          newState[action.scammerIndex] = {
            ...prev[action.scammerIndex],
            contactChannelFields: prev[
              action.scammerIndex
            ].contactChannelFields.map((field, index) =>
              index === action.index
                ? {
                    ...field,
                    contact: action.contact,
                  }
                : field
            ),
          };

          return newState;
        }
        case ReducerActionType.UPDATE_INFO_TYPE: {
          const newState = [...prev];

          newState[action.scammerIndex] = {
            ...prev[action.scammerIndex],
            contactChannelFields: prev[
              action.scammerIndex
            ].contactChannelFields.map((field, index) =>
              index === action.index
                ? {
                    ...field,
                    type: action.channel,
                  }
                : field
            ),
          };

          return newState;
        }
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      {
        key: nanoid(),
        knownScammerName: '',
        nameInfo: {
          id: undefined,
          contact: '',
          type: ChannelType.NAME,
        },
        contactChannelFields: [
          {
            key: nanoid(),
            contact: undefined,
            type: undefined,
          },
        ],
      },
    ]
  );

  const addScammer = () => dispatch({ type: ReducerActionType.ADD_SCAMMER });

  const removeScammer = (index: number) => {
    if (index === 0) {
      throw new Error('Cannot remove first scammer field');
    }

    dispatch({ type: ReducerActionType.REMOVE_SCAMMER, index });
  };

  const updateScammerOrder = (fromIndex: number, toIndex: number) =>
    dispatch({
      type: ReducerActionType.UPDATE_SCAMMER_ORDER,
      fromIndex,
      toIndex,
    });

  const updateName = (name: string, index: number) =>
    dispatch({
      type: ReducerActionType.UPDATE_NAME,
      name,
      index,
    });

  const addInfoField = (scammerIndex: number) =>
    dispatch({ type: ReducerActionType.ADD_INFO, scammerIndex });

  const removeInfoField = ({
    index,
    scammerIndex,
  }: {
    index: number;
    scammerIndex: number;
  }) => dispatch({ type: ReducerActionType.REMOVE_INFO, index, scammerIndex });

  const updateInfoOrder = ({
    fromIndex,
    toIndex,
    scammerIndex,
  }: {
    fromIndex: number;
    toIndex: number;
    scammerIndex: number;
  }) =>
    dispatch({
      type: ReducerActionType.UPDATE_INFO_ORDER,
      fromIndex,
      toIndex,
      scammerIndex,
    });

  const updateInfoType = ({
    index,
    scammerIndex,
    channel,
  }: {
    index: number;
    scammerIndex: number;
    channel?: ChannelType;
  }) =>
    dispatch({
      type: ReducerActionType.UPDATE_INFO_TYPE,
      index,
      scammerIndex,
      channel,
    });

  const updateInfoContact = ({
    index,
    scammerIndex,
    contact,
  }: {
    index: number;
    scammerIndex: number;
    contact: string;
  }) =>
    dispatch({
      type: ReducerActionType.UPDATE_INFO_CONTACT,
      index,
      scammerIndex,
      contact,
    });

  const sanitizedScammers: ValidKnownScammer[] = knownScammerFields
    .map(({ id, contactChannelFields, knownScammerName, nameInfo }) => {
      const sanitizedContactChannels = contactChannelFields
        .map(({ id, type, contact }) => ({ id, type, contact }))
        .filter(
          (field): field is ValidContactChannelData =>
            field.type !== undefined && field.contact !== undefined
        );

      if (knownScammerName || nameInfo.id) {
        sanitizedContactChannels.push(nameInfo);
      }

      return {
        id,
        info: sanitizedContactChannels,
      };
    })
    .filter((field) => field.info.length > 0);

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    addScammer,
    removeScammer,
    updateScammerOrder,
    updateName,
    addInfoField,
    removeInfoField,
    updateInfoOrder,
    updateInfoType,
    updateInfoContact,
    knownScammerFields,
    sanitizedScammers,
  };
};

export default useKnownScammers;
