import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { TextField, RemoveFieldButton } from '@/components';
import type { TokenIdField } from '../../types';

type TokenIDFieldProps = StyleProps & {
  /* Whether the component is disabled */
  isDisabled?: boolean;

  /* Whether to show the remove button */
  showRemoveFieldButton?: boolean;

  /* Callback for removing the field */
  onRemoveField: () => void;

  /* The value of the token ID item */
  field: TokenIdField;

  /* Callback for updating the field value */
  onUpdateFieldValue: (
    value: string
  ) => void;
};

const ROOT = makeRootClassName('MultiTokenIdForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export const TokenIdFields = (props: TokenIDFieldProps): ReactElement => {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={el`token-id-row`}>
      {p.showRemoveFieldButton && (
        <RemoveFieldButton
          isDisabled={p.isDisabled}
          onPress={() => p.onRemoveField?.()}
          className={el`remove-field-button`}
        />
      )}
      <TextField
        value={p.field.value}
        isDisabled={p.isDisabled}
        placeholder="Enter token ID"
        aria-label="Enter token ID"
        className={el`value`}
        onChange={(val) => props.onUpdateFieldValue(val)}
      />
    </div>
  );
};
