import { createContext } from 'react';
import type { ErrorData, GlobalErrorContextValue } from './provider';

const GlobalErrorContext = createContext<GlobalErrorContextValue>({
  state: {
    title: undefined,
    description: undefined,
    error: undefined,
    showContactUsLink: false,
    onTryAgain: undefined,
  },
  actions: {
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    sendError: (errorData: ErrorData) => {},
  },
});

export default GlobalErrorContext;
