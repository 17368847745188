import { ReportSourceKind } from '@/generated/graphql';
export { ReportSourceKind as ReportSource };

export const getDisplayStringForReportSource = (
  reportSource: ReportSourceKind
): string => {
  switch (reportSource) {
    case ReportSourceKind.CHAINABUSE:
      return 'Chainabuse';

    case ReportSourceKind.BITCOINABUSE:
      return 'Bitcoinabuse';

    case ReportSourceKind.PARTNER_RANSOMWHERE:
      return 'Ransomwhe.re';

    case ReportSourceKind.PARTNER_LEDGER:
      return 'Ledger';

    case ReportSourceKind.PARTNER_BLOKK:
      return 'BLOKK';

    case ReportSourceKind.PARTNER_DANY:
      return 'Dany';

    case ReportSourceKind.PARTNER_FBI:
      return 'FBI';

    case ReportSourceKind.PARTNER_SANJOSECA_GOV:
      return 'San Jose Gov';

    case ReportSourceKind.PARTNER_OPP:
      return 'OPP';

    case ReportSourceKind.PARTNER_MTV:
      return 'MTV';

    case ReportSourceKind.PARTNER_COINBASE:
      return 'Coinbase';

    case ReportSourceKind.PARTNER_RCMP:
      return 'RCMP';

    case ReportSourceKind.PARTNER_ACTION_FRAUD:
      return 'Action Fraud';

    case ReportSourceKind.PARTNER_NYPD:
      return 'NYPD';

    case ReportSourceKind.PARTNER_BITFLY:
      return 'Bitfly';

    case ReportSourceKind.PARTNER_SOLANAFM:
      return 'SolanaFM';

    case ReportSourceKind.PARTNER_METAMASK:
      return 'Metamask';

    case ReportSourceKind.PARTNER_SAFEPAL:
      return 'Safepal';

    default:
      throw new Error(`Unrecognized report source: ${reportSource}`);
  }
};
