import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text, Button, ButtonProps } from '@/components';

export type ScamQuizCtaProps = StyleProps & Pick<ButtonProps, 'onPress'>;

const ROOT = makeRootClassName('ScamQuizCta');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ScamQuizCta(props: ScamQuizCtaProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div>
        <Text type="h4">Not sure what to choose?</Text>
        <Text type="body-md" className={el`description`}>
          Take our Scam Quiz to help you find out.
        </Text>
      </div>
      <Button variant="secondary" className="cta-button" onPress={p.onPress}>
        TAKE QUIZ
      </Button>
    </div>
  );
}

export default ScamQuizCta;
