import { nanoid } from 'nanoid';
import { useReducer, useEffect } from 'react';
import { LossField } from '../types';
import { reorder } from '../utils/reorder';

export const DEFAULT_ASSET = 'USD';

type UseLossInputsOptions = {
  /** Initial fields to populate */
  initialFields?: LossField[];

  /** Wheter the data for the initial fields is loading */
  isLoading?: boolean;
};

type UseLossInputsState = {
  /** The fields */
  fields: LossField[];
  sanitizedLossFields: LossField[];
};

enum ReducerActionType {
  ADD_FIELD = 'ADD_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  UPDATE_AMOUNT = 'UPDATE_AMOUNT',
  UPDATE_ORDER = 'UPDATE_ORDER',
  RESET_FIELDS = 'RESET_FIELDS',
}

type AddFieldAction = {
  type: ReducerActionType.ADD_FIELD;
};

type RemoveFieldAction = {
  type: ReducerActionType.REMOVE_FIELD;
  index: number;
};

type UpdateAmountAction = {
  type: ReducerActionType.UPDATE_AMOUNT;
  index: number;
  value: string;
};

type UpdateOrderAction = {
  type: ReducerActionType.UPDATE_ORDER;
  fromIndex: number;
  toIndex: number;
};

type ResetOrderAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: LossField[];
};

type ReducerAction =
  | AddFieldAction
  | RemoveFieldAction
  | UpdateAmountAction
  | UpdateOrderAction
  | ResetOrderAction;

type UseLossInputsActions = {
  addField: () => void;
  removeField: (index: number) => void;
  updateAmount: (index: number, value: string) => void;
  updateOrder: (fromIndex: number, toIndex: number) => void;
};

export type UseLossInputsValue = UseLossInputsState & UseLossInputsActions;

const useLossInputs = (props?: UseLossInputsOptions): UseLossInputsValue => {
  const [fields, dispatch] = useReducer(
    (prev: LossField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_FIELD:
          return [...prev, { key: nanoid(), amount: '', asset: DEFAULT_ASSET }];
        case ReducerActionType.REMOVE_FIELD:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.UPDATE_AMOUNT:
          return prev.map((field, index) =>
            index === action.index ? { ...field, amount: action.value } : field
          );
        case ReducerActionType.UPDATE_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      {
        key: nanoid(),
        amount: '',
        asset: DEFAULT_ASSET,
      },
    ]
  );

  const actions: UseLossInputsActions = {
    addField: () => {
      dispatch({ type: ReducerActionType.ADD_FIELD });
    },
    removeField: (index: number) => {
      if (index === 0) {
        /**
         * allow reset first pair of report loss field if asset is of
         * kind any other than "USD"
         */
        if (fields[index].asset !== 'USD') {
          dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
          dispatch({ type: ReducerActionType.ADD_FIELD });
          return;
        }

        throw new Error('Cannot remove the first pair of report loss fields');
      }

      dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
    },
    updateAmount: (index: number, value: string) => {
      dispatch({ type: ReducerActionType.UPDATE_AMOUNT, index, value });
    },
    updateOrder: (fromIndex: number, toIndex: number) => {
      dispatch({
        type: ReducerActionType.UPDATE_ORDER,
        fromIndex,
        toIndex,
      });
    },
  };

  const sanitizedLossFields = fields.filter(
    (field) => field.amount.length > 0 && field.asset.length > 0
  );

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    fields,
    sanitizedLossFields,
    ...actions,
  };
};

export default useLossInputs;
