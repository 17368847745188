const CHAINABUSE_PRE_TITLE = 'Chainabuse - ';

export function getSeoPropsForPath({ route }: { route: string }): {
  title: string;
  description: string;
  isIndexingDisabled: boolean;
} {
  switch (route) {
    case '/about':
      return {
        title: `${CHAINABUSE_PRE_TITLE}About`,
        description:
          'Learn about our mission, team, and dedication to raising awareness about blockchain technology abuse and promoting responsible use.',
        isIndexingDisabled: false,
      };
    case '/contact':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Contact`,
        description:
          'Get in touch with our team to share insights, report abuse, or collaborate in our efforts to combat the misuse of blockchain technology.',
        isIndexingDisabled: false,
      };
    case '/partner-contact':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Partner contact`,
        description:
          'Get in touch with our team to become a partner, or collaborate in our efforts to combat the misuse of blockchain technology.',
        isIndexingDisabled: false,
      };
    case '/faq':
      return {
        title: `${CHAINABUSE_PRE_TITLE}FAQ`,
        description:
          'Find answers to frequently asked questions about blockchain technology abuse, prevention, reporting, and our platform.',
        isIndexingDisabled: false,
      };
    case '/glossary':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Glossary`,
        description:
          'Explore our glossary of blockchain-related terms to better understand the technology and its potential abuses.',
        isIndexingDisabled: false,
      };
    case '/':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Home`,
        description:
          'Chainabuse.com - Your go-to source for understanding and combating blockchain technology abuse. Stay informed with our latest news, articles, and resources.',
        isIndexingDisabled: false,
      };
    case '/leaderboard':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Leaderboard`,
        description:
          'Discover top contributors and organizations dedicated to combating blockchain technology abuse and promoting responsible practices.',
        isIndexingDisabled: false,
      };
    case '/org-by-slug/[orgSlug]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Organization by slug`,
        description:
          "Learn about the organization's efforts and initiatives in combating blockchain technology abuse and fostering responsible use.",
        isIndexingDisabled: false,
      };
    case '/org/[orgId]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Organization`,
        description:
          "Explore the organization's profile, mission, and actions to prevent and combat blockchain technology abuse.",
        isIndexingDisabled: false,
      };
    case '/orgfeed/[orgId]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Organization feed`,
        description:
          "Stay updated with the organization's latest news, events, and activities to combat blockchain technology abuse.",
        isIndexingDisabled: false,
      };
    case '/profile/[usernameSlug]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Profile`,
        description:
          'View the profile of a community member who is actively working to raise awareness and combat blockchain technology abuse.',
        isIndexingDisabled: true,
      };
    case '/report/edit/[reportId]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Edit report`,
        description:
          'Edit and update an existing report to provide more accurate and relevant information about a blockchain technology abuse case.',
        isIndexingDisabled: false,
      };
    case '/report':
      return {
        title: `${CHAINABUSE_PRE_TITLE}New report`,
        description:
          'Submit a new report about a blockchain technology abuse case to help us raise awareness and take action against the misuse.',
        isIndexingDisabled: false,
      };
    case '/reports':
      return {
        title: `${CHAINABUSE_PRE_TITLE}View Reports`,
        description:
          'Browse through our database of reported blockchain technology abuse cases and learn about the actions taken to address them.',
        isIndexingDisabled: false,
      };
    case '/support-partner-opt-in/[partnerSlug]':
      return {
        title: `${CHAINABUSE_PRE_TITLE}Support partner opt-in`,
        description:
          'Join forces with our partners in the fight against blockchain technology abuse by opting in to support their initiatives.',
        isIndexingDisabled: false,
      };
    default:
      return {
        title: `${CHAINABUSE_PRE_TITLE}Home`,
        description:
          'Chainabuse.com - Your go-to source for understanding and combating blockchain technology abuse. Stay informed with our latest news, articles, and resources.',
        isIndexingDisabled: false,
      };
  }
}
