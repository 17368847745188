import React, { ReactElement, ReactNode } from 'react';
import { Text } from '@/components';
import { makeElementClassNameFactory, makeRootClassName } from '@/utils';

type FieldWithErrorProps = {
  /** The main field element */
  children: ReactNode;
  /**
   * The error message.
   */
  error?: string;
};

const ROOT = makeRootClassName('FormikStyleHelpers');
const el = makeElementClassNameFactory(ROOT);

export const FieldWithError = (props: FieldWithErrorProps): ReactElement => (
  <div className={el`field-with-error`}>
    {props.children}
    {props.error && (
      <Text type="body-sm" className={el`error-helper`}>
        {props.error}
      </Text>
    )}
  </div>
);

export default FieldWithError;
