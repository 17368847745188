import { Popper } from '@material-ui/core';
import useAutocomplete, {
  UseAutocompleteProps,
} from '@mui/material/useAutocomplete';
import { useHover } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import React, {
  ForwardedRef,
  InputHTMLAttributes,
  LabelHTMLAttributes,
  ReactElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { useOptionalRef } from '@/hooks';
import {
  createCaretDown,
  createCaretUp,
  createCheck,
  createCircleCross,
} from '@/assets/icons';
import { Icon, Text } from '..';
import type { ValidationState } from '@react-types/shared';

export interface OptionData {
  /**
   * The primary option text.
   * Used to set the value of the input.
   */
  label: string;
}

export interface AutocompleteBaseProps<
  T extends OptionData,
  Multiple extends boolean | undefined
> extends Omit<
      UseAutocompleteProps<T, Multiple, false, boolean>,
      'getOptionLabel' | 'getOptionSelected' | 'groupBy'
    >,
    StyleProps {
  /**
   * The size of the autocomplete
   * @default 'medium'
   */
  size?: 'medium' | 'small';

  /**
   * Whether the autocomplete is disabled.
   * @default false
   */
  isDisabled?: boolean;

  /** The placeholder for the input protion of the autocomplete */
  placeholder?: string;

  /** Set the Autocomplete component to be a combobox */
  combobox?: boolean;

  /** The label for the autocomplete input */
  label?: string;

  /** The props for the label element. */
  labelProps?: Omit<LabelHTMLAttributes<HTMLLabelElement>, 'id'>;

  /** The props for the input element. */
  inputProps?: Omit<InputHTMLAttributes<HTMLInputElement>, 'id'>;

  /**
   *  Show loading symbol in option box for controlled async
   *  autocomplete service loading.
   * @default false
   */
  isLoading?: boolean;

  /**
   * Determines how the element should overflow when it
   * is filled with more selected items than its width can
   * display in one line. It either expands vertically or
   * truncates horizontally. Usually only matters for
   * when autocomplete is set to `multiple`.
   * @default 'resize'
   */
  overflow?: 'resize' | 'truncate';

  /**
   * Whether the input should show validation styles.
   */
  validationState?: ValidationState;

  /** Selecting the same option twice will unselect it */
  shouldUnselectOnClick?: boolean;
}

const ROOT = makeRootClassName('AutocompleteBase');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  size: 'medium',
  isLoading: false,
  isDisabled: false,
  overflow: 'resize',
} as const;

const SELECTED_ICON_PATH = createCheck;
const DISMISS_ICON_PATH = createCircleCross;

// helpers

function Chip({
  label,
  onDelete,
  ...props
}: {
  label: string;
  onDelete: (event: any) => void;
}): ReactElement {
  return (
    <div {...props} className={el`chip`}>
      <Text type="body-md" as="span">
        {label}
      </Text>
      <button tabIndex={-1} onClick={onDelete}>
        <Icon
          content={DISMISS_ICON_PATH}
          size="custom"
          className={el`dismiss-icon`}
        />
      </button>
    </div>
  );
}

// main

function AutocompleteBaseComponent<
  T extends OptionData,
  Multiple extends boolean | undefined
>(
  props: AutocompleteBaseProps<T, Multiple>,
  ref: ForwardedRef<HTMLDivElement>
): ReactElement {
  const p = {
    ...DEFAULT_PROPS,
    ...props,
  };
  const domRef = useOptionalRef(ref);
  const isComboBox = p.combobox && !p.freeSolo;
  const [currentValue, setCurrentValue] = useState('');

  // autocomplete behavior

  const getOptionLabel = (option: OptionData | string) => {
    // in case Free Solo is enabled the value for option will be a string,
    // so this checks if it's a string just return the option,
    // otherwise it'll be an object so return the label property
    if (typeof option === 'string') {
      return option;
    }
    return option.label;
  };

  const {
    getRootProps,
    getInputLabelProps,
    getInputProps,
    getTagProps,
    getListboxProps,
    getOptionProps,
    groupedOptions,
    value,
    focused,
    setAnchorEl,
    popupOpen,
    getPopupIndicatorProps,
  } = useAutocomplete({
    // based on MUI documentation for a better combobox-like experience
    // @see https://mui.com/material-ui/react-autocomplete/#creatable
    selectOnFocus: isComboBox,
    clearOnBlur: isComboBox,
    handleHomeEndKeys: isComboBox,
    openOnFocus: isComboBox,
    onClose: p.shouldUnselectOnClick
      ? (event, reason) => {
          const e = event.target as HTMLInputElement;
          const val = e.outerText;

          if (val === currentValue && reason === 'selectOption') {
            setCurrentValue('');
            const value: any = null;
            p.onChange?.(event, value, 'removeOption');
          } else {
            setCurrentValue(val);
          }
        }
      : undefined,
    ...p,
    getOptionLabel,
  });

  const isRequired = p.inputProps && p.inputProps.required;
  const { isHovered, hoverProps } = useHover({ isDisabled: p.isDisabled });
  const inputBehaviorProps = mergeProps(hoverProps, {
    placeholder: ' ', // this hack makes :placeholder-shown work
  });

  // Scroll to end of input wrapper in overflow: truncate
  // case so that user can still type in the input.

  const isTruncate = p.overflow === 'truncate';
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isTruncate && scrollRef.current) {
      scrollRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [isTruncate, value]);

  let startAdornment: ReactElement[] = [];

  // hack because TS not recognizing value ** must ** be an array if props.multiple
  if (p.multiple && Array.isArray(value)) {
    if (value && value.length > 0) {
      startAdornment = value.map((option, index): ReactElement => {
        const { onDelete, ...rest } = getTagProps({ index });
        return (
          <Chip
            // If we deleted key property typescript will get mad,
            // if we put it before spreading the props it will also get mad,
            // if we put it after spreading the props it'll overwrite it
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            key={index}
            {...rest}
            onDelete={onDelete}
            label={getOptionLabel(option)}
          />
        );
      });
    }
  }

  return (
    <div
      ref={domRef}
      className={clsx(
        ROOT,
        `size-${p.size}`,
        {
          'has-chips': startAdornment.length > 0,
          'is-required': isRequired,
          'is-hovered': isHovered,
          'is-disabled': p.isDisabled,
          'is-focused': focused,
          'is-truncate': isTruncate,
          'is-single': !p.multiple,
          'is-invalid': p.validationState === 'invalid',
          'is-valid': p.validationState === 'valid',
        },
        p.className
      )}
    >
      <div {...getRootProps()}>
        {p.label && (
          <label
            {...p.labelProps}
            {...getInputLabelProps()}
            className={el`label`}
          >
            {p.label}
            <div className={el`label-required-indicator`} />
          </label>
        )}
        <div
          ref={setAnchorEl}
          {...hoverProps}
          className={clsx(el`input-wrapper`, { focused })}
        >
          {startAdornment}
          <div className={el`textfield-wrapper`}>
            <input
              disabled={p.isDisabled}
              {...p.inputProps}
              {...inputBehaviorProps}
              {...getInputProps()}
              className={el`input`}
            />
            {isComboBox &&
              (popupOpen ? (
                <div {...getPopupIndicatorProps()}>
                  <Icon
                    className={el`caret-icon`}
                    content={createCaretUp}
                    size="medium"
                  />
                </div>
              ) : (
                <div {...getPopupIndicatorProps()}>
                  <Icon
                    className={el`caret-icon`}
                    content={createCaretDown}
                    size="medium"
                  />
                </div>
              ))}
            {isTruncate && <div ref={scrollRef} className={el`scroll`} />}
            {p.placeholder && (
              <div className={el`placeholder-wrapper`}>
                <span className={el`placeholder`}>{p.placeholder}</span>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* either there're options, or it's a combobox AND the list is open,
       or it's loading */}
      {groupedOptions.length > 0 || (isComboBox && popupOpen) || p.isLoading ? (
        <Popper
          placement="bottom"
          open
          keepMounted
          style={{ width: domRef.current?.offsetWidth + 'px' }}
          anchorEl={domRef.current}
        >
          <ul {...getListboxProps()} className={el`listbox`}>
            <>
              {p.isLoading && (
                <li className={el`option`}>
                  <Text type="body-md">Loading...</Text>
                </li>
              )}
              <>
                {groupedOptions.length > 0
                  ? (groupedOptions as typeof p.options).map(
                      (option, index) => (
                        <li
                          key={index}
                          {...getOptionProps({ option, index })}
                          className={el`option`}
                        >
                          <Text as="span">{getOptionLabel(option)}</Text>
                          <Icon
                            content={SELECTED_ICON_PATH}
                            size="medium"
                            className={el`selected-icon`}
                          />
                        </li>
                      )
                    )
                  : !p.isLoading && (
                      <li aria-disabled="true" className={el`option`}>
                        <Text as="span">No options</Text>
                      </li>
                    )}
              </>
            </>
          </ul>
        </Popper>
      ) : null}
    </div>
  );
}

/** Redecalare forwardRef
 * @see https://fettblog.eu/typescript-react-generic-forward-refs/#option-3%3A-augment-forwardref
 * */
declare module 'react' {
  // allow object as type to support widest possible prop surface
  // eslint-disable-next-line @typescript-eslint/ban-types
  function forwardRef<T, P = Record<string, unknown>>(
    render: (props: P, ref: React.Ref<T>) => React.ReactElement | null
  ): (props: P & React.RefAttributes<T>) => React.ReactElement | null;
}

export const AutocompleteBase = React.forwardRef(AutocompleteBaseComponent);
/**
 * A base normal text input enhanced by a panel of suggested options.
 * Useful to create ComboBoxes (the value must be selected from a predefined
 * set of options) or MultiSelects.
 *
 * Should be wrapped to create specific more components (i.e. LocationAutocomplete or
 * MakeSelect, etc.)
 */
export default AutocompleteBase;
