import clsx from 'clsx';
import React, { ReactElement } from 'react';
import ContentLoader from 'react-content-loader';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

const BACKGROUND_COLOR = '#202C38';
const FOREGROUND_COLOR = '#2F4354';
const RADIUS = 0.5;
const ITEM_WIDTH = 100;
const ITEM_HEIGHT = 20;

type CustomContentLoaderType = 'multiple' | 'single' | 'comments' | 'table' | 'badges';

export type CustomContentLoaderProps = StyleProps & {
  /**
   * Type of content loader to use.
   * multiple -> multiple rectangles
   * single -> one rectangle
   */
  type: CustomContentLoaderType;
};

const ROOT = makeRootClassName('CustomContentLoader');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

const renderLoader = (type: CustomContentLoaderType): ReactElement => {
  switch (type) {
    case 'multiple': {
      return (
        <>
          <rect rx={RADIUS} x="0" y="0" width="30" height="5" />
          <rect
            rx={RADIUS}
            x="0"
            y="10"
            width={ITEM_WIDTH}
            height={ITEM_HEIGHT}
          />
          <rect
            rx={RADIUS}
            x="0"
            y="35"
            width={ITEM_WIDTH}
            height={ITEM_HEIGHT}
          />
          <rect
            rx={RADIUS}
            x="0"
            y="60"
            width={ITEM_WIDTH}
            height={ITEM_HEIGHT}
          />
        </>
      );
    }
    case 'single':
      return <rect rx={RADIUS} x="0" y="0" width="100" height="100" />;
    case 'comments':
      return (
        <>
          <rect x="0" y="0" rx={RADIUS} ry={RADIUS} width="100" height="10" />
          <rect x="0" y="15" rx={RADIUS} ry={RADIUS} width="100" height="10" />
        </>
      );
    case 'table':
      return (
        <>
          <rect x="0" y="0" rx={RADIUS} ry={RADIUS} width="15" height="3" />
          <rect x="85" y="0" rx={RADIUS} ry={RADIUS} width="15" height="3" />
          <rect x="0" y="8" rx={RADIUS} ry={RADIUS} width="100" height="2" />
          <rect x="0" y="12" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="22" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="32" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="42" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="52" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="62" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="72" rx={RADIUS} ry={RADIUS} width="100" height="8" />
          <rect x="0" y="84" rx={RADIUS} ry={RADIUS} width="30" height="3" />
          <rect x="70" y="84" rx={RADIUS} ry={RADIUS} width="30" height="3" />
        </>
      );
    case 'badges':
      return (
        <>
          <rect x="40" y="0" rx={RADIUS} ry={RADIUS} width="20" height="2" />
          <rect x="35" y="3" rx={RADIUS} ry={RADIUS} width="30" height="2" />

          <rect x="15" y="10" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="30" y="10" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="45" y="10" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="60" y="10" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="75" y="10" rx={RADIUS} ry={RADIUS} width="10" height="3" />

          <rect x="15" y="15" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="30" y="15" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="45" y="15" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="60" y="15" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="75" y="15" rx={RADIUS} ry={RADIUS} width="10" height="3" />

          <rect x="15" y="20" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="30" y="20" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="45" y="20" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="60" y="20" rx={RADIUS} ry={RADIUS} width="10" height="3" />
          <rect x="75" y="20" rx={RADIUS} ry={RADIUS} width="10" height="3" />
        </>
      );
  }
};

const getViewBox = (type: CustomContentLoaderType): string => {
  if (type === 'comments') return '0 0 100 30';

  return '0 0 100 100';
};

function CustomContentLoader(props: CustomContentLoaderProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <ContentLoader
        backgroundColor={BACKGROUND_COLOR}
        foregroundColor={FOREGROUND_COLOR}
        speed={1}
        viewBox={getViewBox(p.type)}
      >
        {renderLoader(p.type)}
      </ContentLoader>
    </div>
  );
}

CustomContentLoader.BACKGROUND_COLOR = BACKGROUND_COLOR;
CustomContentLoader.FOREGROUND_COLOR = FOREGROUND_COLOR;
CustomContentLoader.RADIUS = RADIUS;
CustomContentLoader.ITEM_WIDTH = ITEM_WIDTH;
CustomContentLoader.ITEM_HEIGHT = ITEM_HEIGHT;

export default CustomContentLoader;
