import { useContext } from 'react';
import SettingsModalContext from './context';
import type { GlobalErrorContextValue } from './provider';

function useErrorModal(): GlobalErrorContextValue {
  const context = useContext(SettingsModalContext);
  if (!context) {
    throw new Error(
      "No GlobalErrorContext available. You need to use this hook in a component that's a downstream child of SettingsModalProvider"
    );
  }
  return context;
}

export default useErrorModal;
