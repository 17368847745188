import { uniqueId } from 'lodash';
import { useState } from 'react';
import {
  MeDocument,
  UploadableEntity,
  useUploadFileMutation,
} from '@/generated/graphql';

export enum UploadFileStatus {
  PENDING,
  SUCCESS,
  ERROR,
  UPLOADING,
}

export type FileUpload = {
  /** The id of the upload attempt */
  uuid: string;
  /** File to upload */
  file: File;
  /** The status of the upload */
  status: UploadFileStatus;
  /** The id of the upload on the server, if successful */
  uploadId?: string;
  /** The url of the upload, if successful */
  uploadUrl?: string;
};

type UseUploadsOptions = {
  /** The subject this upload will be associated with */
  subjectType: UploadableEntity;
};

type UseUploadsState = {
  uploads: FileUpload[];
  wasSuccessful: (uuid: FileUpload['uuid']) => boolean;
  isLoading: (uuid: FileUpload['uuid']) => boolean;
  hasError: (uuid: FileUpload['uuid']) => boolean;
};

type UseUploadsActions = {
  addFileToUpload: (file: File) => FileUpload;
  addFilesToUpload: (files: File[]) => FileUpload[];
};

type UseUploadsValue = UseUploadsState & UseUploadsActions;

const useUploads = (options: UseUploadsOptions): UseUploadsValue => {
  const [uploads, setUploads] = useState<FileUpload[]>([]);
  const [uploadFile, { loading }] = useUploadFileMutation({
    ...(options.subjectType === UploadableEntity.PROFILE_PHOTO_UPLOAD
      ? {
          refetchQueries: [MeDocument],
        }
      : {}),
  });

  const makeFileUploadForFile = (file: File): FileUpload => ({
    file,
    status: UploadFileStatus.PENDING,
    uuid: uniqueId(),
  });

  const updateUpload = (
    uuid: string,
    propsToUpdate: Pick<FileUpload, 'status' | 'uploadId' | 'uploadUrl'>
  ) => {
    setUploads((prevUploads) =>
      prevUploads.map((upload) => {
        if (upload.uuid === uuid) {
          return { ...upload, ...propsToUpdate };
        }
        return upload;
      })
    );
  };

  const startUpload = async (upload: FileUpload): Promise<void> => {
    const { file, uuid } = upload;
    updateUpload(uuid, { status: UploadFileStatus.UPLOADING });
    const { data, errors } = await uploadFile({
      variables: {
        file,
        input: {
          description: 'Evidence Upload',
          subjectType: options.subjectType,
        },
      },
    });
    if (errors && errors.length > 0) {
      updateUpload(uuid, { status: UploadFileStatus.ERROR });
    }
    if (!data) {
      return;
    }
    if (data.uploadFile.success) {
      updateUpload(uuid, {
        status: UploadFileStatus.SUCCESS,
        uploadId: data.uploadFile.upload?.id,
        uploadUrl: data.uploadFile.url,
      });
    }
  };

  const addFileToUpload = (file: File): FileUpload => {
    const newUpload = makeFileUploadForFile(file);
    setUploads((prevUploads) => [...prevUploads, newUpload]);
    startUpload(newUpload);
    return newUpload;
  };

  const wasSuccessful = (uuid: FileUpload['uuid']): boolean =>
    uploads.find((upload) => upload.uuid === uuid)?.status ===
    UploadFileStatus.SUCCESS;

  const hasError = (uuid: FileUpload['uuid']): boolean =>
    uploads.find((upload) => upload.uuid === uuid)?.status ===
    UploadFileStatus.ERROR;

  const isLoading = (uuid: FileUpload['uuid']): boolean => {
    const status = uploads.find((upload) => upload.uuid === uuid)?.status;
    return (
      status === UploadFileStatus.UPLOADING ||
      status === UploadFileStatus.PENDING
    );
  };

  const addFilesToUpload = (files: File[]): FileUpload[] => {
    const newUploads = files.map((file) => makeFileUploadForFile(file));
    setUploads((prevUploads) => [...prevUploads, ...newUploads]);
    newUploads.forEach((newUpload) => startUpload(newUpload));
    return newUploads;
  };

  return {
    uploads,
    addFileToUpload,
    wasSuccessful,
    hasError,
    isLoading,
    addFilesToUpload,
  };
};

export default useUploads;
