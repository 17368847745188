import { useHover } from '@react-aria/interactions';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { FeatherIcon, FeatherIconName } from '../icon';
import { Tooltip } from '../tooltip';

export type RemoveFieldButtonProps = StyleProps & {
  isDisabled?: boolean;
  /** Handler to call when the user presses remove */
  onPress: () => void;
};

const ROOT = makeRootClassName('RemoveFieldButton');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function RemoveFieldButton(props: RemoveFieldButtonProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });

  return (
    <Tooltip content={`Remove field`} isInstant>
      <button
        {...hoverProps}
        onClick={p.onPress}
        disabled={p.isDisabled}
        className={clsx(ROOT, props.className, {
          'is-hovered': isHovered,
          'is-disabled': p.isDisabled,
        })}
      >
        <FeatherIcon
          size="medium"
          content={FeatherIconName.TRASH}
          className={el`trash-icon`}
        />
      </button>
    </Tooltip>
  );
}

export default RemoveFieldButton;
