import { nanoid } from 'nanoid';
import { useReducer, useEffect } from 'react';
import { UploadableEntity } from '@/generated/graphql';
import useUploads, {
  FileUpload,
  UploadFileStatus,
} from '@/hooks/uploads/useUploads';
import {
  EvidenceField,
  isSuccessfulEvidenceUpload,
  SuccessfulEvidenceUpload,
} from '../types';
import { reorder } from '../utils/reorder';
import { FilledEvidenceField } from './../types';

type UseEvidenceUploadsOptions = {
  initialFields?: EvidenceField[];
  isLoading?: boolean;
};

enum ReducerActionType {
  ADD_FIELD = 'add-field',
  ADD_FIELDS = 'add-fields',
  REMOVE_FIELD = 'remove-field',
  ADD_FILE_TO_FIELD = 'add-file',
  REMOVE_FILE_FROM_FIELD = 'remove-file',
  UPDATE_SOURCE_OF_FIELD = 'update-source',
  UPDATE_FIELD_ORDER = 'update-field-order',
  RESET_FIELDS = 'RESET_FIELDS',
  ADD_FILES_TO_FIELDS = 'ADD_FILES_TO_FIELDS',
}

type AddFieldAction = {
  type: ReducerActionType.ADD_FIELD;
};

type RemoveFieldAction = {
  type: ReducerActionType.REMOVE_FIELD;
  index: number;
};

type AddFileAction = {
  type: ReducerActionType.ADD_FILE_TO_FIELD;
  index: number;
  upload: FileUpload;
};

type AddFilesAction = {
  type: ReducerActionType.ADD_FILES_TO_FIELDS;
  uploads: FileUpload[];
};

type RemoveFileAction = {
  type: ReducerActionType.REMOVE_FILE_FROM_FIELD;
  index: number;
};

type UpdateSourceAction = {
  type: ReducerActionType.UPDATE_SOURCE_OF_FIELD;
  index: number;
  source: string;
};

type UpdateFieldOrder = {
  type: ReducerActionType.UPDATE_FIELD_ORDER;
  fromIndex: number;
  toIndex: number;
};

type ResetFieldsAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: EvidenceField[];
};

type ReducerAction =
  | AddFieldAction
  | RemoveFieldAction
  | AddFileAction
  | RemoveFileAction
  | UpdateSourceAction
  | UpdateFieldOrder
  | ResetFieldsAction
  | AddFilesAction;

type EvidenceUploadsState = {
  fields: EvidenceField[];
  successfulEvidenceUploads: SuccessfulEvidenceUpload[];
};

type EvidenceUploadsActions = {
  addField: () => void;
  removeField: (index: number) => void;
  addFileToField: (index: number, file: File) => void;
  isFieldUploading: (index: number) => boolean;
  removeFileFromField: (index: number) => void;
  updateSourceOfField: (index: number, source: string) => void;
  updateFieldOrder: (fromIndex: number, toIndex: number) => void;
  addFilesToFields: (files: File[]) => void;
};

type UseEvidenceUploadsValue = EvidenceUploadsState & EvidenceUploadsActions;

const useEvidenceUploads = (
  props?: UseEvidenceUploadsOptions
): UseEvidenceUploadsValue => {
  const {
    uploads,
    addFileToUpload,
    addFilesToUpload,
    wasSuccessful,
    isLoading: isFileUploading,
  } = useUploads({ subjectType: UploadableEntity.REPORT_EVIDENCE_UPLOAD });

  const [fields, dispatch] = useReducer(
    (prev: EvidenceField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_FIELD:
          return [...prev, { key: nanoid(), source: '', upload: undefined }];
        case ReducerActionType.REMOVE_FIELD:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.ADD_FILE_TO_FIELD:
          return prev.map((field, index) =>
            index === action.index ? { ...field, upload: action.upload } : field
          );
        case ReducerActionType.REMOVE_FILE_FROM_FIELD:
          return prev.map((field, index) =>
            index === action.index
              ? {
                  ...field,
                  upload: undefined,
                  ...(field.id && { shouldUnsetExistingUpload: true }),
                }
              : field
          );
        case ReducerActionType.UPDATE_SOURCE_OF_FIELD:
          return prev.map((field, index) =>
            index === action.index ? { ...field, source: action.source } : field
          );
        case ReducerActionType.UPDATE_FIELD_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        case ReducerActionType.ADD_FILES_TO_FIELDS:
          return prev.map((field, index) => {
            return { ...field, uploads: action.uploads };
          });
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      { key: nanoid(), source: '', upload: undefined },
    ]
  );

  const addField = () => dispatch({ type: ReducerActionType.ADD_FIELD });

  const removeField = (index: number) => {
    dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
  };

  const addFileToField = (index: number, file: File) => {
    const upload = addFileToUpload(file);
    dispatch({
      type: ReducerActionType.ADD_FILE_TO_FIELD,
      index,
      upload,
    });
  };

  const addFilesToFields = (files: File[]) => {
    const uploads = addFilesToUpload(files);
    dispatch({
      type: ReducerActionType.ADD_FILES_TO_FIELDS,
      uploads,
    });
  };

  const removeFileFromField = (index: number) =>
    dispatch({ type: ReducerActionType.REMOVE_FILE_FROM_FIELD, index });

  const updateSourceOfField = (index: number, source: string) =>
    dispatch({ type: ReducerActionType.UPDATE_SOURCE_OF_FIELD, index, source });

  const updateFieldOrder = (fromIndex: number, toIndex: number) => {
    dispatch({
      type: ReducerActionType.UPDATE_FIELD_ORDER,
      fromIndex,
      toIndex,
    });
  };

  const successfulEvidenceUploads = fields
    // field needs to be filled out with either source or sucessful upload
    // or both
    .filter(
      (field): field is FilledEvidenceField =>
        (field.source !== undefined && field.source.length > 0) ||
        (field.upload !== undefined &&
          (field.upload.status === UploadFileStatus.SUCCESS ||
            wasSuccessful(field.upload.uuid)))
    )
    // get the field's source or succesful upload id
    .map(({ id, source, upload, shouldUnsetExistingUpload }) => {
      // this will exist only if the user tried to upload something for this field
      // during this session
      const latestUploadFromUploadQueueForField = uploads.find(
        (u) => u.uuid === upload?.uuid
      );

      let uploadId = latestUploadFromUploadQueueForField?.uploadId;
      if (id) {
        // was prefilled by an existing ReportUpload
        // @TODO figure out null?
        uploadId = uploadId ?? upload?.uploadId; // if not uploaded, was prefilled

        // if uploadId wasn't set, this means it was taken from localStorage/cookies
      } else if (!uploadId) {
        uploadId = upload?.uploadId;
      }

      return { id, source, uploadId, shouldUnsetExistingUpload };
    })
    .filter((fieldData): fieldData is SuccessfulEvidenceUpload =>
      isSuccessfulEvidenceUpload(fieldData)
    );

  const isFieldUploading = (index: number): boolean => {
    const field = fields[index];
    return !!field.upload?.uuid && isFileUploading(field.upload.uuid);
  };

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    fields,
    successfulEvidenceUploads,
    addField,
    removeField,
    addFileToField,
    isFieldUploading,
    removeFileFromField,
    updateSourceOfField,
    updateFieldOrder,
    addFilesToFields,
  };
};

export default useEvidenceUploads;
