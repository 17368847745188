import * as RadixDropdown from '@radix-ui/react-dropdown-menu';
import { useHover, usePress } from '@react-aria/interactions';
import { mergeProps } from '@react-aria/utils';
import clsx from 'clsx';
import { useRouter } from 'next/router';
import React, { ReactElement, useState } from 'react';
import {
  createChevronDown,
  createChevronRight,
  createExit,
  createGear,
} from '@/assets/icons';
import { Avatar, Icon, Text } from '@/components';
import { useLogout } from '@/features';
import { useMe } from '@/hooks';
import { getRouteForProfile } from '@/types/routes';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Desktop, Mobile, Tablet, UpToDesktop } from '@/utils/responsive';

export type ProfileDropdownProps = StyleProps & {
  /** Whether the component is disabled */
  isDisabled?: boolean;

  /** The profile picture src, if it exists */
  profileImgSrc?: string;

  /** The logged in user's username */
  username: string;

  /** Handler to call when user chooses to log out */
  onLogOut: () => void;
};

type MenuItemProps = {
  /** The name of the item */
  title: string;
  /** The path of the icon */
  icon: string;
  /** Handler for when the user chooses the item */
  onPress?: () => void;
};

const ROOT = makeRootClassName('ProfileDropdown');
const el = makeElementClassNameFactory(ROOT);

const DROPDOWN_ICON = createChevronDown;
const MOBILE_DROPDOWN_ICON = createChevronRight;
const LOG_OUT_ICON = createExit;
const PROFILE_SETTINGS_ICON = createGear;

const DEFAULT_PROPS = {} as const;

const MenuItem = (props: MenuItemProps): ReactElement => {
  return (
    <RadixDropdown.Item className={clsx(el`menu-item`)} onClick={props.onPress}>
      <Icon content={props.icon} className={el`menu-icon`} />
      <Text className={el`item-label`}>{props.title}</Text>
    </RadixDropdown.Item>
  );
};

const AccordionItem = (props: MenuItemProps): ReactElement => {
  return (
    <div className={clsx(el`menu-item`)} {...buttonize(props.onPress)}>
      <Icon content={props.icon} className={el`menu-icon`} />
      <Text className={el`item-label`}>{props.title}</Text>
    </div>
  );
};

export function ProfileDropdown(props: ProfileDropdownProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isOpen, setIsOpen] = useState(false);
  // @TODO fix this unreadable prop selection with fragment to replace the isMobile query
  // const isMobile = useMobileMediaQuery();
  const { hoverProps, isHovered } = useHover({ isDisabled: props.isDisabled });
  const { pressProps, isPressed } = usePress({
    isDisabled: props.isDisabled,
    onPress: () => setIsOpen(!isOpen),
  });
  const behaviorProps = mergeProps(hoverProps, pressProps);
  const router = useRouter();

  const getIconForBreakpoint = (breakpoint?: 'mobile') => {
    switch (breakpoint) {
      case 'mobile':
        return (
          <Icon content={MOBILE_DROPDOWN_ICON} className={el`dropdown-icon`} />
        );
      default:
        return <Icon content={DROPDOWN_ICON} className={el`dropdown-icon`} />;
    }
  };

  const profileButton = (
    <div
      {...behaviorProps}
      className={clsx([
        el`button`,
        {
          'is-hovered': isHovered,
          'is-pressed': isPressed,
        },
        p.className,
      ])}
    >
      <Avatar image={p.profileImgSrc ?? '/images/avatar.svg'} size="large" />
      <Text type="body-lg">{p.username}</Text>
      <Mobile>
        {!isOpen ? getIconForBreakpoint('mobile') : getIconForBreakpoint()}
      </Mobile>
      <Tablet>{getIconForBreakpoint()}</Tablet>
    </div>
  );

  return (
    <div
      className={clsx(ROOT, p.className, {
        'is-open': isOpen,
      })}
    >
      <UpToDesktop>
        {profileButton}
        {isOpen && (
          <div className={el`accordion-menu`}>
            <AccordionItem
              title="View profile"
              icon={PROFILE_SETTINGS_ICON}
              onPress={() => {
                router.push(getRouteForProfile(p.username));
              }}
            />
            <AccordionItem
              title="Log out"
              icon={LOG_OUT_ICON}
              onPress={p.onLogOut}
            />
          </div>
        )}
      </UpToDesktop>
      <Desktop>
        <RadixDropdown.Root
          open={isOpen}
          onOpenChange={(open: boolean) => setIsOpen(open)}
        >
          <div className={el`dropdown`}>
            <RadixDropdown.Trigger asChild>
              {profileButton}
            </RadixDropdown.Trigger>
            <RadixDropdown.Content align="end" className={el`menu`}>
              <MenuItem
                title="View profile"
                icon={PROFILE_SETTINGS_ICON}
                onPress={() => router.push(getRouteForProfile(p.username))}
              />
              <MenuItem
                title="Log out"
                icon={LOG_OUT_ICON}
                onPress={p.onLogOut}
              />
            </RadixDropdown.Content>
          </div>
        </RadixDropdown.Root>
      </Desktop>
    </div>
  );
}

function ProfileDropdownContainer(props: StyleProps): ReactElement {
  const { logout } = useLogout();
  const { me, loading } = useMe();

  return (
    <ProfileDropdown
      {...props}
      isDisabled={loading}
      username={me?.username ?? ''}
      profileImgSrc={me?.photo?.url ?? '/images/avatar.svg'}
      onLogOut={logout}
    />
  );
}

export default ProfileDropdownContainer;
