import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { FeatherIcon, FeatherIconName } from '@/components/icon';
import { Modal, Text } from '@/components';

export type TransactionHashInfoModalProps = StyleProps;

const ROOT = makeRootClassName('AddressHelpModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function TransactionHashInfoModal(props: TransactionHashInfoModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div {...buttonize(toggleOpen)}>
        <FeatherIcon
          content={FeatherIconName.HELP_CIRCLE}
          tooltipVariant="block"
          tooltipContent="What is this?"
        />
      </div>
      <Modal.Root open={open} onOpenChange={setOpen} className={el`modal`}>
        <Modal.Content className={el`modal-content`}>
          <Modal.Header title="Scammer’s blockchain address(es)" hasCloseButton />
          <div className={el`content`}>
            <Text type="body-md">
              Transactions IDs or hashes are crucial to getting your funds back.
              They help Law Enforcement prove that your funds were sent to a scammer’s address so they can freeze them on your behalf. 
            </Text>
            <div style={{wordBreak: 'break-word'}}>
              <Text type="body-md">
                Transaction IDs have the same format as the crypto addresses but are longer.
                For example 242bf4f432b129cc07f428313d71c2f5f55489bde674e561c07e3ba27e23b863
              </Text>
            </div>
            <Text type="body-md" className={el`title`}>
              <strong>Where to find it? </strong>
            </Text>
            <Text type="body-md">
              You can usually find your Transaction ID in the history of transactions or withdrawals in the wallet/exchange used during the scam.
              Look for the scam transaction and simply copy/paste the ID.
              Contact the support team at the cryptocurrency company you used for help.
            </Text>
          </div>
          <Modal.Footer className={el`modal-footer`}>
            <Modal.FooterButton isCloseButton>Close</Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

export default TransactionHashInfoModal;
