import React, { ReactElement, ReactNode, useState } from 'react';
import { setIsAcceptedPrivacyPolicyCookie } from '@/utils/privacyPolicy';
import CookieConsentContext from './context';
import { getIsAcceptedCookieValue, setIsAcceptedCookie } from './utils';

type CookieConsentContextState = {
  isAccepted?: boolean;
};

type CookieConsentContextActions = {
  setIsAccepted: (isAccepted: boolean) => void;
};

export type CookieConsentContextValue = {
  state: CookieConsentContextState;
  actions: CookieConsentContextActions;
};

type CookieConsentProviderProps = {
  children: ReactNode;
};

export default function CookieConsentProvider({
  children,
}: CookieConsentProviderProps): ReactElement {
  const [isAccepted, setIsAccepted] = useState<boolean>(
    getIsAcceptedCookieValue()
  );

  const value = {
    state: {
      isAccepted,
    },
    actions: {
      setIsAccepted: (isAccepted: boolean) => {
        setIsAcceptedCookie(isAccepted);
        setIsAcceptedPrivacyPolicyCookie(isAccepted);
        setIsAccepted(isAccepted);
      },
    },
  };

  return (
    <CookieConsentContext.Provider value={value}>
      {children}
    </CookieConsentContext.Provider>
  );
}
