import { MutationResult } from '@apollo/client';
import {
  MeDocument,
  UpdateUserProfileInput,
  UpdateUserProfileMutation,
  useUpdateUserProfileMutation,
} from '../../../generated/graphql';

type UseUpdateProfile = [
  /**
   * Returns success state
   */
  (input: UpdateUserProfileInput) => Promise<boolean>,
  MutationResult<UpdateUserProfileMutation>
];

export const useUpdateProfile = (): UseUpdateProfile => {
  const [UpdateUserProfileMutation, mutationData] =
    useUpdateUserProfileMutation();

  const updateProfile = async ({
    username,
    agreedToBeContacted,
    socialInfos,
    email,
  }: UpdateUserProfileInput) => {
    try {
      const { data, errors } = await UpdateUserProfileMutation({
        variables: {
          input: { username, agreedToBeContacted, socialInfos, email },
        },
        refetchQueries: [{ query: MeDocument }],
      });

      if (errors) {
        throw errors;
      }

      return data?.updateUserProfile.success ?? false;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return [updateProfile, mutationData];
};
