import React, { ReactElement } from 'react';
import NextNProgress from 'nextjs-progressbar';

const COLOR = '#6999FC'; // trm-blue-3

function RouteProgressBar(): ReactElement {
  return (
    <NextNProgress
      color={COLOR}
      height={2}
      // this will hide the progress bar while page is loading
      showOnShallow={false}
      options={{ showSpinner: false }}
    />
  );
}

export default RouteProgressBar;
