import { ReactElement } from 'react';
import {
  ArrowDown,
  ArrowUp,
  Camera,
  Check,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Copy,
  Download,
  Edit,
  ExternalLink,
  Flag,
  Gift,
  HelpCircle,
  Image,
  Info,
  Key,
  LogOut,
  MessageCircle,
  Minus,
  PlusCircle,
  Search,
  ThumbsUp,
  Trash2,
  User,
  X,
} from 'react-feather';
import { StyleProps } from '@/utils';
import { OptionalTooltip } from '../tooltip/Tooltip';
import { IconProps } from '.';

export enum FeatherIconName {
  ARROW_UP = 'arrow-up',
  ARROW_DOWN = 'arrow-down',
  CAMERA = 'camera',
  CIRCLE_PLUS = 'circle-plus',
  CHEVRON_DOWN = 'chevron-down',
  CHEVRON_LEFT = 'chevron-left',
  CHEVRON_RIGHT = 'chevron-right',
  CHECK = 'check',
  CROSS = 'cross',
  IMAGE = 'image',
  EXTERNAL_LINK = 'external-link',
  MESSAGE_CIRCLE = 'message-circle',
  SEARCH = 'search',
  USER = 'user',
  TRASH = 'trash',
  EDIT = 'edit',
  MINUS = 'minus',
  COPY = 'copy',
  DOWNLOAD = 'download',
  FLAG = 'flag',
  INFO = 'info',
  KEY = 'key',
  LOG_OUT = 'log-out',
  GIFT = 'gift',
  THUMB_UP = 'thumb-up',
  HELP_CIRCLE = 'help-circle',
}

export type FeatherIconProps = StyleProps &
  Omit<IconProps, 'content'> & {
    content: FeatherIconName;
  };

const DEFAULT_PROPS = {
  size: 'medium',
  tooltipSide: 'top',
} as const;

const MEDIUM_SIZE = 20;
const SMALL_SIZE = 16;

function FeatherIcon(props: FeatherIconProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const getSizeProp = (): number | undefined => {
    switch (p.size) {
      case 'medium':
        return MEDIUM_SIZE;
      case 'small':
        return SMALL_SIZE;
      default:
        return undefined;
    }
  };

  const iconProps = {
    className: p.className,
    ...{ size: getSizeProp() },
  };

  const renderIconForContent = (): ReactElement => {
    switch (p.content) {
      case FeatherIconName.CAMERA:
        return <Camera {...iconProps} />;
      case FeatherIconName.CHEVRON_DOWN:
        return <ChevronDown {...iconProps} />;
      case FeatherIconName.CHEVRON_LEFT:
        return <ChevronLeft {...iconProps} />;
      case FeatherIconName.CHEVRON_RIGHT:
        return <ChevronRight {...iconProps} />;
      case FeatherIconName.CHECK:
        return <Check {...iconProps} />;
      case FeatherIconName.CIRCLE_PLUS:
        return <PlusCircle {...iconProps} />;
      case FeatherIconName.CROSS:
        return <X {...iconProps} />;
      case FeatherIconName.IMAGE:
        return <Image {...iconProps} />;
      case FeatherIconName.EXTERNAL_LINK:
        return <ExternalLink {...iconProps} />;
      case FeatherIconName.MESSAGE_CIRCLE:
        return <MessageCircle {...iconProps} />;
      case FeatherIconName.ARROW_UP:
        return <ArrowUp {...iconProps} />;
      case FeatherIconName.ARROW_DOWN:
        return <ArrowDown {...iconProps} />;
      case FeatherIconName.SEARCH:
        return <Search {...iconProps} />;
      case FeatherIconName.EDIT:
        return <Edit {...iconProps} />;
      case FeatherIconName.MINUS:
        return <Minus {...iconProps} />;
      case FeatherIconName.COPY:
        return <Copy {...iconProps} />;
      case FeatherIconName.TRASH:
        return <Trash2 {...iconProps} />;
      case FeatherIconName.DOWNLOAD:
        return <Download {...iconProps} />;
      case FeatherIconName.FLAG:
        return <Flag {...iconProps} />;
      case FeatherIconName.USER:
        return <User {...iconProps} />;
      case FeatherIconName.INFO:
        return <Info {...iconProps} />;
      case FeatherIconName.KEY:
        return <Key {...iconProps} />;
      case FeatherIconName.LOG_OUT:
        return <LogOut {...iconProps} />;
      case FeatherIconName.THUMB_UP:
        return <ThumbsUp {...iconProps} />;
      case FeatherIconName.GIFT:
        return <Gift {...iconProps} />;
      case FeatherIconName.HELP_CIRCLE:
        return <HelpCircle {...iconProps} />;
      default: {
        const _exhaustiveCheck: never = p.content;
        throw new Error(`Unknown icon: ${p.content}`);
      }
    }
  };

  return (
    <OptionalTooltip
      side={p.tooltipSide}
      content={p.tooltipContent}
      variant={p.tooltipVariant}
      isInstant
    >
      {renderIconForContent()}
    </OptionalTooltip>
  );
}

/**
 * Convenience wrapper component around the open source [Feather icon set](https://feathericons.com/).
 * Supports the subset of feather icons used in the Chainabuse design.
 */
export default FeatherIcon;
