import clsx from 'clsx';
import { ReactElement } from 'react';
import { CopyButton } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ResponsiveAddressProps = StyleProps & {
  /**
   * The address to render.
   */
  address: string;

  /**
   * Whether to use heavy styles, for body typographies.
   * @default false
   */
  isHeavy?: boolean;

  /**
   * Whether to hide the copy button
   * @default true
   */
  showCopyButton?: boolean;
};

const ROOT = makeRootClassName('ResponsiveAddress');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isHeavy: false,
  showCopyButton: true,
} as const;

function ResponsiveAddress(props: ResponsiveAddressProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div
      className={clsx(ROOT, p.className, {
        'is-heavy': p.isHeavy,
      })}
    >
      <div className={el`text`}>{p.address}</div>
      {p.showCopyButton && (
        <CopyButton textToCopy={p.address} className={el`copy-button`} />
      )}
    </div>
  );
}

export default ResponsiveAddress;
