import localStorage, { CookieStorage } from 'local-storage-fallback';
import isServer from './isServer';

export const getStorageItem = (key: string): string | null => {
  if (!isServer()) {
    const cookieStorage = new CookieStorage();
    return cookieStorage.getItem(key) ?? localStorage.getItem(key);
  }
  return null;
};

export const removeStorageItem = (key: string): void => {
  if (!isServer()) {
    const cookieStorage = new CookieStorage();
    cookieStorage.removeItem(key);
    window.sessionStorage.removeItem(key);
    localStorage.removeItem(key);
  }
};

/**
 * Local storage is not synchronous so we need to use this util to ensure the
 * value is set before we try to use it.
 * @see https://stackoverflow.com/a/20231180
 *
 * @param key - The key in local storage
 * @param timeout - The timeout in milliseconds; default value is 2000
 */
export const ensureKeySetInLocalStorageOrFail = (
  key: string,
  timeout = 2000
): Promise<void> => {
  return new Promise((resolve, reject) => {
    let resolved = false;
    /* eslint prefer-const: ["error", { ignoreReadBeforeAssign: true }] */
    let timeoutTimer: ReturnType<typeof setTimeout> | undefined;

    // set an interval to check every 50ms whether we have the value and
    // resolve if so while canceling the timeout timer
    const pollForValueInterval = setInterval(() => {
      const value = getStorageItem(key);
      if (value && !resolved) {
        resolved = true;
        // clear this interval
        clearInterval(pollForValueInterval);
        // if there's a timeout timer, clear it
        if (timeoutTimer) {
          clearTimeout(timeoutTimer);
        }
        resolve();
      }
    }, 50);

    // set a timeout timer and if it is not cancelled, clear the polling
    // interval and reject
    timeoutTimer = setTimeout(() => {
      if (!resolved) {
        resolved = true;
        clearInterval(pollForValueInterval);
        reject(new Error(`${key} not set in localStorage`));
      }
    }, timeout);
  });
};
