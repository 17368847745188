import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { FeatherIcon, FeatherIconName } from '@/components/icon';
import { Link, Modal, Text } from '@/components';

export type AddressHelpModalProps = StyleProps;

const ROOT = makeRootClassName('AddressHelpModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function AddressHelpModal(props: AddressHelpModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);

  const toggleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <div {...buttonize(toggleOpen)}>
        <FeatherIcon
          content={FeatherIconName.HELP_CIRCLE}
          tooltipVariant="block"
          tooltipContent="What is this?"
        />
      </div>

      <Modal.Root open={open} onOpenChange={setOpen} className={el`modal`}>
        <Modal.Content className={el`modal-content`}>
          <Modal.Header title="Scammer’s blockchain address(es)" hasCloseButton />
          <div className={el`content`}>
            <Text type="body-md">
            The blockchain address(es) where your stolen crypto funds were sent is the most important thing you can report.
            It will tell law enforcement where your funds went. Without this address(es), it is unlikely your funds can be recovered.
            </Text>
            <Text type="body-md" className={el`important-text`}>
              <strong>In most cases it will be the wallet address(es) to which the scammer has asked you to send your crypto funds.
              If this is the case, please copy paste this address(es) in your report.</strong>
            </Text>
            <Text type="body-md" >
            If the scammer has not asked you to send crypto funds to a specific address, but funds were sent on your behalf,
            please follow the steps below to find the crypto address of the scammer.
            </Text>

            <Text type="body-md" className={el`title`}>
              <strong>Finding the scammer address(es) in your wallet/exchange</strong>
            </Text>
            <Text type="body-md">
              <ul className={el`list`}>
                  <li>In your blockchain wallet or crypto exchange, check if you can access a history of transactions or withdrawals.
                    Every wallet/exchange is different, so ask customer support for help finding this if needed.
                    </li>
                  <li>Once you found the address(es) your crypto funds were sent to, simply copy and paste them into your Chainabuse report. You are done!</li>
                  <li>If you cannot find them in your wallet/exchange, use a block explorer to see where your funds were sent to. </li>
              </ul>
            </Text>

            <Text type="body-md" className={el`title`}>
              <strong>Using a Block Exporer to find the scammer address(es)</strong>
            </Text>
            <Text type="body-md">
              <ul className={el`list`}>
                <li>
                Go to the block explorer for the blockchain you are using:
                <ul className={el`list`}>
                  <li>
                    For BTC:{' '}
                    <Link isExternal href="https://www.blockchain.com/explorer">
                      https://www.blockchain.com/explorer
                    </Link>
                  </li>
                  <li>
                    For Cardano:{' '}
                    <Link isExternal href="https://explorer.cardano.org/en">
                      https://explorer.cardano.org/en
                    </Link>
                  </li>
                  <li>
                    For ETH:{' '}
                    <Link isExternal href="https://etherscan.io">
                      https://etherscan.io
                    </Link>
                  </li>
                  <li>
                    For Hedera:{' '}
                    <Link isExternal href="https://hederaexplorer.io">
                      https://hederaexplorer.io
                    </Link>
                  </li>
                  <li>
                    For Litecoin:{' '}
                    <Link isExternal href="https://blockchair.com/litecoin">
                      https://blockchair.com/litecoin
                    </Link>
                  </li>
                  <li>
                    For Polygon:{' '}
                    <Link isExternal href="http://polygonscan.com">
                      http://polygonscan.com
                    </Link>
                  </li>
                  <li>
                    For Solana:{' '}
                    <Link
                      isExternal
                      href="https://solana.fm/?cluster=mainnet-qn1"
                    >
                      https://solana.fm/?cluster=mainnet-qn1
                    </Link>
                  </li>
                  <li>
                    For Tron:{' '}
                    <Link isExternal href="https://tronscan.org">
                      https://tronscan.org
                    </Link>
                  </li>
                </ul>
                </li>
                <li>
                  Within the relevant block explorer, input your public wallet address in the “FROM” field and
                  look for the blockchain address where the funds were sent “TO”.
                </li>
                <li>
                Copy and paste that crypto address in your report. If funds were sent to multiple addresses, please enter all of them.
                </li>
              </ul>
            </Text>
          </div>
          <Modal.Footer className={el`modal-footer`}>
            <Modal.FooterButton isCloseButton>Close</Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

export default AddressHelpModal;
