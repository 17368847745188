import { useFocusRing } from '@react-aria/focus';
import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Text, Tooltip } from '..';
import { FeatherIcon, FeatherIconName } from '../icon';
import useVote, { Vote } from './hooks/useVote';

export type VotingProps = {
  /** The net number of votes */
  netVoteCount: number;
  /**
   * The vote the user has selected, if they've voted.
   */
  vote?: Vote;
  /** Wheter the voting is currently in progress */
  isDisabled?: boolean;
};

export type BidirectionalVotingProps = StyleProps &
  VotingProps & {
    /**
     * The layout of the vote component
     * @default 'vertical'
     */
    direction?: 'vertical' | 'horizontal';

    /**
     * The size of the vote component
     * @default 'medium'
     */
    size?: 'medium' | 'small';

    /** Handler called when the user toggles either the up or down vote */
    onToggleVote?: (voteToggled: 'up' | 'down') => void;
  };

export type VoteButtonProps = StyleProps & {
  /** The name of the icon */
  featherIcon: FeatherIconName;
  /** Whether the button isSelected. @default false */
  isSelected?: boolean;
  /** Whether the button isDisabled. @default false */
  isDisabled?: boolean;
  /** Handler to call on click */
  onClick: () => void;
  /** Message to show in the tooltip */
  tooltipContent: string;
};

const ROOT = makeRootClassName('BidirectionalVoting');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  direction: 'vertical',
  size: 'medium',
} as const;

const DEFAULT_VOTE_PROPS = {
  isSelected: false,
  isDisabled: true,
};

function VoteButton(props: VoteButtonProps): ReactElement {
  const p = { ...DEFAULT_VOTE_PROPS, ...props };

  const { focusProps, isFocusVisible } = useFocusRing();

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    p.onClick();
  };

  return (
    <Tooltip content={p.isDisabled ? undefined : p.tooltipContent} isInstant>
      <button
        {...focusProps}
        aria-label={p.tooltipContent}
        disabled={p.isDisabled}
        onClick={handleClick}
        className={clsx(el`vote-button`, {
          'is-focus-visible': isFocusVisible,
          'is-selected': p.isSelected,
        })}
      >
        <FeatherIcon content={p.featherIcon} className={el`vote-icon`} />
      </button>
    </Tooltip>
  );
}

export function BidirectionalVoting(
  props: BidirectionalVotingProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const upvoted = p.vote === 'up';
  const downvoted = p.vote === 'down';

  return (
    <div
      className={clsx(
        `${ROOT} size-${p.size} vote-${p.vote}`,
        { 'is-horizontal': p.direction === 'horizontal' },
        p.className
      )}
    >
      <VoteButton
        isSelected={upvoted}
        isDisabled={p.isDisabled}
        featherIcon={FeatherIconName.ARROW_UP}
        onClick={() => p.onToggleVote?.('up')}
        tooltipContent={upvoted ? 'Remove Vote' : 'Vote Up'}
      />
      <Text as="span" className={el`vote-count`}>
        {p.netVoteCount}
      </Text>
      <VoteButton
        isSelected={downvoted}
        isDisabled={p.isDisabled}
        featherIcon={FeatherIconName.ARROW_DOWN}
        onClick={() => p.onToggleVote?.('down')}
        tooltipContent={downvoted ? 'Remove Vote' : 'Vote Down'}
      />
    </div>
  );
}

const voteToNumber = (vote?: Vote) => {
  switch (vote) {
    case 'up':
      return 1;
    case undefined:
      return 0;
    case 'down':
      return -1;
  }
};

const getVoteCount = (voteCount: number, vote?: Vote, storedVote?: Vote) => {
  const voteStateNum = voteToNumber(vote);
  const voteStoreNum = voteToNumber(storedVote);

  const diff = voteStoreNum - voteStateNum;

  if (vote === storedVote) {
    return voteCount;
  }

  return voteCount - diff;
};

type BidirectionalVotingContainerProps = Omit<
  BidirectionalVotingProps,
  'onToggleVote'
> & {
  /** The id of the report the vote is on*/
  reportId: string;
};
function BidirectionalVotingContainer(
  props: BidirectionalVotingContainerProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { toggleVote, isLoading, vote } = useVote({
    reportId: p.reportId,
    vote: p.vote,
  });

  return (
    <BidirectionalVoting
      {...p}
      vote={vote}
      netVoteCount={getVoteCount(p.netVoteCount, vote, p.vote)}
      isDisabled={p.isDisabled || isLoading}
      onToggleVote={toggleVote}
    />
  );
}

export default BidirectionalVotingContainer;
