import { useFocusWithin } from '@react-aria/interactions';
import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, TextField } from '@/components';

export type SubmitCommentProps = StyleProps & {
  /**
   * The user's profile image src
   */
  profileImgSrc?: string;

  /**
   * submit comment handler
   */
  onSubmitComment: (comment: string) => void;

  /**
   * submit comment handler
   */
  onCancel?: () => void;

  /**
   * Intitial value for the comment.
   */
  initialValue?: string;
};

const ROOT = makeRootClassName('SubmitComment');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function SubmitComment(props: SubmitCommentProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [value, setValue] = useState(props.initialValue ?? '');

  const [isFocusWithin, setFocusWithin] = React.useState(false);
  const { focusWithinProps } = useFocusWithin({
    onFocusWithinChange: (isFocusWithin) => setFocusWithin(isFocusWithin),
  });

  const handleComment = () => {
    p.onSubmitComment(value.trim());
    setValue('');
  };

  const shouldShowButtons = isFocusWithin || value.trim();

  return (
    <div className={clsx(ROOT, p.className)}>
      <div {...focusWithinProps}>
        <TextField
          value={value}
          onChange={setValue}
          variant="comment"
          placeholder="Write a comment..."
          aria-label="Write a comment"
          autoComplete="off"
        />
      </div>
      {shouldShowButtons && (
        <div className={el`button-container`}>
          <Button
            isDisabled={value.trim().length === 0}
            onPress={handleComment}
            variant="comment"
          >
            SAVE COMMENT
          </Button>
          <Button
            onPress={() => {
              setValue('');
              p.onCancel?.();
            }}
            variant="secondary"
          >
            CANCEL
          </Button>
        </div>
      )}
    </div>
  );
}

export default SubmitComment;
