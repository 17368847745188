import clsx from 'clsx';
import { ReactElement } from 'react';
import { useRouter } from 'next/router';
import { Badge, CustomContentLoader, Text } from '@/components';
import { useGetCommentsForReportQuery } from '@/generated/graphql';
import { useMe } from '@/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { PreAuthModal } from '..';
import { Comment, SubmitComment } from './components';
import useAddEditComment from './hooks/useAddEditComment';
import { CommentData } from './types';

const ROOT = makeRootClassName('CommentSection');
const el = makeElementClassNameFactory(ROOT);

export type CommentSectionProps = StyleProps & {
  /**
   * List of comments
   */
  comments: CommentData[];

  /**
   * The report's id. Required for optimistically updating the comments list
   * when a comment gets edited or removed.
   */
  reportId: string;

  /**
   * Whether or not the user is logged in
   */
  isLoggedIn: boolean;

  /**
   * Submit comment handler
   */
  onSubmitComment: (content: string) => void;

  loading?: boolean;
};

const DEFAULT_PROPS = {} as const;

export function CommentSection(props: CommentSectionProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { asPath } = useRouter();

  return (
    <div className={clsx(ROOT, p.className)}>
      <div className={el`content`}>
        <div className={el`header`}>
          <h3>Comments</h3>
          <Badge size="small" className={el`num-comments`}>
            {p.comments.length ?? 0}
          </Badge>
        </div>
        <div className={el`comments-warning`}>
          <Text as="p" type="body-md">
            Spammy comments promoting asset recovery services will be deleted.
          </Text>
        </div>
        {props.loading ? (
          <CustomContentLoader type="comments" />
        ) : (
          <>
            {p.isLoggedIn ? (
              <SubmitComment onSubmitComment={p.onSubmitComment} />
            ) : (
              <div className="sm:w-[280px]">
                <PreAuthModal
                  returnTo={asPath}
                  triggerButtonText="LOGIN TO COMMENT"
                />
              </div>
            )}
            <div className={el`body`}>
              {p.comments.map((comment, index) => (
                <Comment
                  key={comment.id}
                  reportId={p.reportId}
                  comment={comment}
                  isLast={index === p.comments.length - 1}
                />
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}

type CommentSectionContainerProps = Omit<
  CommentSectionProps,
  'isLoggedIn' | 'profileImgSrc' | 'onSubmitComment' | 'comments'
> & { reportId: string };

const CommentSectionContainer = (
  props: CommentSectionContainerProps
): ReactElement => {
  const { me } = useMe();
  const isLoggedIn = !!me;
  const { addComment } = useAddEditComment({ reportId: props.reportId });

  const { data, loading } = useGetCommentsForReportQuery({
    variables: { input: { id: props.reportId } },
  });

  const comments =
    data?.report?.comments?.map((comment) => ({
      id: comment.id,
      username: comment.author.username,
      content: comment.body,
      profileImgSrc: comment.author.photo?.url,
      date: new Date(comment.createdAt),
      authorId: comment.author.id,
    })) ?? [];

  return (
    <CommentSection
      {...props}
      comments={comments}
      isLoggedIn={isLoggedIn}
      onSubmitComment={addComment}
      loading={loading}
    />
  );
};

export default CommentSectionContainer;
