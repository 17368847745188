import clsx from 'clsx';
import { ReactElement } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  RemoveFieldButton,
  Text,
  TextField,
} from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { DragIcon } from '../drag-icon';

export type MultiLossesFormProps = StyleProps & {
  isDisabled?: boolean;
  lossFields: Array<{ key: string; amount: string; asset: string }>;
  onAddField: () => void;
  onRemoveField: (index: number) => void;
  onUpdateAmount: (index: number, amount: string) => void;
  onUpdateOrder: (fromIndex: number, toIndex: number) => void;
};

const ROOT = makeRootClassName('MultiLossesForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function MultiLossesForm(props: MultiLossesFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.lossFields.length > 1;

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          p.onUpdateOrder(source.index, destination.index);
        }
      }}
    >
      <Droppable droppableId="drop-losses" isDropDisabled={p.isDisabled}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            className={clsx(ROOT, p.className, {
              'has-multiple': hasMultiple,
            })}
          >
            {p.lossFields.map((field, index) => {
              const isUSD = field.asset === 'USD';

              return (
                <Draggable
                  key={field.key}
                  draggableId={field.key}
                  index={index}
                >
                  {(provided, dragSnapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      className={el`losses-row`}
                      style={{
                        ...provided.draggableProps.style,
                        transform: dragSnapshot.isDragging
                          ? provided.draggableProps.style?.transform?.replace(
                              /\(.+,/,
                              '(0,'
                            )
                          : provided.draggableProps.style?.transform,
                      }}
                    >
                      <div {...provided.dragHandleProps}>
                        {hasMultiple && <DragIcon />}
                      </div>
                      <div className={el`fields`}>
                        <TextField
                          type="number"
                          placeholder="Loss Amount (digits only)"
                          aria-label="Loss Amount (digits only)"
                          className={el`field-amount`}
                          value={field.amount}
                          isDisabled={p.isDisabled || !isUSD}
                          onChange={(value) => p.onUpdateAmount(index, value)}
                          wrapperChildren={
                            !isUSD ? (
                              <div className={el`usd`}>
                                <Text type="body-md">{field.asset}</Text>
                              </div>
                            ) : undefined
                          }
                        />
                        {/* allow resetting field field if asset is not usd */}
                        {index > 0 || !isUSD ? (
                          <RemoveFieldButton
                            isDisabled={p.isDisabled}
                            onPress={() => p.onRemoveField(index)}
                            className={el`remove-field-button`}
                          />
                        ) : null}
                      </div>
                    </div>
                  )}
                </Draggable>
              );
            })}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

export default MultiLossesForm;
