import { debounce } from 'lodash';
import { ReactElement, useCallback, useMemo, useState } from 'react';
import { AutocompleteBase } from '@/components/autocomplete';
import {
  AutocompleteBaseProps,
  OptionData,
} from '@/components/autocomplete/AutocompleteBase';
import { useAddressLabelsLazyQuery } from '@/generated/graphql';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type LabelInputProps = StyleProps &
  Pick<
    AutocompleteBaseProps<OptionData, false>,
    | 'validationState'
    | 'placeholder'
    | 'isDisabled'
    | 'isLoading'
    | 'options'
    | 'value'
  > & {
    /**
     * Handler to call when the user changes the value of the label
     * e.g. to find new options
     */
    onChange: (value: string) => void;
  };

const ROOT = makeRootClassName('LabelInput');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function LabelInput(props: LabelInputProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  return (
    <AutocompleteBase
      freeSolo
      className={p.className}
      onInputChange={(_, value) => p.onChange(value)}
      onChange={(_, value) => {
        if (value && !Array.isArray(value)) {
          // value could be free solo (string) or picked from a list (OptionData)
          const query = typeof value === 'string' ? value : value.label;
          p.onChange?.(query);
        }
      }}
      placeholder={p.placeholder}
      validationState={p.validationState}
      isDisabled={p.isDisabled}
      isLoading={p.isLoading}
      options={p.options}
      value={p.value}
    />
  );
}

type LabelInputContainerProps = StyleProps &
  Pick<
    LabelInputProps,
    'validationState' | 'isDisabled' | 'placeholder' | 'value'
  > & {
    /**
     * Handler to call when the user changes the value of the label
     */
    onChange?: (value: string) => void;
  };

function LabelInputContainer(props: LabelInputContainerProps): ReactElement {
  const [options, setOptions] = useState<OptionData[]>([]);
  const [search, { loading }] = useAddressLabelsLazyQuery();

  const getLabelOptions = useCallback(
    async (query: string) => {
      if (query.length === 0) {
        setOptions([]);
        return;
      }
      const { data } = await search({
        variables: {
          input: {
            query,
          },
        },
      });
      const results: OptionData[] =
        data?.addressLabels?.nodes?.map(({ body }) => ({ label: body })) || [];
      setOptions(results);
    },
    [search]
  );

  // @see https://github.com/facebook/react/issues/19240#issuecomment-652945246
  const debouncedSearchOptions = useMemo(
    () => debounce(getLabelOptions, 300),
    [getLabelOptions]
  );

  return (
    <LabelInput
      {...props}
      isLoading={loading}
      options={options}
      onChange={(value) => {
        debouncedSearchOptions(value);
        props.onChange?.(value);
      }}
    />
  );
}

export default LabelInputContainer;
