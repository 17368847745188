import { NextRouter } from 'next/router';

export const removeQueryParam = (router: NextRouter, param: string): void => {
  delete router.query[param];
  router.push(router, undefined, { shallow: true });
};

/** @see https://stackoverflow.com/a/487049/14514368 */
export function insertQueryParam({
  url,
  key,
  value,
}: {
  url: string;
  key: string;
  value: string;
}): string {
  const [urlWithoutQueryParams, queryParams] = url.split('?');
  const kvp = queryParams?.split('&') || [];
  let i = 0;

  for (; i < kvp.length; i++) {
    if (kvp[i].startsWith(key + '=')) {
      const pair = kvp[i].split('=');
      pair[1] = value;
      kvp[i] = pair.join('=');
      break;
    }
  }

  if (i >= kvp.length) {
    kvp[kvp.length] = [key, value].join('=');
  }

  const params = kvp.join('&');

  return urlWithoutQueryParams + '?' + params;
}
