import { nanoid } from 'nanoid';
import { SubmitReportFormValues, TokenIdField, ValidTokenIdData } from '../types';

export const getInitialTokenIdFieldsFromFormValues = (
  props?: SubmitReportFormValues
): TokenIdField[] | undefined => {
  if (!props) return undefined;

  const fields =
    props.tokens?.map((token) => ({
      key: nanoid(),
      id: token.id ?? undefined,
      value: token.tokenId,
    }));

  return fields;
};
