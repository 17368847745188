import clsx from 'clsx';
import { debounce, uniqBy } from 'lodash';
import { ReactElement, useState } from 'react';
import isURL from 'validator/lib/isURL';
import {
  isPossibleAddress,
  isPossibleTransactionHash,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { FeatherIconName } from '@/components/icon';
import { Button, Modal, Text, TextArea } from '@/components';

type InputList = {
  value: string;
  type?: 'domain' | 'address' | 'transaction' | 'token';
};

export type BulkAddModalProps = StyleProps & {
  type: 'address' | 'token' | 'transaction';
  onSubmit: (value: string[]) => void;
};

const ROOT = makeRootClassName('BulkAddModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function BulkAddModal(props: BulkAddModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(false);
  const [list, setList] = useState<InputList[]>([]);
  const [isEmpty, setIsEmpty] = useState(false);
  const dedupedList = uniqBy(list, (item) => item.value);

  let countAddresses = 0,
    countDomains = 0,
    countTokens = 0,
    countTransactionHashes = 0;

  if (p.type == 'address') {
    countAddresses = dedupedList.filter((el) => el.type === 'address').length;
    countDomains = dedupedList.filter((el) => el.type === 'domain').length;
  } else if (p.type === 'token') {
    countTokens = dedupedList.length;
  } else if (p.type === 'transaction') {
    countTransactionHashes = dedupedList.filter(
      (el) => el.type === 'transaction'
    ).length;
  }

  const isNoValueExtracted =
    countAddresses + countDomains + countTokens + countTransactionHashes === 0;

  const isAboveLimit =
    countAddresses + countDomains > 50 ||
    countTokens > 50 ||
    countTransactionHashes > 50;
  const isError = isNoValueExtracted || isAboveLimit;

  const handleChange = debounce((e) => {
    const splittedOptions: string[] = e.split(/,| |\n/);
    let hasFound = false;
    setList([]);

    if (p.type === 'address') {
      splittedOptions.forEach((option) => {
        const isAddress = isPossibleAddress(option);
        const isDomain = isURL(option);
        if (isAddress) {
          hasFound = true;
          setList((prev) => {
            return [...prev, { type: 'address', value: option }];
          });
        } else if (isDomain) {
          hasFound = true;
          setList((prev) => {
            return [...prev, { type: 'domain', value: option }];
          });
        }
      });
    } else if (p.type === 'token') {
      splittedOptions.forEach((option) => {
        const isToken = typeof option === 'string' && option.length > 0;
        if (isToken) {
          hasFound = true;
          setList((prev) => {
            return [...prev, { value: option }];
          });
        }
      });
    } else if (p.type === 'transaction') {
      splittedOptions.forEach((option) => {
        const isTransactionHash = isPossibleTransactionHash(option);
        if (isTransactionHash) {
          hasFound = true;
          setList((prev) => {
            return [...prev, { type: 'transaction', value: option }];
          });
        }
      });
    }

    // check if there's input value but no data have been extracted
    if (e && !hasFound) {
      setIsEmpty(true);
    } else {
      setIsEmpty(false);
    }
  }, 300);

  const toggleOpen = () => {
    setList([]);
    setOpen(!open);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Button
        onPress={toggleOpen}
        startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
        variant="text-white"
      >
        Bulk Add
      </Button>

      <Modal.Root open={open} onOpenChange={setOpen} className={el`modal`}>
        <Modal.Content>
          <Modal.Header
            title="Bulk Add Reported Items"
            subtitle="We'll try to recognize items in any text you enter or paste below."
            hasCloseButton
          />
          <div className={el`text-area-wrapper`}>
            <TextArea
              className={el`text-area`}
              autoFocus
              placeholder="Enter up to 50 items"
              variant="comment"
              onChange={handleChange}
            />
            {!isNoValueExtracted && (
              <Text
                type="body-sm"
                className={clsx(el`description`, {
                  'is-error': isError,
                })}
              >
                Detected{' '}
                {countAddresses +
                  countDomains +
                  countTokens +
                  countTransactionHashes}{' '}
                items.
              </Text>
            )}
            {isAboveLimit && (
              <Text
                type="body-sm"
                className={clsx(el`description`, {
                  'is-error': isError,
                })}
              >
                Only The First 50 items will be added.
              </Text>
            )}
            {isEmpty && (
              <Text
                type="body-sm"
                className={clsx(el`description`, {
                  'is-error': isError,
                })}
              >
                No items detected.
              </Text>
            )}
          </div>
          <Modal.Footer className={el`modal-footer`}>
            <Modal.FooterButton isCloseButton>Cancel</Modal.FooterButton>
            <Modal.FooterButton
              isDisabled={isNoValueExtracted}
              isPrimary
              onPress={(e) => {
                setOpen(false);
                p.onSubmit(dedupedList.slice(0, 50).map(({ value }) => value));
              }}
            >
              Add reported items
            </Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

export default BulkAddModal;
