import clsx from 'clsx';
import { ReactElement, useState } from 'react';
import { useAuthRequiredAction } from '@/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Modal, Text, TextArea } from '..';
import { FeatherIcon, FeatherIconName } from '../icon';
import useFlagReport from './hooks/useFlagReport';

export type FlagReportProps = StyleProps & {
  /** The report's id */
  reportId: string;

  /** Handler to call when flagging a report */
  onFlag: (reason: string) => void;

  /** Whether the user is logged in */
  isLoggedIn: boolean;

  /** Handler to call when a non-logged in user tries to flag a report */
  onOpenAuthModal: () => void;

  /**
   * Whether to use the tablet or mobile version of the flag report trigger
   * @default 'tablet'
   */
  variant?: 'tablet' | 'mobile';
};

const ROOT = makeRootClassName('FlagReport');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  variant: 'tablet',
} as const;

export function FlagReport(props: FlagReportProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [reason, setReason] = useState('');
  const [flagged, setFlagged] = useState(false);
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    if (!p.isLoggedIn) {
      p.onOpenAuthModal();
      return;
    }

    setOpen(true);
  };

  // This will optimistically flag the report.
  // Tracking loading and error state doesn't seem like it's worth it here
  const handleFlag = () => {
    setFlagged(true);
    p.onFlag(reason);
  };

  return (
    <>
      <button
        onClick={handleOpen}
        className={clsx(ROOT, p.className, `variant-${p.variant}`)}
      >
        <FeatherIcon
          content={FeatherIconName.FLAG}
          tooltipContent="Flag Report"
          size={p.variant === 'tablet' ? 'medium' : 'small'}
        />
      </button>

      <Modal.Root open={open} onOpenChange={setOpen}>
        <Modal.Content className={el`content-wrapper`}>
          <Modal.Header title="Flag Report" hasCloseButton />

          {flagged ? (
            <Text>
              Thanks for flagging this report. We&apos;ll investigate it
              promptly.
            </Text>
          ) : (
            <>
              <Text>
                Thank you for looking after yourself and the crypto community by
                calling out improper reports.
              </Text>

              <Text>
                Please flag any reports that incite violence, hate, criminal
                activities, perpetrate impersonation, are spam or spread false
                or harmful information.
              </Text>

              <div>
                <div id={`flag-${p.reportId}`}>
                  <Text isHeavy>Why are you flagging this report?</Text>
                </div>

                <TextArea
                  placeholder="Please describe in what ways this report is inappropriate."
                  aria-labelledby={`flag-${p.reportId}`}
                  variant="transparent"
                  value={reason}
                  onChange={setReason}
                  className="mt-[12px]"
                />
              </div>
            </>
          )}

          <Modal.Footer>
            <Modal.FooterButton
              isPrimary
              onPress={handleFlag}
              isDisabled={!reason || flagged}
            >
              Flag Report
            </Modal.FooterButton>
            <Modal.FooterButton isCloseButton>Cancel</Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </>
  );
}

type FlagReportContainerProps = StyleProps &
  Pick<FlagReportProps, 'variant'> & {
    /** The id of the report to flag */
    reportId: string;
  };

function FlagReportContainer(props: FlagReportContainerProps): ReactElement {
  const { openAuthModal, isLoggedIn } = useAuthRequiredAction();
  const { flagReport } = useFlagReport({ reportId: props.reportId });

  return (
    <FlagReport
      {...props}
      onFlag={flagReport}
      isLoggedIn={isLoggedIn}
      onOpenAuthModal={openAuthModal}
    />
  );
}

export default FlagReportContainer;
