import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { $isListNode, ListNode } from '@lexical/list';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import { $isHeadingNode } from '@lexical/rich-text';
import { $getNearestNodeOfType, mergeRegister } from '@lexical/utils';
import clsx from 'clsx';
import {
  $getSelection,
  $isRangeSelection,
  CAN_REDO_COMMAND,
  CAN_UNDO_COMMAND,
  ElementNode,
  FORMAT_TEXT_COMMAND,
  REDO_COMMAND,
  SELECTION_CHANGE_COMMAND,
  TextNode,
  UNDO_COMMAND,
} from 'lexical';
import {
  ReactElement,
  RefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button } from '@/components';
import {
  createFormatBold,
  createFormatItalic,
  createFormatStrikethrough,
  createFormatUnderline,
  createLink,
  createRotateCcw,
  createRotateCw,
} from '@/assets/icons';
import { BlockOptionsDropdown, LinkEditor } from '../../components';
import { DEFAULT_URL } from '../../components/link-editor/LinkEditor';
import { getSelectedNode } from '../../utils';

import {
  EditorCommandPriority,
  FormattingOption,
  SupportedBlock,
  SUPPORTED_BLOCKS,
} from '../../types';

export type ToolbarPluginProps = StyleProps & {
  /**
   * The ref object where the link editor should be rendered
   */
  editorRef: RefObject<HTMLElement>;
  /**
   * Whether to show the undo / redo buttons
   */
  showHistory: boolean;
  /**
   * Whether the editor is disabled
   * @default false
   */
  isDisabled?: boolean;
  /**
   * A list of supported elements the editor has
   * @default ['paraghraph', 'h2', 'h3', 'h4', 'ul', 'ol']
   */
  supportedElements: ReadonlyArray<SupportedBlock>;
  /**
   * A list of formatting options the editor supports
   * * @default ['bold', 'italic', 'underline', 'strikethrough']
   */
  formattingOptions: ReadonlyArray<FormattingOption>;
};

const ROOT = makeRootClassName('ToolbarPlugin');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  showHistory: false,
  isDisabled: false,
} as const;

const getBlockType = (element: TextNode | ElementNode): SupportedBlock => {
  if ($isHeadingNode(element)) {
    const tag = element.getTag();
    return SUPPORTED_BLOCKS.find((block) => block === tag)
      ? (tag as SupportedBlock)
      : 'h4';
  }
  const type = element.getType();
  return SUPPORTED_BLOCKS.find((block) => block === type)
    ? (type as SupportedBlock)
    : 'paragraph';
};

function ToolbarPlugin(props: ToolbarPluginProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [editor] = useLexicalComposerContext();
  const toolbarRef = useRef<HTMLDivElement>(null);
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);
  const [blockType, setBlockType] = useState<SupportedBlock>('paragraph');
  const [isLink, setIsLink] = useState(false);
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  const updateToolbar = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const anchorNode = selection.anchor.getNode();
      const element =
        anchorNode.getKey() === 'root'
          ? anchorNode
          : anchorNode.getTopLevelElementOrThrow();
      const elementKey = element.getKey();
      const elementDOM = editor.getElementByKey(elementKey);
      if (elementDOM !== null) {
        if ($isListNode(element)) {
          const parentList = $getNearestNodeOfType(anchorNode, ListNode);
          const type = parentList ? parentList.getTag() : element.getTag();
          setBlockType(type);
        } else {
          const type = getBlockType(element);
          setBlockType(type);
        }
      }
      // Update text format
      setIsBold(selection.hasFormat('bold'));
      setIsItalic(selection.hasFormat('italic'));
      setIsUnderline(selection.hasFormat('underline'));
      setIsStrikethrough(selection.hasFormat('strikethrough'));

      // Update links
      const node = getSelectedNode(selection);
      const parent = node.getParent();
      // const rootElement = editor.getRootElement();

      if ($isLinkNode(parent) || $isLinkNode(node)) {
        setIsLink(true);
      } else {
        setIsLink(false);
      }
    }
  }, [editor]);

  useEffect(() => {
    return mergeRegister(
      editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          updateToolbar();
        });
      }),
      editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          updateToolbar();
          return true;
        },
        EditorCommandPriority.LowPriority
      ),
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload) => {
          setCanUndo(payload);
          return false;
        },
        EditorCommandPriority.LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload) => {
          setCanRedo(payload);
          return false;
        },
        EditorCommandPriority.LowPriority
      )
    );
  }, [editor, updateToolbar, p.showHistory]);

  const insertLink = useCallback(() => {
    if (!isLink) {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, DEFAULT_URL);
      // setLinkEditorOpen(true);
    } else {
      editor.dispatchCommand(TOGGLE_LINK_COMMAND, null);
      // setLinkEditorOpen(false);
    }
  }, [editor, isLink]);

  return (
    <div className={clsx(ROOT, p.className)} ref={toolbarRef}>
      {p.showHistory && (
        <>
          <Button
            isDisabled={!canUndo}
            startIcon={createRotateCcw}
            onPress={() => {
              editor.dispatchCommand(UNDO_COMMAND, undefined);
            }}
          />
          <Button
            isDisabled={!canRedo}
            startIcon={createRotateCw}
            onPress={() => {
              editor.dispatchCommand(REDO_COMMAND, undefined);
            }}
          />
        </>
      )}
      <BlockOptionsDropdown
        isDisabled={p.isDisabled}
        editor={editor}
        blockType={blockType}
        supportedElements={p.supportedElements}
      />
      <Button
        isDisabled={p.isDisabled}
        startIcon={createLink}
        variant={isLink ? 'text-blue' : 'text-white'}
        onPress={insertLink}
      />
      {p.editorRef.current &&
        isLink &&
        createPortal(<LinkEditor editor={editor} />, p.editorRef.current)}
      {p.formattingOptions.includes('bold') && (
        <Button
          isDisabled={p.isDisabled}
          startIcon={createFormatBold}
          variant={isBold ? 'text-blue' : 'text-white'}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'bold');
          }}
        />
      )}
      {p.formattingOptions.includes('italic') && (
        <Button
          isDisabled={p.isDisabled}
          startIcon={createFormatItalic}
          variant={isItalic ? 'text-blue' : 'text-white'}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'italic');
          }}
        />
      )}
      {p.formattingOptions.includes('underline') && (
        <Button
          isDisabled={p.isDisabled}
          startIcon={createFormatUnderline}
          variant={isUnderline ? 'text-blue' : 'text-white'}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'underline');
          }}
        />
      )}
      {p.formattingOptions.includes('strikethrough') && (
        <Button
          isDisabled={p.isDisabled}
          startIcon={createFormatStrikethrough}
          variant={isStrikethrough ? 'text-blue' : 'text-white'}
          onPress={() => {
            editor.dispatchCommand(FORMAT_TEXT_COMMAND, 'strikethrough');
          }}
        />
      )}
    </div>
  );
}

export default ToolbarPlugin;
