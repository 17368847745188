import clsx from 'clsx';
import { KeyboardEvent, ReactElement, RefObject, useState } from 'react';
import {
  SearchField,
  TextField,
  LabelInput,
  ChainSelect,
  RemoveFieldButton,
  ChainSelectModal,
} from '@/components';
import { MAX_LABEL_LENGTH } from '@/constants';
import { ChainDiscoverabilityProps, ChainType } from '@/types/chain';
import { CONTACT_SUPPORT_ROUTE } from '@/types/routes';
import {
  isPossibleTransactionHash,
  makeElementClassNameFactory,
  makeRootClassName,
} from '@/utils';
import {
  getDisabledChainsFromHash,
  getPossibleChainsFromHash,
} from '@/utils/chains';
import type { TransactionHashField } from '../submit-report-page/types';
import type { ValidationState } from '@react-types/shared';

export type TransactionHashInputProps = ChainDiscoverabilityProps & {
  /** The value in the input */
  value: string;

  /**
   * Initial chain value to put in the chain select. Will be overridden by the
   * user changing the value of the input or selecting another valid chain.
   * Used when prefilling an input
   */
  initialChain?: ChainType;

  /**
   * Label of the transaction hash.
   */
  transactionHashLabel?: TransactionHashField['label'];

  /** Whether the components are disabled */
  isDisabled?: boolean;

  /** Handler to call when the user changes the value */
  onChange: (value: string) => void;

  /** Handler to call when the user submits what looks like transaction hash */
  onSubmitTransactionHash?: (hash: string, chain?: ChainType) => void;

  /**
   * Handler that gets called when the user selects an chain,
   * a chain gets autoselected, or a chain gets deselected by the user
   * or automatically.
   */
  onChainChanged?: (chain?: ChainType) => void;

  /**
   * Handler that gets called when the label of the transaction hash changes,
   */
  onTransactionHashLabelChanged?: (
    label: TransactionHashField['label']
  ) => void;

  /**
   * Handler called whenever the validation state changes with the new
   * validation state. Useful for when a parent component needs to know
   * validation state.
   */
  onValidationStateChanged?: (valid: boolean) => void;

  /**
   * Whether to show the chain select upfront
   * @default false. If false, chain select step is shown in modal after user
   * types value in field.
   */
  showChainSelectUpfront?: boolean;

  /** Whether to show a remove field button
   * @default false
   */
  showRemoveFieldButton?: boolean;

  /**
   * Whether to show the transaction hash label input
   * @default false
   */
  showTransactionHashLabelInput?: boolean;

  /**
   * Handler to call when the user chooses to remove the field
   */
  onRemoveField?: () => void;

  /**
   * Whether the field in the input should be a searchfield.
   * @default false. If false, the input is a regular textfield
   */
  isSearchField?: boolean;

  /**
   * The direction of the search section
   * @default 'column'
   */
  direction?: 'row' | 'column';

  /**
   * The input's placeholder
   * @default 'Enter Hash'
   */
  placeholder?: string;

  /**
   * The input's ref
   */
  inputRef?: RefObject<HTMLInputElement>;
};

const ROOT = makeRootClassName('TransactionHashInput');
const el = makeElementClassNameFactory(ROOT);

type TransactionHashInputErrorProps = Pick<
  TransactionHashInputProps,
  'showChainSelectUpfront'
> & {
  isTransactionHashInvalid: boolean;
  isLabelInvalid: boolean;
};
const TransactionHashInputError = (
  props: TransactionHashInputErrorProps
): ReactElement => {
  if (props.isTransactionHashInvalid) {
    return (
      <div className={el`error`}>
        <span className={el`invalid-disclaimer`}>
          {props.showChainSelectUpfront ? (
            <>
              <span>The Transaction Hash you entered is incorrect.</span>{' '}
              <span>
                <a href={CONTACT_SUPPORT_ROUTE}>Contact us</a> to suggest new
                supported chains or to report a problem.
              </span>
            </>
          ) : (
            <>Incorrect or unsupported Transaction Hash</>
          )}
        </span>
      </div>
    );
  }

  if (props.isLabelInvalid) {
    return (
      <div className={el`error`}>
        <span className={el`invalid-disclaimer`}>
          Label must be less than 25 characters
        </span>
      </div>
    );
  }

  return <></>;
};

const DEFAULT_PROPS = {
  showChainSelectUpfront: false,
  isSearchField: false,
  direction: 'column',
  placeholder: 'Enter Hash',
  includeUndiscoverableChains: false,
  showTransactionHashLabelInput: false,
};

function TransactionHashInput(props: TransactionHashInputProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const [chain, _setChain] = useState<ChainType | undefined>(p.initialChain);
  const [isInvalid, _setIsInvalid] = useState(false);
  const [modalIsOpen, toggleModalOpen] = useState(false);

  const updateChain = (newChain?: ChainType) => {
    p.onChainChanged?.(newChain);
    _setChain(newChain);
  };

  const updateIsInvalid = (newInvalid: boolean) => {
    p.onValidationStateChanged?.(!newInvalid);
    _setIsInvalid(newInvalid);
  };

  const toggleChain = (selected?: ChainType) => {
    if (selected === chain) return updateChain(undefined);
    updateChain(selected);
  };

  const disabledChains = isPossibleTransactionHash(p.value)
    ? getDisabledChainsFromHash(p.value)
    : [];

  const validate = (value: string) => {
    if (value === '') {
      return updateIsInvalid(true);
    }

    const isValid = isPossibleTransactionHash(value);
    updateIsInvalid(!isValid);
    return isValid;
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (isPossibleTransactionHash(p.value)) {
        if (!p.showChainSelectUpfront) {
          const possibleChains = getPossibleChainsFromHash(p.value);
          if (possibleChains.length > 1) return toggleModalOpen(true);

          if (possibleChains.length === 1) {
            const [onlyPossible] = possibleChains;
            p.onChainChanged?.(onlyPossible);
            return p.onSubmitTransactionHash?.(p.value, chain);
          }

          p.onChainChanged?.(undefined);
          return p.onSubmitTransactionHash?.(p.value, undefined);
        }

        return p.onSubmitTransactionHash?.(p.value, chain);
      }

      if (!isPossibleTransactionHash(p.value)) {
        updateIsInvalid(true);
      }
    }
  };

  const handleUpdateValue = async (value: string) => {
    validate(value);

    if (isPossibleTransactionHash(value)) {
      const possibleChains = getPossibleChainsFromHash(value);
      if (possibleChains.length > 1) {
        const [first] = possibleChains;
        if (chain === undefined) {
          updateChain(first);
        }
      }

      if (possibleChains.length === 1) {
        const [onlyPossible] = possibleChains;
        updateChain(onlyPossible);
      }

      if (possibleChains.length === 0) {
        updateChain(undefined);
      }
    } else {
      updateChain(undefined);
    }

    p.onChange(value);
  };

  const transactionHashValidationProps:
    | { validationState: ValidationState }
    | Record<string, never> =
    p.value.length > 0 && isInvalid ? { validationState: 'invalid' } : {};

  const isLabelInvalid =
    p.showTransactionHashLabelInput &&
    p.transactionHashLabel &&
    p.transactionHashLabel.length >= MAX_LABEL_LENGTH;

  const labelValidationProps:
    | { validationState: ValidationState }
    | Record<string, never> = isLabelInvalid
    ? {
        validationState: 'invalid',
      }
    : {};

  const couldBeTransactionHash = isPossibleTransactionHash(p.value);

  const field = (
    <>
      {p.isSearchField ? (
        <SearchField
          {...transactionHashValidationProps}
          autoComplete="off"
          aria-label="Search Transaction Hash"
          placeholder={p.placeholder}
          value={p.value}
          onChange={handleUpdateValue}
          onKeyDown={handleEnter}
          isDisabled={p.isDisabled}
          className={el`field`}
          inputRef={p.inputRef}
        />
      ) : (
        <TextField
          {...transactionHashValidationProps}
          autoComplete="off"
          aria-label="Enter Transaction Hash"
          placeholder={p.placeholder}
          value={p.value}
          onChange={handleUpdateValue}
          onKeyDown={handleEnter}
          isDisabled={p.isDisabled}
          className={el`field`}
        />
      )}
    </>
  );

  const hasRemoveButtonInlineWithField =
    p.showRemoveFieldButton && p.direction === 'column';

  return (
    <>
      <div
        aria-label="Transaction Hash"
        className={clsx(ROOT, {
          'is-only-field': !p.showChainSelectUpfront,
          'is-horizontal': p.direction === 'row',
          'has-remove-button-inline-with-field': hasRemoveButtonInlineWithField,
        })}
      >
        <div className={el`input-select`}>
          {hasRemoveButtonInlineWithField ? (
            <div className={el`field-with-remove-button`}>
              {field}
              <RemoveFieldButton
                isDisabled={p.isDisabled}
                onPress={() => p.onRemoveField?.()}
              />
            </div>
          ) : (
            field
          )}
          <div className={el`chain-transaction-hash-label-inputs`}>
            {p.showChainSelectUpfront && (
              <ChainSelect
                isDisabled={!couldBeTransactionHash || p.isDisabled}
                value={chain}
                onChange={toggleChain}
                disabledOptions={disabledChains}
                placeholder={'Chain'}
                onFocusChange={() => {
                  p.inputRef?.current?.focus();
                }}
              />
            )}
            {p.showTransactionHashLabelInput && (
              <LabelInput
                {...labelValidationProps}
                isDisabled={p.isDisabled || !couldBeTransactionHash}
                placeholder="Label (optional)"
                onChange={(value) => p.onTransactionHashLabelChanged?.(value)}
                className={el`transaction-hash-label-input`}
                value={p.transactionHashLabel}
              />
            )}
          </div>
          {p.showRemoveFieldButton && p.direction === 'row' && (
            <RemoveFieldButton
              isDisabled={p.isDisabled}
              onPress={() => p.onRemoveField?.()}
              className={el`remove-field-button`}
            />
          )}
        </div>
        <TransactionHashInputError
          isTransactionHashInvalid={p.value.length > 0 && isInvalid}
          showChainSelectUpfront={p.showChainSelectUpfront}
          isLabelInvalid={!!isLabelInvalid}
        />
        {!p.showChainSelectUpfront && couldBeTransactionHash && (
          <ChainSelectModal
            value={p.value}
            options={getPossibleChainsFromHash(p.value)}
            open={modalIsOpen}
            onOpenChange={(open) => toggleModalOpen(open)}
            onSelectChain={(selectedChain) => {
              toggleModalOpen(false);
              p.onChainChanged?.(selectedChain);
              p.onSubmitTransactionHash?.(p.value, selectedChain);
            }}
          />
        )}
      </div>
    </>
  );
}

export default TransactionHashInput;
