import { createContext } from 'react';
import { CookieConsentContextValue } from './provider';

const CookieConsentContext = createContext<CookieConsentContextValue>({
  actions: {
    setIsAccepted: () => {
      throw new Error('Not implemented');
    },
  },
  state: {
    isAccepted: undefined,
  },
});

export default CookieConsentContext;
