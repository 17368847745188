import { ApolloError } from '@apollo/client';
import { consistentCatch } from '@/utils';
import { ErrorData } from './contexts/global-error-context';
import { useErrorModal } from '.';

export default function usePopUpOnError(
  errorData?: Omit<ErrorData, 'error'>
): <T extends unknown[], U>(
  fn: (...args: T) => U,
  ...args: T
) => Promise<void | U> {
  const {
    actions: { sendError },
  } = useErrorModal();

  return consistentCatch((error) => {
    sendError({ ...errorData, error: error as ApolloError });
  });
}
