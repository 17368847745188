import { nanoid } from 'nanoid';
import { useEffect, useReducer } from 'react';
import { MAX_LABEL_LENGTH } from '@/constants';
import { ChainType } from '@/types/chain';
import { getPossibleChainsFromHash } from '@/utils/chains';
import { TransactionHashField, ValidReportTransactionHashData } from '../types';
import { reorder } from '../utils/reorder';

type UseTransactionHashInputsOptions = {
  /** Initial fields to populate */
  initialFields?: TransactionHashField[];

  /** Wheter the data for the initial fields is loading */
  isLoading?: boolean;
};

type UseTransactionHashInputsState = {
  /** The fields */
  fields: TransactionHashField[];
  /** Whether at least one of the created fields is valid */
  hasAtLeastOneValidField: boolean;
  /**
   * Whether all the (optional) labels are valid.
   * Either doesn't exist or less than max length.
   */
  hasValidLabels: boolean;

  /** Only the valid fields */
  sanitizedFields: ValidReportTransactionHashData[];
};

enum ReducerActionType {
  ADD_FIELD = 'ADD_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
  UPDATE_FIELD_CHAIN = 'UPDATE_FIELD_CHAIN',
  UPDATE_FIELD_LABEL = 'UPDATE_FIELD_LABEL',
  UPDATE_FIELD_ORDER = 'UPDATE_FIELD_ORDER',
  TOGGLE_VALIDITY = 'TOGGLE_VALIDITY',
  RESET_FIELDS = 'RESET_FIELDS',
  ADD_FIELDS = 'ADD_FIELDS',
}

type AddFieldAction = {
  type: ReducerActionType.ADD_FIELD;
  value: string;
};

type AddFieldsAction = {
  type: ReducerActionType.ADD_FIELDS;
  value: string[];
};

type RemoveFieldAction = {
  type: ReducerActionType.REMOVE_FIELD;
  index: number;
};

type UpdateFieldValueAction = {
  type: ReducerActionType.UPDATE_FIELD_VALUE;
  index: number;
  value: string;
};

type UpdateFieldChainAction = {
  type: ReducerActionType.UPDATE_FIELD_CHAIN;
  index: number;
  chain?: ChainType;
};

type UpdateFieldLabelAction = {
  type: ReducerActionType.UPDATE_FIELD_LABEL;
  index: number;
  label?: string;
};

type UpdateFieldOrderAction = {
  type: ReducerActionType.UPDATE_FIELD_ORDER;
  fromIndex: number;
  toIndex: number;
};

type ToggleValidityAction = {
  type: ReducerActionType.TOGGLE_VALIDITY;
  index: number;
  newIsValid: boolean;
};

type ResetFieldsAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: TransactionHashField[];
};

type ReducerAction =
  | AddFieldAction
  | AddFieldsAction
  | RemoveFieldAction
  | UpdateFieldValueAction
  | UpdateFieldChainAction
  | UpdateFieldLabelAction
  | UpdateFieldOrderAction
  | ToggleValidityAction
  | ResetFieldsAction;

type UseTransactionHashInputsActions = {
  addField: (initialValue?: string) => void;
  addFields: (initialValue?: string[]) => void;
  removeField: (index: number) => void;
  updateFieldValue: (index: number, value: string) => void;
  updateFieldChain: (index: number, chain?: ChainType) => void;
  updateFieldLabel: (index: number, label?: string) => void;
  updateFieldOrder: (fromIndex: number, toIndex: number) => void;
  toggleFieldIsValid: (index: number, newIsValid: boolean) => void;
};

export type UseTransactionHashInputsValue = UseTransactionHashInputsState &
  UseTransactionHashInputsActions;

const useTransactionHashInputs = (
  props?: UseTransactionHashInputsOptions
): UseTransactionHashInputsValue => {
  const [fields, dispatch] = useReducer(
    (prev: TransactionHashField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_FIELD:
          return [
            ...prev,
            {
              key: nanoid(),
              chain: undefined,
              isValid: false,
              value: action.value || '',
            },
          ];
        case ReducerActionType.ADD_FIELDS:
          return [
            ...prev.filter((field) => field.value !== ''),
            ...action.value.map(
              (value): TransactionHashField => ({
                key: nanoid(),
                chain: getPossibleChainsFromHash(value)[0],
                isValid: true,
                value,
              })
            ),
          ];
        case ReducerActionType.REMOVE_FIELD:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.UPDATE_FIELD_VALUE:
          return prev.map((field, index) =>
            index === action.index ? { ...field, value: action.value } : field
          );
        case ReducerActionType.UPDATE_FIELD_CHAIN:
          return prev.map((field, index) =>
            index === action.index ? { ...field, chain: action.chain } : field
          );
        case ReducerActionType.UPDATE_FIELD_LABEL:
          return prev.map((field, index) =>
            index === action.index ? { ...field, label: action.label } : field
          );
        case ReducerActionType.UPDATE_FIELD_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.TOGGLE_VALIDITY:
          return prev.map((field, index) =>
            index === action.index
              ? { ...field, isValid: action.newIsValid }
              : field
          );
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      {
        key: nanoid(),
        value: '',
        chain: undefined,
        isValid: false,
        label: '',
      },
    ]
  );
  const sanitizedFields = fields
    .filter((field) => field.isValid)
    .map((field) => {
      /**
       * @NOTE: We silently slice the label to the first
       * `MAX_LABEL_LENGTH` characters to not block submission.
       * We warn the user the label is too long in the UI.
       */
      const conformedLabel = field.label
        ? field.label.slice(0, MAX_LABEL_LENGTH)
        : undefined;

      return {
        id: field.id,
        chain: field.chain ?? getPossibleChainsFromHash(field.value)[0],
        hash: field.value,
        label: conformedLabel,
      };
    });

  const hasAtLeastOneValidField = sanitizedFields.length > 0;
  const hasValidLabels = sanitizedFields.every(
    ({ label }) => label === undefined || label.length < MAX_LABEL_LENGTH
  );

  const actions: UseTransactionHashInputsActions = {
    addFields: (values?: string[]) => {
      dispatch({ type: ReducerActionType.ADD_FIELDS, value: values || [] });
    },
    addField: (value?: string) => {
      dispatch({ type: ReducerActionType.ADD_FIELD, value: value || '' });
    },
    removeField: (index: number) => {
      if (index === 0) {
        throw new Error('Cannot remove the first transaction hash input field');
      }
      dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
    },
    updateFieldValue: (index: number, value: string) => {
      dispatch({ type: ReducerActionType.UPDATE_FIELD_VALUE, index, value });
    },
    updateFieldChain: (index: number, chain?: ChainType) => {
      dispatch({ type: ReducerActionType.UPDATE_FIELD_CHAIN, index, chain });
    },
    updateFieldLabel: (index: number, label?: string) => {
      dispatch({ type: ReducerActionType.UPDATE_FIELD_LABEL, index, label });
    },
    updateFieldOrder: (fromIndex: number, toIndex: number) => {
      dispatch({
        type: ReducerActionType.UPDATE_FIELD_ORDER,
        fromIndex,
        toIndex,
      });
    },
    toggleFieldIsValid: (index: number, newIsValid: boolean) => {
      dispatch({ type: ReducerActionType.TOGGLE_VALIDITY, index, newIsValid });
    },
  };

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    fields,
    hasAtLeastOneValidField,
    hasValidLabels,
    sanitizedFields,
    ...actions,
  };
};

export default useTransactionHashInputs;
