import React, { ReactElement, ReactNode } from 'react';
import useHandlePostAuth from '@/hooks/auth/useHandlePostAuth';
import { AuthFlow, PostAuthModal } from '../auth0';
import { useAuthModal } from '.';

type AuthModalProps = {
  children: ReactNode;
};

export default function AuthModalProvider({
  children,
}: AuthModalProps): ReactElement {
  useHandlePostAuth();

  const {
    state: { isAuthModalOpen, isPostAuthModalOpen, redirectTo },
    actions: { setIsAuthModalOpen },
  } = useAuthModal();

  return (
    <>
      {children}
      {isAuthModalOpen && (
        <AuthFlow onOpenChange={setIsAuthModalOpen} returnTo={redirectTo} />
      )}
      {isPostAuthModalOpen && <PostAuthModal />}
    </>
  );
}
