import { nanoid } from 'nanoid';
import { AddressDomainField, SubmitReportFormValues } from '../types';

export const getInitialAddressDomainFieldsFromFormValues = (
  props?: SubmitReportFormValues
): AddressDomainField[] | undefined => {
  if (!props) return undefined;

  const fields = props.addresses?.map((reportAddress) => ({
    key: nanoid(),
    id: reportAddress.id ?? undefined,
    value: reportAddress.address ?? reportAddress.domain ?? '',
    chain: reportAddress.chain ?? undefined,
    label: reportAddress.label ?? undefined,
    isValid: true,
  }));

  return fields;
};
