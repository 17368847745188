import { useCallback } from 'react';
import { useRouter } from 'next/router';
import { useAuthModal } from '@/features/auth';
import { useMe } from '.';

type UseAuthRequiredActionState = {
  isLoggedIn: boolean;
  isLoading: boolean;
};

type UseAuthRequiredActionActions = {
  openAuthModal: (redirectTo?: string) => void;
};

type UseAuthRequiredActionValue = UseAuthRequiredActionActions &
  UseAuthRequiredActionState;

/**
 * Hook to guard an action from unauthorized access.
 */
const useAuthRequiredAction = (): UseAuthRequiredActionValue => {
  const { me, loading } = useMe();

  const {
    actions: { setIsAuthModalOpen, setRedirectTo },
  } = useAuthModal();

  const { asPath } = useRouter();

  const openAuthModal = useCallback(() => {
    setRedirectTo(asPath);
    setIsAuthModalOpen(true);
  }, [asPath, setIsAuthModalOpen, setRedirectTo]);

  return {
    openAuthModal,
    isLoggedIn: !!me && me.isVerified,
    isLoading: loading,
  };
};

export default useAuthRequiredAction;
