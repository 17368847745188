import { nanoid } from 'nanoid';
import { LossField, SubmitReportFormValues } from '../types';

export const getInitialLossFieldsFromFormValues = (
  props?: SubmitReportFormValues
): LossField[] | undefined => {
  if (!props) return undefined;

  const fields = props.losses?.map((loss) => ({
    key: nanoid(),
    id: loss.id ?? undefined,
    amount: loss.amount ?? '',
    asset: loss.asset,
  }));

  return fields && fields.length > 0 ? fields : undefined;
};
