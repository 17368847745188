import { useUser } from '@auth0/nextjs-auth0';
import { useEffect } from 'react';
import { MeQueryResult, useMeQuery } from '@/generated/graphql';
import { clearAuthStorage, getCurrentMe, storeMe } from '@/utils/auth';

export type Me = NonNullable<MeQueryResult['data']>['me'];

type UseMeOptions = {
  /**
   * Whether to fetch with network-only fetch policy
   * @default false
   */
  networkOnly?: boolean;
};

type UseMeState = {
  me: Me | null;
  loading: boolean;
  refetch: () => Promise<Me | null>;
};

const DEFAULT_OPTIONS = {
  networkOnly: false,
};

const useMe = (props: UseMeOptions = DEFAULT_OPTIONS): UseMeState => {
  const { user: auth0Me, isLoading: isUserLoading } = useUser();

  const {
    data,
    loading: isMeLoading,
    refetch: refetchMeQuery,
  } = useMeQuery({
    fetchPolicy: props.networkOnly ? 'network-only' : 'cache-first',
    nextFetchPolicy: 'cache-only',
    skip: !auth0Me,
    onError: clearAuthStorage,
  });

  useEffect(() => {
    if (data?.me) {
      storeMe({ me: data.me, remember: true });
    }
  }, [data?.me]);

  const isLoading = isUserLoading || isMeLoading;

  const refetch = async () => {
    const { data } = await refetchMeQuery();

    if (data?.me) {
      storeMe({ me: data.me, remember: true });
    }

    return data?.me;
  };

  return {
    me: isLoading ? getCurrentMe() : data?.me,
    loading: isLoading,
    refetch,
  };
};

export default useMe;
