import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { ChainIcon, Text } from '@/components';
import { Option, Select } from '@/components/select';
import {
  ChainDiscoverabilityProps,
  ChainType,
  getDisplayStringForChainType,
  getTickerForChain,
  isChainDiscoverable,
} from '@/types/chain';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type ChainSelectProps = StyleProps &
  ChainDiscoverabilityProps & {
    /** The chain the chain select should be set to */
    value?: ChainType;

    /** Handler to call when the selected value changes */
    onChange: (value?: ChainType) => void;

    /** Whether the chain select is disabled. @default false */
    isDisabled?: boolean;

    /** The chains that should be disabled as options in the menu. @default []*/
    disabledOptions?: ChainType[];

    /** The select's placeholder */
    placeholder?: string;

    /**
     * This is a hack to focus search input after chain select.
     * This works because Listbox.Button gets focus when the listbox is closed.
     * {@link} https://headlessui.dev/react/listbox#focus-management
     */
    onFocusChange?: () => void;
  };

const ROOT = makeRootClassName('ChainSelect');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  isDisabled: false,
  disabledOptions: [] as ChainType[],
  placeholder: 'Select a chain',
  includeUndiscoverableChains: false,
} as const;

function ChainSelect(props: ChainSelectProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const chainContent = (type: ChainType): ReactElement => (
    <div className={el`chain-info`}>
      <ChainIcon chainType={type} size="small" />
      <Text type="body-md" className={el`selected-label`}>
        {`${getDisplayStringForChainType(type)} (${getTickerForChain(type)})`}
      </Text>
    </div>
  );

  const renderSelectedChain = (value: ChainType | undefined): ReactElement => (
    <>{value ? <>{chainContent(value)}</> : <></>}</>
  );

  const optionIsDisabled = (value: ChainType): boolean =>
    p.disabledOptions.includes(value);

  return (
    <Select
      placeholder={p.placeholder}
      triggerLabel="Select address or domain"
      isDisabled={p.isDisabled}
      value={p.value}
      renderValue={renderSelectedChain}
      onChange={p.onChange}
      className={clsx(ROOT, p.className)}
      onFocusChange={p.onFocusChange}
    >
      {Object.values(ChainType)
        .filter((chainType) => {
          return (
            p.includeUndiscoverableChains || isChainDiscoverable(chainType)
          );
        })
        .map((type) => (
          <Option
            key={`chain-select-option-${type}`}
            value={type}
            isDisabled={optionIsDisabled(type)}
          >
            {chainContent(type)}
          </Option>
        ))}
    </Select>
  );
}

export default ChainSelect;
