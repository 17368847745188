import clsx from 'clsx';
import { isEqual } from 'lodash';
import React, { ReactElement, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { queryTypes, useQueryState } from 'next-usequerystate';
import {
  BackButton,
  Button,
  ClientOnly,
  DescriptionEditor,
  Modal,
  Text,
  TextField,
} from '@/components';
import type { ModalRootProps } from '@/components/modal';
import FieldWithError from '@/features/formik/components/FieldWithError';
import { ScamCategory } from '@/types/scam-categories';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Desktop } from '@/utils/responsive';
import { PreAuthModal } from '@/features';
import { useMe } from '@/hooks';
import { ReportSourceKind } from '@/generated/graphql';
import { Me } from '@/utils/auth';
import useAddressDomainInputs from '../../hooks/useAddressDomainInputs';
import useCompromiseIndicators from '../../hooks/useCompromiseIndicators';
import useSupportOptIn from '../../hooks/useSupportOptIn';
import useEvidenceUploads from '../../hooks/useEvidenceUploads';
import useKnownScammers from '../../hooks/useKnownScammers';
import useLossFields from '../../hooks/useLossFields';
import {
  FormValuesFromReportFragment,
  SubmitReportFormValues,
} from '../../types';
import { getInitialAddressDomainFieldsFromFormValues } from '../../utils/getInitialAddressDomainFieldsFromFormValues';
import { getInitialTransactionHashFieldsFromFormValues } from '../../utils/getInitialTransactionHashFieldsFromFormValues';
import { getInitialCompromiseIndicatorFieldsFromFormValues } from '../../utils/getInitialCompromiseIndicatorFieldsFromFormValues';
import { getInitialTokenIdFieldsFromFormValues } from '../../utils/getInitialTokenIdFieldsFromFormValues';
import { getInitialEvidenceUploadFieldsFromFormValues } from '../../utils/getInitialEvidenceUploadFieldsFromFormValues';
import { getInitialKnownScammerFieldsFromFormValues } from '../../utils/getInitialKnownScammerFieldsFromFormValues';
import { getInitialLossFieldsFromFormValues } from '../../utils/getInitialLossFieldsFromFormValues';
import { MultiAddressForm } from '../multi-address-form';
import { MultiCompromiseIndicatorForm } from '../multi-compromise-indicator-form';
import { MultiKnownScammerForm } from '../multi-known-scammer-form';
import { MultiLossesForm } from '../multi-losses-form';
import { RequestSupportForm } from '../request-support-form';
import { ScamCategorySelect } from '../scam-category-select';
import { UploadEvidenceForm } from '../upload-evidence-form';
import { WrapWithLabel } from '../wrap-with-label';
import { AddressHelpModal } from '../address-help-modal';
import { getInitialSupportOptInFromFormValues } from '../../utils/getInitialSupportOptInFromFormValues';
import { ScamQuiz } from '../scam-quiz';
import { MultiTokenIdForm } from '../multi-token-id-form';
import useTokenIds from '../../hooks/useTokenIds';
import {
  getLabelForReportFormSection,
  getReportFormLocalState,
  ReportFormSection,
  setReportFormLocalState,
} from './utils';
import { MistakenScammerErrorMessage } from './error-messages';
import { MultiTransactionHashForm } from '../multi-transaction-hash-form';
import useTransactionHashInputs from '../../hooks/useTransactionHashInputs';
import * as RadioGroup from '@radix-ui/react-radio-group';
import useDomainInputs from '../../hooks/useDomainInputs';
import { TransactionHashInfoModal } from '../transaction-hash-info-modal';

enum REPORT_SHARING_TYPES {
  PUBLIC = 'public',
  PRIVATE = 'private',
}


export type ReportFormProps = StyleProps & {
  /**
   * Initial values to fill the form with. Useful for edit mode or pre-filling.
   */
  initialValues?: SubmitReportFormValues & {
    scamDescription: string;
  };

  /**
   * Whether the form is in edit mode.
   * @default false
   */
  isEditMode?: boolean;

  /** Wheter the initial data is loading for the report which
   *  is currently being edited*/
  isLoading?: boolean;

  /** Whether the form is submitting to the backend */
  isSubmitting?: boolean;

  /** Handler to call when user submits all fields */
  onSubmitForm: (props: SubmitReportFormValues) => void;

  /** Handler to call when the user deletes this form. Only works in EditMode.*/
  onDeleteReport?: () => void;
};

const ROOT = makeRootClassName('ReportForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = { isEditMode: false } as const;
const MIN_DESCRIPTION_LENGTH = 10;
const MAX_DESCRIPTION_LENGTH = 3000;
const MAX_OTHER_DESCRIPTION_LENGTH = 24;

const noOp = () => {};

function DeleteModal(
  props: Pick<ModalRootProps, 'open' | 'onOpenChange'> &
    Pick<ReportFormProps, 'onDeleteReport' | 'isLoading' | 'isSubmitting'>
): ReactElement {
  return (
    <Modal.Root open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.Trigger hasReactAriaChildren>
        <Button
          variant="warning"
          isDisabled={props.isLoading || props.isSubmitting}
        >
          Delete Report
        </Button>
      </Modal.Trigger>
      <Modal.Content size="small" className={el`delete-modal`}>
        <Modal.Header hasCloseButton title="Delete Report" />
        <div className={el`body`}>
          <p>This action can&apos;t be undone</p>
        </div>
        <div className={el`actions`}>
          <Button
            variant="warning"
            onPress={() => {
              props.onDeleteReport?.();
              props.onOpenChange?.(false);
            }}
          >
            Delete Report
          </Button>
          <Button
            variant="secondary"
            onPress={() => props.onOpenChange?.(false)}
          >
            Cancel
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}

function VerifyEmailModal(
  props: { onRetrySubmit: () => void; isSubmitting: boolean } & Pick<
    ModalRootProps,
    'open' | 'onOpenChange'
  >
): ReactElement {
  return (
    <Modal.Root open={props.open} onOpenChange={props.onOpenChange}>
      <Modal.Content size="small" className={el`verify-email-modal`}>
        <Modal.Header hasCloseButton title="Submit Report" />
        <div className={el`body`}>
          <Text type="body-md">
            We were unable to process your submission because you have not
            verified your email address yet.
          </Text>
          <Text type="body-md">
            Please check your email inbox for a verification email from us.
            Click on retry once email is verified.
          </Text>
        </div>
        <div className={el`actions`}>
          <Button
            variant="secondary"
            onPress={props.onRetrySubmit}
            isDisabled={props.isSubmitting}
          >
            Retry
          </Button>
        </div>
      </Modal.Content>
    </Modal.Root>
  );
}

type ReportFormVerticalStepperProps = {
  index: number;
  label: string;
  isActive?: boolean;
  isCompleted?: boolean;
};

function VerticalStep(p: ReportFormVerticalStepperProps) {
  return (
    <div
      className={clsx(el`step`, {
        isActive: p.isActive,
        isCompleted: p.isCompleted,
      })}
    >
      <div className={el`stepper`}>
        <div className={el`circle`}>{p.index}</div>
        <div className={el`line`}></div>
      </div>
      <Text type="body-md" className={el`label`}>
        {p.label}
      </Text>
    </div>
  );
}

type OnSubmitOptions = {
  me?: Me;
  isPrivate?: boolean;
};

function ReportForm(props: ReportFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const { asPath } = useRouter();
  const { me, refetch: refetchMe } = useMe();

  const [formStep, setFormStep] = useQueryState<ReportFormSection>(
    'step',
    queryTypes
      .stringEnum<ReportFormSection>(Object.values(ReportFormSection))
      .withDefault(ReportFormSection.CASE_DETAILS)
  );

  const [showSubmitAsGuestOrLoginModal, setShowSubmitAsGuestOrLoginModal] =
    useState(false);

  const [showVerifyEmailModal, setShowVerifyEmailModal] = useState(false);
  const [isRetryingSubmit, setIsRetryingSubmit] = useState(false);

  const [showSubmitPrivatelyModal, setShowSubmitPrivatelyModal] =
    useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const reportFormLocalState = getReportFormLocalState();
  const initialValues: FormValuesFromReportFragment | undefined | null =
    p.isEditMode || p.initialValues || !reportFormLocalState
      ? p.initialValues
      : reportFormLocalState;

  const {
    fields: addressDomainFields,
    hasAtLeastOneValidField: hasValidAddress,
    hasValidLabels,
    hasMistakenScammerAddress,
    hasMistakenScammerURL,
    checkingMistakenAddressesAndDomains,
    sanitizedFields: sanitizedAddressDomainFields,
    addField: addAddressDomainField,
    addFields: addAddressDomainFields,
    removeField: removeAddressDomainField,
    updateFieldValue: updateAddressDomainFieldValue,
    updateFieldChain: updateAddressDomainFieldChain,
    updateFieldOrder: updateAddressDomainFieldOrder,
    toggleFieldIsValid: toggleAddressDomainFieldIsValid,
    updateFieldLabel: updateAdressLabel,
  } = useAddressDomainInputs({
    isLoading: p.isLoading,
    initialFields: getInitialAddressDomainFieldsFromFormValues(initialValues),
  });


  const {
    fields: domainFields,
    hasAtLeastOneValidField: hasValidUrl,
    hasValidLabels: hasValidUrlLabels,
    hasMistakenScammerURL: hasMistakenScammerDomain,
    checkingMistakenDomains,
    sanitizedFields: sanitizedDomainFields,
    addUrlField,
    removeUrlField,
    updateUrlFieldValue,
    updateUrlFieldOrder,
    toggleUrlFieldIsValid,
  } = useDomainInputs({
    isLoading: p.isLoading,
    initialFields: getInitialAddressDomainFieldsFromFormValues(initialValues),
  });

  const {
    fields: transactionHashFields,
    hasAtLeastOneValidField: hasValidTransactionHash,
    hasValidLabels: hasValidTransactionHashLabels,
    sanitizedFields: sanitizedTransactionHashFields,
    addField: addTransactionHashField,
    addFields: addTransactionHashFields,
    removeField: removeTransactionHashField,
    updateFieldValue: updateTransactionHashFieldValue,
    updateFieldChain: updateTransactionHashFieldChain,
    updateFieldOrder: updateTransactionHashFieldOrder,
    toggleFieldIsValid: toggleTransactionHashFieldIsValid,
    updateFieldLabel: updateTransactionHashLabel,
  } = useTransactionHashInputs({
    isLoading: p.isLoading,
    initialFields: getInitialTransactionHashFieldsFromFormValues(initialValues),
  });

  const {
    fields: compromiseIndicatorFields,
    sanitizedCompromiseIndicatorFields,
    addField: addCompromiseIndicatorField,
    removeField: removeCompromiseIndicatorField,
    updateFieldValue: updateCompromiseIndicatorFieldValue,
    updateFieldOrder: updateCompromiseIndicatorFieldOrder,
  } = useCompromiseIndicators({
    isLoading: p.isLoading,
    initialFields:
      getInitialCompromiseIndicatorFieldsFromFormValues(initialValues),
  });

  const {
    fields: tokenIdFields,
    sanitizedTokenIdFields,
    addField: addTokenIdField,
    addFields: addTokenIdFields,
    removeField: removeTokenIdField,
    updateFieldValue: updateTokenIdFieldValue,
    updateFieldOrder: updateTokenIdFieldOrder,
  } = useTokenIds({
    isLoading: p.isLoading,
    initialFields: getInitialTokenIdFieldsFromFormValues(initialValues),
  });

  const [category, setCategory] = useState<ScamCategory | null>(
    initialValues?.category ?? null
  );
  const [categoryTouched, setCategoryTouched] = useState(false);

  const [otherCategoryDescription, updateOtherCategoryDescription] = useState(
    initialValues?.otherCategoryDescription ?? ''
  );
  const [scamDescription, updateScamDescription] = useState(
    initialValues?.scamDescription ?? ''
  );
  const [lexicalSerializedDescription, updateLexicalSerializedDescription] =
    useState(initialValues?.lexicalSerializedDescription ?? undefined);

  const {
    fields: lossFields,
    sanitizedLossFields,
    addField: addLossField,
    removeField: removeLossField,
    updateAmount: updateLossAmount,
    updateOrder: updateLossOrder,
  } = useLossFields({
    isLoading: p.isLoading,
    initialFields: getInitialLossFieldsFromFormValues(initialValues),
  });

  const {
    fields: evidenceFields,
    successfulEvidenceUploads,
    addField: addEvidenceField,
    removeField: removeEvidenceField,
    addFileToField,
    isFieldUploading,
    removeFileFromField,
    updateSourceOfField,
    updateFieldOrder: updateEvidenceFieldOrder,
  } = useEvidenceUploads({
    isLoading: p.isLoading,
    initialFields: getInitialEvidenceUploadFieldsFromFormValues(initialValues),
  });

  const {
    addInfoField,
    addScammer,
    knownScammerFields,
    removeInfoField,
    removeScammer,
    updateInfoContact,
    updateInfoOrder,
    updateInfoType,
    updateName: updateScammerName,
    updateScammerOrder,
    sanitizedScammers,
  } = useKnownScammers({
    isLoading: p.isLoading,
    initialFields: getInitialKnownScammerFieldsFromFormValues(initialValues),
  });

  const supportOptInFormProps = useSupportOptIn({
    isLoading: p.isLoading,
    initialValues: getInitialSupportOptInFromFormValues(initialValues),
  });

  const [deleteModalOpen, setDeleteModalOpen] = useState(false);

  const handleDeleteModalOpen = (open: boolean) => setDeleteModalOpen(open);

  const [isDescriptionTouched, setIsDescriptionTouched] = useState(false);
  const [isCategoryDescriptionTouched, setIsCategoryDescriptionTouched] =
    useState(false);

  // @NOTE TextArea needs useLayoutEffect, which needs to be done once
  // client is loaded to not break SSR.
  // https://gist.github.com/gaearon/e7d97cdf38a2907924ea12e4ebdf3c85
  const [showTextArea, setShowTextArea] = useState(false);

  useEffect(() => {
    setShowTextArea(true);
  }, []);

  useEffect(() => {
    if (initialValues?.category) {
      setCategory(initialValues.category);
    }
  }, [initialValues?.category]);

  useEffect(() => {
    if (initialValues?.otherCategoryDescription) {
      updateOtherCategoryDescription(initialValues.otherCategoryDescription);
    }
  }, [initialValues?.otherCategoryDescription]);

  useEffect(() => {
    if (initialValues?.scamDescription) {
      updateScamDescription(initialValues.scamDescription);
    }
  }, [initialValues?.scamDescription]);

  const isOtherCategory = category === ScamCategory.OTHER;

  const isCategoryDescriptionValid = isOtherCategory
    ? otherCategoryDescription !== '' &&
    otherCategoryDescription.length <= MAX_OTHER_DESCRIPTION_LENGTH
    : true;

  const isDescriptionValid =
    scamDescription.trim().length >= MIN_DESCRIPTION_LENGTH &&
    scamDescription.length <= MAX_DESCRIPTION_LENGTH;

  const isSupportOptInValid =
    supportOptInFormProps.isNameValid && supportOptInFormProps.isEmailValid;

  const isContactDetailsForLawEnforcementValid =
    supportOptInFormProps.agreedToBeContactedByLawEnforcement
      ? supportOptInFormProps.isNameValid &&
      supportOptInFormProps.isEmailValid &&
      supportOptInFormProps.phoneNumber &&
      supportOptInFormProps.country &&
      supportOptInFormProps.state &&
      supportOptInFormProps.city?.label &&
      supportOptInFormProps.zipCode
      : true;

  const isValidSubmission =
    (hasValidAddress || hasValidUrl) &&
    (hasValidLabels || hasValidUrlLabels) &&
    isDescriptionValid &&
    isCategoryDescriptionValid &&
    category !== null &&
    isSupportOptInValid &&
    isContactDetailsForLawEnforcementValid;

  const getSubmitFormValuesFromState = ():
    | SubmitReportFormValues
    | undefined => {
    // You can't have submit form values unless you pass validation
    if (!isValidSubmission) return undefined;

    const currentState: SubmitReportFormValues = {
      isPrivate: false,
      source: ReportSourceKind.CHAINABUSE,
      addresses: [...sanitizedAddressDomainFields, ...sanitizedDomainFields],
      losses: sanitizedLossFields,
      category,
      ...(isOtherCategory && { otherCategoryDescription }),
      lexicalSerializedDescription: lexicalSerializedDescription,
      evidence: successfulEvidenceUploads,
      agreedToBeContacted: {
        agreed: supportOptInFormProps.agreed,
        agreedToBeContactedByLawEnforcement:
          supportOptInFormProps.agreedToBeContactedByLawEnforcement,
        name: supportOptInFormProps.name,
        email: supportOptInFormProps.email,
        phoneNumber: supportOptInFormProps.phoneNumber,
        country: supportOptInFormProps.country?.label,
        countryCode: supportOptInFormProps.country?.isoCode,
        state: supportOptInFormProps.state?.label,
        stateCode: supportOptInFormProps.state?.isoCode,
        city: supportOptInFormProps.city?.label,
        zipCode: supportOptInFormProps.zipCode,
      },
      knownScammers: sanitizedScammers,
      compromiseIndicators: sanitizedCompromiseIndicatorFields,
      tokens: sanitizedTokenIdFields,
      transactionHashes: sanitizedTransactionHashFields,
    };
    return currentState;
  };

  const evaluateIfChangedFromInitial = (): boolean => {
    // @TODO figure out how to eval if there are changes;
    return !isEqual(initialValues, getSubmitFormValuesFromState());
  };

  const hasChangesFromInitialValues =
    !!initialValues && evaluateIfChangedFromInitial();

  const canSubmit =
    !p.isLoading &&
    !p.isSubmitting &&
    !hasMistakenScammerAddress &&
    (!hasMistakenScammerURL && !hasMistakenScammerDomain)&&
    (p.isEditMode
      ? isValidSubmission && hasChangesFromInitialValues
      : isValidSubmission);

  const [isNextOrSubmitButtonDisabled, setIsNextOrSubmitButtonDisabled] =
    useState(true);
  
  const [reportSharingType, setReportSharingType] = useState(REPORT_SHARING_TYPES.PUBLIC);

  useEffect(() => {
    if (
      formStep === ReportFormSection.CASE_DETAILS &&
      isCategoryDescriptionValid &&
      category !== null
    ) {
      setIsNextOrSubmitButtonDisabled(false);
    } else if (
      formStep === ReportFormSection.SCAMMER_INFORMATION &&
      (hasValidAddress || hasValidUrl) &&
      (hasValidLabels || hasValidUrlLabels) &&
      !hasMistakenScammerAddress &&
      (!hasMistakenScammerURL && !hasMistakenScammerDomain) &&
      (!checkingMistakenAddressesAndDomains && !checkingMistakenDomains)
    ) {
      setIsNextOrSubmitButtonDisabled(false);
    } else if (
      formStep === ReportFormSection.DESCRIPTION &&
      isDescriptionValid
    ) {
      setIsNextOrSubmitButtonDisabled(false);
    } else {
      setIsNextOrSubmitButtonDisabled(!canSubmit);
    }
  }, [
    canSubmit,
    category,
    checkingMistakenAddressesAndDomains,
    checkingMistakenDomains,
    formStep,
    hasMistakenScammerAddress,
    hasMistakenScammerURL,
    hasMistakenScammerDomain,
    hasValidAddress,
    hasValidUrl,
    hasValidLabels,
    hasValidUrlLabels,
    isCategoryDescriptionValid,
    isDescriptionValid,
  ]);

  function getFormStepIndex(step: ReportFormSection) {
    const values = Object.values(ReportFormSection);
    return values.indexOf(step);
  }

  const handleNextFormStepOrSubmit = (options?: OnSubmitOptions) => {
    if (formStep !== ReportFormSection.CONTACT_SUPPORT) {
      const currentStepIndex = getFormStepIndex(formStep);
      const nextFormStep =
        Object.values(ReportFormSection)[currentStepIndex + 1];
      if (nextFormStep) {
        setFormStep(nextFormStep, {
          shallow: true,
          scroll: true,
        });
      }
      return;
    }

    const currentState = getSubmitFormValuesFromState();
    const user = options?.me || me;

    if (!currentState) {
      return;
    }

    if (options?.isPrivate !== undefined) {
      currentState.isPrivate = options.isPrivate;
    }

    if (
      !user &&
      !showSubmitAsGuestOrLoginModal &&
      !!process.env.NEXT_PUBLIC_REQUIRE_AUTH_TO_REPORT_ENABLED
    ) {
      setReportFormLocalState({
        ...currentState,
        scamDescription,
      });

      setShowSubmitPrivatelyModal(false);
      setShowSubmitAsGuestOrLoginModal(true);
      return;
    }

    if (user && !user.isVerified) {
      setReportFormLocalState({
        ...currentState,
        scamDescription,
      });

      setShowSubmitPrivatelyModal(false);
      setShowVerifyEmailModal(true);
      return;
    }

    p.onSubmitForm(currentState);
  };

  function handleRetrySubmit() {
    setIsRetryingSubmit(true);

    refetchMe()
      .then((me) => {
        if (me && me.isVerified) {
          setShowVerifyEmailModal(false);
          handleNextFormStepOrSubmit({ me });
        }
      })
      .catch(() => {
        // no-op
      })
      .finally(() => setIsRetryingSubmit(false));
  }

  function onBack() {
    setFormStep(
      Object.values(ReportFormSection)[getFormStepIndex(formStep) - 1],
      {
        shallow: true,
        scroll: true,
      }
    );
  }

  function handleSubmitReport() {
    if(reportSharingType == REPORT_SHARING_TYPES.PUBLIC) {
      handleSubmitPublicReport();
    } else {
      handleSubmitPrivateReport();
    }
  }
  function handleSubmitPublicReport() {
    setIsPrivate(false);
    handleNextFormStepOrSubmit({ isPrivate: false });
  }

  function handleSubmitPrivateReport() {
    setIsPrivate(true);
    handleNextFormStepOrSubmit({ isPrivate: true });
  }

  function handleReportSharingTypeChange(event: React.ChangeEvent<HTMLInputElement>) {
    const inputElement = event.target;
    // Access the value property
    const value = inputElement.value as REPORT_SHARING_TYPES;
    setReportSharingType(value);
  }

  return (
    <>
      {/*
        @HACK: I had to wrap the stepper with ClientOnly because
        navigating to ?step=CONTACT_SUPPORT messes up the stepper
      */}
      <ClientOnly>
        <Desktop>
          <div className={el`stepper-wrapper`}>
            {Object.values(ReportFormSection).map((value, i) => (
              <VerticalStep
                key={i}
                index={i + 1}
                label={getLabelForReportFormSection(value)}
                isActive={formStep === value}
                isCompleted={
                  getFormStepIndex(formStep) > getFormStepIndex(value)
                }
              />
            ))}
          </div>
        </Desktop>
      </ClientOnly>

      {showSubmitAsGuestOrLoginModal && (
        <PreAuthModal
          hasTrigger={false}
          returnTo={asPath}
          shouldStartOpen
          onOpenChange={setShowSubmitAsGuestOrLoginModal}
          onReportAsGuest={() =>
            handleNextFormStepOrSubmit({
              isPrivate,
            })
          }
        />
      )}

      {showVerifyEmailModal && (
        <VerifyEmailModal
          open={showVerifyEmailModal}
          onOpenChange={(open) => setShowVerifyEmailModal(open)}
          isSubmitting={isRetryingSubmit}
          onRetrySubmit={handleRetrySubmit}
        />
      )}

      <div className={clsx(ROOT, p.className)}>
        {formStep === ReportFormSection.CASE_DETAILS && (
          <>
            <WrapWithLabel label="What happened?" isRequired>
              <FieldWithError
                error={
                  !category && categoryTouched
                    ? `Please select a type of scam`
                    : undefined
                }
              >
                <ScamCategorySelect
                  value={category}
                  isDisabled={p.isLoading || p.isSubmitting}
                  onChange={setCategory}
                  onFocusChange={() => setCategoryTouched(true)}
                  validationState={
                    !category && categoryTouched ? 'invalid' : undefined
                  }
                />
              </FieldWithError>

              <ScamQuiz onSelectCategory={setCategory} />

              <WrapWithLabel
                size="small"
                label="If other, please specify"
                isRequired={isOtherCategory}
              >
                <FieldWithError
                  error={
                    isCategoryDescriptionTouched && !isCategoryDescriptionValid
                      ? `Please specify the new category in less than ${MAX_OTHER_DESCRIPTION_LENGTH} characters.`
                      : undefined
                  }
                >
                  <TextField
                    aria-label="Enter scam category, if other"
                    placeholder="Type of scam"
                    isDisabled={
                      !isOtherCategory || p.isLoading || p.isSubmitting
                    }
                    value={otherCategoryDescription}
                    onChange={updateOtherCategoryDescription}
                    onBlur={() => setIsCategoryDescriptionTouched(true)}
                    validationState={
                      isCategoryDescriptionTouched &&
                        !isCategoryDescriptionValid
                        ? 'invalid'
                        : undefined
                    }
                    maxLength={MAX_OTHER_DESCRIPTION_LENGTH}
                  />
                </FieldWithError>
              </WrapWithLabel>
            </WrapWithLabel>

            <WrapWithLabel label="Evidence" isOptional>
              <div>
                <Text className={el`section-description`} type="body-md">
                  Upload any screenshots or images that provide evidence of this
                  case. For your own safety, make sure you do not include or
                  blur out any personal information.
                </Text>
                <UploadEvidenceForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  fields={evidenceFields}
                  onAddField={addEvidenceField}
                  onRemoveField={removeEvidenceField}
                  onAddFile={addFileToField}
                  isFieldUploading={isFieldUploading}
                  onRemoveFile={removeFileFromField}
                  onUpdateSource={updateSourceOfField}
                  onUpdateFieldOrder={updateEvidenceFieldOrder}
                />
              </div>
            </WrapWithLabel>

            <WrapWithLabel label="Report Sharing">
              <form>
                <RadioGroup.Root
                  className="RadioGroupRoot"
                  defaultValue={reportSharingType}
                  name="report-sharing"
                  onChange={handleReportSharingTypeChange}
                >
                  <div style={{ display: 'flex' }}>
                    <div className="radio-button-container">
                      <RadioGroup.Item className="RadioGroupItem" value={REPORT_SHARING_TYPES.PUBLIC}>
                        <RadioGroup.Indicator className="RadioGroupIndicator" />
                      </RadioGroup.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                      <label className="Label" htmlFor={REPORT_SHARING_TYPES.PUBLIC}>
                        Post report on Chainabuse.com
                      </label>
                      <Text className={el`section-description`}>
                        Most users choose to make their report public to alert the community. All personal information will remain private.
                        Chainabuse will never share your personal details on the platform.
                      </Text>
                    </div>
                  </div>
                  <div style={{ display: 'flex' }}>
                    <div className="radio-button-container">
                      <RadioGroup.Item className="RadioGroupItem" value={REPORT_SHARING_TYPES.PRIVATE}>
                        <RadioGroup.Indicator className="RadioGroupIndicator" />
                      </RadioGroup.Item>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: '8px' }}>
                      <label className="Label" htmlFor={REPORT_SHARING_TYPES.PRIVATE}>
                        Submit report privately
                      </label>
                      <Text className={el`section-description`}>
                        Private reports are not visible and shared with Law Enforcements Partners only.
                        Choose this option for sensitive reports tied to active investigations.
                      </Text>
                    </div>
                  </div>
                </RadioGroup.Root>
              </form>
            </WrapWithLabel>
          </>
        )}

        {formStep === ReportFormSection.SCAMMER_INFORMATION && (
          <>
            <WrapWithLabel
              label="Blockchain address of the scammer"
              cta={<AddressHelpModal />}
              isRequired
            >
              <div>
                <Text className={el`section-description`} type="body-md">
                This helps investigate the case and track the stolen funds.
                </Text>
                <MultiAddressForm
                  placeholder="Enter address"
                  addButtonText='Add address'
                  isDisabled={p.isLoading || p.isSubmitting}
                  addressDomainFields={addressDomainFields}
                  onAddField={addAddressDomainField}
                  onAddFields={addAddressDomainFields}
                  onRemoveField={removeAddressDomainField}
                  onUpdateFieldValue={updateAddressDomainFieldValue}
                  updateFieldOrder={updateAddressDomainFieldOrder}
                  includeUndiscoverableChains
                  onUpdateFieldChain={updateAddressDomainFieldChain}
                  onToggleFieldIsValid={toggleAddressDomainFieldIsValid}
                  updateAddressLabel={updateAdressLabel}
                />
              </div>
            </WrapWithLabel>
            <WrapWithLabel
              label="URL(s) used by the scammer, if any"
              isRequired
            >
              <div>
                <Text className={el`section-description`} type="body-md">
                  Enter any web domains used by the scammer.
                </Text>
                <MultiAddressForm
                  showUrlInput
                  addButtonText='Add Domain'
                  placeholder="Enter URL"
                  isDisabled={p.isLoading || p.isSubmitting}
                  addressDomainFields={domainFields}
                  onAddField={addUrlField}
                  onRemoveField={removeUrlField}
                  onUpdateFieldValue={updateUrlFieldValue}
                  updateFieldOrder={updateUrlFieldOrder}
                  onToggleFieldIsValid={toggleUrlFieldIsValid}
                  onAddFields={noOp}
                  onUpdateFieldChain={noOp}
                  updateAddressLabel={noOp}
                />
              </div>
            </WrapWithLabel>
            <WrapWithLabel label="Stolen Token ID(s)" isOptional>
              <>
                <Text className={el`section-description`} type="body-md">
                  Provide the ID of the stolen token if applicable
                </Text>
                <MultiTokenIdForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  tokenIdFields={tokenIdFields}
                  onAddField={addTokenIdField}
                  onAddFields={addTokenIdFields}
                  onRemoveField={removeTokenIdField}
                  onUpdateFieldValue={updateTokenIdFieldValue}
                  onUpdateFieldOrder={updateTokenIdFieldOrder}
                />
              </>
            </WrapWithLabel>

            <WrapWithLabel label={'Scammer IP address'} isOptional>
              <div>
                <Text className={el`section-description`} type="body-md">
                  Provide any known IP addresses the scammer has used.
                </Text>
                <MultiCompromiseIndicatorForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  compromiseIndicatorFields={compromiseIndicatorFields}
                  onAddField={addCompromiseIndicatorField}
                  onRemoveField={removeCompromiseIndicatorField}
                  onUpdateFieldValue={updateCompromiseIndicatorFieldValue}
                  onUpdateFieldOrder={updateCompromiseIndicatorFieldOrder}
                />
              </div>
            </WrapWithLabel>

            <WrapWithLabel label="Scammer contact if available" isOptional>
              <div>
                <Text className={el`section-description`} type="body-md">
                  Providing any known scammer contact information will help
                  raising visibility and identifying linked cases.
                </Text>
                <MultiKnownScammerForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  knownScammerFields={knownScammerFields}
                  onAddScammer={addScammer}
                  onRemoveScammer={removeScammer}
                  onUpdateScammerOrder={updateScammerOrder}
                  onUpdateName={updateScammerName}
                  onAddInfoField={addInfoField}
                  onRemoveInfoField={removeInfoField}
                  onUpdateInfoContact={updateInfoContact}
                  onUpdateInfoOrder={updateInfoOrder}
                  onUpdateInfoType={updateInfoType}
                />
              </div>
            </WrapWithLabel>
          </>
        )}

        {formStep === ReportFormSection.DESCRIPTION && (
          <>
            <WrapWithLabel
              label="How much was lost, demanded or stolen?"
              additionalText=" — Required for support"
            >
              <div>
                <Text
                  className={clsx(el`section-description`)}
                  type="body-md"
                >
                  Provide $ amount lost in this case, if available, to receive support.
                </Text>
                <MultiLossesForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  lossFields={lossFields}
                  onAddField={addLossField}
                  onRemoveField={removeLossField}
                  onUpdateAmount={updateLossAmount}
                  onUpdateOrder={updateLossOrder}
                />
              </div>
            </WrapWithLabel>

            <WrapWithLabel label="Transaction hash(es)" isOptional cta={<TransactionHashInfoModal />}>
              <div>
                <Text className={el`section-description`} type="body-md">
                  Provide transaction IDs related to this scam, if available.
                </Text>
                <MultiTransactionHashForm
                  isDisabled={p.isLoading || p.isSubmitting}
                  transactionHashFields={transactionHashFields}
                  onAddField={addTransactionHashField}
                  onAddFields={addTransactionHashFields}
                  onRemoveField={removeTransactionHashField}
                  onUpdateFieldValue={updateTransactionHashFieldValue}
                  updateFieldOrder={updateTransactionHashFieldOrder}
                  includeUndiscoverableChains
                  onUpdateFieldChain={updateTransactionHashFieldChain}
                  onToggleFieldIsValid={toggleTransactionHashFieldIsValid}
                  updateTransactionHashLabel={updateTransactionHashLabel}
                />
              </div>
            </WrapWithLabel>

            <WrapWithLabel label="Description" isRequired>
              <div className={el`section-description`}>
                <Text type="body-md">
                  Please describe the case with as many details as possible to support investigations.
                  If you are posting publicly, make sure not to enter any personal information here.
                </Text>
              </div>
              <div>
                {showTextArea && (
                  <FieldWithError
                    error={
                      isDescriptionTouched && !isDescriptionValid
                        ? `The description's length should be between ${MIN_DESCRIPTION_LENGTH} and ${MAX_DESCRIPTION_LENGTH} characters.`
                        : undefined
                    }
                  >
                    {!(p.isEditMode && !initialValues) && (
                      <DescriptionEditor
                        defaultValues={{
                          text:
                            initialValues?.scamDescription || scamDescription,
                          serializedEditorState:
                            initialValues?.lexicalSerializedDescription ||
                            lexicalSerializedDescription,
                        }}
                        namespace="description-editor"
                        onError={(error) => {
                          console.error(error);
                        }}
                        onBlur={() => setIsDescriptionTouched(true)}
                        onChange={(textContent, editorState) => {
                          updateScamDescription(textContent);
                          updateLexicalSerializedDescription(
                            editorState.toJSON()
                          );
                        }}
                        placeholder="Enter case details. Do not include personal information."
                        validationState={
                          isDescriptionTouched && !isDescriptionValid
                            ? 'invalid'
                            : undefined
                        }
                        isDisabled={p.isLoading || p.isSubmitting}
                      />
                    )}
                  </FieldWithError>
                )}
              </div>
            </WrapWithLabel>

          </>
        )}

        {formStep === ReportFormSection.CONTACT_SUPPORT && (
          <RequestSupportForm
            isDisabled={p.isLoading || p.isSubmitting}
            name={supportOptInFormProps.name}
            isNameValid={supportOptInFormProps.isNameValid}
            email={supportOptInFormProps.email}
            isEmailValid={supportOptInFormProps.isEmailValid}
            setIsNameFieldTouched={supportOptInFormProps.setIsNameFieldTouched}
            setIsEmailFieldTouched={
              supportOptInFormProps.setIsEmailFieldTouched
            }
            onUpdateName={supportOptInFormProps.updateName}
            onUpdateEmail={supportOptInFormProps.updateEmail}
            countries={supportOptInFormProps.countries}
            states={supportOptInFormProps.states}
            cities={supportOptInFormProps.cities}
            country={supportOptInFormProps.country}
            state={supportOptInFormProps.state}
            city={supportOptInFormProps.city}
            zipCode={supportOptInFormProps.zipCode}
            countriesLoading={supportOptInFormProps.countriesLoading}
            statesLoading={supportOptInFormProps.statesLoading}
            citiesLoading={supportOptInFormProps.citiesLoading}
            onUpdateCountry={supportOptInFormProps.updateCountry}
            onUpdateState={supportOptInFormProps.updateState}
            onUpdateCity={supportOptInFormProps.updateCity}
            onUpdateZipCode={supportOptInFormProps.updateZipCode}
            onLoadCountries={supportOptInFormProps.fetchCountries}
            agreed={supportOptInFormProps.agreed}
            onToggleSupportOptIn={supportOptInFormProps.toggleSupportOptIn}
            agreedToBeContactedByLawEnforcement={
              supportOptInFormProps.agreedToBeContactedByLawEnforcement
            }
            phone={supportOptInFormProps.phoneNumber}
            onUpdatePhone={supportOptInFormProps.updatePhoneNumber}
          />
        )}

        <div className="space-y-[10px]">
          <MistakenScammerErrorMessage
            hasMistakenScammerURL={hasMistakenScammerURL || hasMistakenScammerDomain}
            hasMistakenScammerAddress={hasMistakenScammerAddress}
          />

          <div className={el`form-footer`}>
            {!p.isEditMode && (
              <>
                <div>
                  {formStep !== ReportFormSection.CASE_DETAILS && (
                    <BackButton onPress={onBack}>Back</BackButton>
                  )}
                </div>
                <div className={el`footer-cta`}>
                  {formStep !== ReportFormSection.CONTACT_SUPPORT && (
                    <Button
                      // dynamic key to force rerendering submit button as
                      // any text input causes this button to be focused
                      key={
                        isNextOrSubmitButtonDisabled
                          ? 'disabled-next-button'
                          : 'next-button'
                      }
                      variant="secondary"
                      onPress={() => handleNextFormStepOrSubmit()}
                      isDisabled={isNextOrSubmitButtonDisabled}
                      className={el`submit-button`}
                    >
                      Next
                    </Button>
                  )}

                  {formStep === ReportFormSection.CONTACT_SUPPORT && (
                    <>
                      <Button
                        // dynamic key to force rerendering submit button as
                        // any text input causes this button to be focused
                        key={
                          isNextOrSubmitButtonDisabled
                            ? 'disabled-submit-button'
                            : 'submit-button'
                        }
                        variant="primary"
                        onPress={handleSubmitReport}
                        isDisabled={isNextOrSubmitButtonDisabled}
                        className={el`submit-button`}
                      >
                        Submit Report
                      </Button>
                    </>
                  )}
                </div>
              </>
            )}

            {p.isEditMode && (
              <>
                <div>
                  {formStep !== ReportFormSection.CASE_DETAILS && (
                    <BackButton onPress={onBack}>Back</BackButton>
                  )}
                </div>

                <div className={el`edit-action-buttons`}>
                  {formStep === ReportFormSection.CASE_DETAILS && (
                    <DeleteModal
                      onDeleteReport={p.onDeleteReport}
                      open={deleteModalOpen}
                      isLoading={p.isLoading}
                      isSubmitting={p.isSubmitting}
                      onOpenChange={handleDeleteModalOpen}
                    />
                  )}
                  <Button
                    className={el`edit-next-button`}
                    variant="primary"
                    isDisabled={isNextOrSubmitButtonDisabled}
                    onPress={handleSubmitPublicReport}
                  >
                    {formStep === ReportFormSection.CONTACT_SUPPORT
                      ? 'Update Report'
                      : 'Next'}
                  </Button>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ReportForm;
