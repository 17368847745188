import clsx from 'clsx';
import { ReactElement } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Editor } from '../editor';
import { EditorProps } from '../editor/Editor';

export type DescriptionEditorProps = StyleProps &
  Pick<
    EditorProps,
    | 'namespace'
    | 'onError'
    | 'onBlur'
    | 'onChange'
    | 'showHistory'
    | 'isDisabled'
    | 'placeholder'
    | 'validationState'
    | 'defaultValues'
  >;

const ROOT = makeRootClassName('DescriptionEditor');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  defaultValue: '',
} as const;

function DescriptionEditor(props: DescriptionEditorProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Editor
        supportedElements={['paragraph', 'h3', 'h4', 'ul', 'ol']}
        defaultValues={p.defaultValues}
        namespace={p.namespace}
        showHistory={p.showHistory}
        placeholder={p.placeholder}
        onError={p.onError}
        onChange={(value, editorState) => {
          p.onChange?.(value, editorState);
        }}
        onBlur={p.onBlur}
        isDisabled={p.isDisabled}
        validationState={p.validationState}
      />
    </div>
  );
}

export default DescriptionEditor;
