import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { Button, Modal, Text } from '@/components';
import ButtonBase from '@/components/button/ButtonBase';
import { ProfileDropdown } from '@/features/auth/components';
import { useMe } from '@/hooks';
import {
  insertQueryParam,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Switch, Link } from '@/components';
import { PRIVACY_POLICY_URL } from '@/types/routes';
import type { DialogProps } from '@radix-ui/react-dialog';

type LabeledSwitchProps = {
  isDisabled?: boolean;
  checked?: boolean;
  onCheckedChange: (checked: boolean) => void;
};

function LabeledSwitch(props: LabeledSwitchProps) {
  return (
    <div className={el`toggle-wrapper`}>
      <div>
        <Switch
          checked={props.checked}
          onCheckedChange={props.onCheckedChange}
          disabled={props.isDisabled}
          className={el`toggle`}
        />
      </div>
      <div className="flex">
        <Text type="body-sm" className={clsx(el`text`, 'mr-[5px]')}>
          I have read and agree with the
        </Text>
        <Link
          variant="white"
          size="xs"
          isExternal
          href={PRIVACY_POLICY_URL}
          className="mt-[2px] underline"
        >
          Privacy Policy
        </Link>
      </div>
    </div>
  );
}

export type PreAuthModalProps = StyleProps &
  DialogProps & {
    /**
     * The text of the trigger
     */
    triggerButtonText: string;

    /**
     * Whether the user is logged in
     */
    isLoggedIn: boolean;

    /**
     * Whether the modal has a trigger
     * @default true
     */
    hasTrigger?: boolean;

    /**
     * Whether the modal should start in open state on first render.
     * @default false
     */
    shouldStartOpen?: boolean;

    returnTo: string;

    /**
     * Callback when report as guest button is pressed
     * When showing preauth modal while submitting report
     * we are showing report as guest button, this will be called when
     * it is clicked
     */
    onReportAsGuest?: () => void;
  };

const ROOT = makeRootClassName('PreAuthModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  hasTrigger: true,
} as const;

/**
 * When we route from partner subdomains, we want to override of this url to
 * go directly to the url without the subdomain.
 */
const getUrlForLogin = ({ returnTo }: { returnTo: string }): string => {
  // we need to do this for useHandlePostAuth
  const returnToWithPostAuth = insertQueryParam({
    url: returnTo,
    key: 'postAuth',
    value: '1',
  });

  const encodedReturnTo = encodeURIComponent(returnToWithPostAuth);

  return process.env.NEXT_PUBLIC_VERCEL_ENV === 'production'
    ? `https://www.chainabuse.com/api/auth/login?returnTo=${encodedReturnTo}`
    : `/api/auth/login?returnTo=${encodedReturnTo}`;
};

export function PreAuthModal(props: PreAuthModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [open, setOpen] = useState(p.shouldStartOpen ?? false);
  const [isAgreedPrivacyPolicy, setIsAgreedPrivacyPolicy] = useState(false);
  const [showErrorMsg, setShowErrorMsg] = useState(false);

  const onSubmitAsGuest = () => {
    if (isAgreedPrivacyPolicy && p.onReportAsGuest) return p.onReportAsGuest();
    setShowErrorMsg(true);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Modal.Root
        open={open}
        onOpenChange={(open) => {
          setOpen(open);
          p.onOpenChange?.(open);
        }}
      >
        {p.hasTrigger &&
          (p.isLoggedIn ? (
            <ProfileDropdown />
          ) : (
            <a href={getUrlForLogin({ returnTo: p.returnTo })}>
              <Button variant="secondary" className={el`login-button`}>
                {p.triggerButtonText}
              </Button>
            </a>
          ))}
        <Modal.Content size="small">
          <Modal.Header
            title="Submit Report"
            className={el`header`}
            subtitle={
              !p.onReportAsGuest
                ? 'Please sign in to collaborate with the community.'
                : undefined
            }
            hasCloseButton
          />
          <main className={el`body`}>
            {p.onReportAsGuest ? (
              <>
                <div className={el`section`}>
                  <a href={getUrlForLogin({ returnTo: p.returnTo })}>
                    <ButtonBase variant="custom" className={el`sign-up-button`}>
                      LOG IN OR SIGN UP
                    </ButtonBase>
                  </a>
                  <Text type="body-sm" className={clsx(el`hint`, el`text`)}>
                    Login or create a free account to edit and receive updates
                    on your report.
                  </Text>
                </div>

                <div className={el`divider`}>
                  <hr className={el`left`} />
                  <Text
                    type="body-sm"
                    className={clsx(el`hint`, el`divider-text`)}
                  >
                    OR
                  </Text>
                  <hr className={el`right`} />
                </div>

                <div className={el`section`}>
                  <Button
                    variant="secondary"
                    className={el`guest-submit-button`}
                    onPress={onSubmitAsGuest}
                  >
                    SUBMIT AS A GUEST
                  </Button>

                  <Text type="body-sm" className={clsx(el`hint`, el`text`)}>
                    You will not be able to edit your report.
                  </Text>

                  <LabeledSwitch
                    checked={isAgreedPrivacyPolicy}
                    onCheckedChange={() => {
                      setIsAgreedPrivacyPolicy(!isAgreedPrivacyPolicy);
                      if (!isAgreedPrivacyPolicy) setShowErrorMsg(false);
                    }}
                  />

                  {showErrorMsg && (
                    <Text type="custom" className={clsx(el`error-msg`)}>
                      You need to accept the privacy terms before submitting.
                    </Text>
                  )}
                </div>
              </>
            ) : (
              <a href={getUrlForLogin({ returnTo: p.returnTo })}>
                <ButtonBase variant="custom" className={el`sign-up-button`}>
                  LOG IN OR SIGN UP
                </ButtonBase>
              </a>
            )}
          </main>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

type PreAuthModalContaierProps = Partial<
  Pick<
    PreAuthModalProps,
    | 'hasTrigger'
    | 'triggerButtonText'
    | 'shouldStartOpen'
    | 'onOpenChange'
    | 'onReportAsGuest'
  >
> &
  Pick<PreAuthModalProps, 'returnTo'>;

const PreAuthModalContainer = (
  props: PreAuthModalContaierProps
): ReactElement => {
  const { me } = useMe();

  return (
    <PreAuthModal
      {...props}
      returnTo={props.returnTo}
      triggerButtonText={props.triggerButtonText ?? 'LOGIN'}
      isLoggedIn={!!me}
    />
  );
};

export default PreAuthModalContainer;
