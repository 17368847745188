import { RangeSelection } from 'lexical';
import { $isLinkNode } from '@lexical/link';
import { $isAtNodeEnd } from '@lexical/selection';

export function getSelectedNode(selection: RangeSelection) {
  const anchor = selection?.anchor;
  const focus = selection?.focus;
  const anchorNode = selection?.anchor.getNode();
  const focusNode = selection?.focus.getNode();
  if (anchorNode === focusNode) {
    return anchorNode;
  }
  const isBackward = selection.isBackward();
  if (isBackward) {
    return $isAtNodeEnd(focus) ? anchorNode : focusNode;
  } else {
    return $isAtNodeEnd(anchor) ? focusNode : anchorNode;
  }
}

export function getSelectedUrl(selection: RangeSelection) {
  const node = getSelectedNode(selection);
  const parent = node.getParent();
  if ($isLinkNode(parent)) {
    return parent.getURL();
  } else if ($isLinkNode(node)) {
    return node.getURL();
  }
  return undefined;
}

export function getSelectionRect(rootElement: HTMLElement) {
  const nativeSelection = window.getSelection();
  const domRange = nativeSelection?.getRangeAt(0);
  if (nativeSelection?.anchorNode === rootElement) {
    let inner: HTMLElement | Element = rootElement;
    while (inner.firstElementChild != null) {
      inner = inner.firstElementChild;
    }
    return inner.getBoundingClientRect();
  }
  return domRange?.getBoundingClientRect();
}

export const focusOnInputChild = (element?: HTMLElement | null) => {
  const linkInput = element?.querySelector('input');
  if (linkInput) {
    setTimeout(() => {
      linkInput.focus();
      linkInput.setSelectionRange(9999, 9999);
    });
  }
};

const URL_MATCHER =
  /((https?:\/\/(www\.)?)|(www\.))[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/;
const EMAIL_MATCHER =
  /(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))/;

export const AUTOLINK_MATCHERS = [
  (text: string) => {
    const match = URL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: match[0],
      }
    );
  },
  (text: string) => {
    const match = EMAIL_MATCHER.exec(text);
    return (
      match && {
        index: match.index,
        length: match[0].length,
        text: match[0],
        url: `mailto:${match[0]}`,
      }
    );
  },
];
