import { sanitizeUrl } from '@braintree/sanitize-url';
import clsx from 'clsx';
import { KeyboardEvent, ReactElement, RefObject, useState } from 'react';
import isURL from 'validator/lib/isURL';
import {
  SearchField,
  TextField,
  Text,
  ChainSelect,
  LabelInput,
  RemoveFieldButton,
  ChainSelectModal,
} from '@/components';
import { MAX_LABEL_LENGTH } from '@/constants';
import { ChainDiscoverabilityProps, ChainType } from '@/types/chain';
import { CONTACT_SUPPORT_ROUTE } from '@/types/routes';
import {
  isPossibleAddress,
  makeElementClassNameFactory,
  makeRootClassName,
} from '@/utils';
import { getDisabledChains, getPossibleChains } from '@/utils/chains';
import type { AddressDomainField } from '../submit-report-page/types';
import type { ValidationState } from '@react-types/shared';

export type AddressDomainInputProps = ChainDiscoverabilityProps & {
  /** The value in the input */
  value: string;

  /**
   * Whether the address is a mistaken scammer address
   */
  isMistakenAddress?: boolean;

  /**
   * Initial chain value to put in the chain select. Will be overridden by the
   * user changing the value of the input or selecting another valid chain.
   * Used when prefilling an input
   */
  initialChain?: ChainType;

  /**
   * Label of the address.
   */
  addressLabel?: AddressDomainField['label'];

  /** Whether the components are disabled */
  isDisabled?: boolean;

  /** Handler to call when the user changes the value */
  onChange: (value: string) => void;

  /** Handler to call when the user submits what looks like an address */
  onSubmitAddress?: (address: string, chain?: ChainType) => void;

  /** Handler to call when the user submits what looks like a url */
  onSubmitDomain?: (url: string) => void;

  /**
   * Handler that gets called when the user selects an chain,
   * a chain gets autoselected, or a chain gets deselected by the user
   * or automatically.
   */
  onChainChanged?: (chain?: ChainType) => void;

  /**
   * Handler that gets called when the label of the address changes,
   */
  onAddressLabelChanged?: (label: AddressDomainField['label']) => void;

  /**
   * Handler called whenever the validation state changes with the new
   * validation state. Useful for when a parent component needs to know
   * validation state.
   */
  onValidationStateChanged?: (valid: boolean) => void;

  /**
   * Whether to show the chain select upfront
   * @default false. If false, chain select step is shown in modal after user
   * types value in field.
   */
  showChainSelectUpfront?: boolean;

  /** Whether to show a remove field button
   * @default false
   */
  showRemoveFieldButton?: boolean;

  /**
   * Whether to show the address label input
   * @default false
   */
  showAddressLabelInput?: boolean;

  /**
   * Whether to show the url input
   */

  showUrlInput?: boolean;

  /**
   * Handler to call when the user chooses to remove the field
   */
  onRemoveField?: () => void;

  /**
   * Whether the field in the input should be a searchfield.
   * @default false. If false, the input is a regular textfield
   */
  isSearchField?: boolean;

  /**
   * The direction of the search section
   * @default 'column'
   */
  direction?: 'row' | 'column';

  /**
   * The input's placeholder
   * @default 'Enter address or URL'
   */
  placeholder?: string;

  /**
   * The input's ref
   */
  inputRef?: RefObject<HTMLInputElement>;
};

const ROOT = makeRootClassName('AddressDomainInput');
const el = makeElementClassNameFactory(ROOT);

type AddressDomainInputErrorProps = Pick<
  AddressDomainInputProps,
  'showChainSelectUpfront' | 'showUrlInput'

> & {
  isAddressInvalid: boolean;
  /**
   * Whether to show a short or long message
   */
  isAddressDomainInvalid: boolean;
  isLabelInvalid: boolean;
  /**
   * Whether or not the address is a known mistaken address.
   */
  isMistakenAddress?: boolean;
  /**
   * Whether it's domain or address
   */
  isDomain?: boolean;
};
const AddressDomainInputError = (
  props: AddressDomainInputErrorProps
): ReactElement => {
  if (props.isAddressInvalid) {
    return (
      <div className={el`error`}>
        <Text type="body-sm" className={el`error-text`}>
          Please provide at least one crypto address or one URL for the scammer.
        </Text>
      </div>
    );
  }

  if (props.isAddressDomainInvalid) {
    return (
      <div className={el`error`}>
        <span className={el`invalid-disclaimer`}>
          {props.showChainSelectUpfront ? (
            <>
              <span>
                The Address/URL you entered is incorrect or belongs to an
                unsupported blockchain.
              </span>{' '}
              <span>
                <a href={CONTACT_SUPPORT_ROUTE}>Contact us</a> to suggest new
                supported chains or to report a problem.
              </span>
            </>
          ) : (
            <span>Incorrect or unsupported Address/URL</span>
          )}
        </span>
      </div>
    );
  }

  if (props.isLabelInvalid) {
    return (
      <div className={el`error`}>
        <span className={el`invalid-disclaimer`}>
          Label must be less than 25 characters
        </span>
      </div>
    );
  }

  if (props.isMistakenAddress) {
    if (props.isDomain) {
      return (
        <div className={el`error`}>
          <span className={el`invalid-disclaimer`}>
            Thank you for reporting your case on Chainabuse - The domain you are
            trying to report does not seem to be the of a scammer. Can you
            please double-check before submitting your report?
          </span>
        </div>
      );
    }

    return (
      <div className={el`error`}>
        <span className={el`invalid-disclaimer`}>
          Thank you for reporting your case on Chainabuse - The crypto address
          you are trying to report does not seem to be the address of a scammer.
          Can you please double-check before submitting your report?
        </span>
      </div>
    );
  }

  return <></>;
};

const DEFAULT_PROPS = {
  showChainSelectUpfront: false,
  isSearchField: false,
  direction: 'column',
  placeholder: 'Enter address or URL',
  includeUndiscoverableChains: false,
  showAddressLabelInput: false,
};

function AddressDomainInput(props: AddressDomainInputProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const [chain, _setChain] = useState<ChainType | undefined>(p.initialChain);
  const [isInvalid, _setIsInvalid] = useState(false);
  const [modalIsOpen, toggleModalOpen] = useState(false);

  const isDomain = isURL(p.value);

  const updateChain = (newChain?: ChainType) => {
    p.onChainChanged?.(newChain);
    _setChain(newChain);
  };

  const updateIsInvalid = (newInvalid: boolean) => {
    p.onValidationStateChanged?.(!newInvalid);
    _setIsInvalid(newInvalid);
  };

  const toggleChain = (selected?: ChainType) => {
    if (selected === chain) return updateChain(undefined);
    updateChain(selected);
  };

  const disabledChains = isPossibleAddress(p.value)
    ? getDisabledChains(p.value)
    : [];

  const validate = (value: string) => {
    if (value === '') {
      return updateIsInvalid(true);
    }

    const isValid = p.showUrlInput ? isURL(value) : isPossibleAddress(value);
    updateIsInvalid(!isValid);
    return isValid;
  };

  const handleEnter = (e: KeyboardEvent) => {
    if (e.key === 'Enter') {
      if (isDomain) {
        // sanitize any urls before they're submitted
        // (in case they contain any malicious url format)
        const sanitizedUrl = sanitizeUrl(p.value);
        return p.onSubmitDomain?.(sanitizedUrl);
      }

      if (isPossibleAddress(p.value)) {
        if (!p.showChainSelectUpfront) {
          const possibleChains = getPossibleChains(p.value);
          if (possibleChains.length > 1) return toggleModalOpen(true);

          if (possibleChains.length === 1) {
            const [onlyPossible] = possibleChains;
            p.onChainChanged?.(onlyPossible);
            return p.onSubmitAddress?.(p.value, chain);
          }

          p.onChainChanged?.(undefined);
          return p.onSubmitAddress?.(p.value, undefined);
        }

        return p.onSubmitAddress?.(p.value, chain);
      }

      if (!isDomain && !isPossibleAddress(p.value)) {
        updateIsInvalid(true);
      }
    }
  };

  const handleUpdateValue = async (value: string) => {
    validate(value);

    if (isPossibleAddress(value)) {
      const possibleChains = getPossibleChains(value);
      if (possibleChains.length > 1) {
        const [first] = possibleChains;
        if (chain === undefined) {
          updateChain(first);
        }
      }

      if (possibleChains.length === 1) {
        const [onlyPossible] = possibleChains;
        updateChain(onlyPossible);
      }

      if (possibleChains.length === 0) {
        updateChain(undefined);
      }
    } else {
      updateChain(undefined);
    }

    p.onChange(value);
  };

  const addressValidationProps:
    | { validationState: ValidationState }
    | Record<string, never> = isInvalid ? { validationState: 'invalid' } : {};

  const isLabelInvalid =
    p.showAddressLabelInput &&
    p.addressLabel &&
    p.addressLabel.length >= MAX_LABEL_LENGTH;

  const labelValidationProps:
    | { validationState: ValidationState }
    | Record<string, never> = isLabelInvalid
    ? {
        validationState: 'invalid',
      }
    : {};

  const couldBeAddress = isPossibleAddress(p.value);

  const field = (
    <>
      {p.isSearchField ? (
        <SearchField
          {...addressValidationProps}
          autoComplete="off"
          aria-label="Search Address or URL"
          placeholder={p.placeholder}
          value={p.value}
          onChange={handleUpdateValue}
          onKeyDown={handleEnter}
          isDisabled={p.isDisabled}
          className={el`field`}
          inputRef={p.inputRef}
        />
      ) : (
        <TextField
          {...addressValidationProps}
          autoComplete="off"
          aria-label="Enter Address or URL"
          placeholder={p.placeholder}
          value={p.value}
          onChange={handleUpdateValue}
          onKeyDown={handleEnter}
          isDisabled={p.isDisabled}
          className={el`field`}
        />
      )}
    </>
  );

  const hasRemoveButtonInlineWithField =
    p.showRemoveFieldButton && p.direction === 'column';

  return (
    <>
      <div
        aria-label="Address or Domain"
        className={clsx(ROOT, {
          'is-only-field': !p.showChainSelectUpfront,
          'is-horizontal': p.direction === 'row',
          'has-remove-button-inline-with-field': hasRemoveButtonInlineWithField,
        })}
      >
        <div className={el`input-select`}>
          {hasRemoveButtonInlineWithField ? (
            <div className={el`field-with-remove-button`}>
              {field}
              <RemoveFieldButton
                isDisabled={p.isDisabled}
                onPress={() => p.onRemoveField?.()}
              />
            </div>
          ) : (
            field
          )}
          <div className={el`chain-address-label-inputs`}>
            {p.showChainSelectUpfront && (
              <ChainSelect
                isDisabled={isDomain || !couldBeAddress || p.isDisabled}
                value={chain}
                onChange={toggleChain}
                disabledOptions={disabledChains}
                placeholder={'Chain'}
                onFocusChange={() => {
                  p.inputRef?.current?.focus();
                }}
              />
            )}
            {p.showAddressLabelInput && (
              <LabelInput
                {...labelValidationProps}
                isDisabled={p.isDisabled || !couldBeAddress}
                placeholder="Label (optional)"
                onChange={(value) => p.onAddressLabelChanged?.(value)}
                className={el`address-label-input`}
                value={p.addressLabel}
              />
            )}
          </div>
          {p.showRemoveFieldButton && p.direction === 'row' && (
            <RemoveFieldButton
              isDisabled={p.isDisabled}
              onPress={() => p.onRemoveField?.()}
              className={el`remove-field-button`}
            />
          )}
        </div>
        <AddressDomainInputError
          isDomain={isDomain}
          isAddressInvalid={!p.value && isInvalid}
          showChainSelectUpfront={p.showChainSelectUpfront}
          isAddressDomainInvalid={isInvalid}
          isLabelInvalid={!!isLabelInvalid}
          isMistakenAddress={p.isMistakenAddress}
        />
        {!p.showChainSelectUpfront && couldBeAddress && (
          <ChainSelectModal
            value={p.value}
            options={getPossibleChains(p.value)}
            open={modalIsOpen}
            onOpenChange={(open) => toggleModalOpen(open)}
            onSelectChain={(selectedChain) => {
              toggleModalOpen(false);
              p.onChainChanged?.(selectedChain);
              p.onSubmitAddress?.(p.value, selectedChain);
            }}
          />
        )}
      </div>
    </>
  );
}

export default AddressDomainInput;
