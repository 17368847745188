/**
 * Casts a string as an enum, where appropriate
 */
export function enumFromStringValue<T>(
  enumObject: { [s: string]: T },
  value?: string
): T | undefined {
  if (!value) {
    return undefined;
  }
  return (Object.values(enumObject) as unknown as string[]).includes(value)
    ? (value as unknown as T)
    : undefined;
}
