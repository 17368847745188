import { useRequestOtpMutation } from '@/generated/graphql';
type UseRequestOTPValue = {
  /** Handler to call when the user asks to resent an OTP */
  requestOTP: () => Promise<boolean>;
};

/**
 * Request the OTP or the verification email to be sent.
 */
const useRequestOTP = (): UseRequestOTPValue => {
  const [requestOtpMutation] = useRequestOtpMutation();

  const requestOTP = async () => {
    try {
      const { data, errors } = await requestOtpMutation();

      if (errors) {
        throw errors;
      }

      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  return { requestOTP };
};

export default useRequestOTP;
