import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { $createHeadingNode } from '@lexical/rich-text';
import { $wrapNodes } from '@lexical/selection';
import clsx from 'clsx';
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
} from 'lexical';
import { ReactElement, useMemo } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Icon, IconProps, Select } from '@/components';
import {
  createBlockParagraph,
  createFormatHeading,
  createListOrdered,
  createListUnordered,
} from '@/assets/icons';
import { SupportedBlock } from '../../types';

export type BlockOptionsDropdownProps = StyleProps & {
  editor: LexicalEditor;
  supportedElements: ReadonlyArray<SupportedBlock>;
  blockType?: SupportedBlock;
  isDisabled: boolean;
};

const ROOT = makeRootClassName('BlockOptionsDropdown');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  supportedElements: ['paraghaph'],
} as const;

const blockTextMapping: Record<SupportedBlock, string> = {
  paragraph: 'Normal',
  h2: 'Large heading',
  h3: 'Normal heading',
  h4: 'Small heading',
  ul: 'Bullet list',
  ol: 'Numbered list',
};

const blockIconMapping: Record<SupportedBlock, IconProps> = {
  paragraph: {
    content: createBlockParagraph,
  },
  h2: {
    content: createFormatHeading,
  },
  h3: {
    content: createFormatHeading,
    size: 'small',
  },
  h4: {
    content: createFormatHeading,
    size: 'xs',
  },
  ul: {
    content: createListUnordered,
  },
  ol: {
    content: createListOrdered,
  },
};

const nonListBlocks = [
  'h1',
  'h2',
  'h3',
  'h4',
  'h5',
  'h6',
  'quote',
  'code',
  'paragraph',
] as const;
type NonListBlock = typeof nonListBlocks[number];

const createNode = (blockType: NonListBlock) => {
  switch (blockType) {
    case 'h2':
      return $createHeadingNode('h2');
    case 'h3':
      return $createHeadingNode('h3');
    case 'h4':
      return $createHeadingNode('h4');
    default:
      return $createParagraphNode();
  }
};

const formatElement = (
  editor: LexicalEditor,
  targetBlockType: SupportedBlock,
  blockType?: SupportedBlock
) => {
  switch (targetBlockType) {
    case 'paragraph':
    case 'h2':
    case 'h3':
    case 'h4': {
      if (blockType !== targetBlockType) {
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $wrapNodes(selection, () => {
              return createNode(targetBlockType);
            });
          }
        });
      }
      break;
    }
    case 'ul': {
      if (blockType !== 'ul') {
        editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
      break;
    }
    case 'ol': {
      if (blockType !== 'ol') {
        editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined);
      } else {
        editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
      }
      break;
    }
  }
};

function BlockOptionsDropdown(props: BlockOptionsDropdownProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { editor, blockType } = p;

  const handleFormat = (targetBlockType?: SupportedBlock) => {
    if (targetBlockType) {
      formatElement(editor, targetBlockType, blockType);
    }
  };

  const supportedElements = useMemo(() => {
    // ensure we always have a paragraph available
    return p.supportedElements.reduce<SupportedBlock[]>(
      (acc, el) => {
        if (!acc.includes(el)) {
          acc.push(el);
        }
        return acc;
      },
      ['paragraph']
    );
  }, [p.supportedElements.join('')]);

  return (
    <div className={clsx(ROOT, p.className)}>
      <Select
        isDisabled={p.isDisabled}
        value={blockType}
        onChange={(targetBlockType) => handleFormat(targetBlockType)}
        renderValue={(value) =>
          value && (
            <div className={el`option`}>
              <Icon className={el`icon`} {...blockIconMapping[value]} />
              <span>{blockTextMapping[value]}</span>
            </div>
          )
        }
      >
        {supportedElements.map((blockType) => (
          <option value={blockType} key={blockType}>
            <div className={el`option`}>
              <Icon className={el`icon`} {...blockIconMapping[blockType]} />
              <span>{blockTextMapping[blockType]}</span>
            </div>
          </option>
        ))}
      </Select>
    </div>
  );
}

export default BlockOptionsDropdown;
