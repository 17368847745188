import { Text } from '@/components';
import { makeElementClassNameFactory, makeRootClassName } from '@/utils';

const ROOT = makeRootClassName('ErrorMessages');
const el = makeElementClassNameFactory(ROOT);

export function MistakenScammerErrorMessage(props: {
  hasMistakenScammerURL: boolean;
  hasMistakenScammerAddress: boolean;
}): JSX.Element {
  return (
    <>
      {props.hasMistakenScammerURL && (
        <Text type="body-sm" className={el`missing-fields-warning-container`}>
          Please change the URL you are reporting as this is often mistaken as a
          scammer&apos;s URL but is not.
        </Text>
      )}
      {props.hasMistakenScammerAddress && (
        <Text type="body-sm" className={el`missing-fields-warning-container`}>
          Please change the blockchain address you are reporting as this is
          often mistaken as a scammer&apos;s address but is not.
        </Text>
      )}
    </>
  );
}
