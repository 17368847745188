import { useField } from 'formik';
import React, { ReactElement } from 'react';
import { TextField, TextFieldProps } from '@/components/textfield';
import { RequiredFormikProps } from '../types';
import FieldWithError from './FieldWithError';

type FormikTextFieldProps = TextFieldProps & RequiredFormikProps;

const DEFAULT_PROPS = {} as const;

export default function FormikTextField(
  props: FormikTextFieldProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const [{ value }, { touched, error }, { setValue, setTouched }] = useField(
    p.name
  );

  return (
    <FieldWithError error={error && touched ? error : undefined}>
      <TextField
        {...p}
        value={value}
        onChange={(newValue) => setValue(newValue)}
        onBlur={() => setTouched(true)}
        validationState={touched && error ? 'invalid' : undefined}
      />
    </FieldWithError>
  );
}
