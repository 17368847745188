export const solana = (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="#05131D" stroke="#8784F7" />
    <path d="M8 15.4457C8.01753 12.9372 9.02527 10.5372 10.8038 8.76808C12.5824 6.999 14.9878 6.00411 17.4964 6H18.1917V7.50992H17.5069C15.3969 7.51096 13.3727 8.34545 11.8749 9.83173C10.3772 11.318 9.52717 13.3357 9.50992 15.4457H8Z" fill="white" />
    <path d="M10.8965 24.9999V23.49H11.5918C13.7015 23.4875 15.7248 22.652 17.2215 21.1652C18.7182 19.6784 19.5671 17.6606 19.5835 15.551H21.0945C21.077 18.0605 20.0685 20.4613 18.2888 22.2305C16.5091 23.9998 14.1024 24.994 11.5929 24.9967L10.8965 24.9999Z" fill="white" />
    <path d="M10.8965 22.1035V20.5936H11.5918C12.9336 20.592 14.2207 20.0619 15.1744 19.1181C16.1281 18.1742 16.6716 16.8927 16.6871 15.551H18.1971C18.1804 17.2925 17.4774 18.9571 16.2406 20.1834C15.0039 21.4096 13.3334 22.0985 11.5918 22.1004L10.8965 22.1035Z" fill="white" />
    <path d="M10.8965 15.4458C10.9126 13.7029 11.6161 12.0368 12.854 10.8098C14.0918 9.58283 15.764 8.89407 17.507 8.89331H18.2023V10.4032H17.507C16.1643 10.4034 14.8758 10.9329 13.921 11.8769C12.9662 12.8208 12.422 14.1032 12.4064 15.4458H10.8965Z" fill="white" />
    <path d="M10.8965 19.2075V17.6976H11.5918C12.1657 17.6972 12.7168 17.4726 13.1274 17.0716C13.538 16.6707 13.7757 16.1252 13.7897 15.5514V15.4955C13.7917 14.5112 14.1843 13.5681 14.8814 12.8732C15.5784 12.1784 16.5228 11.7887 17.507 11.7898H18.2023V13.2997H17.507C16.9323 13.299 16.38 13.5231 15.9684 13.9241C15.5567 14.3252 15.3182 14.8714 15.3038 15.4459V15.5018C15.3027 16.4853 14.9115 17.4282 14.2159 18.1235C13.5204 18.8188 12.5774 19.2098 11.5939 19.2107L10.8965 19.2075Z" fill="white" />
</svg>
);
export const solanaHover = (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="#05131D" stroke="#8784F7" />
    <path d="M8 15.4457C8.01753 12.9372 9.02527 10.5372 10.8038 8.76808C12.5824 6.999 14.9878 6.00411 17.4964 6H18.1917V7.50992H17.5069C15.3969 7.51096 13.3727 8.34545 11.8749 9.83173C10.3772 11.318 9.52717 13.3357 9.50992 15.4457H8Z" fill="#8784F7" />
    <path d="M10.8965 24.9999V23.49H11.5918C13.7015 23.4875 15.7248 22.652 17.2215 21.1652C18.7182 19.6784 19.5671 17.6606 19.5835 15.551H21.0945C21.077 18.0605 20.0685 20.4613 18.2888 22.2305C16.5091 23.9998 14.1024 24.994 11.5929 24.9967L10.8965 24.9999Z" fill="#8784F7" />
    <path d="M10.8965 22.1035V20.5936H11.5918C12.9336 20.592 14.2207 20.0619 15.1744 19.1181C16.1281 18.1742 16.6716 16.8927 16.6871 15.551H18.1971C18.1804 17.2925 17.4774 18.9571 16.2406 20.1834C15.0039 21.4096 13.3334 22.0985 11.5918 22.1004L10.8965 22.1035Z" fill="#8784F7" />
    <path d="M10.8965 15.4458C10.9126 13.7029 11.6161 12.0368 12.854 10.8098C14.0918 9.58283 15.764 8.89407 17.507 8.89331H18.2023V10.4032H17.507C16.1643 10.4034 14.8758 10.9329 13.921 11.8769C12.9662 12.8208 12.422 14.1032 12.4064 15.4458H10.8965Z" fill="#8784F7" />
    <path d="M10.8965 19.2075V17.6976H11.5918C12.1657 17.6972 12.7168 17.4726 13.1274 17.0716C13.538 16.6707 13.7757 16.1252 13.7897 15.5514V15.4955C13.7917 14.5112 14.1843 13.5681 14.8814 12.8732C15.5784 12.1784 16.5228 11.7887 17.507 11.7898H18.2023V13.2997H17.507C16.9323 13.299 16.38 13.5231 15.9684 13.9241C15.5567 14.3252 15.3182 14.8714 15.3038 15.4459V15.5018C15.3027 16.4853 14.9115 17.4282 14.2159 18.1235C13.5204 18.8188 12.5774 19.2098 11.5939 19.2107L10.8965 19.2075Z" fill="#8784F7" />
</svg>
);
export const solanaFocus = (<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.5" y="0.5" width="29" height="29" rx="1.5" fill="#05131D" stroke="#4A4887" />
    <path d="M8 15.4457C8.01753 12.9372 9.02527 10.5372 10.8038 8.76808C12.5824 6.999 14.9878 6.00411 17.4964 6H18.1917V7.50992H17.5069C15.3969 7.51096 13.3727 8.34545 11.8749 9.83173C10.3772 11.318 9.52717 13.3357 9.50992 15.4457H8Z" fill="#4A4887" />
    <path d="M10.8965 24.9999V23.49H11.5918C13.7015 23.4875 15.7248 22.652 17.2215 21.1652C18.7182 19.6784 19.5671 17.6606 19.5835 15.551H21.0945C21.077 18.0605 20.0685 20.4613 18.2888 22.2305C16.5091 23.9998 14.1024 24.994 11.5929 24.9967L10.8965 24.9999Z" fill="#4A4887" />
    <path d="M10.8965 22.1035V20.5936H11.5918C12.9336 20.592 14.2207 20.0619 15.1744 19.1181C16.1281 18.1742 16.6716 16.8927 16.6871 15.551H18.1971C18.1804 17.2925 17.4774 18.9571 16.2406 20.1834C15.0039 21.4096 13.3334 22.0985 11.5918 22.1004L10.8965 22.1035Z" fill="#4A4887" />
    <path d="M10.8965 15.4458C10.9126 13.7029 11.6161 12.0368 12.854 10.8098C14.0918 9.58283 15.764 8.89407 17.507 8.89331H18.2023V10.4032H17.507C16.1643 10.4034 14.8758 10.9329 13.921 11.8769C12.9662 12.8208 12.422 14.1032 12.4064 15.4458H10.8965Z" fill="#4A4887" />
    <path d="M10.8965 19.2075V17.6976H11.5918C12.1657 17.6972 12.7168 17.4726 13.1274 17.0716C13.538 16.6707 13.7757 16.1252 13.7897 15.5514V15.4955C13.7917 14.5112 14.1843 13.5681 14.8814 12.8732C15.5784 12.1784 16.5228 11.7887 17.507 11.7898H18.2023V13.2997H17.507C16.9323 13.299 16.38 13.5231 15.9684 13.9241C15.5567 14.3252 15.3182 14.8714 15.3038 15.4459V15.5018C15.3027 16.4853 14.9115 17.4282 14.2159 18.1235C13.5204 18.8188 12.5774 19.2098 11.5939 19.2107L10.8965 19.2075Z" fill="#4A4887" />
</svg>
);
