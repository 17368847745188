import { Listbox, Transition } from '@headlessui/react';
import { Popper } from '@material-ui/core';
import { useFocusRing } from '@react-aria/focus';
import { useHover } from '@react-aria/interactions';
import clsx from 'clsx';
import React, { Fragment, ReactElement, ReactNode, useRef } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { createCheck, createChevronDown } from '@/assets/icons';
import { Icon } from '..';

export type OptionProps<T> = {
  /**
   * The value of the option.
   * Used by the select parent's renderValue to render the option
   * when selected and used in the state of the select.
   */
  value: T;

  /**
   * The content of the option.
   */
  children: ReactNode;
  /** Whether the options is disabled. */
  isDisabled?: boolean;
};

export type SelectProps<T> = StyleProps & {
  /** Whether the select should be disabled. @default false */
  isDisabled?: boolean;

  /** The size of the select dropdown. @default 'medium' */
  size?: 'medium' | 'small';

  /**
   * The option elements to populate the select menu with.
   */
  children: ReactElement<OptionProps<T>>[];

  /**
   * Handler to call when an option is selected.
   */
  onChange: (value: T) => void;

  /**
   * The input value. Providing null will select no
   * options. If the value is an object, it must have reference
   * equality with the option's value to be selected. If the value
   * is not an object, the string representation must match with the
   * string representation of the option to be selected.
   */
  value: T;

  /* The aria label of the select trigger button */
  triggerLabel?: string;

  /* Class name for the listbox menu */
  menuClassName?: string;

  /**
   * Handler to render the selected value.
   */
  renderValue?: (value: T) => ReactNode;

  /** Message to put in the input when no option is selected @default 'Select...'*/
  placeholder?: string;

  /** The label of the select input */
  label?: string;

  /**
   * Handler to call when the focus state changes.
   */
  onFocusChange?: () => void;

  /**
   * Validation state
   */
  validationState?: 'valid' | 'invalid';
};

const ROOT = makeRootClassName('Select');
const el = makeElementClassNameFactory(ROOT);

const DROPDOWN_ICON = createChevronDown;
const SELECTED_ICON = createCheck;

const DEFAULT_PROPS = {
  placeholder: 'Select...',
  size: 'medium',
  isDisabled: false,
  renderValue: (value: any) => value,
} as const;

export function Option<T>(props: OptionProps<T>): ReactElement {
  return <></>;
}

function Select<T>(props: SelectProps<T>): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const domRef = useRef<HTMLDivElement>(null);

  // @TODO create optional uncontrolled state

  const { hoverProps, isHovered } = useHover({ isDisabled: p.isDisabled });
  const { focusProps, isFocusVisible } = useFocusRing();

  return (
    <Listbox
      as="div"
      // tabIndex={p.isDisabled ? -1 : 0}
      {...hoverProps}
      className={clsx(
        `${ROOT} size-${p.size}`,
        {
          'is-disabled': p.isDisabled,
          'is-hovered': isHovered,
        },
        p.className
      )}
      value={p.value}
      onChange={p.onChange}
      disabled={p.isDisabled}
    >
      {({ open }) => (
        <>
          {p.label && (
            <label className={el`label`}>
              {p.label}
              <div className={el`label-required-indicator`} />
            </label>
          )}
          <Listbox.Button
            {...focusProps}
            aria-label={p.triggerLabel}
            tabIndex={p.isDisabled ? -1 : 0}
            as="div"
            className={clsx(el`input-wrapper`, {
              'is-focused': open,
              'is-hovered': isHovered,
              'is-focus-visible': isFocusVisible,
              'is-valid': p.validationState === 'valid',
              'is-invalid': p.validationState === 'invalid',
            })}
            onFocus={p.onFocusChange}
          >
            <div className={el`text`} ref={domRef}>
              <span
                className={clsx(el`placeholder`, {
                  invisible: !!p.value,
                })}
              >
                {p.placeholder}
              </span>
              {p.value && (
                <span className={el`value`}>{p.renderValue(p.value)}</span>
              )}
            </div>
            <Icon content={DROPDOWN_ICON} className={el`dropdown-icon`} />
          </Listbox.Button>
          <Popper
            open
            // couldn't use Ref on the ListBox elements so I had to target it with parentElement
            anchorEl={domRef.current?.parentElement}
            style={{
              width: domRef.current?.parentElement?.offsetWidth + 'px',
            }}
          >
            <Transition
              as={Fragment}
              enter="transition duration-100 ease-in"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition duration-75 ease-in"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Listbox.Options
                className={clsx(el`menu`, p.menuClassName)}
                role="select"
              >
                {React.Children.map(p.children, ({ props }, idx) => (
                  <Listbox.Option
                    key={idx}
                    value={props.value}
                    disabled={props.isDisabled}
                    as={Fragment}
                  >
                    {({ active, selected, disabled }) => (
                      <li
                        role="option"
                        aria-disabled={disabled}
                        aria-selected={selected}
                        className={clsx(el`option`, {
                          'is-selected': selected,
                          'is-active': active,
                          'is-disabled': disabled,
                        })}
                      >
                        {props.children}
                        {selected && (
                          <Icon
                            content={SELECTED_ICON}
                            className={el`selected-icon`}
                          />
                        )}
                      </li>
                    )}
                  </Listbox.Option>
                ))}
              </Listbox.Options>
            </Transition>
          </Popper>
        </>
      )}
    </Listbox>
  );
}

//@TODO switch to radix rather than headlessui it's when ready
export default Select;
