import React, { ReactElement, ReactNode, useEffect, useState } from 'react';

export type ClientOnlyProps = {
  children: ReactNode;
};

/** @see https://www.joshwcomeau.com/react/the-perils-of-rehydration/ */
function ClientOnly({ children }: ClientOnlyProps): ReactElement {
  const [hasMounted, setHasMounted] = useState(false);

  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return <></>;
  }

  return <>{children}</>;
}

export default ClientOnly;
