import clsx from 'clsx';
import { ReactElement } from 'react';
import { Button, DragDropContext, Draggable, Droppable } from '@/components';
import { FeatherIconName } from '@/components/icon';
import { TransactionHashInput } from '@/features/transaction-hash-input';
import { TransactionHashInputProps } from '@/features/transaction-hash-input/TransactionHashInput';
import { ChainDiscoverabilityProps, ChainType } from '@/types/chain';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Mobile, Tablet } from '@/utils/responsive';
import { TransactionHashField } from '../../types';
import { DragIcon } from '../drag-icon';
import { BulkAddModal } from '../bulk-add-modal';

export type MultiTransactionHashFormProps = StyleProps &
  ChainDiscoverabilityProps & {
    isDisabled?: boolean;
    transactionHashFields: TransactionHashField[];
    onAddField: (value?: string) => void;
    onAddFields: (values?: string[]) => void;
    onRemoveField: (index: number) => void;
    onUpdateFieldValue: (index: number, value: string) => void;
    onUpdateFieldChain: (index: number, chain?: ChainType) => void;
    updateFieldOrder: (fromIndex: number, toIndex: number) => void;
    onToggleFieldIsValid: (index: number, isValid: boolean) => void;
    updateTransactionHashLabel: (
      index: number,
      value?: TransactionHashField['label']
    ) => void;
  };

const ROOT = makeRootClassName('MultiTransactionHashForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  includeUndiscoverableChains: false,
} as const;

function MultiTransactionHashForm(
  props: MultiTransactionHashFormProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.transactionHashFields.length > 1;

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          p.updateFieldOrder(source.index, destination.index);
        }
      }}
    >
      <div
        className={clsx(
          ROOT,
          {
            'has-multiple': hasMultiple,
          },
          p.className
        )}
      >
        <div className={el`transaction-hashes-wrapper`}>
          <Droppable
            droppableId="drop-transaction-hash"
            isDropDisabled={p.isDisabled}
          >
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {p.transactionHashFields.map((field, index) => {
                  const transactionHashInputProps: TransactionHashInputProps = {
                    value: field.value,
                    initialChain: field.chain,
                    transactionHashLabel: field.label,
                    showChainSelectUpfront: true,
                    showTransactionHashLabelInput: true,
                    onChange: (value: string) =>
                      props.onUpdateFieldValue(index, value),
                    onChainChanged: (chain?: ChainType) =>
                      props.onUpdateFieldChain(index, chain),
                    onTransactionHashLabelChanged: (
                      value: TransactionHashField['label']
                    ) => props.updateTransactionHashLabel(index, value),
                    onValidationStateChanged: (isValid: boolean) =>
                      props.onToggleFieldIsValid(index, isValid),
                    includeUndiscoverableChains: p.includeUndiscoverableChains,
                    showRemoveFieldButton: index !== 0,
                    onRemoveField: () => props.onRemoveField(index),
                    isDisabled: p.isDisabled,
                  };

                  return (
                    <Draggable
                      key={field.key}
                      draggableId={field.key}
                      index={index}
                    >
                      {(provided, dragSnapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={el`transaction-hash-row`}
                          style={{
                            ...provided.draggableProps.style,
                            transform: dragSnapshot.isDragging
                              ? provided.draggableProps.style?.transform?.replace(
                                  /\(.+,/,
                                  '(0,'
                                )
                              : provided.draggableProps.style?.transform,
                          }}
                        >
                          <div {...provided.dragHandleProps}>
                            {hasMultiple && (
                              <DragIcon className={el`drag-icon`} />
                            )}
                          </div>
                          <Mobile className={el`input-row-wrapper`}>
                            <TransactionHashInput
                              {...transactionHashInputProps}
                              direction="column"
                            />
                          </Mobile>
                          <Tablet className={el`input-row-wrapper`}>
                            <TransactionHashInput
                              {...transactionHashInputProps}
                              direction="row"
                            />
                          </Tablet>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className={el`actions-wrapper`}>
          <Button
            variant="text-white"
            startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
            onPress={() => {
              p.onAddField();
            }}
            className={el`add-button`}
            size="custom"
            isDisabled={p.isDisabled}
          >
            Add Transaction Hash
          </Button>
          <BulkAddModal
            type="transaction"
            onSubmit={(value) => {
              p.onAddFields(value);
            }}
          />
        </div>
      </div>
    </DragDropContext>
  );
}

export default MultiTransactionHashForm;
