import { ChainKind } from '@/generated/graphql';
import { Address } from './address';

export const getBlockExplorerAddressDetailUrl = (address: Address): string => {
  switch (address.chain) {
    case ChainKind.SOL:
      return `https://solana.fm/address/${address.address}`;
    case ChainKind.AVALANCHE:
      return `https://avascan.info/blockchain/all/address/${address.address}`;
    default:
      throw new Error(`Block Explorer for Chain not found: ${address.chain}`);
  }
};

export const getBlockExplorerNameByAddress = (address: Address): string => {
  switch (address.chain) {
    case ChainKind.SOL:
      return `SolanaFM`;
    case ChainKind.AVALANCHE:
      return `Avascan`;
    default:
      throw new Error(`Block Explorer for Chain not found: ${address.chain}`);
  }
};

/**
 * Partnered chains are those that have a block explorer that we can link to.
 */
const partneredChains = [ChainKind.SOL, ChainKind.AVALANCHE];

export const hasBlockExplorerPartner = (chain: ChainKind): boolean => {
  return partneredChains.includes(chain);
};
