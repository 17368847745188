import * as cookie from 'cookie';
import isServer from '@/utils/isServer';

const PRIVACY_POLICY_CONSENT_COOKIE_NAME = 'privacy_policy_consent_is_accepted';
const ONE_WEEK_IN_SECONDS = 7 * 24 * 60 * 60;

export const getIsAcceptedPrivacyPolicyCookieValue = (): boolean => {
  if (!isServer()) {
    const cookies = cookie.parse(document.cookie);
    const isAccepted = cookies[PRIVACY_POLICY_CONSENT_COOKIE_NAME];
    return isAccepted === 'true';
  }
  return false;
};

export const setIsAcceptedPrivacyPolicyCookie = (isAccepted: boolean): void => {
  if (!isServer()) {
    document.cookie = cookie.serialize(
      PRIVACY_POLICY_CONSENT_COOKIE_NAME,
      isAccepted.toString(),
      {
        maxAge: ONE_WEEK_IN_SECONDS,
        path: '/',
        sameSite: 'lax',
        domain: process.env.NEXT_PUBLIC_COOKIE_DOMAIN || undefined,
      }
    );
  }
};
