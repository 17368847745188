import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import { Link, Modal, Text } from '@/components';
import { useRequestOTP } from '@/features/auth/hooks';
import { useMe } from '@/hooks';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import type { DialogProps } from '@radix-ui/react-dialog';

export type VerificationModalProps = StyleProps &
  DialogProps & {
    /** The email of the user that needs to be verified */
    email: string;
    /** Handler to call when user requests email to be resent */
    onRequestResend: () => void;
  };

const ROOT = makeRootClassName('VerificationModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function VerificationModal(props: VerificationModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const [isOpen, setIsOpen] = useState(true);
  const [resentEmail, setResentEmail] = useState(false);

  return (
    <div className={clsx(ROOT, p.className)}>
      <Modal.Root
        open={isOpen}
        onOpenChange={(open) => {
          setIsOpen(open);
          p.onOpenChange?.(open);
        }}
      >
        <Modal.Content size="small">
          <Modal.Header title="Confirm your email" hasCloseButton />
          <main className={el`body`}>
            <Text>
              Please confirm your email before submitting your first report. You
              can verify your email by clicking the link we sent you.
            </Text>
            <Text type="h5" className="text-trm-gray-1">
              {resentEmail ? (
                <>Resent!</>
              ) : (
                <>
                  Not getting the email?{' '}
                  <Link
                    size="xs"
                    onPress={() => {
                      setResentEmail(true);
                      p.onRequestResend();
                    }}
                  >
                    Resend the email
                  </Link>{' '}
                </>
              )}
            </Text>
          </main>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

type VerificationModalContainerProps = Pick<
  VerificationModalProps,
  'onOpenChange'
>;

const VerificationModalContainer = (
  props: VerificationModalContainerProps
): ReactElement => {
  const { me, loading } = useMe();
  const { requestOTP } = useRequestOTP();

  if (loading) {
    return <></>;
  }

  // don't show modal if:
  // not auth'd or auth'd && verified or doesn't have email to verify
  if (!me || me.isVerified || !me.email) return <></>;

  return (
    <VerificationModal
      email={me.email}
      onRequestResend={() => {
        requestOTP();
      }}
      onOpenChange={(open) => {
        props.onOpenChange?.(open);
      }}
    />
  );
};
export default VerificationModalContainer;
