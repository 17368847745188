import { NextPage } from 'next';
import { AppProps } from 'next/app';
import React, { ReactElement, ReactNode } from 'react';
import { install } from 'resize-observer';
import { GoogleTagManager } from '@/hooks';
import { PrivacyPolicyModal } from '@/features/privacy-policy-modal';
import { RouteProgressBar, SeoWrapper } from '@/features';
import '../styles/all.scss';
import AppProvider from '../utils/AppProvider';
import isServer from '../utils/isServer';

type NextPageWithLayout = NextPage & {
  getLayout?: (page: ReactElement) => ReactNode;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithLayout;
};

function App({ Component, pageProps }: AppPropsWithLayout): React.ReactNode {
  if (!isServer()) {
    install();
  }

  // use layout defined at page level, if available
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <AppProvider {...pageProps}>
      <SeoWrapper />
      <RouteProgressBar />
      <GoogleTagManager />
      {getLayout(<Component {...pageProps} />)}
      <PrivacyPolicyModal />
    </AppProvider>
  );
}

export default App;
