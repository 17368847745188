import { sanitizeUrl } from '@braintree/sanitize-url';
import { $isLinkNode, TOGGLE_LINK_COMMAND } from '@lexical/link';
import { mergeRegister } from '@lexical/utils';
import clsx from 'clsx';
import {
  $getSelection,
  $isRangeSelection,
  LexicalEditor,
  RangeSelection,
  SELECTION_CHANGE_COMMAND,
} from 'lexical';
import { ReactElement, useCallback, useEffect, useRef, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, Link, TextField } from '@/components';
import { createCheck, createEdit } from '@/assets/icons';
import { EditorCommandPriority } from '../../types';
import { getSelectedNode, getSelectionRect } from '../../utils';

export type LinkEditorProps = StyleProps & {
  /**
   * The Lexical editor instance
   */
  editor: LexicalEditor;
};

const ROOT = makeRootClassName('LinkEditor');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function positionEditorElement(
  container: HTMLElement,
  linkEditor: HTMLDivElement,
  rect?: DOMRect
) {
  const containerRect = container.getBoundingClientRect();
  if (rect !== undefined) {
    const horizontalAlignment = rect.left - containerRect.left;
    const maxLeftOffset = containerRect.right - linkEditor.offsetWidth;
    const verticalAlignmentAbove = rect.top + 10 - containerRect.top;
    const verticalAlignmentBelow = rect.bottom + 10 - containerRect.top;
    const maxTopOffset =
      window.innerHeight - linkEditor.offsetHeight - 10 - containerRect.top;

    linkEditor.style.display = '';
    linkEditor.style.top = `${
      maxTopOffset < verticalAlignmentBelow
        ? verticalAlignmentAbove
        : verticalAlignmentBelow
    }px`;
    linkEditor.style.left = `${Math.max(
      0,
      Math.min(horizontalAlignment, maxLeftOffset)
    )}px`;
  }
}

function hideEditorElement(linkEditor: HTMLDivElement) {
  linkEditor.style.display = 'none';
}

export const DEFAULT_URL = 'https://';

function LinkEditor(props: LinkEditorProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const ref = useRef<HTMLDivElement>(null);
  const [linkUrl, setLinkUrl] = useState('');
  const [isEditMode, setEditMode] = useState(false);
  const [lastSelection, setLastSelection] =
    useState<null | RangeSelection>(null);
  const [validationError, setValidationError] =
    useState<string | undefined>(undefined);

  const onPositionChange = useCallback(() => {
    const selection = $getSelection();
    if ($isRangeSelection(selection)) {
      const node = getSelectedNode(selection);

      const parent = node.getParent();
      if ($isLinkNode(parent)) {
        setLinkUrl(parent.getURL());
      } else if ($isLinkNode(node)) {
        setLinkUrl(node.getURL());
      } else {
        setLinkUrl('');
      }
    }
    const linkEditorElement = ref.current;
    const nativeSelection = window.getSelection();
    const activeElement = document.activeElement;

    if (linkEditorElement === null) {
      return;
    }

    const rootElement = p.editor.getRootElement();

    if (
      selection !== null &&
      !nativeSelection?.isCollapsed &&
      rootElement !== null &&
      rootElement.contains(nativeSelection?.anchorNode ?? null)
    ) {
      const rect = getSelectionRect(rootElement);

      if (rect) {
        positionEditorElement(
          rootElement.parentNode as HTMLElement,
          linkEditorElement,
          rect
        );
      }
      setLastSelection(selection as RangeSelection);
    } else if (
      !activeElement ||
      activeElement.className !== clsx(ROOT, p.className)
    ) {
      hideEditorElement(linkEditorElement);
      setLastSelection(null);
      setEditMode(false);
      setLinkUrl('');
    }
  }, [p.editor, setLastSelection, setEditMode, setLinkUrl]);

  useEffect(() => {
    onPositionChange();
  }, []);

  useEffect(() => {
    return mergeRegister(
      p.editor.registerUpdateListener(({ editorState }) => {
        editorState.read(() => {
          onPositionChange();
        });
      }),

      p.editor.registerCommand(
        SELECTION_CHANGE_COMMAND,
        () => {
          onPositionChange();
          return true;
        },
        EditorCommandPriority.LowPriority
      )
    );
  }, [p.editor, onPositionChange]);

  const saveLink = useCallback(
    (linkUrl: string) => {
      const hasLinkValue = linkUrl !== '' && linkUrl !== DEFAULT_URL;
      if (hasLinkValue && sanitizeUrl(linkUrl) !== linkUrl) {
        setValidationError('Make sure your link is a valid url.');
        return;
      } else {
        p.editor.dispatchCommand(
          TOGGLE_LINK_COMMAND,
          hasLinkValue ? linkUrl : null
        );
        setEditMode(false);
      }
    },
    [p.editor, setEditMode, setValidationError]
  );

  return (
    <div ref={ref} className={clsx(ROOT, p.className)}>
      <TextField
        placeholder={DEFAULT_URL}
        value={linkUrl}
        isDisabled={!isEditMode}
        size="small"
        validationState={validationError ? 'invalid' : 'valid'}
        className={clsx(el`input`, {
          [el`editing`]: isEditMode,
        })}
        aria-label="Editable text link"
        onChange={setLinkUrl}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            event.preventDefault();
            if (lastSelection !== null) {
              saveLink(linkUrl);
            }
          } else if (event.key === 'Escape') {
            event.preventDefault();
            setEditMode(false);
          } else {
            setValidationError(undefined);
          }
        }}
      />
      {isEditMode && validationError && (
        <span className={el`error`}>{validationError}</span>
      )}
      {!isEditMode && (
        <Link
          href={linkUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={el`floating-link`}
        >
          {linkUrl}
        </Link>
      )}
      <Button
        size="small"
        isDisabled={
          isEditMode
            ? linkUrl === '' || linkUrl === DEFAULT_URL || !!validationError
            : false
        }
        startIcon={isEditMode ? createCheck : createEdit}
        onPress={() => {
          if (isEditMode) {
            saveLink(linkUrl);
          } else {
            setEditMode(!isEditMode);
          }
        }}
      />
    </div>
  );
}

export default LinkEditor;
