import clsx from 'clsx';
import { ReactElement } from 'react';
import { Button, DragDropContext, Draggable, Droppable } from '@/components';
import { FeatherIconName } from '@/components/icon';
import { AddressDomainInput } from '@/features/address-domain-input';
import type { AddressDomainInputProps } from '@/features/address-domain-input/AddressDomainInput';
import { ChainDiscoverabilityProps, ChainType } from '@/types/chain';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Mobile, Tablet } from '@/utils/responsive';
import { AddressDomainField } from '../../types';
import { BulkAddModal } from '../bulk-add-modal';
import { DragIcon } from '../drag-icon';

export type MultiAddressFormProps = StyleProps &
  ChainDiscoverabilityProps & {
    isDisabled?: boolean;
    showUrlInput?: boolean;
    placeholder?: string;
    addButtonText?: string;
    addressDomainFields: AddressDomainField[];
    onAddField: (value?: string) => void;
    onAddFields: (values?: string[]) => void;
    onRemoveField: (index: number) => void;
    onUpdateFieldValue: (index: number, value: string) => void;
    onUpdateFieldChain: (index: number, chain?: ChainType) => void;
    updateFieldOrder: (fromIndex: number, toIndex: number) => void;
    onToggleFieldIsValid: (index: number, isValid: boolean) => void;
    updateAddressLabel: (
      index: number,
      value?: AddressDomainField['label']
    ) => void;
  };

const ROOT = makeRootClassName('MultiAddressForm');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  includeUndiscoverableChains: false,
  addButtonText: 'Add Address or Domain',
  showUrlInput: false,
} as const;

function MultiAddressForm(props: MultiAddressFormProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const hasMultiple = p.addressDomainFields.length > 1;

  return (
    <DragDropContext
      onDragEnd={({ destination, source }) => {
        if (destination && hasMultiple) {
          p.updateFieldOrder(source.index, destination.index);
        }
      }}
    >
      <div
        className={clsx(
          ROOT,
          {
            'has-multiple': hasMultiple,
          },
          p.className
        )}
      >
        <div className={el`addresses-wrapper`}>
          <Droppable droppableId="drop-address" isDropDisabled={p.isDisabled}>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {p.addressDomainFields.map((field, index) => {
                  const addressDomainInputProps: AddressDomainInputProps = {
                    value: field.value,
                    initialChain: field.chain,
                    addressLabel: field.label,
                    showChainSelectUpfront: !p.showUrlInput,
                    showAddressLabelInput: !p.showUrlInput,
                    isMistakenAddress: field.isMistakenAddress,
                    onChange: (value: string) =>
                      props.onUpdateFieldValue(index, value),
                    onChainChanged: (chain?: ChainType) =>
                      props.onUpdateFieldChain(index, chain),
                    onAddressLabelChanged: (
                      value: AddressDomainField['label']
                    ) => props.updateAddressLabel(index, value),
                    onValidationStateChanged: (isValid: boolean) =>
                      props.onToggleFieldIsValid(index, isValid),
                    includeUndiscoverableChains: p.includeUndiscoverableChains,
                    showRemoveFieldButton: index !== 0,
                    onRemoveField: () => props.onRemoveField(index),
                    isDisabled: p.isDisabled,
                    placeholder: p.placeholder,
                    showUrlInput: p.showUrlInput,
                  };

                  return (
                    <Draggable
                      key={field.key}
                      draggableId={field.key}
                      index={index}
                    >
                      {(provided, dragSnapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={el`address-row`}
                          style={{
                            ...provided.draggableProps.style,
                            transform: dragSnapshot.isDragging
                              ? provided.draggableProps.style?.transform?.replace(
                                  /\(.+,/,
                                  '(0,'
                                )
                              : provided.draggableProps.style?.transform,
                          }}
                        >
                          <div {...provided.dragHandleProps}>
                            {hasMultiple && (
                              <DragIcon className={el`drag-icon`} />
                            )}
                          </div>
                          <Mobile className={el`input-row-wrapper`}>
                            <AddressDomainInput
                              {...addressDomainInputProps}
                              direction="column"
                            />
                          </Mobile>
                          <Tablet className={el`input-row-wrapper`}>
                            <AddressDomainInput
                              {...addressDomainInputProps}
                              direction="row"
                            />
                          </Tablet>
                        </div>
                      )}
                    </Draggable>
                  );
                })}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div className={el`actions-wrapper`}>
          <Button
            variant="text-white"
            startFeatherIcon={FeatherIconName.CIRCLE_PLUS}
            onPress={() => {
              p.onAddField();
            }}
            className={el`add-button`}
            size="custom"
            isDisabled={p.isDisabled}
          >
            {p.addButtonText}
          </Button>
          {!p.showUrlInput &&
            <BulkAddModal
              type="address"
              onSubmit={(value) => {
                p.onAddFields(value);
              }}
            />
          }
        </div>
      </div>
    </DragDropContext>
  );
}

export default MultiAddressForm;
