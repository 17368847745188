import clsx from 'clsx';
import { ReactElement } from 'react';
import { useHover } from '@react-aria/interactions';
import { Text } from '@/components';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type PrivateReportTooltipProps = StyleProps & {
  // add props here
  isAuthor: boolean;
};

const ROOT = makeRootClassName('PrivateReportTooltip');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

export function PrivateReportTooltip(
  props: PrivateReportTooltipProps
): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };
  const { hoverProps, isHovered } = useHover({});

  return (
    <div
      className={clsx(ROOT, p.className, {
        'is-hovered': isHovered,
        'is-author': p.isAuthor,
      })}
      {...hoverProps}
    >
      <div className={el`hover-tip`}>
        <Text type="body-sm" className={el`hover-tip-text`}>
          For security reasons and to protect investigations, this report is
          currently only shared with Law Enforcement Partners.
        </Text>
      </div>
    </div>
  );
}
export default PrivateReportTooltip;
