import { useUsernameAvailableLazyQuery } from '@/generated/graphql';

type UseUsernameAvailableQuery = {
  isUsernameAvailable: (username: string) => Promise<boolean>;
};
const useUsernameAvailable = (): UseUsernameAvailableQuery => {
  const [usernameAvailableQuery, queryData] = useUsernameAvailableLazyQuery({
    fetchPolicy: 'network-only',
  });

  /** username can be username or email */
  const isUsernameAvailable = async (username: string): Promise<boolean> => {
    const { data, error } = await usernameAvailableQuery({
      variables: {
        input: {
          username,
        },
      },
    });

    if (error) {
      throw error;
    }

    return data?.usernameAvailable.available ?? false;
  };

  return { isUsernameAvailable };
};

export default useUsernameAvailable;
