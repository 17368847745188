import clsx from 'clsx';
import HTMLReactParser from 'html-react-parser';
import { SerializedEditorState } from 'lexical';
import { ReactElement } from 'react';
import sanitizeHtml from 'sanitize-html';
import { parseNodesToHtml } from '@/utils/parseNodesToHtml';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';

export type LexicalViewerProps = StyleProps & {
  /**
   * Serialized lexical editor state
   */
  serializedEditorState?: SerializedEditorState;

  /**
   * fallbackText to be rendered incase SerializedEditorState is not provided
   */
  fallbackText?: string;
};

const ROOT = makeRootClassName('LexicalViewer');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {
  fallback: '',
} as const;

/**
 * Component renders rich text view of given serializedEditorState from
 * lexical editor
 */
function LexicalViewer(props: LexicalViewerProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const html = p.serializedEditorState
    ? parseNodesToHtml(p.serializedEditorState)
    : '';

  const sanitizedHtml = sanitizeHtml(html, {
    // only allow classes which starts with create-Editor this is
    // needed to respect editorTheme, without this the rendered output
    // won't look like what user sees in <Editor/> component
    allowedClasses: {
      '*': ['create-Editor*'],
    },
  });

  return (
    <div className={clsx(ROOT, p.className)}>
      {sanitizedHtml ? HTMLReactParser(sanitizedHtml) : p.fallbackText}
    </div>
  );
}

export default LexicalViewer;
