import { nanoid } from 'nanoid';
import { useReducer, useEffect } from 'react';
import { CompromiseIndicatorKind } from '@/types/compromise-indicators';
import { CompromiseIndicatorField, ValidCompromiseIndicator } from '../types';
import { reorder } from '../utils/reorder';

type UseCompromiseIndicatorsOptions = {
  /** Initial fields to populate */
  initialFields?: CompromiseIndicatorField[];

  /** Wheter the data for the initial fields is loading */
  isLoading?: boolean;
};

type UseCompromiseIndicatorsState = {
  /** The fields */
  fields: CompromiseIndicatorField[];

  /** The sanitized fields */
  sanitizedCompromiseIndicatorFields: ValidCompromiseIndicator[];
};

enum ReducerActionType {
  ADD_FIELD = 'ADD_FIELD',
  REMOVE_FIELD = 'REMOVE_FIELD',
  UPDATE_FIELD_VALUE = 'UPDATE_FIELD_VALUE',
  UPDATE_FIELD_TYPE = 'UPDATE_FIELD_TYPE',
  UPDATE_FIELD_ORDER = 'UPDATE_FIELD_ORDER',
  RESET_FIELDS = 'RESET_FIELDS',
}

type AddFieldAction = {
  type: ReducerActionType.ADD_FIELD;
};

type RemoveFieldAction = {
  type: ReducerActionType.REMOVE_FIELD;
  index: number;
};

type UpdateFieldValueAction = {
  type: ReducerActionType.UPDATE_FIELD_VALUE;
  index: number;
  value: string;
};

type UpdateFieldTypeAction = {
  type: ReducerActionType.UPDATE_FIELD_TYPE;
  index: number;
  value?: CompromiseIndicatorKind;
};

type UpdateFieldOrderAction = {
  type: ReducerActionType.UPDATE_FIELD_ORDER;
  fromIndex: number;
  toIndex: number;
};

type ResetFieldsAction = {
  type: ReducerActionType.RESET_FIELDS;
  fields: CompromiseIndicatorField[];
};

type ReducerAction =
  | AddFieldAction
  | RemoveFieldAction
  | UpdateFieldValueAction
  | UpdateFieldTypeAction
  | UpdateFieldOrderAction
  | ResetFieldsAction;

type UseCompromiseIndicatorsActions = {
  addField: () => void;
  removeField: (index: number) => void;
  updateFieldValue: (index: number, value: string) => void;
  updateFieldType: (index: number, type?: CompromiseIndicatorKind) => void;
  updateFieldOrder: (fromIndex: number, toIndex: number) => void;
};

export type UseCompromiseIndicatorsValue = UseCompromiseIndicatorsState &
  UseCompromiseIndicatorsActions;

const getInitialCompromiseIndicatorFieldValue =
  (): CompromiseIndicatorField => ({
    key: nanoid(),
    value: '',
    type: CompromiseIndicatorKind.IP, // set IP as the default compromise indicator type
  });

const useCompromiseIndicators = (
  props?: UseCompromiseIndicatorsOptions
): UseCompromiseIndicatorsValue => {
  const [fields, dispatch] = useReducer(
    (prev: CompromiseIndicatorField[], action: ReducerAction) => {
      switch (action.type) {
        case ReducerActionType.ADD_FIELD:
          return [...prev, getInitialCompromiseIndicatorFieldValue()];
        case ReducerActionType.REMOVE_FIELD:
          return prev.filter((_, index) => index !== action.index);
        case ReducerActionType.UPDATE_FIELD_VALUE:
          return prev.map((field, index) =>
            index === action.index ? { ...field, value: action.value } : field
          );
        case ReducerActionType.UPDATE_FIELD_TYPE:
          return prev.map((field, index) =>
            index === action.index ? { ...field, type: action.value } : field
          );
        case ReducerActionType.UPDATE_FIELD_ORDER:
          return reorder(prev, action.fromIndex, action.toIndex);
        case ReducerActionType.RESET_FIELDS:
          return action.fields;
        default:
          return prev;
      }
    },
    props?.initialFields ?? [
      // start with one empty field
      getInitialCompromiseIndicatorFieldValue(),
    ]
  );

  const actions: UseCompromiseIndicatorsActions = {
    addField: () => {
      dispatch({ type: ReducerActionType.ADD_FIELD });
    },
    removeField: (index: number) => {
      if (index === 0) {
        throw new Error(
          'Cannot remove the first indicator of compromise input field'
        );
      }
      dispatch({ type: ReducerActionType.REMOVE_FIELD, index });
    },
    updateFieldValue: (index: number, value: string) => {
      dispatch({ type: ReducerActionType.UPDATE_FIELD_VALUE, index, value });
    },
    updateFieldType: (index: number, value?: CompromiseIndicatorKind) => {
      dispatch({ type: ReducerActionType.UPDATE_FIELD_TYPE, index, value });
    },
    updateFieldOrder: (fromIndex: number, toIndex: number) => {
      dispatch({
        type: ReducerActionType.UPDATE_FIELD_ORDER,
        fromIndex,
        toIndex,
      });
    },
  };

  const sanitizedCompromiseIndicatorFields = fields.reduce<
    ValidCompromiseIndicator[]
  >((acc, indicator) => {
    const { id, type, value } = indicator;
    if (type && value) {
      acc.push({ id, type, value });
    }
    return acc;
  }, []);

  useEffect(() => {
    if (props?.initialFields) {
      dispatch({
        type: ReducerActionType.RESET_FIELDS,
        fields: props.initialFields,
      });
    }
  }, [props?.isLoading]);

  return {
    fields,
    sanitizedCompromiseIndicatorFields,
    ...actions,
  };
};

export default useCompromiseIndicators;
