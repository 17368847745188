import clsx from 'clsx';
import React, { ReactElement } from 'react';
import { makeRootClassName, StyleProps } from '@/utils';
import { Icon } from '@/components';
import { svgDrag } from '@/assets/icons';

export type DragIconProps = StyleProps;

const ROOT = makeRootClassName('DragIcon');

const DEFAULT_PROPS = {} as const;

function DragIcon(props: DragIconProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Icon viewBoxHeight={26} viewBoxWidth={11} content={svgDrag} />
    </div>
  );
}

export default DragIcon;
