import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { ButtonBase, Tooltip } from '..';
import { FeatherIconName } from '../icon';

export type CopyButtonProps = StyleProps & {
  textToCopy: string;
};

const ROOT = makeRootClassName('CopyButton');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function CopyButton(props: CopyButtonProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(p.textToCopy);
    setIsTooltipOpen(true);
    setTimeout(() => {
      setIsTooltipOpen(false);
    }, 1000);
  };

  return (
    <div className={clsx(ROOT, p.className)}>
      <Tooltip content="Copied!" isInstant open={isTooltipOpen}>
        <ButtonBase
          startFeatherIcon={
            isTooltipOpen ? FeatherIconName.CHECK : FeatherIconName.COPY
          }
          variant="custom"
          size="small"
          onPress={handleCopy}
          startIconSize="small"
          isDisabled={isTooltipOpen}
          aria-label="Copy to clipboard"
        />
      </Tooltip>
    </div>
  );
}

export default CopyButton;
