import { ReactElement, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useCookieConsent } from '@/providers';

/**
 * A component responsible for initializing Google Tag Manager.
 *
 * NOTE: This component does not handle anything related to page changes,
 * and if this is running in a SPA context, the Google Tag Manager integrator
 * must impelement it inside of their GTA instance via triggers. For more
 * information {@see https://support.google.com/tagmanager/answer/7679322?hl=en}.
 */
function useGoogleTagManager(): void {
  const {
    state: { isAccepted: isCookieConsentAccepted },
  } = useCookieConsent();

  // run in effect so it only runs on the client and not on server
  useEffect(() => {
    if (process.env.NODE_ENV === 'production' && isCookieConsentAccepted) {
      TagManager.initialize({
        gtmId: process.env.NEXT_PUBLIC_GOOGLE_TAG_MANAGER_ID || 'GTM-K7JZL3W',
      });
    }
  }, [isCookieConsentAccepted]);
}

export enum GTMEvent {
  USER_SIGN_UP = 'user-sign-up',
  SCAM_REPORT_FILED = 'scam-report-filed',
}

/**
 * Convenience function for sending a message to Google Tag Manager.
 *
 * @param {string} event Name of the event.
 * @param {object} data Optional object containing variables to be sent.
 */
export function trackGTMEvent(
  event: string,
  data?: Record<string, unknown>
): void {
  const dataLayerArgs = {
    dataLayer: {
      event,
      ...data,
    },
  };

  TagManager.dataLayer(dataLayerArgs);
}

/**
 * Wrapper component over the `useGoogleTagManager` hook.
 */
export function GoogleTagManager(): ReactElement {
  useGoogleTagManager();

  return <></>;
}

export default useGoogleTagManager;
