import clsx from 'clsx';
import React, { ReactElement, useState } from 'react';
import {
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { ScamQuizCta } from '../scam-quiz-cta';
import { ScamQuizModal } from '../scam-quiz-modal';
import { ScamQuizModalProps } from '../scam-quiz-modal/ScamQuizModal';

export type ScamQuizProps = StyleProps &
  Pick<ScamQuizModalProps, 'onSelectCategory'>;

const ROOT = makeRootClassName('ScamQuiz');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

function ScamQuiz(props: ScamQuizProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const [scamQuizModalOpen, setScamQuizModalOpen] = useState(false);

  return (
    <div className={clsx(ROOT, p.className)}>
      <ScamQuizCta onPress={() => setScamQuizModalOpen(true)} />
      <ScamQuizModal
        open={scamQuizModalOpen}
        onOpenChange={setScamQuizModalOpen}
        onSelectCategory={p.onSelectCategory}
      />
    </div>
  );
}

export default ScamQuiz;
