import clsx from 'clsx';
import React, { ReactElement } from 'react';
import {
  buttonize,
  makeElementClassNameFactory,
  makeRootClassName,
  StyleProps,
} from '@/utils';
import { Button, InteractableComponent, Modal, Text } from '@/components';
import {
  getDescriptionForScamCategory,
  getDisplayStringForScamCategory,
  ScamCategory,
} from '@/types/scam-categories';
import { ModalRootProps } from '@/components/modal';
import { createChevronLeft } from '@/assets/icons';
import { useScamQuiz } from '../../hooks/useScamQuiz';

export type ScamQuizModalProps = StyleProps &
  Pick<ModalRootProps, 'open' | 'onOpenChange'> & {
    onSelectCategory: (category: ScamCategory) => void;
  };

const ROOT = makeRootClassName('ScamQuizModal');
const el = makeElementClassNameFactory(ROOT);

const DEFAULT_PROPS = {} as const;

type OptionProps = {
  title: string;
  onPress: () => void;
  selected: boolean;
};

function Option({ title, onPress, selected }: OptionProps) {
  return (
    <InteractableComponent asChild preventFocusOnPress>
      <div
        {...buttonize(onPress)}
        className={clsx(el`option`, {
          'is-selected': selected,
        })}
      >
        <Text type="body-md">{title}</Text>
      </div>
    </InteractableComponent>
  );
}

function ScamQuizModal(props: ScamQuizModalProps): ReactElement {
  const p = { ...DEFAULT_PROPS, ...props };

  const {
    title,
    options,
    selectedOption,
    category,
    onOptionPressed,
    handleBack,
    handleNext,
    isBackDisabled,
    isNextDisabled,
    handleModalOpenChange,
  } = useScamQuiz({
    onOpenChange: p.onOpenChange,
    onSelectCategory: p.onSelectCategory,
  });

  return (
    <div className={clsx(ROOT, p.className)}>
      <Modal.Root
        open={p.open}
        onOpenChange={handleModalOpenChange}
        className={el`modal`}
      >
        <Modal.Content className={el`modal-content`}>
          <Modal.Header title="Scam Quiz" hasCloseButton />

          {!category && (
            <div>
              <Text type="h4" className={el`title`}>
                {title}
              </Text>
              <Text type="body-md" className={el`subtitle`}>
                Choose the scenario that best fits your case.
              </Text>
            </div>
          )}

          {category ? (
            <div className={el`category-info`}>
              <Text type="body-md" className={el`category-subtitle`}>
                It seems like you are trying to report a
              </Text>
              <Text type="h4" className={el`category`}>
                {getDisplayStringForScamCategory(category)}
              </Text>
              <Text type="body-md" className={el`category-description`}>
                {getDescriptionForScamCategory(category)}
              </Text>

              <Text type="body-md" className={el`footer-text`}>
                Please close this window and continue with your report.
              </Text>
            </div>
          ) : (
            <>
              {options?.length && (
                <div className={el`options`}>
                  {options.map(
                    (option) =>
                      option.option && (
                        <Option
                          key={option.option}
                          title={option.option}
                          onPress={() => onOptionPressed(option)}
                          selected={option.option === selectedOption}
                        />
                      )
                  )}
                </div>
              )}
            </>
          )}

          <Modal.Footer className={el`modal-footer`}>
            <Button
              startIcon={createChevronLeft}
              variant="text-white"
              onPress={handleBack}
              isDisabled={isBackDisabled}
            >
              Back
            </Button>
            <Modal.FooterButton
              onPress={handleNext}
              isDisabled={isNextDisabled}
            >
              {category ? 'Close' : 'Next'}
            </Modal.FooterButton>
          </Modal.Footer>
        </Modal.Content>
      </Modal.Root>
    </div>
  );
}

export default ScamQuizModal;
